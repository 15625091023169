import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonEditor from "../../../../components/commonEditor/commonEditor";

function GulfAddBlogs() {
  const [content, setContent] = useState("");
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const initialValues = {
    title: "",
    content: "",
    image: null,
  };

  const MAX_LENGTH = 9000;

  const handleEditorChange = (value, setFieldValue) => {
    if (value <= MAX_LENGTH) {
      setContent(value);
      setFieldValue("content", value);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", values.content);
    if (values.image) {
      formData.append("image", values.image);
    }

    // Handle form submission, e.g., sending formData to an API endpoint
    console.log(formData);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add Blogs"} backButton={true} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <Field type="text" name="title" className="form-control" />
            </div>
            <div className="image-upload-section mb-4">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="d-none"
                id="imageUpload"
              />
              <label htmlFor="imageUpload" className="image-upload-label">
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded"
                    // className="img-fluid rounded-circle"
                    style={{
                      width: "200px",
                      padding: "10px",
                      border: "1px solid",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="image-placeholder">
                    <span>Upload Image</span>
                  </div>
                )}
              </label>
            </div>
            <div className="form-group">
              <CommonEditor
                value={content}
                onChange={(value) => handleEditorChange(value, setFieldValue)}
                maxLength={MAX_LENGTH}
                label="Content"
              />
            </div>

            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GulfAddBlogs;
