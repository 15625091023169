import { ErrorMessage } from "formik";
import React from "react";
import thumbnail from "../assests/svg/thumbnail.svg";

const ImageUpload = ({
  mainClass,
  previewConClass,
  previewImage,
  type = "file",
  placeholder = "Profile Picture",
  id,
  onChange,
  inputClass = "",
  name,
  errComp = "div",
  errClass = "error",
  style = { color: "red" },
  label = "Upload Profile",
  labelClass = "global_admin_btn"
}) => {
  return (
    <div className={mainClass}>
      <div className={previewConClass}>
        {previewImage ? (
          <img src={previewImage} alt="Preview" className="thumbnail" />
        ) : (
          <img src={thumbnail} alt="Thumbnail" className="thumbnail" />
        )}
      </div>
      <div>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          className={inputClass}
        />
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
        <ErrorMessage
          name={name}
          component={errComp}
          className={errClass}
          style={style}
        />
      </div>
    </div>
  );
};

export default ImageUpload;
