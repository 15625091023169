import React from "react";
import "./dashboardgulf.css"; // Import CSS file for styling


const CardGulf = ({ title, subtitle, content }) => {
  return (
    <div className="card cards dashboard-cards py-3" style={{}}>
      <div className="card-body">
      <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>
        <h5 className="card-title">{title}</h5>
      
        {/* <p className="card-text">{content}</p> */}
        {/* <a href="#" className="card-link">
      
        </a>
        <a href="#" className="card-link">
        
        </a> */}
      </div>
    </div>
  );
};

export default CardGulf;
