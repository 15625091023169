import React, { useEffect, useState } from "react";
import "./dashboardgulf.css"; // Import CSS file for styling
import { CgNotes } from "react-icons/cg";
import { BiCategory } from "react-icons/bi";
import { MdManageAccounts } from "react-icons/md";
import CardGulf from "./cardGulf";
import BarChartGulf from "./barchartGulf";
import { getAdminDashboard } from "../../services/admin/manageworkforce/manageworkforceservices";
import UserCountBar from "./usercountBar";

const DashboardGulf = () => {
  const [dashboardData, setDashboardData] = useState({
    monthlyWorkforce: [],
    workforceEnquiry: 0,
    datalakeUser: 0,
    nationalityUserCount: [] // Added state for nationalityUserCount
  });
  const [selectedYear1, setSelectedYear1] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await getAdminDashboard();
        if (response.status) {
          const { monthlyWorkforce, nationalityUserCount } = response;
          setDashboardData({
            ...response,
            monthlyWorkforce: monthlyWorkforce || [],
            nationalityUserCount: nationalityUserCount || []
          });
          const years = [...new Set(monthlyWorkforce.map(item => item.year))];
          if (years.length > 0) setSelectedYear1(years[0]);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  if (!dashboardData || !Array.isArray(dashboardData.monthlyWorkforce) || !Array.isArray(dashboardData.nationalityUserCount)) {
    return <div>Loading...</div>;
  }

  const { monthlyWorkforce, nationalityUserCount } = dashboardData;
  const years = [...new Set(monthlyWorkforce.map(item => item.year))];
  const filteredWorkforceData = monthlyWorkforce.filter(item => item.year === selectedYear1);

  return (
    <div className="dashboard_page">
      <div className="row">
        <div className="col-md-4 p-relative">
          <CardGulf
            title="Workforce"
            subtitle={dashboardData.workforce}
            content="Some quick example text to build on the card title and make up the bulk of the card's content."
          />
          <span className="dash-icons">
            <MdManageAccounts />
          </span>
        </div>
        <div className="col-md-4 p-relative">
          <CardGulf
            title="Total Profiles"
            subtitle={dashboardData.datalakeUser}
            content="Some quick example text to build on the card title and make up the bulk of the card's content."
          />
          <span className="dash-icons">
            <BiCategory />
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-2">
          <div className="px-5 py-3 bg-light shadow rounded">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-4">Workforce</h3>
              <div className="filtersec">
                <label htmlFor="year-select-1">Select Year:</label>
                <select
                  id="year-select-1"
                  value={selectedYear1}
                  onChange={(e) => setSelectedYear1(parseInt(e.target.value))}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <BarChartGulf data={filteredWorkforceData} year={selectedYear1} />
          </div>
        </div>
        <div className="col-md-6 mt-2">
          <div className="px-5 py-3 bg-light shadow rounded">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-4">Users</h3>
            </div>
            <UserCountBar data={nationalityUserCount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardGulf;
