import React from "react";

export const LogoSvg = (navigate) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 585.2 175.3"
    style={{ enableBackground: "new 0 0 585.2 175.3" }}
  >
    <style type="text/css">
      {`.st0{fill:#5D5D5D;}
	.st1{fill:#00BDA7;}
	.st2{fill:#05D6B8;}`}
    </style>
    <g>
      <path
        className="st0"
        d="M74.7,111.4c-1.3-1.3-2.6-2.9-3.7-4.6c-1.1,1.7-2.3,3.3-3.7,4.6c-2.5,2.5-5.4,4.4-8.6,5.7
		c-3.2,1.3-6.6,2-10.2,2c-3.6,0-7-0.7-10.2-2c-3.2-1.3-6.1-3.2-8.6-5.7c-2.5-2.5-4.5-5.4-5.8-8.6c-1.3-3.2-2-6.6-2-10.2V57.1h8.3
		v35.4c0,2.5,0.5,4.9,1.5,7.1c1,2.2,2.3,4.1,4,5.8c1.7,1.7,3.6,3,5.8,3.9c2.2,0.9,4.6,1.4,7.1,1.4c2.4,0,4.8-0.5,6.9-1.4
		c2.2-0.9,4.1-2.2,5.7-3.7c1.7-1.6,3-3.4,4-5.5c1-2.1,1.6-4.4,1.7-6.8V57.1h8.3v36.2c0.1,2.4,0.6,4.7,1.6,6.8c1,2.1,2.3,3.9,4,5.5
		c1.7,1.6,3.6,2.8,5.8,3.7c2.2,0.9,4.5,1.4,7,1.4c2.5,0,4.9-0.5,7.1-1.4c2.2-0.9,4.1-2.3,5.8-3.9c1.7-1.7,3-3.6,4-5.8
		c1-2.2,1.5-4.6,1.5-7.1V57.1h8.3v35.4c0,3.6-0.7,7-2,10.2c-1.3,3.2-3.3,6.1-5.8,8.6c-2.5,2.5-5.4,4.4-8.6,5.7
		c-3.2,1.3-6.6,2-10.2,2c-3.6,0-7-0.7-10.2-2C80.1,115.8,77.2,113.9,74.7,111.4z"
      />
      <path
        className="st0"
        d="M136.9,87c0,3.3,0.7,6.4,2,9.3c1.3,2.9,3,5.4,5.2,7.5c2.2,2.1,4.7,3.8,7.6,5.1c2.9,1.3,6,1.9,9.3,1.9
		c4.5,0,8.6-1.1,12.3-3.4c3.7-2.3,6.6-5.2,8.6-8.9h9.2c-1.7,4.2-4.1,7.9-7.3,11.1c-3.1,3-6.6,5.3-10.5,7c-3.9,1.7-8,2.5-12.4,2.5
		c-4.3,0-8.5-0.8-12.4-2.5c-4-1.7-7.4-4-10.4-7c-3.1-3.1-5.4-6.6-7-10.5c-1.6-3.9-2.4-8-2.4-12.4c0-4.3,0.8-8.5,2.4-12.4
		c1.6-3.9,4-7.4,7-10.4c3-3.1,6.5-5.4,10.4-7.1c3.9-1.7,8.1-2.5,12.4-2.5c4.3,0,8.5,0.8,12.4,2.5c3.9,1.7,7.4,4,10.5,7.1
		c3.1,3,5.4,6.5,7,10.5c1.6,4,2.4,8.2,2.4,12.6H136.9z M160.9,62.6c-2.6,0-5.1,0.4-7.5,1.2c-2.4,0.8-4.5,1.9-6.5,3.3
		c-2,1.4-3.7,3.1-5.2,5.1c-1.5,2-2.7,4.1-3.6,6.5h45.4c-1.7-4.7-4.6-8.6-8.7-11.6C170.8,64.1,166.1,62.6,160.9,62.6z"
      />
      <path className="st1" d="M276.9,33.4h8.3v82.9h-8.3V33.4z" />
      <path
        className="st1"
        d="M346.3,66.2c-2.9-2.9-6.4-5.2-10.2-6.8c-7.7-3.2-16.7-3.2-24.4,0c-3.8,1.6-7.3,3.9-10.2,6.8
		c-3,3-5.3,6.5-6.9,10.3c-1.6,3.8-2.4,7.9-2.4,12.2c0,4.2,0.8,8.2,2.4,12.1c1.6,3.8,3.9,7.3,6.9,10.3c2.9,2.9,6.4,5.2,10.2,6.8
		c3.8,1.6,7.9,2.4,12.2,2.4c2,0,4.1-0.2,6.2-0.6c2.2-0.4,4.3-1,6.3-1.9c2.1-0.9,4-2.1,5.9-3.5c1.2-1,2.4-2.1,3.6-3.4v6.8h9.6V88.7
		c0-4.2-0.8-8.3-2.4-12.2C351.6,72.6,349.3,69.2,346.3,66.2z M346,88.7c0,3-0.6,5.9-1.7,8.6c-1.2,2.7-2.7,5-4.7,7
		c-2,2-4.3,3.6-7,4.7c-5.3,2.3-11.8,2.3-17.1,0c-2.7-1.2-5-2.7-7-4.7c-2-2-3.6-4.3-4.7-7c-1.2-2.7-1.7-5.5-1.7-8.6
		c0-3,0.6-5.9,1.7-8.6c1.2-2.7,2.7-5.1,4.7-7.1c2-2,4.3-3.6,7-4.7c2.7-1.2,5.5-1.7,8.6-1.7c3,0,5.9,0.6,8.6,1.7c2.7,1.2,5,2.7,7,4.7
		c2,2,3.6,4.4,4.7,7.1C345.4,82.7,346,85.6,346,88.7z"
      />
      <path
        className="st1"
        d="M379,73c2-2,4.3-3.6,7-4.7c2.7-1.2,5.5-1.7,8.6-1.7c4.1,0,7.9,1,11.3,3.1c3.4,2.1,6,4.8,7.9,8.2l0.2,0.5h10.6
		l-0.5-1.2c-1.6-4.1-4-7.7-7.2-10.9c-2.9-2.9-6.4-5.2-10.2-6.8c-7.7-3.2-16.7-3.2-24.4,0c-3.9,1.6-7.3,3.9-10.2,6.8
		c-3,3-5.3,6.5-6.9,10.3c-1.6,3.8-2.4,7.9-2.4,12.2c0,4.2,0.8,8.2,2.4,12.1c1.6,3.8,3.9,7.3,6.9,10.3c2.9,2.9,6.4,5.2,10.2,6.8
		c3.8,1.6,7.9,2.4,12.2,2.4c4.2,0,8.3-0.8,12.2-2.4c3.8-1.6,7.3-3.9,10.2-6.8c3.2-3.2,5.6-6.8,7.2-10.9l0.5-1.2H414l-0.2,0.5
		c-1.9,3.4-4.5,6.1-7.9,8.2c-3.4,2-7.2,3.1-11.3,3.1c-3,0-5.9-0.6-8.6-1.7c-2.7-1.2-5-2.7-7-4.7c-2-2-3.6-4.3-4.7-7
		c-1.1-2.7-1.7-5.5-1.7-8.6c0-3,0.6-5.9,1.7-8.6C375.4,77.3,377,75,379,73z"
      />
      <path
        className="st1"
        d="M484.5,66.3c-3-3-6.4-5.3-10.3-7c-7.6-3.2-16.6-3.2-24.3,0c-3.9,1.6-7.3,4-10.2,6.9c-3,2.9-5.3,6.4-6.9,10.2
		c-1.6,3.8-2.4,7.9-2.4,12.2c0,4.2,0.8,8.3,2.4,12.1c1.6,3.8,3.9,7.3,6.9,10.3c2.9,2.9,6.4,5.2,10.2,6.8c3.8,1.6,7.9,2.4,12.2,2.4
		s8.3-0.8,12.1-2.4c3.8-1.6,7.3-3.9,10.3-6.8c3.2-3.2,5.6-6.8,7.2-10.9l0.5-1.2h-10.6l-0.2,0.5c-1.9,3.4-4.5,6.1-7.9,8.2
		c-3.4,2-7.2,3.1-11.3,3.1c-3,0-5.9-0.6-8.5-1.7c-2.6-1.2-5-2.7-7-4.7c-2-1.9-3.6-4.3-4.8-6.9c-1.1-2.4-1.7-4.9-1.8-7.6h53.7v-0.9
		c0-4.2-0.8-8.4-2.4-12.3C489.8,72.7,487.5,69.2,484.5,66.3z M462.1,66.5c4.7,0,9,1.4,12.7,4.1c3.4,2.5,5.9,5.7,7.5,9.5h-40.6
		c0.7-1.7,1.7-3.3,2.8-4.8c1.4-1.8,3-3.4,4.8-4.7c1.8-1.3,3.8-2.3,6-3C457.4,66.9,459.7,66.5,462.1,66.5z"
      />
      <path
        className="st1"
        d="M553.7,37v28.8c-2.8-2.8-6.2-4.9-9.8-6.5c-7.7-3.2-16.7-3.2-24.4,0c-3.8,1.6-7.3,3.9-10.2,6.8
		c-3,3-5.3,6.5-6.9,10.3c-1.6,3.8-2.4,7.9-2.4,12.1c0,4.2,0.8,8.3,2.4,12.2c1.6,3.8,3.9,7.3,6.9,10.3c2.9,2.9,6.4,5.2,10.2,6.8
		c3.8,1.6,7.9,2.4,12.2,2.4c4.2,0,8.3-0.8,12.2-2.4c3.8-1.6,7.3-3.9,10.2-6.8c3-3,5.3-6.5,6.9-10.3c1.6-3.8,2.4-7.9,2.4-12.2V37
		H553.7z M553.7,88.5c0,3-0.6,5.9-1.7,8.6c-1.2,2.7-2.7,5.1-4.7,7.1c-2,2-4.3,3.6-7,4.7c-5.3,2.3-11.8,2.3-17.1,0
		c-2.7-1.2-5-2.7-7-4.7c-2-2-3.6-4.4-4.7-7.1c-1.2-2.7-1.7-5.6-1.7-8.6c0-3,0.6-5.9,1.7-8.6c1.2-2.7,2.7-5,4.7-7c2-2,4.3-3.6,7-4.7
		c2.7-1.2,5.5-1.7,8.6-1.7c3,0,5.9,0.6,8.6,1.7c2.7,1.2,5,2.7,7,4.7c2,2,3.6,4.3,4.7,7C553.1,82.6,553.7,85.5,553.7,88.5z"
      />
      <polygon
        className="st1"
        points="220.8,87.5 234.3,103.3 270.3,68 268.7,54.6 235.5,88.4 228,80.4 	"
      />
      <path
        className="st1"
        d="M258.9,87.4c0,0.4,0.1,0.8,0.1,1.2c0,3-0.6,5.9-1.7,8.6c-1.2,2.7-2.7,5-4.7,7c-2,2-4.3,3.6-7,4.7
		c-5.3,2.3-11.8,2.3-17.1,0c-2.7-1.2-5-2.7-7-4.7c-2-2-3.6-4.3-4.7-7c-1.1-2.7-1.7-5.5-1.7-8.6c0-3,0.6-5.9,1.7-8.6
		c1.2-2.7,2.7-5.1,4.7-7.1c2-2,4.3-3.6,7-4.7c2.7-1.2,5.5-1.7,8.6-1.7c3,0,5.9,0.6,8.6,1.7c2.7,1.2,5,2.7,7,4.7
		c1.1,1.1,2,2.3,2.9,3.6l6.9-7c-0.9-1.2-1.9-2.4-2.9-3.4c-2.9-2.9-6.4-5.2-10.2-6.8c-7.7-3.2-16.7-3.2-24.4,0
		c-3.9,1.6-7.3,3.9-10.2,6.8c-3,3-5.3,6.5-6.9,10.3c-1.6,3.8-2.4,7.9-2.4,12.2v53.2h9.6v-30.5c2.8,2.8,6.1,4.9,9.8,6.5
		c7.7,3.2,16.7,3.2,24.4,0c3.8-1.6,7.3-3.9,10.2-6.8c3-3,5.3-6.5,6.9-10.3c1.6-3.9,2.4-7.9,2.4-12.1c0-3.2-0.5-6.4-1.4-9.4
		L258.9,87.4z"
      />
      <path className="st2" d="M21.8,57.1c0,0,8.3,4.8,8.3,15.9V57.1H21.8z" />
    </g>
  </svg>
);

export const StartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="107.687"
    height="125.03"
    viewBox="0 0 107.687 125.03"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M426.812,2208.25l-53.837-29.66v-95.36h5.195v92.27l48.642,26.79,48.642-26.79v-92.27h5.195v95.36ZM476,2088.46h-98.38v-5.23H476v5.23Zm-49.19,27.55,7.375,22.16h23.868l-19.31,13.69,7.376,22.16-19.309-13.69-19.31,13.69,7.376-22.16-19.31-13.69h23.868Zm51.24-14.15H375.572v-5.23h102.48v5.23Z"
      transform="translate(-372.969 -2083.22)"
    />
  </svg>
);

export const quailifiedIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109.41"
    height="125.03"
    viewBox="0 0 109.41 125.03"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M1543.44,2162.83h-32.99v-5.23h32.99v5.23Zm0,15.52h-32.99v-5.24h32.99v5.24Zm18.57,31.58h-109.4V2084.92h109.4v125.01Zm-104.43-5h99.46V2089.92h-99.46v115.01Zm26.66-95.58a14.125,14.125,0,1,1-14.05,14.13A14.086,14.086,0,0,1,1484.24,2109.35Zm59.2,9.48h-32.99v-5.24h32.99v5.24Zm0,15.51h-32.99v-5.24h32.99v5.24Zm-59.2,19.62a14.125,14.125,0,1,1-14.05,14.12A14.084,14.084,0,0,1,1484.24,2153.96Z"
      transform="translate(-1452.59 -2084.91)"
    />
  </svg>
);
export const quailifiedIcon3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109.434"
    height="125.03"
    viewBox="0 0 109.434 125.03"
  >
    <defs>
      <style>
        {` .cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M966.744,2149.05c-0.018-.05-0.037-0.09-0.057-0.14L961.7,2137.5l3.156-7.23a4.528,4.528,0,0,1,8.379,0l4.164,9.54,4.205-9.63a4.452,4.452,0,0,1,6.073-2.36,5.021,5.021,0,0,1,2.212,6.48l-8.248,18.89a4.7,4.7,0,0,1-2.035,2.26c-0.108.07-.22,0.13-0.335,0.19a4.452,4.452,0,0,1-6.073-2.36l-4.152-9.51Zm-10.6-18.78a4.459,4.459,0,0,0-6.073-2.36,5.032,5.032,0,0,0-2.211,6.49l8.246,18.88a4.453,4.453,0,0,0,6.074,2.36,5.022,5.022,0,0,0,2.211-6.48Zm67.441,72.65h-5.18v-69.63c0-27.64-22.222-50.13-49.532-50.13s-49.528,22.49-49.528,50.13v69.63h-5.182v-69.63c0-30.53,24.542-55.37,54.71-55.37s54.712,24.84,54.712,55.37v69.63Zm-4.68,0H918.844v-5.24H1018.91v5.24Zm-48.219-107.51a2.705,2.705,0,0,0-3.626,0l-8.819,7.88a2.727,2.727,0,0,1-2.073.69l-11.714-1.14a2.752,2.752,0,0,0-2.933,2.16l-2.557,11.63a2.754,2.754,0,0,1-1.281,1.78l-10.133,6.06a2.786,2.786,0,0,0-1.121,3.49l4.683,10.92a2.809,2.809,0,0,1,0,2.21l-4.683,10.92a2.786,2.786,0,0,0,1.121,3.49l10.133,6.06a2.754,2.754,0,0,1,1.281,1.78l2.557,11.63a2.735,2.735,0,0,0,2.933,2.15l11.714-1.13a2.759,2.759,0,0,1,2.073.68l8.819,7.89a2.727,2.727,0,0,0,3.626,0l8.82-7.89a2.757,2.757,0,0,1,2.073-.68l11.713,1.13a2.736,2.736,0,0,0,2.934-2.15l2.556-11.63a2.757,2.757,0,0,1,1.283-1.78l10.13-6.06a2.786,2.786,0,0,0,1.12-3.49l-4.68-10.92a2.826,2.826,0,0,1,0-2.21l4.68-10.92a2.786,2.786,0,0,0-1.12-3.49l-10.13-6.06a2.757,2.757,0,0,1-1.283-1.78L996.231,2105a2.753,2.753,0,0,0-2.934-2.16l-11.713,1.14a2.725,2.725,0,0,1-2.073-.69Zm-2.269,76.91a32.8,32.8,0,1,0-32.4-32.8A32.6,32.6,0,0,0,968.422,2172.32Zm22.352,30.36H946.983v-27.83h5.182v22.58h33.426v-22.58h5.183v27.83Z"
      transform="translate(-914.156 -2077.91)"
    />
  </svg>
);

export const genralWorkers = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}`}
    </style>
    <g>
      <path
        className="st0"
        d="M19.8,73.4c-1.9,0-3.4,0.8-4.2,2.5c-0.8,1.5-0.6,3.1-0.4,4.8c0,0.4,0,1,0,1.3c0,0.2,0,0.2,0,0.4
		c0,0.4,0,0.8,0,1.1c-0.8,2.3-1.5,4.8-2.3,7.1l-0.8,2.1c-0.4,1-0.4,1.5,0,1.9c0.2,0.4,0.8,0.6,1.7,0.6h60.1c1,0,1.5-0.2,1.7-0.6
		c0.2-0.4,0.2-1,0-1.9l-1-2.9c-0.8-2.1-1.3-4.4-2.1-6.5v-0.2c-0.2-0.2-0.2-0.6-0.2-0.8c0.6-1.1,0.4-2.1,0.4-3.3c0-0.4,0-1,0-1.3
		c0-1.5-0.8-2.9-2.1-3.6c-0.8-0.4-1.7-0.6-2.9-0.6c-5.4,0-10.7,0-15.5,0c-0.4,0-0.8,0-1.1-0.4c-0.2-0.2-0.2-0.8-0.2-1
		c0-0.8,0-1.3,0-2.1v-0.6c0-1-0.4-1.5-1.1-1.9c-1-0.4-1-1.1-1-1.9c0-6.7,0-13.4,0-20.1V13.5c0-2.1-1-3.6-2.7-4.4
		c-1.7-1-3.6-0.8-5.2,0.4c-1.3,1-2.1,2.5-2.1,4.6c0,13.2,0,26.2,0,39.4v12.1v0.2c0,0.2,0,0.4,0,0.8c0,0.2-0.2,0.8-0.6,1
		c-1.1,0.6-1.5,1.3-1.5,2.7c0,0.4,0,0.8,0,1.3v0.2c0,0.2,0,0.4,0,0.6c0,0.6,0,1-0.2,1.1s-0.6,0.2-1.1,0.2h-0.4
		C29.9,73.4,24.8,73.4,19.8,73.4L19.8,73.4z M17.1,92.6c-0.4,0-1,0-1.5-0.2h-0.4l0.2-0.8c0-0.4,0.2-0.6,0.2-1c0.4-1,0.8-2.1,1-3.1
		c0.4-1,0.8-1.9,1-3.1c0.6-1.9,0.8-2.1,3.1-1.7h0.4l-2.1,7.5C18.4,92,18.2,92.6,17.1,92.6z M23.2,92.4c-0.2,0-0.6,0-0.8,0
		c-0.2,0-0.6,0-1,0h-0.4l0.6-1.9c0.8-2.5,1.3-4.8,2.1-7.1c0.2-0.4,0.6-0.6,1-0.6c0.6,0,1.1,0,1.7,0c0.2,0,0.6,0,0.8,0h0.4l-1.5,5.9
		c-0.2,1-0.6,1.9-0.8,2.9c-0.2,0.4-0.6,1-1,1C24,92.4,23.6,92.4,23.2,92.4z M29.9,92.4c-0.2,0-0.6,0-0.8,0c-0.2,0-0.6,0-0.8,0H28
		l1.5-5.7c0-0.4,0.2-0.6,0.2-1c0.2-0.8,0.4-1.3,0.6-2.1c0.2-0.6,0.4-1.1,1.3-1.1c0.4,0,0.8,0,1.1,0c0.2,0,0.4,0,0.6,0h0.4l-0.4,2.3
		c-0.2,1.3-0.6,2.7-0.8,4l-0.2,0.6c-0.2,0.6-0.2,1.3-0.4,1.9c0,0.4-0.6,0.8-1,0.8C30.7,92.4,30.3,92.4,29.9,92.4z M64.6,92.4
		c-0.4,0-0.8,0-1.3,0c-0.4,0-0.8-0.4-1-0.8c-0.6-2.1-1.1-4.4-1.7-6.7L60,82.6h1.1c0.8,0,1.3,0,1.9,0c0.4,0,0.6,0.4,0.8,0.8
		c0.8,2.3,1.3,4.4,2.1,6.7l0.6,2.1h-0.4c-0.4,0-0.6,0-0.8,0C65.1,92.4,64.8,92.4,64.6,92.4z M57.9,92.4c-0.6,0-1,0-1.5,0
		c-0.4,0-0.6-0.4-0.8-0.6c-0.4-2.3-1-4.6-1.3-7.1l-0.4-1.9h0.4c0.2,0,0.4,0,0.8,0c0.6,0,1,0,1.5,0c0.4,0,0.8,0.4,1,0.8
		c0.6,2.3,1.1,4.4,1.7,6.7l0.6,2.3h-1.1C58.4,92.4,58.2,92.4,57.9,92.4z M70.9,92.4c-0.4,0-0.8,0-1,0c-0.4,0-0.6-0.4-0.8-0.6
		c-0.8-2.5-1.5-4.8-2.1-7.3L66.5,83h0.4l0,0c2.3-0.4,2.5-0.2,3.1,1.7l2.7,7.8h-1.1C71.3,92.4,71.1,92.4,70.9,92.4z M53.1,92.4h-4.4
		l-0.8-9.8h0.4c0.2,0,0.4,0,0.8,0c0.6,0,1.1,0,1.5,0s0.8,0.4,0.8,0.8c0.4,2.1,0.8,4.4,1.1,6.7L53.1,92.4z M38.9,92.4h-4.4l1.3-7.1
		c0-0.2,0-0.2,0-0.4c0.2-1.1,0.4-1.7,1-2.1c0.6-0.4,1.3-0.2,2.5,0h0.2L38.9,92.4z M46,92.4h-4.4l0.8-9.6h2.9L46,92.4z M69.9,79.9
		H17.7v-0.8c0-0.4,0-0.6,0-1c0-1.5,0.8-2.1,2.1-2.1c15.9,0,31.8,0,47.7,0c1.7,0,2.3,0.6,2.3,2.3v1.5H69.9z M48.1,73.4h-8.6v-3.3h8.6
		V73.4z M46.2,67.1h-4.6v-0.6c0-0.2,0-0.6,0-0.8c0-17.2,0-34.5,0-51.7v-0.2c0-0.2,0-0.6,0-0.8c0.2-1.1,1.1-1.9,2.3-1.9
		s2.1,1,2.1,2.1c0,0.2,0,0.4,0,0.6v0.2L46.2,67.1z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M81.4,92.4c-0.2,0-0.4,0-0.6,0c-0.8,0.2-1.1,0.8-1.1,1.5s0.6,1.1,1.1,1.1c0.8,0,1.7,0,2.5,0c3.1,0,6.1,0,9.2,0
		c2.7-0.2,4.8-2.1,5.4-5c0.8-4.4,1.5-8.6,2.3-13c0.8-4.4,1.5-8.6,2.3-13c0.2-0.8,0.4-1.1,1-1.3c1.3-0.6,2.1-1.7,2.3-3.4
		c0-0.8,0-1.5,0-2.1c0-1.5-0.8-2.7-2.1-3.3c-1-0.4-1.1-1-1.1-1.9c-0.6-6.1-3.8-11.9-8.8-15.7c-5-3.8-11.5-5.6-17.6-4.6
		c-10.7,1.7-18.6,10-19.7,20.9c0,0.4-0.4,1-0.8,1.1c-1.5,0.8-2.3,2.1-2.3,3.8c0,0.6,0,1.1,0,1.7c0,1.7,0.8,2.9,2.3,3.6
		c0.4,0.2,0.8,0.8,1,1.1c0.2,1.1,0.4,2.5,0.6,3.6c0,0.4,0.2,1,0.2,1.3c0.2,1,0.8,1.5,1.5,1.5c0.4,0,0.8-0.2,1-0.4
		c0.2-0.4,0.4-0.8,0.2-1.5c-0.2-1.1-0.4-2.3-0.6-3.4L59,63.3h40.8v0.6c0,0.2,0,0.2,0,0.4l-1,4.8c-1.1,6.7-2.3,13.4-3.4,20.1
		c-0.4,2.3-1.5,3.3-4,3.3c-1.9,0-3.6,0-5.6,0H81.4L81.4,92.4z M94,60.4c-1,0-1.9,0-2.7,0H57.7c-1.7,0-1.9-0.4-1.9-1.9v-0.2
		c0-0.2,0-0.6,0-0.8c0-0.8,0.6-1.1,1.3-1.1c0.4,0,0.6,0,1,0h6.3c12.2,0,24.3,0,36.6,0c0,0,1.7,0,1.9,0.8c0.2,0.8,0.2,1.7,0,2.9
		c-0.2,0.6-1.3,0.8-1.5,0.8C98.8,60.4,96.5,60.4,94,60.4z M100,53.3H59V53c0.8-6.9,3.8-12.1,9.2-15.5c6.9-4.4,14.4-4.6,21.4-0.6
		c5.9,3.4,9.4,8.8,10.1,16.1L100,53.3z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M93.3,67.5c-1,0-1.1,0.6-1.1,1.1c-0.4,3.1-0.8,6.1-1.1,9.2c-0.4,2.7-0.6,5.4-1,8c0,0.8,0.2,1.1,0.8,1.5
		c0.4,0.2,1,0.2,1.3-0.4c0.2-0.4,0.4-0.8,0.4-1.3c0.2-1.5,0.4-3.1,0.6-4.6L94.4,69C94.6,68.1,94.2,67.5,93.3,67.5
		C93.5,67.5,93.5,67.5,93.3,67.5z"
      />
    </g>
  </svg>
);

export const skilledTrades = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000003073142118921405280000007753155905910009731_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000145740794141525650900000002411340374624061866_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000145016985110624202170000014097844756763715774_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000145761454636522230600000002418978602483494536_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000021834779201521292190000009415717108530862492_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000010281346564379499860000002946946952444080313_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000065781593430135766750000017020447893236714421_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000114773292069237185770000007407058708333506433_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000163766817419653865970000002596038909630604678_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000026867886477684990800000008365574425230169751_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000093160716649266449830000004880682358834907822_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000124160070014350781830000007274822579103834809_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000085962046287265578430000018268226034871531704_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000015344048906702411200000009300645317788466876_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000008120800209738574020000010485046350375500440_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000173126346578069456120000009803074545163825573_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000058591784919217682310000008319645327122237619_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000042003840481852559930000004733496330696779922_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000090253064189375785350000007211838446957670022_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000117659209608452309620000015931979643701491640_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000124142908360144081970000007655902174260991658_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000065076231233776128950000011819937524559922336_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000172437190010787261640000013256424747918722986_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000066514420309348206880000010469552712255429298_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000159458160655101499670000002931599982587775165_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000167385194725010528290000010220603550883805586_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000010995789115031320090000012638098092873880723_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000127729422236974507710000003722005369899665300_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000127728262972990183470000008232739060399753640_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000052104377079677473270000003739247710272185995_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000133498692577540700590000006351600323464240555_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000128444438173190543480000004084905760727971738_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000129911069999162498290000000953972333270988711_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000052075931332688584760000013844917396053882529_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000158009191780675622990000003799445843746324925_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000102530752321231913280000014401916977081458057_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000079484002393963622550000012719949613074373526_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000072271248709426740320000010245405056138722722_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000044140319832907275010000006017306444647243181_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000003075870366237477570000009024314498926327976_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000023959826439910692240000003066449049485434270_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000150804834613323761340000011766738863453171900_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000071541634561512773570000001527195267166589109_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000016073662754221908830000004865719864641515435_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000091706457327134033910000003545166295763421067_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000080174245719139933620000002192476778732432539_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000101066500591988937670000017650066145458512779_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000067932454497648668020000015581577094874287001_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000018221551659169663030000007992394948640582016_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000049903579665054102810000002125581756135012797_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000159427608307186857180000007481396361527532944_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000172398046516662347040000002757804249054669962_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000024683459473836318810000006502224961378909340_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000036960893931362155640000005115919996434141355_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000067235921381583575060000012274219076468103583_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000074436964057238421820000000033627624586498436_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000116213802116705411380000018044378133396103087_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000052796336414258459470000012755999077568994481_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000130620181996597792900000015827134890238833309_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000006705562544642477370000016959834328491158687_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000165220598959269315620000011909419174872990630_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000085953481119326747750000004656123297133514907_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000133521959775795106720000009352548000277570750_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000173120709287426801730000009519434885915059903_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000026865588700527591370000016784018778334779831_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000110444770107203930570000005638886214529943730_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000054228664835200100370000011599590664015062161_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000126320247773438560780000009022003305495046021_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000060001222392190940190000003086093233116928908_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000082354160374195413920000000922588099964434848_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000078765855921009121610000001142832295374195371_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000147919509776170729740000014818954381130813372_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000053536866715779153230000015735447672797712530_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000177455025086423321690000010916316371870760078_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000119823753642277825850000004432311911757981087_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000003106706942038519170000007398676383075956136_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000089543983863039970320000005822663049597838470_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000042712102179205644220000001702806375118882713_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <g>
        <defs>
          <path
            id="SVGID_311_"
            d="M73.5,57.2c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8s-8.2-0.2-12.2-0.8c0.4-2.9,0.8-5.7,1.1-8.6
				c3.6,0.4,7.5,0.8,11.3,0.8C66.4,58,69.7,57.6,73.5,57.2 M73.5,55.1c-3.8,0.4-7.5,0.8-11.1,0.8s-7.3-0.2-10.9-0.8h-0.2
				c-1,0-1.9,0.8-2.1,1.9L48,65.6c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8c1.1-0.2,1.9-1.1,1.9-2.3
				L76,57C75.4,55.9,74.5,55.1,73.5,55.1z"
          />
        </defs>
        <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
        <clipPath id="SVGID_00000090285532990178231990000010461164173197136306_">
          <use style={{ overflow: "visible" }} />
        </clipPath>
        <path
          style={{
            clipPath:
              "url(#SVGID_00000090285532990178231990000010461164173197136306_)",
            fill: "#5D5D5D",
          }}
          d="M74.5,57.2h0.2v9.4
			h-0.2V57.2z"
        />
      </g>
      <path
        className="st0"
        d="M100.9,56.4C100.3,46,96.6,38,93.6,33c-3.6-5.9-7.7-9.8-10.3-10.9c-0.4-1.3-1.5-2.5-3.1-2.7l-4-0.8
		c-0.4-1.7-1.9-3.1-3.8-3.1H52.8c-1.7,0-3.4,1.3-3.8,3.1l-4.2,0.8c-1.5,0.4-2.7,1.3-3.1,2.9C39,23.4,35,27.3,31.6,33
		c-3.1,5-6.7,12.6-7.3,23v0.2c-2.1,1.7-4.6,4.4-4.2,8.8c0.4,7.1,11.7,21.8,15.7,24.5c0.4,0.2,0.8,0.6,1,0.8c1.5,1.1,2.5,1.7,5.7,1.9
		s8.8,0.4,19.9,0.4c11.3,0,16.8,0,19.9-0.4c3.4-0.4,4.2-0.8,5.7-1.9c0.4-0.2,0.6-0.4,1-0.8c4-2.7,15.7-17.4,15.7-24.3
		C105.1,59.8,102.4,57.3,100.9,56.4z M96.4,77.2c-3.4,5-7.1,8.8-8.6,9.8c-0.4,0.4-0.8,0.6-1.1,0.8c-2.1,1.5-2.3,1.7-24.1,1.7
		s-22-0.2-24.1-1.7c-0.4-0.2-0.8-0.6-1.1-0.8c-1.5-1-5.2-4.8-8.6-9.8c-3.6-5.2-5.9-9.8-5.9-12.2c0-1.7,0.6-3.3,1.5-4.4
		c0,0,2.7-2.7,2.9-5.9v-0.2c1-8.6,4-15.5,6.7-19.9c2.9-4.6,5.7-7.5,7.7-8.8l-0.8,20.1c0,0.6,0.6,1.3,1.3,1.3c0.6,0,1.3-0.6,1.3-1.3
		l0.8-22.2v-0.2c0-0.6,0.6-1,1.1-1.1l3.4-0.8l-1.3,28.7c0,0.6,0.6,1.3,1.3,1.3c0.6,0,1.3-0.6,1.3-1.3L51.5,20c0-0.8,0.6-1.3,1.3-1.3
		h19.5c0.8,0,1.3,0.6,1.3,1.3L75,50.2c0,0.8,0.6,1.3,1.3,1.3c0.8-0.2,1.3-0.8,1.3-1.5l-1.3-28.5l3.4,0.6c0.8,0.2,1,0.6,1.1,1.1v0.4
		l1,22.2c0,0.8,0.6,1.3,1.3,1.3s1.3-0.6,1.1-1.5l-1-20.3c1.9,1.3,4.8,4.2,7.8,9s6.5,12.4,7.1,22.8v0.6v1.5c0,3.3-3.6,6.9-9.6,10
		c-6.9,3.4-16.3,5.2-26,5.2c-7.3,0-14.2-1-20.1-2.9c-1-0.2-1.7,0.4-1.7,1.3c0,0.6,0.4,1.1,1,1.3c5.9,2.1,13.2,3.3,20.7,3.3
		c10.3,0,19.9-1.9,27.4-5.6c6.7-3.3,10.7-7.5,11.1-11.7s0,0,0-0.2c0.8,1,1.3,2.7,1.3,5C102.4,67.5,100.3,72.1,96.4,77.2z"
      />
    </g>
  </svg>
);

export const constructors = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000048459623654925508000000003124747284278815128_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000028284014926938767250000013756411473168590728_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000088096865411322946920000011428746175663264953_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000168078094339704174440000004264816971431620543_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000021086777586537333320000002844745170161528749_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000031890409740964348700000016678253646401451924_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000142162711233146723320000018342277654417028024_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000105416006601974655450000005471297886892375980_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000123406358460675833630000005170951099304075653_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000146457887442974738260000006686344292021209217_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000174562151748446782630000012517429331439689131_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000155831586811900789270000003781992565415535745_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000131334171050611493900000010948150748344751280_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000140016384117765308990000012976869489759389084_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000124122981578357297330000016725593310606865802_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000108290956775893526060000009363202877765728930_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000024723796566346360100000006089881793170713259_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000091719177385838002630000001465211662674294449_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000158010033810502604100000009321027556986581145_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000128479455577678080720000004691829458971251630_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000109742020598909610220000012417209624649225358_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000109016038716185473940000014595108543972722305_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000072281386490947344050000014166437707747443597_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000062150145055685361040000008455920242390722238_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000158729611156873377800000010005086924337100969_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000017490029262374261300000010878012968163555988_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000124879144254056804360000001577818391229368723_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000036957739847023834330000014991203244145009808_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000048463518978547427890000004297946512752321183_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000089572915931900440960000000494313052596214446_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000111882998691992231360000014939309993123888021_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000023969026611424580620000011739923434988060338_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000170256934437290912610000007944422943994077087_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000158741452024032193090000009822415809424541608_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000093144004547210226070000001966977213197072266_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000007387569857829551950000005757518425458135434_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000106137690785046867820000004320059976814166461_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000048492105930847005750000011085113901774703755_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000091007141192008236560000003517845309964270510_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000070106981245841071170000012915601685619960727_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000031929448108504368120000017578049812691493023_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000014617630988280733650000001965311818135183507_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000039851324078976065210000008924480218687900033_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000064337046632308593930000000387287689621247139_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000120558982459069992860000004086897519047223443_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000035529031297876067570000002468469009021308086_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000103265364808939082230000009570663767081104031_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000047029190133994721250000001053194569152021892_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000016767682722672705920000018193811697643809675_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000155861040373147650770000008533578716017192335_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000173134578802905475850000009099133159600392112_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000063635813438636422740000018175280336053463218_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000018198274789835923760000000848962789579544250_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000105395351278681740310000016653139699483711365_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000140004846371303394930000013867905285705270924_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000106865073456808372280000014891393805088973738_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000143582404561103311950000000557974324669364872_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000138553965939836505880000014965417395442513297_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000128452581443438126860000008333456413529023672_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000166662665095776884330000003741681993250111645_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000028288154450970465730000004571202641341410213_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000000924019734930983850000001355016061414744766_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000075848392513581124600000001829937115183299754_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000062874411846613719980000008817730497996210366_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000008119156865622895260000012404455179740258960_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000083082006820938944590000003736034577495848122_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000156553094137822972050000009728920368672283553_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000183207710503049726580000017637089975444256163_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000089550710644814235530000015831266974236205486_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000046328155320530818990000008436535196838639262_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000036965519883628714450000005070422205492337817_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000114760952265610531440000000261339821252783492_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000067921696003320955780000011527397927241760441_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000126318752816983238650000003334574562800363413_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000156555653821120999710000011223230774365513601_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000139996064018057564620000007048083892134831285_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000021111695522243925190000002412289802473999541_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000015352257274105064470000005962628936122927022_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <path
        className="st0"
        d="M41,45.3c-0.1,0-0.1,0-0.2-0.1C40.9,45.2,40.9,45.3,41,45.3z"
      />
      <path
        className="st0"
        d="M35.1,39.8c-0.1-0.1-0.2-0.2-0.3-0.2C34.9,39.6,35.1,39.7,35.1,39.8z"
      />
      <path
        className="st0"
        d="M34.7,39.5c-0.1,0-0.2-0.1-0.2-0.1C34.5,39.4,34.6,39.4,34.7,39.5z"
      />
      <path
        className="st0"
        d="M40.6,45.1c0,0-0.1,0-0.1-0.1C40.5,45.1,40.6,45.1,40.6,45.1z"
      />
      <path
        className="st0"
        d="M34.3,39.3c-0.1,0-0.2,0-0.2-0.1C34.1,39.3,34.2,39.3,34.3,39.3z"
      />
      <path
        className="st0"
        d="M41.8,45.4c-0.1,0-0.1,0-0.2,0C41.6,45.4,41.7,45.4,41.8,45.4z"
      />
      <path
        className="st0"
        d="M42.8,44.9c-0.1,0.1-0.1,0.1-0.2,0.2C42.7,45,42.8,44.9,42.8,44.9z"
      />
      <path
        className="st0"
        d="M52.2,33.3c0.1,0.1,0.2,0.3,0.2,0.4C52.3,33.6,52.3,33.4,52.2,33.3z"
      />
      <path
        className="st0"
        d="M42.1,45.3c-0.1,0-0.1,0-0.2,0.1C42,45.3,42.1,45.3,42.1,45.3z"
      />
      <path
        className="st0"
        d="M42.5,45.1c-0.1,0-0.1,0.1-0.2,0.1C42.3,45.2,42.4,45.2,42.5,45.1z"
      />
      <path
        className="st0"
        d="M41.4,45.4c-0.1,0-0.1,0-0.2,0C41.2,45.3,41.3,45.3,41.4,45.4z"
      />
      <path
        className="st0"
        d="M13.4,35.4l-6.2,6.2C7,41.7,6.9,42,6.8,42.2C6.9,42,7,41.7,7.2,41.6L13.4,35.4z"
      />
      <path
        className="st0"
        d="M22.1,26.8C22,27,22,27.2,21.9,27.4c-0.2,1.9-0.7,3.3-1.6,4.4c0.8-1.1,1.4-2.5,1.6-4.4
		C21.9,27.2,22,27,22.1,26.8z"
      />
      <path
        className="st0"
        d="M18.6,54.6c-0.1,0-0.1,0-0.2,0C18.5,54.6,18.5,54.6,18.6,54.6z"
      />
      <path
        className="st0"
        d="M47.8,28.8c-0.5-0.5-1-1.1-1.6-1.6c-0.9-0.9-1.4-1.8-1.6-2.6c0.2,0.9,0.8,1.8,1.6,2.6
		C46.7,27.8,47.2,28.3,47.8,28.8z"
      />
      <path
        className="st0"
        d="M19.5,54.3c0.1-0.1,0.2-0.2,0.3-0.2l6.2-6.2l-6.2,6.2C19.7,54.1,19.6,54.2,19.5,54.3z"
      />
      <path
        className="st0"
        d="M19.3,54.4c-0.1,0-0.1,0.1-0.2,0.1C19.2,54.5,19.3,54.4,19.3,54.4z"
      />
      <path
        className="st0"
        d="M19,54.5c-0.1,0-0.1,0-0.2,0.1C18.8,54.6,18.9,54.5,19,54.5z"
      />
      <path
        className="st0"
        d="M33.5,39.3c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.1,0C33.7,39.3,33.6,39.3,33.5,39.3z"
      />
      <path
        className="st0"
        d="M31.8,16.7c-0.6,0.7-1.3,1.3-1.9,1.9l0,0l0,0C30.5,18,31.1,17.4,31.8,16.7z"
      />
      <path
        className="st0"
        d="M17.6,34c0.4-0.2,0.8-0.4,1.2-0.7C18.4,33.6,18,33.8,17.6,34z"
      />
      <path
        className="st0"
        d="M18.7,33.4c0.4-0.3,0.8-0.6,1.1-0.9C19.5,32.8,19.1,33.1,18.7,33.4z"
      />
      <path
        className="st0"
        d="M13.6,35.1c0.2-0.1,0.4-0.2,0.6-0.3C14,34.9,13.8,35,13.6,35.1z"
      />
      <path
        className="st0"
        d="M15.9,34.6c0.3-0.1,0.7-0.2,1.1-0.3C16.6,34.4,16.2,34.5,15.9,34.6z"
      />
      <path
        className="st0"
        d="M17.4,54.4c0,0-0.1,0-0.1-0.1C17.4,54.3,17.4,54.3,17.4,54.4z"
      />
      <path
        className="st0"
        d="M17.8,54.5c-0.1,0-0.1,0-0.2,0C17.7,54.5,17.8,54.5,17.8,54.5z"
      />
      <path
        className="st0"
        d="M14.6,34.8c0.2,0,0.5-0.1,0.8-0.1C15.1,34.7,14.8,34.8,14.6,34.8z"
      />
      <path
        className="st0"
        d="M18.2,54.6c-0.1,0-0.1,0-0.2,0C18.1,54.6,18.1,54.6,18.2,54.6z"
      />
      <path
        className="st0"
        d="M49.2,16.6c-0.5,0.4-1,0.7-1.4,1.1C48.2,17.4,48.7,17,49.2,16.6z"
      />
      <path
        className="st0"
        d="M18,54.6c-0.1,0-0.1,0-0.2-0.1C17.9,54.5,18,54.6,18,54.6z"
      />
      <path
        className="st0"
        d="M41.2,45.3c-0.1,0-0.1,0-0.2-0.1C41.1,45.3,41.1,45.3,41.2,45.3z"
      />
      <path
        className="st0"
        d="M47.8,17.7c-0.4,0.4-0.9,0.8-1.2,1.2C46.9,18.5,47.4,18.1,47.8,17.7z"
      />
      <path
        className="st0"
        d="M40.8,45.2c-0.1,0-0.2-0.1-0.2-0.1C40.7,45.1,40.8,45.2,40.8,45.2z"
      />
      <path
        className="st0"
        d="M41.9,45.3c-0.1,0-0.1,0-0.2,0C41.8,45.3,41.9,45.3,41.9,45.3z"
      />
      <path
        className="st0"
        d="M41.6,45.4L41.6,45.4c-0.1,0-0.1,0-0.2,0C41.4,45.4,41.5,45.4,41.6,45.4z"
      />
      <path
        className="st0"
        d="M42.3,45.2c-0.1,0-0.1,0-0.2,0.1C42.2,45.3,42.2,45.2,42.3,45.2z"
      />
      <path
        className="st0"
        d="M46,19.5c-0.3,0.4-0.6,0.8-0.9,1.2C45.4,20.3,45.7,19.9,46,19.5z"
      />
      <path
        className="st0"
        d="M42.6,45.1c-0.1,0-0.1,0.1-0.2,0.1C42.5,45.1,42.6,45.1,42.6,45.1z"
      />
      <path
        className="st0"
        d="M17.7,54.5c-0.1,0-0.2-0.1-0.2-0.1C17.5,54.4,17.6,54.5,17.7,54.5z"
      />
      <path
        className="st0"
        d="M17.3,54.3c-0.1-0.1-0.2-0.2-0.3-0.2C17.1,54.2,17.2,54.2,17.3,54.3z"
      />
      <path
        className="st0"
        d="M40.5,45.1c-0.1-0.1-0.2-0.2-0.3-0.2C40.3,44.9,40.4,45,40.5,45.1z"
      />
      <path
        className="st0"
        d="M34.8,39.6c0,0-0.1,0-0.1-0.1C34.7,39.5,34.8,39.5,34.8,39.6z"
      />
      <path
        className="st0"
        d="M31.5,39.7c0.6-0.2,1.3-0.3,2-0.4l0,0l0,0C32.8,39.4,32.1,39.5,31.5,39.7z"
      />
      <path
        className="st0"
        d="M31.5,39.7c-0.6,0.2-1.1,0.5-1.6,0.7C30.4,40.2,30.9,39.9,31.5,39.7z"
      />
      <path
        className="st0"
        d="M18.8,54.6c-0.1,0-0.1,0-0.2,0C18.7,54.6,18.7,54.6,18.8,54.6z"
      />
      <path
        className="st0"
        d="M27.9,42.5c-0.2,0.4-0.5,0.8-0.6,1.2C27.4,43.3,27.6,42.9,27.9,42.5z"
      />
      <path
        className="st0"
        d="M19.5,54.3c0,0-0.1,0-0.1,0.1C19.4,54.3,19.4,54.3,19.5,54.3z"
      />
      <path
        className="st0"
        d="M19.1,54.5c-0.1,0-0.1,0-0.2,0.1C19,54.5,19.1,54.5,19.1,54.5z"
      />
      <path
        className="st0"
        d="M52.4,35.1c0.2-0.4,0.2-0.9,0-1.4C52.5,34.2,52.5,34.6,52.4,35.1z"
      />
      <path
        className="st0"
        d="M42.8,44.9C42.9,44.9,42.9,44.8,42.8,44.9l9.1-9.1L42.8,44.9C42.9,44.8,42.9,44.8,42.8,44.9z"
      />
      <polygon
        className="st0"
        points="35.1,39.8 35.2,39.8 40.2,44.8 35.2,39.8 	"
      />
      <path
        className="st0"
        d="M18.4,54.6L18.4,54.6c-0.1,0-0.2,0-0.2,0C18.2,54.6,18.3,54.6,18.4,54.6z"
      />
      <path
        className="st0"
        d="M33.9,39.3c0.1,0,0.1,0,0.2,0C34,39.3,33.9,39.3,33.9,39.3z"
      />
      <path
        className="st0"
        d="M34.5,39.4c-0.1,0-0.1,0-0.2,0C34.3,39.4,34.4,39.4,34.5,39.4z"
      />
      <path
        className="st1"
        d="M18.8,54.6c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1
		c0.1-0.1,0.2-0.2,0.3-0.2l6.2-6.2c0.3-0.3,0.5-0.7,0.6-1.2c0.1-0.7,0.2-1.8,0.7-3c0.2-0.4,0.4-0.8,0.6-1.2c0.5-0.8,1.2-1.5,2.1-2.1
		c0.5-0.3,1-0.6,1.6-0.7c0.6-0.2,1.3-0.3,2-0.4l0,0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2l0,0l5,5c0.1,0.1,0.2,0.2,0.3,0.2
		c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
		s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1l9-9.1c0.2-0.2,0.3-0.4,0.4-0.7c0.2-0.4,0.2-0.9,0-1.4c-0.1-0.2-0.1-0.3-0.2-0.4
		C52,33.1,52,33.1,51.9,33c-1.2-1.2-2.6-2.6-4.2-4.2c-0.5-0.5-1-1.1-1.6-1.6c-0.9-0.9-1.4-1.8-1.6-2.6c-0.4-1.3-0.1-2.6,0.7-3.8
		c0.2-0.4,0.5-0.8,0.9-1.2c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.8-0.8,1.2-1.2c0.4-0.4,0.9-0.7,1.4-1.1c3.7-2.7,8.7-4.9,11.8-6.1
		c0.5-0.2,0.8-0.6,1-1c0.1-0.3,0.2-0.6,0.2-0.9c0-0.5-0.2-0.9-0.6-1.3C61.4,7.1,61.2,7,61,6.9c-0.2-0.1-0.5-0.2-0.7-0.2
		c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.2,0.1
		c0,0-0.1,0-0.1,0c-0.4,0-0.8,0.1-1.3,0.1c-0.1,0-0.1,0-0.2,0C52.9,7,52.4,7,52,7.1c-0.1,0-0.2,0-0.2,0c-0.5,0.1-0.9,0.1-1.4,0.2
		c-0.1,0-0.2,0-0.3,0c-0.5,0.1-1,0.2-1.5,0.3c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1,0.2-1.5,0.3c0,0-0.1,0-0.1,0c-0.5,0.1-1.1,0.3-1.6,0.4
		c-0.1,0-0.2,0-0.3,0.1c-0.5,0.2-1,0.3-1.5,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.4-1.5,0.6c-0.1,0-0.1,0.1-0.2,0.1
		c-0.5,0.2-1,0.4-1.5,0.7c0,0,0,0-0.1,0c-0.5,0.2-1,0.5-1.5,0.8c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,0.9
		c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.9,0.7-1.3,1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.9,0.8-1.3,1.2c-0.7,0.7-1.3,1.3-2,2
		c-0.6,0.7-1.3,1.3-1.9,1.9l0,0l-1.1,1.1c-2.2,2.2-5.2,5.3-6.4,6.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.4-0.2,0.7
		c-0.2,1.9-0.7,3.3-1.6,4.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.3-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.4,0.1-0.8,0.2-1.1,0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.1,0.1-0.2,0.1-0.2,0.2l-6.2,6.2c-0.2,0.2-0.3,0.4-0.4,0.7c-0.3,0.7-0.1,1.5,0.4,2.1l3.1,3.1l3.5,3.5l3.2,3.2
		c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0C18.7,54.6,18.7,54.6,18.8,54.6z M16.1,48.7l-3.5-3.5l-2.3-2.3l5-5.1c4.4-0.6,9.1-3.4,9.7-9.8
		c1.3-1.4,4-4.1,6-6.2l0.3-0.3l0,0l0.8-0.8c1.2-1.2,2.5-2.5,3.8-3.9c4.8-4.9,12.7-6.4,18.3-6.9c-7.7,3.7-12,7.7-12.8,11.8
		c-0.4,1.9-0.3,4.7,2.5,7.6c1.8,1.8,3.5,3.5,4.9,5l-7.3,7.4l-4.2-4.2c-1-1-2.2-1.5-3.6-1.5c-0.2,0-0.4,0-0.6,0
		c-6.4,0.7-9.2,5.4-9.7,9.8l-5,5.1L16.1,48.7z"
      />
      <path
        className="st0"
        d="M53.3,38.5l-8.1,8.1c-0.8,0.8-0.8,2.1,0,2.9l5.9,5.9c0.6,0.6,1.5,0.6,2.1,0l0.1-0.1l0,0c0.6-0.6,0.6-1.6,0-2.2
		l-5.1-5.1l6.5-6.5l5.5,5.3c0.6,0.5,1.5,0.5,2,0l0.1-0.1h0c0.6-0.6,0.6-1.6,0-2.1l-6.4-6C55.3,37.7,54.1,37.7,53.3,38.5z"
      />
      <path
        className="st0"
        d="M106,85.9L80.9,62c-0.6-0.6-1.6-0.6-2.1,0l0,0c-0.7,0.6-0.7,1.6,0,2.3l25.1,23.8c0.2,0.2,0.3,0.5,0.3,0.8
		c0,0.3-0.1,0.6-0.3,0.8l-6.4,6.4c-0.3,0.3-0.8,0.3-1.2,0L71.4,71.1c-0.6-0.6-1.6-0.6-2.2,0l0,0c-0.6,0.6-0.7,1.6,0,2.2L94,98.2
		c0.5,0.5,1.2,1,1.9,1.1c1.4,0.3,2.8-0.1,3.8-1.1L106,92c0.6-0.6,1-1.3,1.2-2.1C107.5,88.4,107.1,87,106,85.9z"
      />
      <path
        className="st0"
        d="M118,24.3c0-0.3-0.1-0.7-0.1-1c-0.1-0.7-0.6-1.5-1.4-1.8c-0.6-0.2-1.3,0-1.9,0.4c-0.2,0.1-0.7,0.7-5.6,5.3
		l-3.4,3.2c-0.4,0.4-0.6,0.4-1.1,0.2c-4.1-1.9-7.1-4.8-9.1-8.6c-0.2-0.4-0.2-0.5,0.1-0.8l2.3-2.2c3.5-3.3,6.2-5.9,6.6-6.3
		c0.8-0.9,0.7-1.7,0.6-2.1c-0.2-0.5-0.6-1.1-1.8-1.4c-3.5-0.6-6.9-0.4-10.2,0.8c-8.5,3-13.4,11.4-11.7,19.9c0.2,1.2-0.1,2.2-1,3.1
		c-1.5,1.5-7.5,7.1-14.1,13.4c-0.3,0.2-0.5,0.5-0.8,0.7c-0.4,0.4-0.7,0.7-1.1,1.1c-0.4,0.4-0.7,0.7-1.1,1.1
		c-2.3,2.2-4.7,4.4-6.9,6.5c-0.4,0.4-0.8,0.7-1.1,1.1c-0.4,0.4-0.8,0.7-1.1,1.1c-3,2.9-5.6,5.4-7.5,7.1c-0.8,0.7-1.8,1-3,0.9
		c-2-0.2-3-0.3-3.6-0.3h-0.2c-0.3,0-0.5,0-0.8,0c-3.4,0.2-6.4,1.1-8.9,2.7c-6.7,4.4-9.5,10.5-8.4,18.2c0.1,0.5,0.2,0.8,0.4,1.1
		c0,0.1,0.1,0.1,0.1,0.2l0.7,1.5l1.5-0.7c0.7-0.4,9.8-8.8,9.9-8.9c0.3-0.3,0.5-0.3,0.8-0.2c4.2,1.9,7.3,4.9,9.3,8.8
		c0.2,0.3,0.2,0.4-0.1,0.7l-0.5,0.5c-6.1,5.8-8.2,7.9-8.5,8.2c-0.7,0.8-0.6,1.6-0.4,2s0.5,1.1,1.6,1.3c1.2,0.2,2.5,0.4,3.7,0.4
		c0.7,0,1.5,0,2.2-0.1c4.8-0.6,8.8-2.6,11.8-6c3.8-4.2,5.2-9,4.4-14.5c-0.2-1-0.2-1.8,0.1-2.5c0.2-0.5,0.7-0.9,1.2-1.4l6.7-6.4
		l1.1-1.1l0.3-0.2c0.3-0.3,0.6-0.6,0.9-0.8c2.3-2.2,4.7-4.5,7.2-6.9c0.4-0.4,0.7-0.7,1.1-1.1c0.4-0.4,0.7-0.7,1.1-1.1
		c6.5-6.2,12.7-12.1,14.9-14.2c0.8-0.7,1.7-1,2.8-0.9c1.8,0.3,3.8,0.5,5.9,0.2c5.9-0.7,10.5-3.5,13.7-8.2c1.8-2.7,2.8-5.6,2.8-8.8
		C118.2,26.3,118.1,25.3,118,24.3z M112.8,34.5c-2.7,4-6.5,6.3-11.5,6.9c-1.7,0.2-3.4,0-5-0.2c-2-0.3-3.9,0.3-5.4,1.7
		c-2.2,2.1-25,23.8-25.3,24l-8.1,7.7c-0.7,0.6-1.4,1.3-1.9,2.4c-0.7,1.5-0.6,3-0.4,4.3c0.7,4.6-0.5,8.5-3.6,11.9
		c-2.5,2.8-5.9,4.5-9.9,5c-0.9,0.1-1.9,0.1-2.9,0.1c1.9-1.9,5.1-4.9,6.8-6.5l0.5-0.5c1.3-1.2,1.6-2.8,0.7-4.4
		c-2.3-4.5-5.9-8-10.7-10.2c-1.5-0.7-3.1-0.4-4.3,0.7c-1.4,1.3-5.2,4.8-7.5,6.9c-0.3-5.6,2.1-9.9,7.2-13.2c2.1-1.3,4.6-2.1,7.4-2.2
		c0.2,0,0.4,0,0.6,0H40c0.5,0,2.1,0.2,3,0.3l0,0c2.1,0.3,4.1-0.3,5.5-1.7c1.9-1.8,32.3-30.7,33.8-32.2c1.7-1.6,2.4-3.7,1.9-5.9
		C82.9,22.4,87,15.5,94,13c2.1-0.7,4.3-1.1,6.6-0.9c-1.4,1.3-3.4,3.3-4.9,4.7l-2.3,2.2c-1.4,1.3-1.6,2.8-0.8,4.5
		c2.3,4.4,5.8,7.8,10.5,10c1.7,0.8,3.2,0.5,4.6-0.7l3.4-3.2c1.3-1.3,2.9-2.8,4-3.8c0.1,0.6,0.1,1.1,0.1,1.6
		C115,29.9,114.3,32.3,112.8,34.5z"
      />
      <path
        className="st1"
        d="M80.3,45.8c-0.6-0.6-1.6-0.6-2.2,0L62.7,61.2c-0.2,0.2-0.3,0.5-0.4,0.8c-0.1,0.5,0,1,0.4,1.4
		c0.3,0.3,0.7,0.5,1.1,0.5c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.4L80.3,48C80.9,47.4,80.9,46.4,80.3,45.8z"
      />
    </g>
  </svg>
);

export const energyIndustry = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000081645271798985751280000004380934695571564952_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000124151280027492807850000002387724416528826255_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000163788851383669179660000016833997621687184273_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000153705301574637968690000014594814266436352669_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000134940601350755738920000012732125381010669725_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000081631998946091233730000003937724312190175363_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000008854535407317814620000003718944408257835939_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000145757712446676098890000004083797445592236417_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000176759492638906268960000004780786091506933394_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000072276006132469870100000010893412932188057232_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000094587878444890797340000002383654427298332034_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000151515647612754191620000010246769365126480306_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000120530089449459057620000011371138773117090699_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000065758727113681243210000007071618324572531859_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000059291896773968141890000014118101787992819885_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000053524198781271611040000014204052281910439831_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000016767002868029428380000013679327181645047228_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000078010698932293648020000012544477922544768162_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000042014515060779244380000007580400300582507945_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000018199491392697150140000000091665794638404250_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000136374451107251717480000013422571585893573522_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000182528671022224276830000002816870653115659918_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000041281831804329814860000014143167549495077760_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000141435177673492346070000004785615508055579022_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000121262584040638726240000017610418646511300765_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000048469588203303480470000005506551611127991941_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000165927923581090635820000014752599763033562542_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000152963126785486084810000005039986249614049461_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000169551455054202049360000007205457944847945661_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000072998324818722162990000009450352612919469976_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000005978414071220360640000013716195796214844295_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000008150320443232438580000011048977685681420473_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000050664253104734566610000001742442817561182639_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000091706043555507326920000002254768442541849728_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000162353635070597436780000011856842918718454925_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000008830703080567800590000000383187336877132717_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000157286064125464608590000017626493236249282179_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000075884479774287100380000010687700536148661656_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000036227824237405828970000012146473828483460508_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000173125234822829257230000007004160389904492710_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000078764774432746081660000014811906063164761257_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000135680084796435182780000012261661403305583538_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000027586335581609266320000001946384151415720892_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000129886743216513943750000004364653541879669141_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000160887829613590076670000017920368045237875330_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000127748425287938839180000016977724653301599149_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000164472858164640494990000005393522758228957602_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000090276837624605517080000001642159160927886524_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000090284645013465723960000016360075147378775949_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000130612189646866168070000003056637644562068662_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000033335782709185914500000010573459819766809734_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000100361396113585325980000017993448156488268217_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000047046269809059129990000016108343963179705017_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000004533610480528383440000011956119414582567553_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000132085429824419882600000013416958131016869310_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000174570704642256384140000007297260339354061496_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000096766565210964405790000001061492975990923933_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000046306258763898610830000009036810410368727953_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000042016659878698426130000005237864592183610501_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000139283997012500443530000014306320403979810195_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000031924550705360966090000007346644917473640576_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000034073798738729115730000003560377402580851363_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000031894607548356811210000003469057398473457309_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000001638203169130086490000012633021156508513926_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000054949509866100699940000015508154926895118230_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000096758970541970460070000006792586715241956752_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000003102207687151949020000007989829954210170558_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000035527287259572812530000015718118461985619337_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000102519375706576600580000010128245539265086902_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000114040626120236359120000007000492294844646323_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000083772646413033928420000017759267231466811546_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000030446874144680581210000004739445266212485561_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000079487158929021723160000013471219951445658269_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000164490091557950693830000005093848239091646610_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000095296826633716485640000010795027625306538658_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000042738255377720116260000003549990746669662869_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000004541543585744747780000015102717218765181877_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000171700134622977914030000017034615351062099329_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <path
        className="st0"
        d="M175.4-71c-1.9,0-3.4,0.8-4.2,2.5c-0.8,1.5-0.6,3.1-0.4,4.8c0,0.4,0,1,0,1.3c0,0.2,0,0.2,0,0.4
		c0,0.4,0,0.8,0,1.1c-0.8,2.3-1.5,4.8-2.3,7.1l-0.8,2.1c-0.4,1-0.4,1.5,0,1.9c0.2,0.4,0.8,0.6,1.7,0.6h60.1c1,0,1.5-0.2,1.7-0.6
		c0.2-0.4,0.2-1,0-1.9l-1-2.9c-0.8-2.1-1.3-4.4-2.1-6.5v-0.2c-0.2-0.2-0.2-0.6-0.2-0.8c0.6-1.1,0.4-2.1,0.4-3.3c0-0.4,0-1,0-1.3
		c0-1.5-0.8-2.9-2.1-3.6c-0.8-0.4-1.7-0.6-2.9-0.6c-5.4,0-10.7,0-15.5,0c-0.4,0-0.8,0-1.1-0.4c-0.2-0.2-0.2-0.8-0.2-1
		c0-0.8,0-1.3,0-2.1v-0.6c0-1-0.4-1.5-1.1-1.9c-1-0.4-1-1.1-1-1.9c0-6.7,0-13.4,0-20.1v-32.2c0-2.1-1-3.6-2.7-4.4
		c-1.7-1-3.6-0.8-5.2,0.4c-1.3,1-2.1,2.5-2.1,4.6c0,13.2,0,26.2,0,39.4v12.1v0.2c0,0.2,0,0.4,0,0.8c0,0.2-0.2,0.8-0.6,1
		c-1.1,0.6-1.5,1.3-1.5,2.7c0,0.4,0,0.8,0,1.3v0.2c0,0.2,0,0.4,0,0.6c0,0.6,0,1-0.2,1.1c-0.2,0.2-0.6,0.2-1.1,0.2h-0.4
		C185.6-71,180.4-71,175.4-71L175.4-71z M172.8-51.9c-0.4,0-1,0-1.5-0.2h-0.4l0.2-0.8c0-0.4,0.2-0.6,0.2-1c0.4-1,0.8-2.1,1-3.1
		c0.4-1,0.8-1.9,1-3.1c0.6-1.9,0.8-2.1,3.1-1.7h0.4l-2.1,7.5C174.1-52.5,173.9-51.9,172.8-51.9z M178.9-52.1c-0.2,0-0.6,0-0.8,0
		c-0.2,0-0.6,0-1,0h-0.4l0.6-1.9c0.8-2.5,1.3-4.8,2.1-7.1c0.2-0.4,0.6-0.6,1-0.6c0.6,0,1.1,0,1.7,0c0.2,0,0.6,0,0.8,0h0.4l-1.5,5.9
		c-0.2,1-0.6,1.9-0.8,2.9c-0.2,0.4-0.6,1-1,1C179.6-52.1,179.3-52.1,178.9-52.1z M185.6-52.1c-0.2,0-0.6,0-0.8,0c-0.2,0-0.6,0-0.8,0
		h-0.4l1.5-5.7c0-0.4,0.2-0.6,0.2-1c0.2-0.8,0.4-1.3,0.6-2.1c0.2-0.6,0.4-1.1,1.3-1.1c0.4,0,0.8,0,1.1,0c0.2,0,0.4,0,0.6,0h0.4
		l-0.4,2.3c-0.2,1.3-0.6,2.7-0.8,4l-0.2,0.6c-0.2,0.6-0.2,1.3-0.4,1.9c0,0.4-0.6,0.8-1,0.8C186.3-52.1,186-52.1,185.6-52.1z
		 M220.2-52.1c-0.4,0-0.8,0-1.3,0c-0.4,0-0.8-0.4-1-0.8c-0.6-2.1-1.1-4.4-1.7-6.7l-0.6-2.3h1.1c0.8,0,1.3,0,1.9,0
		c0.4,0,0.6,0.4,0.8,0.8c0.8,2.3,1.3,4.4,2.1,6.7l0.6,2.1h-0.4c-0.4,0-0.6,0-0.8,0C220.8-52.1,220.4-52.1,220.2-52.1z M213.5-52.1
		c-0.6,0-1,0-1.5,0c-0.4,0-0.6-0.4-0.8-0.6c-0.4-2.3-1-4.6-1.3-7.1l-0.4-1.9h0.4c0.2,0,0.4,0,0.8,0c0.6,0,1,0,1.5,0
		c0.4,0,0.8,0.4,1,0.8c0.6,2.3,1.1,4.4,1.7,6.7l0.6,2.3h-1.1C214.1-52.1,213.9-52.1,213.5-52.1z M226.5-52.1c-0.4,0-0.8,0-1,0
		c-0.4,0-0.6-0.4-0.8-0.6c-0.8-2.5-1.5-4.8-2.1-7.3l-0.6-1.5h0.4l0,0c2.3-0.4,2.5-0.2,3.1,1.7l2.7,7.8h-1.1
		C226.9-52.1,226.7-52.1,226.5-52.1z M208.7-52.1h-4.4l-0.8-9.8h0.4c0.2,0,0.4,0,0.8,0c0.6,0,1.1,0,1.5,0s0.8,0.4,0.8,0.8
		c0.4,2.1,0.8,4.4,1.1,6.7L208.7-52.1z M194.6-52.1h-4.4l1.3-7.1c0-0.2,0-0.2,0-0.4c0.2-1.1,0.4-1.7,1-2.1c0.6-0.4,1.3-0.2,2.5,0
		h0.2L194.6-52.1z M201.7-52.1h-4.4l0.8-9.6h2.9L201.7-52.1z M225.6-64.5h-52.3v-0.8c0-0.4,0-0.6,0-1c0-1.5,0.8-2.1,2.1-2.1
		c15.9,0,31.8,0,47.7,0c1.7,0,2.3,0.6,2.3,2.3v1.5H225.6z M203.8-71h-8.6v-3.3h8.6V-71z M201.8-77.4h-4.6v-0.6c0-0.2,0-0.6,0-0.8
		c0-17.2,0-34.5,0-51.7v-0.2c0-0.2,0-0.6,0-0.8c0.2-1.1,1.1-1.9,2.3-1.9s2.1,1,2.1,2.1c0,0.2,0,0.4,0,0.6v0.2L201.8-77.4z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M237.1-52.1c-0.2,0-0.4,0-0.6,0c-0.8,0.2-1.1,0.8-1.1,1.5c0,0.8,0.6,1.1,1.1,1.1c0.8,0,1.7,0,2.5,0
		c3.1,0,6.1,0,9.2,0c2.7-0.2,4.8-2.1,5.4-5c0.8-4.4,1.5-8.6,2.3-13c0.8-4.4,1.5-8.6,2.3-13c0.2-0.8,0.4-1.1,1-1.3
		c1.3-0.6,2.1-1.7,2.3-3.4c0-0.8,0-1.5,0-2.1c0-1.5-0.8-2.7-2.1-3.3c-1-0.4-1.1-1-1.1-1.9c-0.6-6.1-3.8-11.9-8.8-15.7
		c-5-3.8-11.5-5.6-17.6-4.6c-10.7,1.7-18.6,10-19.7,20.9c0,0.4-0.4,1-0.8,1.1c-1.5,0.8-2.3,2.1-2.3,3.8c0,0.6,0,1.1,0,1.7
		c0,1.7,0.8,2.9,2.3,3.6c0.4,0.2,0.8,0.8,1,1.1c0.2,1.1,0.4,2.5,0.6,3.6c0,0.4,0.2,1,0.2,1.3c0.2,1,0.8,1.5,1.5,1.5
		c0.4,0,0.8-0.2,1-0.4c0.2-0.4,0.4-0.8,0.2-1.5c-0.2-1.1-0.4-2.3-0.6-3.4l-0.4-1.9h40.8v0.6c0,0.2,0,0.2,0,0.4l-1,4.8
		c-1.1,6.7-2.3,13.4-3.4,20.1c-0.4,2.3-1.5,3.3-4,3.3c-1.9,0-3.6,0-5.6,0H237.1L237.1-52.1z M249.7-84.1c-1,0-1.9,0-2.7,0h-33.7
		c-1.7,0-1.9-0.4-1.9-1.9v-0.2c0-0.2,0-0.6,0-0.8c0-0.8,0.6-1.1,1.3-1.1c0.4,0,0.6,0,1,0h6.3c12.2,0,24.3,0,36.6,0
		c0,0,1.7,0,1.9,0.8c0.2,0.8,0.2,1.7,0,2.9c-0.2,0.6-1.3,0.8-1.5,0.8C254.5-84.1,252.2-84.1,249.7-84.1z M255.6-91.1h-41v-0.4
		c0.8-6.9,3.8-12.1,9.2-15.5c6.9-4.4,14.4-4.6,21.4-0.6c5.9,3.4,9.4,8.8,10.1,16.1L255.6-91.1z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M248.9-77c-1,0-1.1,0.6-1.1,1.1c-0.4,3.1-0.8,6.1-1.1,9.2c-0.4,2.7-0.6,5.4-1,8c0,0.8,0.2,1.1,0.8,1.5
		c0.4,0.2,1,0.2,1.3-0.4c0.2-0.4,0.4-0.8,0.4-1.3c0.2-1.5,0.4-3.1,0.6-4.6l1.3-12.1C250.3-76.4,249.9-77,248.9-77
		C249.1-77,249.1-77,248.9-77z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_157_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000042012914878557517890000010369689648538818436_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000042012914878557517890000010369689648538818436_),fill:#5D5D5D;",
        }}
        d="M90.6-72.6h0.6V-59
		h-0.6V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_159_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000023979810529951874860000002473793855236617347_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000023979810529951874860000002473793855236617347_)",
          fill: "#5D5D5D",
        }}
        d="M91.1-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_161_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000023242161108108128210000003952862025397510817_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000023242161108108128210000003952862025397510817_)",
          fill: "#5D5D5D",
        }}
        d="M91.9-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_163_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000181080213508047957890000001494659961044770957_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000181080213508047957890000001494659961044770957_)",
          fill: "#5D5D5D",
        }}
        d="M92.7-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_165_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000061467553644506674110000015378652699622217102_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000061467553644506674110000015378652699622217102_)",
          fill: "#5D5D5D",
        }}
        d="M93.2-72.6H94V-59h-0.8
		V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_167_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000075878081863248627930000002404534146312654776_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000075878081863248627930000002404534146312654776_)",
          fill: "#5D5D5D",
        }}
        d="M94-72.6h0.8V-59H94
		V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_169_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000052826008938071569310000014016449688738871424_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000052826008938071569310000014016449688738871424_)",
          fill: "#5D5D5D",
        }}
        d="M94.8-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_171_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000142862594125559783020000017453322770424057480_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000142862594125559783020000017453322770424057480_)",
          fill: "#5D5D5D;",
        }}
        d="M95.3-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_173_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000026876019122928875930000013383074759403779200_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000026876019122928875930000013383074759403779200_)",
          fill: "#5D5D5D;",
        }}
        d="M96.1-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_175_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000036241628246002207340000015765793628784866714_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000036241628246002207340000015765793628784866714_)",
          fill: "#5D5D5D",
        }}
        d="M96.9-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_177_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000044179394049420741770000004670249687347724171_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000044179394049420741770000004670249687347724171_)",
          fill: "#5D5D5D",
        }}
        d="M97.4-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_179_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000055691594302195167530000012395285437822198184_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000055691594302195167530000012395285437822198184_)",
          fill: "#5D5D5D",
        }}
        d="M98.2-72.6H99V-59h-0.8
		V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_181_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000160900534386891057810000015868875298864296614_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000160900534386891057810000015868875298864296614_)",
          fill: "#5D5D5D",
        }}
        d="M99-72.6h0.8V-59H99
		V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_183_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000069362871515326597900000013083736882454750085_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000069362871515326597900000013083736882454750085_)",
          fill: "#5D5D5D",
        }}
        d="M99.6-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_185_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000121966326946818964880000002271113640685192597_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000121966326946818964880000002271113640685192597_)",
          fill: "#5D5D5D",
        }}
        d="M100.3-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_187_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000037652853529836676940000010338222604653448877_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000037652853529836676940000010338222604653448877_)",
          fill: "#5D5D5D",
        }}
        d="M101.1-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_189_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000154411855994430502770000011544024173785262981_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000154411855994430502770000011544024173785262981_)",
          fill: "#5D5D5D",
        }}
        d="M101.7-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_191_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000146469556355886609560000012052896313840594330_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000146469556355886609560000012052896313840594330_)",
          fill: "#5D5D5D",
        }}
        d="M102.4-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_193_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000111179084780270293820000016218913748995875002_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000111179084780270293820000016218913748995875002_)",
          fill: "#5D5D5D",
        }}
        d="M103.2-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_195_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000165938872150097648600000009800052812502428850_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000165938872150097648600000009800052812502428850_)",
          fill: "#5D5D5D",
        }}
        d="M103.8-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_197_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000144301273336762754330000011143149552666374808_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000144301273336762754330000011143149552666374808_)",
          fill: "#5D5D5D",
        }}
        d="M104.5-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_199_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000137123051853994012910000001596686902084700342_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000137123051853994012910000001596686902084700342_)",
          fill: "#5D5D5D",
        }}
        d="M105.3-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_201_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000100352195039638616750000010442643917940750246_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000100352195039638616750000010442643917940750246_)",
          fill: "#5D5D5D",
        }}
        d="M106.1-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_203_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000012432281390893053760000004358638354938838950_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000012432281390893053760000004358638354938838950_)",
          fill: "#5D5D5D",
        }}
        d="M106.6-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_205_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000092432221472610386110000000994997233807823271_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000092432221472610386110000000994997233807823271_)",
          fill: "#5D5D5D",
        }}
        d="M107.4-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_207_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000142885519446094549360000003516203096379994039_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000142885519446094549360000003516203096379994039_)",
          fill: "#5D5D5D",
        }}
        d="M108.2-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_209_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000082333525116593664380000001292594045552546439_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000082333525116593664380000001292594045552546439_)",
          fill: "#5D5D5D",
        }}
        d="M108.7-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_211_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000074425688492586898990000015652135753879180472_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000074425688492586898990000015652135753879180472_)",
          fill: "#5D5D5D",
        }}
        d="M109.5-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_213_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000147901734256902889480000008285626429987543731_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000147901734256902889480000008285626429987543731_)",
          fill: "#5D5D5D",
        }}
        d="M110.3-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_215_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000165198591195994967540000011590925306350257081_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000165198591195994967540000011590925306350257081_)",
          fill: "#5D5D5D",
        }}
        d="M110.8-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_217_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000052064935248021799270000015665087329137157305_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000052064935248021799270000015665087329137157305_)",
          fill: "#5D5D5D",
        }}
        d="M111.6-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_219_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000121999418983433097260000017334984096332772258_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000121999418983433097260000017334984096332772258_)",
          fill: "#5D5D5D",
        }}
        d="M112.4-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_221_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000082370363071855802820000018200271883392276132_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000082370363071855802820000018200271883392276132_)",
          fill: "#5D5D5D",
        }}
        d="M112.9-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_223_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000148655463449770028550000001598077782699652237_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000148655463449770028550000001598077782699652237_)",
          fill: "#5D5D5D",
        }}
        d="M113.7-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_225_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000069362571725331341540000016623782443878044546_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000069362571725331341540000016623782443878044546_)",
          fill: "#5D5D5D",
        }}
        d="M114.5-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_227_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000150099736895725319310000015509341505779687853_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000150099736895725319310000015509341505779687853_)",
          fill: "#5D5D5D",
        }}
        d="M115.1-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_229_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000080193585700293032210000013787922954677829812_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000080193585700293032210000013787922954677829812_)",
          fill: "#5D5D5D",
        }}
        d="M115.8-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_231_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000119809261518302284900000017596279370474070970_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000119809261518302284900000017596279370474070970_)",
          fill: "#5D5D5D",
        }}
        d="M116.6-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_233_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000178915831269411337100000002952001733297457084_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000178915831269411337100000002952001733297457084_)",
          fill: "#5D5D5D",
        }}
        d="M117.2-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_235_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000008134123645382810360000012693636747645393848_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000008134123645382810360000012693636747645393848_)",
          fill: "#5D5D5D",
        }}
        d="M117.9-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_237_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000169524824391584838950000006703300056892403865_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000169524824391584838950000006703300056892403865_)",
          fill: "#5D5D5D",
        }}
        d="M118.7-72.6h0.8V-59
		h-0.8V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_239_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000061443142940983905670000005572635358269980050_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000061443142940983905670000005572635358269980050_)",
          fill: "#5D5D5D",
        }}
        d="M119.3-72.6h0.6V-59
		h-0.6V-72.6z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_241_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000134969334933771354860000001591655276339348634_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000134969334933771354860000001591655276339348634_)",
          fill: "#5D5D5D",
        }}
        d="M92.9-70.5h0.4v9.4
		h-0.4V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_243_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000183231548345816095460000016760067890573452679_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000183231548345816095460000016760067890573452679_)",
          fill: "#5D5D5D",
        }}
        d="M93.2-70.5H94v9.4h-0.8
		V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_245_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000004528479934239904830000013482019571284411321_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000004528479934239904830000013482019571284411321_)",
          fill: "#5D5D5D",
        }}
        d="M94-70.5h0.8v9.4H94
		V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_247_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000163792520041045168110000005413236223507512714_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000163792520041045168110000005413236223507512714_)",
          fill: "#5D5D5D",
        }}
        d="M94.8-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_249_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000157267801847807434880000000783092253297462203_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000157267801847807434880000000783092253297462203_)",
          fill: "#5D5D5D",
        }}
        d="M95.3-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_251_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000139996405473282025760000012896594132083934620_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000139996405473282025760000012896594132083934620_)",
          fill: "#5D5D5D",
        }}
        d="M96.1-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_253_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000113335051035628461260000008563516690998455205_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000113335051035628461260000008563516690998455205_)",
          fill: "#5D5D5D",
        }}
        d="M96.9-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_255_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000058548707334086040750000011899477846226485893_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000058548707334086040750000011899477846226485893_)",
          fill: "#5D5D5D",
        }}
        d="M97.4-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_257_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000053522260861992463050000016186685452773821627_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000053522260861992463050000016186685452773821627_)",
          fill: "#5D5D5D",
        }}
        d="M98.2-70.5H99v9.4h-0.8
		V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_259_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000026134216260402930490000004819043264781174974_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000026134216260402930490000004819043264781174974_)",
          fill: "#5D5D5D",
        }}
        d="M99-70.5h0.8v9.4H99
		V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_261_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000008110007422325956910000012787367621723953803_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000008110007422325956910000012787367621723953803_)",
          fill: "#5D5D5D",
        }}
        d="M99.6-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_263_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000007403552947332774980000003085920092284817024_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000007403552947332774980000003085920092284817024_)",
          fill: "#5D5D5D",
        }}
        d="M100.3-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_265_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000072249521231040113570000013502413667655690425_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000072249521231040113570000013502413667655690425_)",
          fill: "#5D5D5D",
        }}
        d="M101.1-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_267_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000070809004518997048670000010076366579236613024_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000070809004518997048670000010076366579236613024_)",
          fill: "#5D5D5D",
        }}
        d="M101.7-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_269_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000047057259663828454570000012572692846345119872_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000047057259663828454570000012572692846345119872_)",
          fill: "#5D5D5D",
        }}
        d="M102.4-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_271_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000178197938755990258110000006444400614342838173_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000178197938755990258110000006444400614342838173_)",
          fill: "#5D5D5D",
        }}
        d="M103.2-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_273_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000008114031213745281230000001215714907333404094_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000008114031213745281230000001215714907333404094_)",
          fill: "#5D5D5D",
        }}
        d="M103.8-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_275_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000026140875536824476440000013075694276711078272_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000026140875536824476440000013075694276711078272_)",
          fill: "#5D5D5D",
        }}
        d="M104.5-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_277_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000177484178989992289850000013242812160343109043_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000177484178989992289850000013242812160343109043_)",
          fill: "#5D5D5D",
        }}
        d="M105.3-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_279_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000119807680846655792230000011316602252003633280_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000119807680846655792230000011316602252003633280_)",
          fill: "#5D5D5D",
        }}
        d="M106.1-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_281_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000167392998771577860670000004142316397588374185_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000167392998771577860670000004142316397588374185_)",
          fill: "#5D5D5D",
        }}
        d="M106.6-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_283_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000138572866050909840840000008105634747515481522_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000138572866050909840840000008105634747515481522_)",
          fill: "#5D5D5D",
        }}
        d="M107.4-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_285_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000111167491367270927150000006255841525745074078_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000111167491367270927150000006255841525745074078_)",
          fill: "#5D5D5D",
        }}
        d="M108.2-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_287_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000120528610925401860230000015116249285985297087_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000120528610925401860230000015116249285985297087_)",
          fill: "#5D5D5D",
        }}
        d="M108.7-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_289_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000171686545150160472470000018308918265004088754_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000171686545150160472470000018308918265004088754_)",
          fill: "#5D5D5D",
        }}
        d="M109.5-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_291_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000111187631578287480580000007118890163880254364_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000111187631578287480580000007118890163880254364_)",
          fill: "#5D5D5D",
        }}
        d="M110.3-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_293_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000147201841266589253550000005657865262083484059_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000147201841266589253550000005657865262083484059_)",
          fill: "#5D5D5D",
        }}
        d="M110.8-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_295_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000147199306453787726180000012667446418298029228_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000147199306453787726180000012667446418298029228_)",
          fill: "#5D5D5D",
        }}
        d="M111.6-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_297_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000160882731788048482680000016629666781489767310_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000160882731788048482680000016629666781489767310_)",
          fill: "#5D5D5D",
        }}
        d="M112.4-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_299_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000035516895586949090620000015521058382595182730_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000035516895586949090620000015521058382595182730_)",
          fill: "#5D5D5D",
        }}
        d="M112.9-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_301_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000090986495527553860150000011646649961540711342_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000090986495527553860150000011646649961540711342_)",
          fill: "#5D5D5D",
        }}
        d="M113.7-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_303_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000008139005678419352960000013692727110233926804_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000008139005678419352960000013692727110233926804_)",
          fill: "#5D5D5D",
        }}
        d="M114.5-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_305_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000039100169742900421420000010453369649006412196_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000039100169742900421420000010453369649006412196_)",
          fill: "#5D5D5D",
        }}
        d="M115.1-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_307_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000080181243868124389340000007721476572774531481_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000080181243868124389340000007721476572774531481_)",
          fill: "#5D5D5D",
        }}
        d="M115.8-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_309_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000048466449706261691420000004239709154121692093_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000048466449706261691420000004239709154121692093_)",
          fill: "#5D5D5D",
        }}
        d="M116.6-70.5h0.8v9.4
		h-0.8V-70.5z"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_311_"
          d="M116.2-70.5c0.4,2.9,0.8,5.7,1.1,8.6c-4,0.6-8.2,0.8-12.2,0.8c-4,0-8.2-0.2-12.2-0.8
			c0.4-2.9,0.8-5.7,1.1-8.6c3.6,0.4,7.5,0.8,11.3,0.8S112.4-70.1,116.2-70.5 M116.2-72.6c-3.8,0.4-7.5,0.8-11.1,0.8
			c-3.6,0-7.3-0.2-10.9-0.8H94c-1,0-1.9,0.8-2.1,1.9L90.7-62c-0.2,1.1,0.8,2.3,1.9,2.3c4.2,0.6,8.4,0.8,12.6,0.8s8.4-0.2,12.6-0.8
			c1.1-0.2,1.9-1.1,1.9-2.3l-1.1-8.6C118.1-71.8,117.2-72.6,116.2-72.6z"
        />
      </defs>
      <use style={{ overflow: "visible", fill: "#5D5D5D;" }} />
      <clipPath id="SVGID_00000042726987794907756010000002092330093048441015_">
        <use style={{ overflow: "visible" }} />
      </clipPath>
      <path
        style={{
          clipPath:
            "url(#SVGID_00000042726987794907756010000002092330093048441015_)",
          fill: "#5D5D5D",
        }}
        d="M117.2-70.5h0.2v9.4
		h-0.2V-70.5z"
      />
    </g>
    <path
      className="st0"
      d="M16.2-106.3c-0.6-10.3-4.2-18.4-7.3-23.4c-3.6-5.9-7.7-9.8-10.3-10.9c-0.4-1.3-1.5-2.5-3.1-2.7l-4-0.8
	c-0.4-1.7-1.9-3.1-3.8-3.1h-19.5c-1.7,0-3.4,1.3-3.8,3.1l-4.2,0.8c-1.5,0.4-2.7,1.3-3.1,2.9c-2.7,1.1-6.7,5-10.1,10.7
	c-3.1,5-6.7,12.6-7.3,23v0.2c-2.1,1.7-4.6,4.4-4.2,8.8c0.4,7.1,11.7,21.8,15.7,24.5c0.4,0.2,0.8,0.6,1,0.8c1.5,1.1,2.5,1.7,5.7,1.9
	c3.3,0.2,8.8,0.4,19.9,0.4c11.3,0,16.8,0,19.9-0.4c3.4-0.4,4.2-0.8,5.7-1.9c0.4-0.2,0.6-0.4,1-0.8C8.3-75.9,20-90.6,20-97.5
	C20.4-102.9,17.7-105.4,16.2-106.3z M11.8-85.5c-3.4,5-7.1,8.8-8.6,9.8c-0.4,0.4-0.8,0.6-1.1,0.8c-2.1,1.5-2.3,1.7-24.1,1.7
	s-22-0.2-24.1-1.7c-0.4-0.2-0.8-0.6-1.1-0.8c-1.5-1-5.2-4.8-8.6-9.8c-3.6-5.2-5.9-9.8-5.9-12.2c0-1.7,0.6-3.3,1.5-4.4
	c0,0,2.7-2.7,2.9-5.9v-0.2c1-8.6,4-15.5,6.7-19.9c2.9-4.6,5.7-7.5,7.7-8.8l-0.8,20.1c0,0.6,0.6,1.3,1.3,1.3c0.6,0,1.3-0.6,1.3-1.3
	l0.8-22.2v-0.2c0-0.6,0.6-1,1.1-1.1l3.4-0.8l-1.3,28.7c0,0.6,0.6,1.3,1.3,1.3c0.6,0,1.3-0.6,1.3-1.3l1.3-30.2c0-0.8,0.6-1.3,1.3-1.3
	h19.5c0.8,0,1.3,0.6,1.3,1.3l1.3,30.2c0,0.8,0.6,1.3,1.3,1.3c0.8-0.2,1.3-0.8,1.3-1.5l-1.3-28.5l3.4,0.6c0.8,0.2,1,0.6,1.1,1.1v0.4
	l1,22.2c0,0.8,0.6,1.3,1.3,1.3s1.3-0.6,1.1-1.5l-1-20.3c1.9,1.3,4.8,4.2,7.8,9c3.1,4.8,6.5,12.4,7.1,22.8v0.6v1.5
	c0,3.3-3.6,6.9-9.6,10c-6.9,3.4-16.3,5.2-26,5.2c-7.3,0-14.2-1-20.1-2.9c-1-0.2-1.7,0.4-1.7,1.3c0,0.6,0.4,1.1,1,1.3
	c5.9,2.1,13.2,3.3,20.7,3.3c10.3,0,19.9-1.9,27.4-5.6c6.7-3.3,10.7-7.5,11.1-11.7c0.4-4.2,0,0,0-0.2c0.8,1,1.3,2.7,1.3,5
	C17.7-95.2,15.6-90.6,11.8-85.5z"
    />
    <g>
      <path
        className="st0"
        d="M332.7-96.9c-0.1,0-0.1,0-0.2-0.1C332.6-96.9,332.6-96.9,332.7-96.9z"
      />
      <path
        className="st0"
        d="M326.8-102.3c-0.1-0.1-0.2-0.2-0.3-0.2C326.6-102.5,326.7-102.4,326.8-102.3z"
      />
      <path
        className="st0"
        d="M326.4-102.7c-0.1,0-0.2-0.1-0.2-0.1C326.2-102.7,326.3-102.7,326.4-102.7z"
      />
      <path
        className="st0"
        d="M332.3-97c0,0-0.1,0-0.1-0.1C332.2-97.1,332.2-97,332.3-97z"
      />
      <path
        className="st0"
        d="M326-102.8c-0.1,0-0.2,0-0.2-0.1C325.8-102.8,325.9-102.8,326-102.8z"
      />
      <path
        className="st0"
        d="M333.4-96.8c-0.1,0-0.1,0-0.2,0C333.3-96.8,333.4-96.8,333.4-96.8z"
      />
      <path
        className="st0"
        d="M334.5-97.3c-0.1,0.1-0.1,0.1-0.2,0.2C334.4-97.1,334.4-97.2,334.5-97.3z"
      />
      <path
        className="st0"
        d="M343.9-108.8c0.1,0.1,0.2,0.3,0.2,0.4C344-108.6,344-108.7,343.9-108.8z"
      />
      <path
        className="st0"
        d="M333.8-96.9c-0.1,0-0.1,0-0.2,0.1C333.7-96.8,333.7-96.8,333.8-96.9z"
      />
      <path
        className="st0"
        d="M334.1-97c-0.1,0-0.1,0.1-0.2,0.1C334-96.9,334.1-97,334.1-97z"
      />
      <path
        className="st0"
        d="M333-96.8c-0.1,0-0.1,0-0.2,0C332.9-96.8,333-96.8,333-96.8z"
      />
      <path
        className="st0"
        d="M305-106.8l-6.2,6.2c-0.2,0.2-0.3,0.4-0.4,0.7c0.1-0.2,0.2-0.5,0.4-0.7L305-106.8z"
      />
      <path
        className="st0"
        d="M313.8-115.4c-0.1,0.2-0.1,0.4-0.2,0.7c-0.2,1.9-0.7,3.3-1.6,4.4c0.8-1.1,1.4-2.5,1.6-4.4
		C313.6-114.9,313.7-115.2,313.8-115.4z"
      />
      <path
        className="st0"
        d="M310.3-87.5c-0.1,0-0.1,0-0.2,0C310.1-87.5,310.2-87.5,310.3-87.5z"
      />
      <path
        className="st0"
        d="M339.4-113.3c-0.5-0.5-1-1.1-1.6-1.6c-0.9-0.9-1.4-1.8-1.6-2.6c0.2,0.9,0.8,1.8,1.6,2.6
		C338.4-114.4,338.9-113.9,339.4-113.3z"
      />
      <path
        className="st0"
        d="M311.1-87.8c0.1-0.1,0.2-0.2,0.3-0.2l6.2-6.2l-6.2,6.2C311.4-88,311.3-87.9,311.1-87.8z"
      />
      <path
        className="st0"
        d="M311-87.8c-0.1,0-0.1,0.1-0.2,0.1C310.9-87.7,311-87.7,311-87.8z"
      />
      <path
        className="st0"
        d="M310.6-87.6c-0.1,0-0.1,0-0.2,0.1C310.5-87.6,310.6-87.6,310.6-87.6z"
      />
      <path
        className="st0"
        d="M325.2-102.9c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.1,0C325.3-102.9,325.3-102.9,325.2-102.9z"
      />
      <path
        className="st0"
        d="M323.4-125.4c-0.6,0.7-1.3,1.3-1.9,1.9l0,0l0,0C322.2-124.1,322.8-124.8,323.4-125.4z"
      />
      <path
        className="st0"
        d="M309.2-108.1c0.4-0.2,0.8-0.4,1.2-0.7C310-108.5,309.6-108.3,309.2-108.1z"
      />
      <path
        className="st0"
        d="M310.4-108.8c0.4-0.3,0.8-0.6,1.1-0.9C311.2-109.4,310.8-109,310.4-108.8z"
      />
      <path
        className="st0"
        d="M305.3-107c0.2-0.1,0.4-0.2,0.6-0.3C305.7-107.2,305.5-107.1,305.3-107z"
      />
      <path
        className="st0"
        d="M307.5-107.6c0.3-0.1,0.7-0.2,1.1-0.3C308.3-107.7,307.9-107.6,307.5-107.6z"
      />
      <path
        className="st0"
        d="M309.1-87.8c0,0-0.1,0-0.1-0.1C309-87.8,309.1-87.8,309.1-87.8z"
      />
      <path
        className="st0"
        d="M309.5-87.6c-0.1,0-0.1,0-0.2,0C309.4-87.6,309.4-87.6,309.5-87.6z"
      />
      <path
        className="st0"
        d="M306.2-107.3c0.2,0,0.5-0.1,0.8-0.1C306.7-107.4,306.5-107.4,306.2-107.3z"
      />
      <path
        className="st0"
        d="M309.8-87.5c-0.1,0-0.1,0-0.2,0C309.8-87.5,309.8-87.5,309.8-87.5z"
      />
      <path
        className="st0"
        d="M340.9-125.5c-0.5,0.4-1,0.7-1.4,1.1C339.9-124.8,340.4-125.2,340.9-125.5z"
      />
      <path
        className="st0"
        d="M309.7-87.6c-0.1,0-0.1,0-0.2-0.1C309.6-87.6,309.6-87.6,309.7-87.6z"
      />
      <path
        className="st0"
        d="M332.9-96.8c-0.1,0-0.1,0-0.2-0.1C332.7-96.8,332.8-96.8,332.9-96.8z"
      />
      <path
        className="st0"
        d="M339.5-124.4c-0.4,0.4-0.9,0.8-1.2,1.2C338.6-123.6,339-124,339.5-124.4z"
      />
      <path
        className="st0"
        d="M332.5-96.9c-0.1,0-0.2-0.1-0.2-0.1C332.4-97,332.4-96.9,332.5-96.9z"
      />
      <path
        className="st0"
        d="M333.6-96.8c-0.1,0-0.1,0-0.2,0C333.5-96.8,333.5-96.8,333.6-96.8z"
      />
      <path
        className="st0"
        d="M333.2-96.8L333.2-96.8c-0.1,0-0.1,0-0.2,0C333.1-96.8,333.2-96.8,333.2-96.8z"
      />
      <path
        className="st0"
        d="M334-96.9c-0.1,0-0.1,0-0.2,0.1C333.9-96.9,333.9-96.9,334-96.9z"
      />
      <path
        className="st0"
        d="M337.7-122.6c-0.3,0.4-0.6,0.8-0.9,1.2C337.1-121.8,337.4-122.2,337.7-122.6z"
      />
      <path
        className="st0"
        d="M334.3-97.1c-0.1,0-0.1,0.1-0.2,0.1C334.2-97,334.2-97.1,334.3-97.1z"
      />
      <path
        className="st0"
        d="M309.3-87.7c-0.1,0-0.2-0.1-0.2-0.1C309.2-87.7,309.3-87.7,309.3-87.7z"
      />
      <path
        className="st0"
        d="M309-87.8c-0.1-0.1-0.2-0.2-0.3-0.2C308.8-88,308.9-87.9,309-87.8z"
      />
      <path
        className="st0"
        d="M332.2-97.1c-0.1-0.1-0.2-0.2-0.3-0.2C332-97.2,332.1-97.2,332.2-97.1z"
      />
      <path
        className="st0"
        d="M326.5-102.6c0,0-0.1,0-0.1-0.1C326.4-102.6,326.5-102.6,326.5-102.6z"
      />
      <path
        className="st0"
        d="M323.2-102.4c0.6-0.2,1.3-0.3,2-0.4l0,0l0,0C324.5-102.8,323.8-102.7,323.2-102.4z"
      />
      <path
        className="st0"
        d="M323.2-102.4c-0.6,0.2-1.1,0.5-1.6,0.7C322.1-102,322.6-102.3,323.2-102.4z"
      />
      <path
        className="st0"
        d="M310.4-87.6c-0.1,0-0.1,0-0.2,0C310.3-87.5,310.4-87.5,310.4-87.6z"
      />
      <path
        className="st0"
        d="M319.5-99.6c-0.2,0.4-0.5,0.8-0.6,1.2C319.1-98.8,319.3-99.2,319.5-99.6z"
      />
      <path
        className="st0"
        d="M311.1-87.8c0,0-0.1,0-0.1,0.1C311.1-87.8,311.1-87.8,311.1-87.8z"
      />
      <path
        className="st0"
        d="M310.8-87.7c-0.1,0-0.1,0-0.2,0.1C310.7-87.6,310.7-87.6,310.8-87.7z"
      />
      <path
        className="st0"
        d="M344-107.1c0.2-0.4,0.2-0.9,0-1.4C344.2-108,344.2-107.5,344-107.1z"
      />
      <path
        className="st0"
        d="M334.5-97.3C334.5-97.3,334.6-97.3,334.5-97.3l9.1-9.1L334.5-97.3C334.6-97.3,334.5-97.3,334.5-97.3z"
      />
      <polygon
        className="st0"
        points="326.8,-102.3 326.8,-102.3 331.9,-97.3 326.8,-102.3 	"
      />
      <path
        className="st0"
        d="M310.1-87.5L310.1-87.5c-0.1,0-0.2,0-0.2,0C309.9-87.5,310-87.5,310.1-87.5z"
      />
      <path
        className="st0"
        d="M325.6-102.9c0.1,0,0.1,0,0.2,0C325.7-102.9,325.6-102.9,325.6-102.9z"
      />
      <path
        className="st0"
        d="M326.1-102.8c-0.1,0-0.1,0-0.2,0C326-102.8,326.1-102.8,326.1-102.8z"
      />
      <path
        className="st1"
        d="M310.4-87.6c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1
		c0.1-0.1,0.2-0.2,0.3-0.2l6.2-6.2c0.3-0.3,0.5-0.7,0.6-1.2c0.1-0.7,0.2-1.8,0.7-3c0.2-0.4,0.4-0.8,0.6-1.2c0.5-0.8,1.2-1.5,2.1-2.1
		c0.5-0.3,1-0.6,1.6-0.7c0.6-0.2,1.3-0.3,2-0.4l0,0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.1
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2l0,0l5,5c0.1,0.1,0.2,0.2,0.3,0.2
		c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1l9-9.1c0.2-0.2,0.3-0.4,0.4-0.7c0.2-0.4,0.2-0.9,0-1.4c-0.1-0.2-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.2-0.3c-1.2-1.2-2.6-2.6-4.2-4.2c-0.5-0.5-1-1.1-1.6-1.6c-0.9-0.9-1.4-1.8-1.6-2.6c-0.4-1.3-0.1-2.6,0.7-3.8
		c0.2-0.4,0.5-0.8,0.9-1.2c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.4,0.8-0.8,1.2-1.2s0.9-0.7,1.4-1.1c3.7-2.7,8.7-4.9,11.8-6.1
		c0.5-0.2,0.8-0.6,1-1c0.1-0.3,0.2-0.6,0.2-0.9c0-0.5-0.2-0.9-0.6-1.3c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.7-0.2
		c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.2,0.1
		c0,0-0.1,0-0.1,0c-0.4,0-0.8,0.1-1.3,0.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.9,0.1-1.4,0.2c-0.1,0-0.2,0-0.2,0c-0.5,0.1-0.9,0.1-1.4,0.2
		c-0.1,0-0.2,0-0.3,0c-0.5,0.1-1,0.2-1.5,0.3c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1,0.2-1.5,0.3c0,0-0.1,0-0.1,0c-0.5,0.1-1.1,0.3-1.6,0.4
		c-0.1,0-0.2,0-0.3,0.1c-0.5,0.2-1,0.3-1.5,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.2-1,0.4-1.5,0.6c-0.1,0-0.1,0.1-0.2,0.1
		c-0.5,0.2-1,0.4-1.5,0.7c0,0,0,0-0.1,0c-0.5,0.2-1,0.5-1.5,0.8c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.3-0.9,0.6-1.4,0.9
		c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.9,0.7-1.3,1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.9,0.8-1.3,1.2c-0.7,0.7-1.3,1.3-2,2
		c-0.6,0.7-1.3,1.3-1.9,1.9l0,0l-1.1,1.1c-2.2,2.2-5.2,5.3-6.4,6.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.4-0.2,0.7
		c-0.2,1.9-0.7,3.3-1.6,4.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.3-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.2-0.6,0.2
		c-0.4,0.1-0.8,0.2-1.1,0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
		c-0.1,0.1-0.2,0.1-0.2,0.2l-6.2,6.2c-0.2,0.2-0.3,0.4-0.4,0.7c-0.3,0.7-0.1,1.5,0.4,2.1l3.1,3.1l3.5,3.5l3.2,3.2
		c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0C310.3-87.5,310.4-87.5,310.4-87.6z M307.8-93.4l-3.5-3.5l-2.3-2.3l5-5.1
		c4.4-0.6,9.1-3.4,9.7-9.8c1.3-1.4,4-4.1,6-6.2l0.3-0.3l0,0l0.8-0.8c1.2-1.2,2.5-2.5,3.8-3.9c4.8-4.9,12.7-6.4,18.3-6.9
		c-7.7,3.7-12,7.7-12.8,11.8c-0.4,1.9-0.3,4.7,2.5,7.6c1.8,1.8,3.5,3.5,4.9,5l-7.3,7.4l-4.2-4.2c-1-1-2.2-1.5-3.6-1.5
		c-0.2,0-0.4,0-0.6,0c-6.4,0.7-9.2,5.4-9.7,9.8l-5,5.1L307.8-93.4z"
      />
      <path
        className="st0"
        d="M345-103.7l-8.1,8.1c-0.8,0.8-0.8,2.1,0,2.9l5.9,5.9c0.6,0.6,1.5,0.6,2.1,0l0.1-0.1l0,0c0.6-0.6,0.6-1.6,0-2.2
		l-5.1-5.1l6.5-6.5l5.5,5.3c0.6,0.5,1.5,0.5,2,0l0.1-0.1h0c0.6-0.6,0.6-1.6,0-2.1l-6.4-6C347-104.5,345.7-104.5,345-103.7z"
      />
      <path
        className="st0"
        d="M397.7-56.2l-25.1-23.9c-0.6-0.6-1.6-0.6-2.1,0l0,0c-0.7,0.6-0.7,1.6,0,2.3L395.5-54c0.2,0.2,0.3,0.5,0.3,0.8
		c0,0.3-0.1,0.6-0.3,0.8l-6.4,6.4c-0.3,0.3-0.8,0.3-1.2,0L363.1-71c-0.6-0.6-1.6-0.6-2.2,0l0,0c-0.6,0.6-0.7,1.6,0,2.2L385.7-44
		c0.5,0.5,1.2,1,1.9,1.1c1.4,0.3,2.8-0.1,3.8-1.1l6.3-6.3c0.6-0.6,1-1.3,1.2-2.1C399.2-53.7,398.7-55.2,397.7-56.2z"
      />
      <path
        className="st0"
        d="M409.6-117.9c0-0.3-0.1-0.7-0.1-1c-0.1-0.7-0.6-1.5-1.4-1.8c-0.6-0.2-1.3,0-1.9,0.4c-0.2,0.1-0.7,0.7-5.6,5.3
		l-3.4,3.2c-0.4,0.4-0.6,0.4-1.1,0.2c-4.1-1.9-7.1-4.8-9.1-8.6c-0.2-0.4-0.2-0.5,0.1-0.8l2.3-2.2c3.5-3.3,6.2-5.9,6.6-6.3
		c0.8-0.9,0.7-1.7,0.6-2.1c-0.2-0.5-0.6-1.1-1.8-1.4c-3.5-0.6-6.9-0.4-10.2,0.8c-8.5,3-13.4,11.4-11.7,19.9c0.2,1.2-0.1,2.2-1,3.1
		c-1.5,1.5-7.5,7.1-14.1,13.4c-0.3,0.2-0.5,0.5-0.8,0.7c-0.4,0.4-0.7,0.7-1.1,1.1c-0.4,0.4-0.7,0.7-1.1,1.1
		c-2.3,2.2-4.7,4.4-6.9,6.5c-0.4,0.4-0.8,0.7-1.1,1.1c-0.4,0.4-0.8,0.7-1.1,1.1c-3,2.9-5.6,5.4-7.5,7.1c-0.8,0.7-1.8,1-3,0.9
		c-2-0.2-3-0.3-3.6-0.3h-0.2c-0.3,0-0.5,0-0.8,0c-3.4,0.2-6.4,1.1-8.9,2.7c-6.7,4.4-9.5,10.5-8.4,18.2c0.1,0.5,0.2,0.8,0.4,1.1
		c0,0.1,0.1,0.1,0.1,0.2l0.7,1.5l1.5-0.7c0.7-0.4,9.8-8.8,9.9-8.9c0.3-0.3,0.5-0.3,0.8-0.2c4.2,1.9,7.3,4.9,9.3,8.8
		c0.2,0.3,0.2,0.4-0.1,0.7l-0.5,0.5c-6.1,5.8-8.2,7.9-8.5,8.2c-0.7,0.8-0.6,1.6-0.4,2c0.1,0.4,0.5,1.1,1.6,1.3
		c1.2,0.2,2.5,0.4,3.7,0.4c0.7,0,1.5,0,2.2-0.1c4.8-0.6,8.8-2.6,11.8-6c3.8-4.2,5.2-9,4.4-14.5c-0.2-1-0.2-1.8,0.1-2.5
		c0.2-0.5,0.7-0.9,1.2-1.4l6.7-6.4l1.1-1.1l0.3-0.2c0.3-0.3,0.6-0.6,0.9-0.8c2.3-2.2,4.7-4.5,7.2-6.9c0.4-0.4,0.7-0.7,1.1-1.1
		c0.4-0.4,0.7-0.7,1.1-1.1c6.5-6.2,12.7-12.1,14.9-14.2c0.8-0.7,1.7-1,2.8-0.9c1.8,0.3,3.8,0.5,5.9,0.2c5.9-0.7,10.5-3.5,13.7-8.2
		c1.8-2.7,2.8-5.6,2.8-8.8C409.9-115.8,409.8-116.9,409.6-117.9z M404.5-107.6c-2.7,4-6.5,6.3-11.5,6.9c-1.7,0.2-3.4,0-5-0.2
		c-2-0.3-3.9,0.3-5.4,1.7c-2.2,2.1-25,23.8-25.3,24l-8.1,7.7c-0.7,0.6-1.4,1.3-1.9,2.4c-0.7,1.5-0.6,3-0.4,4.3
		c0.7,4.6-0.5,8.5-3.6,11.9c-2.5,2.8-5.9,4.5-9.9,5c-0.9,0.1-1.9,0.1-2.9,0.1c1.9-1.9,5.1-4.9,6.8-6.5l0.5-0.5
		c1.3-1.2,1.6-2.8,0.7-4.4c-2.3-4.5-5.9-8-10.7-10.2c-1.5-0.7-3.1-0.4-4.3,0.7c-1.4,1.3-5.2,4.8-7.5,6.9c-0.3-5.6,2.1-9.9,7.2-13.2
		c2.1-1.3,4.6-2.1,7.4-2.2c0.2,0,0.4,0,0.6,0h0.5c0.5,0,2.1,0.2,3,0.3l0,0c2.1,0.3,4.1-0.3,5.5-1.7c1.9-1.8,32.3-30.7,33.8-32.2
		c1.7-1.6,2.4-3.7,1.9-5.9c-1.4-7,2.7-13.9,9.7-16.4c2.1-0.7,4.3-1.1,6.6-0.9c-1.4,1.3-3.4,3.3-4.9,4.7l-2.3,2.2
		c-1.4,1.3-1.6,2.8-0.8,4.5c2.3,4.4,5.8,7.8,10.5,10c1.7,0.8,3.2,0.5,4.6-0.7l3.4-3.2c1.3-1.3,2.9-2.8,4-3.8
		c0.1,0.6,0.1,1.1,0.1,1.6C406.7-112.2,405.9-109.8,404.5-107.6z"
      />
      <path
        className="st1"
        d="M372-96.3c-0.6-0.6-1.6-0.6-2.2,0L354.4-81c-0.2,0.2-0.3,0.5-0.4,0.8c-0.1,0.5,0,1,0.4,1.4
		c0.3,0.3,0.7,0.5,1.1,0.5c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.4L372-94.1C372.6-94.7,372.6-95.7,372-96.3z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M47,76.2c-2.5,0-5,0-7.5,0c-1.1,0-2.5-0.4-3.4-1.1c-7.8-5.7-12.6-15.1-12.4-25.3c0-10.1,4.8-19.1,12.8-24.9
		c1-0.6,2.1-1,3.3-1c10.9,0,20.9,0,30.2,0c1.1,0,2.5,0.4,3.4,1.1C81.5,30.8,86,40,86,50.1s-4.6,19.3-12.8,25.1c-0.8,0.6-2.1,1-3.1,1
		c-3.8,0-7.8,0-11.5,0h-3.8c-1.3,0-2.7,0-3.8,0C49.5,76.2,48.2,76.2,47,76.2z M54.5,26.4c-4.8,0-9.8,0-14.9,0
		c-0.6,0-1.3,0.2-1.7,0.6c-7.5,5.2-11.7,13.6-11.7,22.8c0,9.4,4,17.8,11.5,23.2c0.6,0.4,1.3,0.6,1.9,0.6c3.6,0,7.5,0,11.1,0
		c1.3,0,2.7,0,3.8,0h3.8c3.8,0,7.7,0,11.5,0c0.6,0,1.3-0.2,1.7-0.6c7.5-5.4,11.7-13.6,11.7-23c0-9.2-4.2-17.6-11.5-23
		c-0.6-0.4-1.3-0.8-1.9-0.8C64.8,26.4,59.8,26.4,54.5,26.4z"
      />
      <path
        className="st1"
        d="M61.2,68.9h-13c-0.6,0-1.1-0.6-1.1-1.1c0-0.2-0.2-5.6,0.2-7.8c0.6-3.6,4-6.3,7.8-6.1c4,0.2,7.1,3.3,7.3,7.1
		c0,1.9,0,6.7,0,6.9C62.3,68.3,61.9,68.9,61.2,68.9z M49.5,66.4H60c0-1.5,0-4.2,0-5.6c-0.2-2.7-2.3-4.6-5-4.8c-2.5-0.2-5,1.7-5.4,4
		C49.3,61.6,49.5,64.9,49.5,66.4z"
      />
      <g>
        <path
          className="st1"
          d="M68.6,49h-6.3c-0.8,0-1.3-0.6-1.3-1.3V35c0-0.8,0.6-1.3,1.3-1.3h6.3c0.8,0,1.3,0.6,1.3,1.3v12.6
			C70,48.4,69.4,49,68.6,49z M63.7,46.5h3.8v-10h-3.8V46.5z"
        />
        <path
          className="st1"
          d="M47.2,50.5h-6.3c-0.8,0-1.3-0.6-1.3-1.3V33.5c0-0.8,0.6-1.3,1.3-1.3h6.3c0.8,0,1.3,0.6,1.3,1.3v15.7
			C48.3,50,47.8,50.5,47.2,50.5z M42,48h3.8V35H42V48z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M88.5,82.9c0-1.1-1.3-1.5-2.1-0.8C84.5,83.8,82,85,79.2,85H31.5c-6.5,0-11.9-5.4-11.9-11.9V26.8
			c0-6.5,5.4-11.9,11.9-11.9h46.3c6.5,0,11.9,5.4,11.9,11.9v32.2c0,1,1.1,1.5,1.9,1.1l0,0c0.4-0.2,0.6-0.6,0.6-1.1V26.8
			c0-8-6.5-14.5-14.5-14.5H31.5c-8,0-14.5,6.5-14.5,14.5v46.3c0,8,6.5,14.5,14.5,14.5H79c3.4,0,6.7-1.3,9-3.6
			C88.3,83.6,88.5,83.3,88.5,82.9L88.5,82.9z"
        />
      </g>
      <path
        className="st1"
        d="M88.3,95.9c-0.2,0-0.4,0-0.6-0.2h-0.2c-0.6-0.2-1-1-0.8-1.7l6.3-16.8h-8.6c-0.6,0-1-0.2-1.1-0.8
		c-0.2-0.4-0.2-1,0.2-1.3l17.6-24.1c0.4-0.6,1.1-0.8,1.5-0.4h0.2c0.6,0.4,0.8,1,0.6,1.5l-5.6,16.3h8.6c0.6,0,1,0.2,1.1,0.8
		c0.2,0.4,0.2,1-0.2,1.3L89.3,95.5C89.1,95.7,88.7,95.9,88.3,95.9z M87.4,74.8h7.8c0.4,0,0.8,0.2,1.1,0.6c0.2,0.4,0.4,0.8,0.2,1.1
		l-3.8,10.1L104.2,71H96c-0.4,0-0.8-0.2-1.1-0.6c-0.2-0.4-0.4-0.8-0.2-1.1l3.4-9.6L87.4,74.8z M102.5,52.1L102.5,52.1L102.5,52.1z"
      />
    </g>
  </svg>
);

export const transport = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000065035772078737662580000010872546833375015058_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000011741239801092488680000018110670539756560025_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000050664232034353965980000003342111304367398053_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000027589084388300887930000012921047365281964933_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000080894611654904664170000000829942286558937230_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000130636694727057092280000017377898319944402599_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000041297726211781805810000006770952060651897247_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000094609303982527675960000009541784409333949590_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000029755244136789604940000009140570845396151472_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000030475151683180833370000018393764445875706800_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000144334878577995467050000002317790722157077150_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000050627515002400428900000001313888841456526997_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000163769430228889001620000012824275778632459198_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000065049372477024295630000017174553768941066429_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000132057050468493536660000003324659050366063030_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000102538555675114665510000002326652638090195597_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000049219396938235083430000017317105434928648123_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000118396827469557014390000015069555071760574880_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000013882853056888352860000007997640344147065791_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000154384202141573391200000005811831914581406869_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000026870960172895338660000010501456114004320437_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000143614724755736280200000004545865613543889820_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000117645886343737639620000005714077331892302476_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000013880296551080220210000001513816558425270682_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000102531346287005270650000014062698929765925760_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000162353329907648224480000017357192941478805669_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000091719493674787666050000006468946639079061425_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000098903128630731822660000014826653505240843663_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000163064919993690441270000015557246569656066192_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000172424725498624412940000011412760853848078526_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000079467051762549027840000006508048498018728625_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000155866470875727531010000000829183374470008733_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000010293912635118014590000002342603088928555139_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000116209740184747784730000009899846819211002252_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000052797602484125838220000003810037781809754019_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000058555655800666538210000000614896819656227731_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000074407593909550407800000017433990256566863010_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000181805732445967084710000006585737889653064886_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000022559817503855766500000015159230666194089386_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000013161941640531574650000017162871999827966353_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000132792361402183135710000009646901301834280849_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000041992555017689874690000008892790586107607693_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000052804351875161389370000005338113616100083642_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000168828039147969221860000008375633287383330700_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000107589296627687917400000006004049719107972535_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000134955156986472918660000000141906061439869582_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000112632865966905965260000007565851473411841679_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000016064345149120968280000009237073904691362465_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000070830366450105210000000004975673725659208328_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000030481352918137877760000011488933289442538899_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000121969151684808050610000004897900345208755891_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000123416214476800215380000006004052048337083787_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000009560215873884645940000008463273246423481243_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000005243873835786987550000010091105152637617326_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000088115652509671636740000015203738594363270072_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000163035996129471025360000018433145341794257545_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000160166548950243068190000007528645731492843144_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000142152294117314183610000002506346697446032314_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000064333745135799692630000005377278613706008994_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000172429812158032996330000003265781638809637284_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000048498282464427393610000007149491705947219627_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000011015738788928421080000007025713934166360727_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000135673735784813852690000014101574476304450470_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000118377641905368604330000003210596281882939310_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000079449908828339265320000013084267991783303353_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000044886106291640883280000015861861770969011092_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000013909555612240832120000010875278364049097621_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000060736343506680084640000013166863717294945699_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000042005860975481399440000003470738098591362951_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000093893347248692822870000008506025969704577685_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000127009863372420571200000006968088624226231438_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000029019775764689774950000001244315864782214789_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000052067296341247839050000001460471445621960608_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000114044718685801092380000017831954816788985266_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000165950860643609106250000001416357129660954815_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000119800394877856923650000007949968080321335435_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000101790757105266017430000006407478412490152578_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000146495215140038724590000006730846622348493471_);fill:#5D5D5D;}
`}
    </style>
    <path
      className="st0"
      d="M62.4,101.7c-26.2,0-47.7-21.4-47.7-47.7S36.2,6.4,62.4,6.4S110,27.8,110,54.1S88.6,101.7,62.4,101.7z
	 M62.4,8.9c-24.9,0-45.2,20.3-45.2,45.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2S87.3,8.9,62.4,8.9z"
    />
    <path
      className="st0"
      d="M62.4,85.5c-17.2,0-31.4-14-31.4-31.4c0-17.2,14-31.4,31.4-31.4s31.4,14.2,31.4,31.4S79.6,85.5,62.4,85.5z
	 M62.4,25c-16.1,0-29.1,13-29.1,29.1s13,29.1,29.1,29.1s29.1-13,29.1-29.1S78.5,25,62.4,25z"
    />
    <path
      className="st1"
      d="M87.5,45.8L87.5,45.8C84,34.9,73.9,27.7,62.4,27.7s-21.6,7.3-25.1,18.2l0,0c-1,2.7-1.3,5.4-1.3,8.2
	c0,8.4,4,16.5,10.9,21.4l0,0c4.6,3.3,10,5,15.5,5s10.9-1.7,15.5-5l0,0c6.9-5,10.9-13,10.9-21.4C88.8,51.4,88.4,48.5,87.5,45.8z
	 M77.5,72.6L68.3,60c-0.6,0.6-1.3,1.1-2.1,1.5l9.2,12.6c-3.8,2.5-8.2,3.8-13,3.8c-4.6,0-9-1.3-13-3.8l9.2-12.6
	c-0.8-0.4-1.5-1-2.1-1.5l-9.2,12.6c-5.6-4.6-8.8-11.3-8.8-18.6c0-2.1,0.2-4.2,0.8-6.1l14.9,4.8c0.2-1,0.4-1.7,0.8-2.5L40,45.5
	c3.4-8.8,11.5-14.7,21.1-15.3v15.7c0.4,0,0.8-0.2,1.3-0.2s1,0,1.3,0.2V30.1c9.4,0.6,17.6,6.5,21.1,15.3l-14.9,4.8
	c0.4,0.8,0.6,1.5,0.8,2.5l14.9-4.8c0.6,2.1,0.8,4,0.8,6.1C86.3,61.2,83.1,68,77.5,72.6z"
    />
    <path
      className="st0"
      d="M70.6,52.7c-0.2-1-0.4-1.7-0.8-2.5c-1.1-2.3-3.4-4-6.1-4.4c-0.4,0-0.8-0.2-1.3-0.2s-1,0-1.3,0.2
	c-2.7,0.4-5,2.1-6.1,4.4c-0.4,0.8-0.6,1.5-0.8,2.5c0,0.4-0.2,0.8-0.2,1.3c0,2.3,1,4.4,2.5,5.9c0.6,0.6,1.3,1.1,2.1,1.5
	c1.1,0.6,2.5,1,3.8,1s2.7-0.4,3.8-1c0.8-0.4,1.5-1,2.1-1.5c1.5-1.5,2.5-3.6,2.5-5.9C70.6,53.7,70.6,53.1,70.6,52.7z M62.4,59.8
	c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7C68.1,57.1,65.4,59.8,62.4,59.8z"
    />
  </svg>
);

export const manfacturing = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000062896655509119470060000001208479359787070909_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000044162210324349636950000008854915592083267969_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000152948743148293057730000017384963191814453655_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000028290450941084837620000015357555936168719010_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000177444566364427984080000013974457925136280460_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000070092228979277973000000003810902256356437650_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000138569078603389749540000017092901912802472325_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000148647829563036925360000010116830073043199673_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000163761338308082086240000012574216293338310576_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000150082634892801110770000012287118204868666753_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000070829311029673526940000012938063616617748879_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000034799294717942616160000001291082938255890365_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000067232135371297437240000001200459129138070966_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000139263329652486900560000014153183172296556214_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000035504962979862186520000010377994539139480731_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000023251474390844563990000007886344049607076514_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000143594006420130765060000017109067127056190375_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000137831917016581557290000009651583288716068500_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000031182130362862654830000002388757624213543079_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000126310200072602258870000006699771504105598909_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000140716073438304090720000013258230931275145357_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000176746521759529764250000001492622063202280627_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000107588242710294620940000013475205640442908580_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000047758909093689796070000014226793887227384991_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000116196371617715842480000002637811391717427600_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000031171357436547932190000011136091575274153902_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000068659013878921285830000000836945964367237270_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000148645802280557464950000011020773208198009491_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000026845911738014836410000000912248505545869202_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000065774602603777081690000007178721658742222776_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000181078367395216901610000009629592345522934434_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000172439436752486419590000013979080630715712170_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000105424011285941014810000000998923614144592573_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000065037559888810417710000002557437850730434212_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000164475032584184535320000011513071426625563781_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000141414104316644101380000007759596840918427833_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000006708974390928228830000017613699294356429190_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000168090101207166668460000009345778887022755499_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000041995816314789141740000006772527824691186570_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000173127380497498591850000005303505064147045807_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000031169113534133297180000007800358566903198092_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000085248305672015799590000017459213845902561170_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000095339765662501987330000005061615760637554855_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000160910268599734368390000013584934926968010114_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000054245382641545199110000013554226106656821655_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000077291043000139130170000016428302448372678300_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000061437506977594812500000010404454775231133585_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000088103871751126805000000004173191562298974893_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000072239988445956544070000002741652481732282280_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000108274260644242930890000002493622766689557426_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000016032845001766644930000008543814256929176199_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000062896659481910578190000011463246197177472913_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000067923446247164559510000006535591641920116133_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000117663462859319919890000018101415182119883190_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000131353653136282512290000008208119730556146860_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000088103153752118728650000016943637441888858513_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000141422720263172204350000002182369138915813254_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000095334970692455555160000012068466968188929950_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000117654825487602457120000009405961979663346831_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000054254800264075839390000007617569326377929130_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000174585535276825491740000017353998817844851859_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000158026750319718099320000011209948281939131548_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000039122719197134663750000002170170257945655991_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000005978074169147525770000006372385199754606233_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000155136554273518142760000002675993219058772365_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000039100945520716371340000017855946349168508862_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000076579285021038739780000012043407398590820021_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000043428452377273127130000002097302102621053348_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000009550395168974155920000010609347650963851144_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000180361423869281748100000014794612841054787478_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000026844697907361216670000003793300680018315182_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000132784450356064232400000017717599040651247536_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000129202273435481237440000002566269425299187094_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000009547704042669442800000003811492291613316275_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000005253076408831810160000012660424609389333905_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000074420460097063904740000017324977904549814704_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000177449313660801008130000010753450105049708956_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000132766843037456003720000005508979553276755336_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <g>
        <path
          className="st1"
          d="M35,90.9c-1.5,0-3.3-0.2-4.8-0.6c-10.9-2.5-17.6-13.6-14.9-24.5c1.3-5.4,4.6-9.8,9.2-12.6
			c4.6-2.9,10.1-3.6,15.3-2.5l0,0c10.9,2.7,17.6,13.6,14.9,24.5c-1.3,5.4-4.6,9.8-9.2,12.6C42.5,89.9,38.8,90.9,35,90.9z M35,52.8
			c-3.3,0-6.3,1-9.2,2.7c-4,2.5-6.9,6.3-8,10.9c-2.3,9.6,3.6,19.1,13,21.4c4.6,1.1,9.4,0.4,13.4-2.1c4-2.5,6.9-6.3,8-10.9
			c2.3-9.6-3.6-19.1-13-21.4C37.9,53,36.3,52.8,35,52.8z"
        />
      </g>
      <g>
        <rect
          x="49.8"
          y="54"
          transform="matrix(0.6119 -0.7909 0.7909 0.6119 -20.892 67.9724)"
          className="st0"
          width="18"
          height="2.5"
        />
      </g>
      <g>
        <rect
          x="34.6"
          y="42.3"
          transform="matrix(0.6119 -0.7909 0.7909 0.6119 -17.4008 51.64)"
          className="st0"
          width="18.6"
          height="2.5"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M63.9,49.7c-3.6,0-7.3-1.1-10.1-3.6c-6.9-5.6-8-15.9-2.5-22.8s15.9-8,22.8-2.5l0,0c6.9,5.6,8,15.9,2.5,22.8
			C73.3,47.8,68.5,49.7,63.9,49.7z M63.9,19.8c-4,0-8,1.7-10.7,5.2c-4.8,5.9-3.8,14.5,2.1,19.3s14.5,3.8,19.1-2.1
			c4.8-5.9,3.8-14.5-2.1-19.3l0,0C69.8,20.8,66.8,19.8,63.9,19.8z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M96.8,84.9c-0.2,0-0.2,0-0.4,0l-12.8-3.8c-0.6-0.2-1-0.8-1-1.5l1.9-10.9l-3.6-4.4L64.7,49.3
			c-0.6-0.6-0.6-1.3,0-1.9s1.3-0.6,1.9,0l16.3,15.1c0,0,0,0,0.2,0.2l4.2,4.8c0.2,0.4,0.4,0.8,0.4,1.1L85.3,79l11.9,3.6
			c0.8,0.2,1.1,1,1,1.5C98,84.7,97.4,84.9,96.8,84.9z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M108.7,74.8c-0.6,0-1.1-0.4-1.3-1.1l-1.7-12.2l-10.5,0.2c-0.4,0-0.8-0.2-1-0.4l-4.2-4.8c0,0,0,0-0.2-0.2
			L77.7,36.9c-0.4-0.6-0.2-1.3,0.4-1.7c0.6-0.4,1.3-0.2,1.7,0.4L92,54.9l3.6,4.2l11.1-0.2c0.6,0,1.1,0.4,1.3,1.1l1.9,13.4
			C110.2,74,109.6,74.6,108.7,74.8C108.9,74.8,108.9,74.8,108.7,74.8z"
        />
      </g>
      <g>
        <rect
          x="84.4"
          y="63.1"
          transform="matrix(0.7597 -0.6503 0.6503 0.7597 -20.1133 74.3539)"
          className="st1"
          width="12.2"
          height="2.5"
        />
      </g>
      <path
        className="st1"
        d="M81.7,64.8c-0.4,0-0.8-0.2-1-0.4c-0.4-0.6-0.4-1.3,0.2-1.7l9.8-8.2c0.6-0.4,1.3-0.4,1.7,0.2
		c0.4,0.6,0.4,1.3-0.2,1.7l-9.8,8.2C82.5,64.6,82.1,64.8,81.7,64.8z"
      />
      <g>
        <path
          className="st1"
          d="M35,78.4c-0.6,0-1.1,0-1.9-0.2c-2.1-0.6-3.6-1.7-4.8-3.4c-1.1-1.7-1.3-3.8-1-5.9c1-4.2,5.2-6.7,9.4-5.7l0,0
			c4.2,1,6.7,5.2,5.7,9.4c-0.6,2.1-1.7,3.6-3.4,4.8C37.9,78,36.5,78.4,35,78.4z M35,65.4c-2.3,0-4.4,1.5-5,4c-0.4,1.3-0.2,2.7,0.6,4
			c0.8,1.1,1.9,2.1,3.3,2.3c1.3,0.4,2.7,0.2,4-0.6c1.3-0.8,2.1-1.9,2.3-3.3c0.8-2.9-1-5.6-3.8-6.3C36,65.4,35.4,65.4,35,65.4z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M63.9,39.6c-1.3,0-2.7-0.4-3.8-1.3c-2.7-2.1-3.1-5.9-1-8.4c2.1-2.7,5.9-3.1,8.4-1l0,0c2.7,2.1,3.1,5.9,1,8.4
			C67.3,38.8,65.6,39.6,63.9,39.6z M63.9,30c-1,0-1.9,0.4-2.7,1.3c-1.1,1.5-1,3.6,0.6,4.8c1.5,1.1,3.6,1,4.8-0.6
			c1.1-1.5,1-3.6-0.6-4.8C65.4,30.4,64.7,30,63.9,30z"
        />
      </g>
    </g>
  </svg>
);

export const Retail = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
    .st1{fill:#5D5D5D;}
    .st2{clip-path:url(#SVGID_00000087380431127506325700000004926990845407643525_);fill:#5D5D5D;}
    .st3{clip-path:url(#SVGID_00000064354014516915280570000008174550436004887201_);fill:#5D5D5D;}
    .st4{clip-path:url(#SVGID_00000116921202512643570760000017369798782447060134_);fill:#5D5D5D;}
    .st5{clip-path:url(#SVGID_00000178921992677810446590000009708925868693812886_);fill:#5D5D5D;}
    .st6{clip-path:url(#SVGID_00000177483392099227502900000001484422123344557492_);fill:#5D5D5D;}
    .st7{clip-path:url(#SVGID_00000168117633798482744090000012703718265673912987_);fill:#5D5D5D;}
    .st8{clip-path:url(#SVGID_00000031176544943974973900000001826725558175882883_);fill:#5D5D5D;}
    .st9{clip-path:url(#SVGID_00000087393524962240919680000006629117446188511417_);fill:#5D5D5D;}
    .st10{clip-path:url(#SVGID_00000046338991649358262680000015365308530926840470_);fill:#5D5D5D;}
    .st11{clip-path:url(#SVGID_00000067941706540855095730000002456954969915648425_);fill:#5D5D5D;}
    .st12{clip-path:url(#SVGID_00000123411862761319719230000006659109167864303240_);fill:#5D5D5D;}
    .st13{clip-path:url(#SVGID_00000037687313951176897320000014794428611874246791_);fill:#5D5D5D;}
    .st14{clip-path:url(#SVGID_00000055671795534272599030000012515572949639286935_);fill:#5D5D5D;}
    .st15{clip-path:url(#SVGID_00000026867463530444130810000018269145028651738781_);fill:#5D5D5D;}
    .st16{clip-path:url(#SVGID_00000053535793675974092270000015466793110364370576_);fill:#5D5D5D;}
    .st17{clip-path:url(#SVGID_00000092441458866386513820000016827073289093912472_);fill:#5D5D5D;}
    .st18{clip-path:url(#SVGID_00000128447381140922973580000012199634838522255514_);fill:#5D5D5D;}
    .st19{clip-path:url(#SVGID_00000134965346308478997380000014650421388763999885_);fill:#5D5D5D;}
    .st20{clip-path:url(#SVGID_00000031171364982718571670000011868852080642967177_);fill:#5D5D5D;}
    .st21{clip-path:url(#SVGID_00000004527012077089122090000013141511515823965068_);fill:#5D5D5D;}
    .st22{clip-path:url(#SVGID_00000012444866598231626560000016724580783136765627_);fill:#5D5D5D;}
    .st23{clip-path:url(#SVGID_00000011731301915599416510000008857242167920296066_);fill:#5D5D5D;}
    .st24{clip-path:url(#SVGID_00000105425467650575470220000016221622595606673826_);fill:#5D5D5D;}
    .st25{clip-path:url(#SVGID_00000138558673029031276480000017810628606534685314_);fill:#5D5D5D;}
    .st26{clip-path:url(#SVGID_00000081633168117189164560000008977579573549416872_);fill:#5D5D5D;}
    .st27{clip-path:url(#SVGID_00000092415341113048992830000011796765221555849624_);fill:#5D5D5D;}
    .st28{clip-path:url(#SVGID_00000142148766130676369420000000542457294800579203_);fill:#5D5D5D;}
    .st29{clip-path:url(#SVGID_00000033358317126650491090000010782636943205750920_);fill:#5D5D5D;}
    .st30{clip-path:url(#SVGID_00000047747816310450806540000006062024262752815777_);fill:#5D5D5D;}
    .st31{clip-path:url(#SVGID_00000004528116192433760040000010828254793375830968_);fill:#5D5D5D;}
    .st32{clip-path:url(#SVGID_00000119077191348595412000000014258303786352587688_);fill:#5D5D5D;}
    .st33{clip-path:url(#SVGID_00000124140921309706748570000012437028295018955445_);fill:#5D5D5D;}
    .st34{clip-path:url(#SVGID_00000001661067483276489170000013184821102664048022_);fill:#5D5D5D;}
    .st35{clip-path:url(#SVGID_00000142885603915532527140000011881583987128703375_);fill:#5D5D5D;}
    .st36{clip-path:url(#SVGID_00000020389851275079707040000009846776385153224868_);fill:#5D5D5D;}
    .st37{clip-path:url(#SVGID_00000083777862562277091070000005069726263857762192_);fill:#5D5D5D;}
    .st38{clip-path:url(#SVGID_00000001632641334573270970000011344385195297832587_);fill:#5D5D5D;}
    .st39{clip-path:url(#SVGID_00000121268931600310841110000005912550668489555841_);fill:#5D5D5D;}
    .st40{clip-path:url(#SVGID_00000153695199800151646120000016633756985736094884_);fill:#5D5D5D;}
    .st41{clip-path:url(#SVGID_00000088838370696054214440000002117647335359551421_);fill:#5D5D5D;}
    .st42{clip-path:url(#SVGID_00000120542095304714202870000002934372565923464364_);fill:#5D5D5D;}
    .st43{clip-path:url(#SVGID_00000078021795329358448160000014167584482219450273_);fill:#5D5D5D;}
    .st44{clip-path:url(#SVGID_00000095306199199223240650000005756426054608137870_);fill:#5D5D5D;}
    .st45{clip-path:url(#SVGID_00000034073736868504966780000006481460072978921385_);fill:#5D5D5D;}
    .st46{clip-path:url(#SVGID_00000029009505868257341800000012852614024000033962_);fill:#5D5D5D;}
    .st47{clip-path:url(#SVGID_00000107574183606020269500000006020207894384093361_);fill:#5D5D5D;}
    .st48{clip-path:url(#SVGID_00000162337918527064750380000013254107436506335113_);fill:#5D5D5D;}
    .st49{clip-path:url(#SVGID_00000160149665862993042460000009020610875613737902_);fill:#5D5D5D;}
    .st50{clip-path:url(#SVGID_00000042004248649008801670000018082133906474369215_);fill:#5D5D5D;}
    .st51{clip-path:url(#SVGID_00000064350184477483710590000005260747570049420678_);fill:#5D5D5D;}
    .st52{clip-path:url(#SVGID_00000169555117615555085350000012204895129958608273_);fill:#5D5D5D;}
    .st53{clip-path:url(#SVGID_00000089536679356977254600000006482800618611456958_);fill:#5D5D5D;}
    .st54{clip-path:url(#SVGID_00000026859665498130569740000007419901252999586233_);fill:#5D5D5D;}
    .st55{clip-path:url(#SVGID_00000012462760740945170560000009184017950798114746_);fill:#5D5D5D;}
    .st56{clip-path:url(#SVGID_00000076587400253014085130000007017758275395710877_);fill:#5D5D5D;}
    .st57{clip-path:url(#SVGID_00000047018733986382581350000001409843022048215424_);fill:#5D5D5D;}
    .st58{clip-path:url(#SVGID_00000127012738586781554860000012711425760751042447_);fill:#5D5D5D;}
    .st59{clip-path:url(#SVGID_00000079453292683588258140000001545711616073650327_);fill:#5D5D5D;}
    .st60{clip-path:url(#SVGID_00000018917478021642575940000016151674121322118059_);fill:#5D5D5D;}
    .st61{clip-path:url(#SVGID_00000101804116358954160490000014840202669694100113_);fill:#5D5D5D;}
    .st62{clip-path:url(#SVGID_00000052082030563803187970000003239760327018668938_);fill:#5D5D5D;}
    .st63{clip-path:url(#SVGID_00000155109305436453403370000018346048371535326141_);fill:#5D5D5D;}
    .st64{clip-path:url(#SVGID_00000174559933210756955280000013902597037886229377_);fill:#5D5D5D;}
    .st65{clip-path:url(#SVGID_00000177457006873447660450000001106280470761947033_);fill:#5D5D5D;}
    .st66{clip-path:url(#SVGID_00000044857932640749419130000013878113696445079448_);fill:#5D5D5D;}
    .st67{clip-path:url(#SVGID_00000177464570274769824590000008081145677444851338_);fill:#5D5D5D;}
    .st68{clip-path:url(#SVGID_00000065038160606437663390000003078794653631439267_);fill:#5D5D5D;}
    .st69{clip-path:url(#SVGID_00000062170014401037485970000003628415434707632548_);fill:#5D5D5D;}
    .st70{clip-path:url(#SVGID_00000071529921985354276600000011956207626262883507_);fill:#5D5D5D;}
    .st71{clip-path:url(#SVGID_00000023281838499986291320000005443426501172978353_);fill:#5D5D5D;}
    .st72{clip-path:url(#SVGID_00000182523452698615249080000016342392713284924303_);fill:#5D5D5D;}
    .st73{clip-path:url(#SVGID_00000045582109906005466580000016383411175411421569_);fill:#5D5D5D;}
    .st74{clip-path:url(#SVGID_00000149360159560682321090000016056740351225451702_);fill:#5D5D5D;}
    .st75{clip-path:url(#SVGID_00000075127268027819953570000017908377754531730065_);fill:#5D5D5D;}
    .st76{clip-path:url(#SVGID_00000077290021870698351730000006802558010369779112_);fill:#5D5D5D;}
    .st77{clip-path:url(#SVGID_00000080198751173201342850000001002055490231586955_);fill:#5D5D5D;}
    .st78{clip-path:url(#SVGID_00000057827691342270968660000008754769842300603309_);fill:#5D5D5D;}
    .st79{clip-path:url(#SVGID_00000071530709984532277760000009376863026611767989_);fill:#5D5D5D;}
  `}
    </style>
    <g>
      <path
        className="st0"
        d="M97.9,87c-0.2,0-0.4,0-0.6-0.2c-0.6-0.2-1-1-0.6-1.5l5.6-12.8H47.4l-8.2,14c-0.4,0.6-1,0.8-1.5,0.6
      c-0.6-0.2-1-0.8-0.8-1.5L41,62.9l-10.9-48H17.5c-0.8,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3h13.8c0.6,0,1.1,0.4,1.3,1l11.1,49.4
      c0,0.2,0,0.4,0,0.6l-2.9,15.3l4.8-8c0.2-0.4,0.8-0.6,1.1-0.6h57.8c0.4,0,1,0.2,1.1,0.6s0.4,0.8,0.2,1.3l-6.3,14.7
      C99,86.8,98.5,87,97.9,87z"
      />
      <path
        className="st0"
        d="M42.2,64.2c-0.8,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l61.2-2.7l2.3-31L33,23.3c-0.8,0-1.3-0.8-1.3-1.3
      c0-0.8,0.8-1.3,1.3-1.3l74.3,4.8c0.4,0,0.8,0.2,1,0.4c0.2,0.2,0.4,0.6,0.4,1l-2.5,33.5c0,0.8-0.6,1.1-1.3,1.1L42.2,64.2L42.2,64.2z
      "
      />
      <path
        className="st1"
        d="M38.4,96c-5.6,0-10.1-4.6-10.1-10.1s4.6-10.1,10.1-10.1s10.1,4.6,10.1,10.1C48.5,91.4,44.1,96,38.4,96z
       M38.4,78.2c-4.2,0-7.7,3.4-7.7,7.7c0,4.2,3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7C46,81.6,42.6,78.2,38.4,78.2z"
      />
      <path
        className="st1"
        d="M97.9,96c-5.6,0-10.1-4.6-10.1-10.1s4.6-10.1,10.1-10.1S108,80.3,108,85.8C108.2,91.4,103.6,96,97.9,96z
       M97.9,78.2c-4.2,0-7.7,3.4-7.7,7.7c0,4.2,3.4,7.7,7.7,7.7c4.2,0,7.7-3.4,7.7-7.7C105.5,81.6,102.1,78.2,97.9,78.2z"
      />
      <path
        className="st1"
        d="M77.2,54.8H65v-9.8h12.2V54.8z M81,36.5l-4.8,3.4l11.3,15.9l4.8-3.4L81,36.5z M61.9,36.8h-8v19.5h8V36.8z"
      />
    </g>
  </svg>
);

export const disaterRestored = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000073000112780365547970000005833193012689620665_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000137134198435830865930000017838761425755529654_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000036218709608742231960000006060270273222361475_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000040540717766948186480000003772919679949069460_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000033356784025724573150000001709357208034319768_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000123426480020912718620000012097787904445697666_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000164499444600458247880000007649027849126493585_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000180324406877821210050000009981265588244467637_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000084495509078442331540000008769777884432831677_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000105405003779817999070000017769884001965011114_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000029762595014500956410000010504328397715669150_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000170250680890080249370000011310952416589245074_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000157285168589345488300000018293331959891112842_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000087399864819993462390000004317237828985386687_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000168801629318555995220000014222746019568282764_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000152223190269700806980000003761250701823312572_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000163040273340213377680000017919606696291139206_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000178887589301223480480000011984001465007083693_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000057106445902291741420000015236961389516266662_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000065045808664194600880000003924793508998638506_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000130639636317649055190000004855681540142452664_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000148642959803171457870000003317159076122919553_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000170252522899858486730000005229001400139887793_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000060028747720320282510000014230020977657769147_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000131330660005454081290000014892673722317335713_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000150092115389754395930000016193347818686298507_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000010284326657444544010000004803927422553478322_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000017515107332184459480000015888679206542976903_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000063606868884160111720000016335090722666035867_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000132780910773724632610000002057370334358969002_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000020385059851224310430000016835737047270772401_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000166647624308659621390000012424134451313982866_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000177477059352652630440000001528305344401374393_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000173872947262148794950000013268488470646653883_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000183937468578900994980000006384755985308759190_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000030485083856114980440000009196392673205400474_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000134250812300018686350000017649381571954922632_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000055709039993073301670000004725541018356736701_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000124840663208943278430000002371093788026346404_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000069362879399677947810000000616676082816091527_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000007419382839716759260000013489571988801757861_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000071533545270317733820000006803063937414618246_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000029751713411460649380000016086359780284132784_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000096755495775494171460000018376706307057152180_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000025420764738097175040000014250742849023244216_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000051374978308772757630000006319915769900283069_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000121981088597924893780000009683648251408499346_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000041264797052947045390000014262245105258414253_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000082359967986919736900000001051178070724088740_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000119828373674602444610000015659187749763725485_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000004520289434173705770000012603829420950901644_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000015344869624391964880000007450395074284973968_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000024702863490663558190000000887985883374621861_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000114062213793038733530000010921419973459678099_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000139990225738056784170000009698030332093637769_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000021800751590040537380000016164674943507750070_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000165211025714527464510000010437912224999103903_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000011716685869296504910000014764925867822914740_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000181776627349339332450000014170693542349366400_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000040534085651229689570000000406000779391977609_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000083048832680442286350000005686250571811014826_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000153698585549061044450000017713100397052541065_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000012460108454216181340000014912015142053798038_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000010284313969863266310000013578405667463084202_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000118381018041019315480000006862852980353972357_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000137124167025445021600000014161114166442027660_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000042013076997072578520000018200929907276245662_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000019675282650815347210000007845518979541289401_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000120533600146849902770000014393506570130060472_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000041257851384789923700000016338592850159340987_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000026132124677067089820000003302955580358390147_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000157309290781355691070000007089262852512542617_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000051366749178944151400000005339256721366790792_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000040562160190272275770000004428996987742052246_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000042017725982831695880000006062305315325575093_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000111173061307599705680000009546595713714444216_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000019637617672587018630000013269421862057296769_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000071519050272866444330000000044208378527905958_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <path
        className="st1"
        d="M82.8,61.7c-0.4,0-0.8,0-1.1-0.2c-8.4-2.5-17-5-25.5-7.3c-5.4-1.5-10.5-3.1-15.9-4.6c-1.1-0.4-1.7-1-2.1-1.9
		c-1.1-3.4-1.9-6.9-1.9-10.3c0,0,0-0.2,0-0.4c-0.6,0-1,0-1.5,0c-2.5,0-4.4-1.7-4.2-4c0-2.3,1.7-3.8,4.4-3.8c23.9,0,47.8,0,71.8,0
		c2.5,0,4.2,1.7,4.4,4c0,1.1-0.4,2.1-1.1,2.9c-0.8,0.8-1.9,1.1-3.1,1.1c-0.8,0-1,0-1.3,1.1c-4,10.5-11.1,18.4-20.7,23.4
		C84.1,61.6,83.4,61.7,82.8,61.7z M37.6,34.6H39v1.9c0,0.4,0,0.6,0,1c0,3.1,0.6,6.3,1.7,9.6c0,0.2,0.2,0.2,0.4,0.4
		c5.4,1.5,10.5,3.1,15.9,4.6c8.4,2.5,17,5,25.5,7.3c0.2,0,0.8,0,1,0c9.2-4.6,15.7-11.9,19.5-21.8c0.6-1.3,1.5-2.9,4-2.7
		c0.6,0,1-0.2,1.1-0.4c0.2-0.2,0.4-0.6,0.4-1c0-1-1-1.3-1.7-1.3c-23.9,0-47.8,0-71.8,0c-0.8,0-1.7,0.2-1.7,1.3
		c0,1.1,1.1,1.5,1.7,1.5c0.6,0,1.1,0,1.7,0L37.6,34.6z"
      />
      <path
        className="st0"
        d="M82.4,56c-0.4,0-1-0.2-1.1-0.8c-0.4-0.6-0.2-1.3,0.6-1.7c7.3-3.8,12.4-9.8,15.7-18c0.2-0.6,1-1,1.7-0.8
		c0.6,0.2,1,1,0.8,1.7c-3.4,8.8-9,15.1-16.8,19.3C83,55.8,82.6,56,82.4,56z"
      />
      <path
        className="st0"
        d="M101,86.6c-7.1,0-13-5-14.5-11.9c-0.2-0.8,0.4-1.3,1-1.5c0.8-0.2,1.3,0.4,1.5,1c1.1,5.7,6.1,9.8,11.9,9.8
		c6.7,0,12.2-5.6,12.2-12.2s-5.6-12.2-12.2-12.2c-3.4,0-6.7,1.5-9,4c-0.6,0.6-1.3,0.6-1.9,0s-0.6-1.3,0-1.9
		c2.9-3.1,6.7-4.8,10.9-4.8c8.2,0,14.7,6.7,14.7,14.7C115.7,79.9,109.2,86.6,101,86.6z"
      />
      <path
        className="st0"
        d="M98.7,72.3c-0.2,0-0.2,0-0.4,0L31.7,53.1l-8.4-21.4H6c-0.8,0-1.3-0.6-1.3-1.3S5.3,29,6,29H25l8.6,22l65.3,18.8
		c0.8,0.2,1.1,1,1,1.5C99.8,71.9,99.2,72.3,98.7,72.3z"
      />
      <path
        className="st0"
        d="M35.5,86.8c-0.4,0-0.6-0.2-1-0.4c-0.4-0.4-0.6-1-0.4-1.3l9.6-29.9c0.2-0.8,1-1,1.5-0.8c0.8,0.2,1.1,1,0.8,1.5
		l-8.6,27.2l43.3-18.4c0.6-0.2,1.3,0,1.7,0.8c0.2,0.6,0,1.3-0.8,1.7L36.1,86.6C35.9,86.8,35.7,86.8,35.5,86.8z"
      />
    </g>
  </svg>
);

export const hospitality = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000075852771771646939340000001675559295182511259_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000096034812379255808190000016368628295616836482_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000003790894679511402380000004817089542518505656_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000137824353878687597060000005415725292060968625_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000114072340640780578240000005427124742935461254_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000064347520688461756070000004815505874197770113_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000088814909548491171890000001762375266495742365_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000035503449986785054080000000672942170348335287_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000039822113509433184890000004301654631429015190_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000163058030780454734610000001076719310908795292_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000062878034435995280670000017678733963231880383_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000103953809506795113730000002634927522201535150_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000051353048981841479380000014597470103308953746_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000158718152461538290500000017580413153460664229_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000087384845145315292990000001470257852912165559_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000109723654740900040110000006288822055482556805_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000178206160411533828560000007254136788420660360_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000076602264372696381070000003832265013664594338_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000084494799061680774820000007956279909000398779_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000020369991349554504330000008473792325132668833_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000131356824909326099620000008743473685257771180_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000170257689169199259140000015103041170452449974_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000036965876804984613560000006007398481376007340_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000098916572178452096390000015836576796393507721_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000107563761649829115790000012120029374633639073_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000111186463457325145680000011762943929876822151_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000026143506015666998290000018324040841722178194_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000155829762451216253220000005607279902383218594_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000048496415387558601020000004615961846143168428_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000083088311073299987250000014781205949632344210_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000032624564964370736640000012727871546250334366_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000054231254531772548510000013887821886169528465_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000098931108144214257030000007529243313968062863_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000067952197154055473280000000278133312026955670_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000082335171318512777200000002518543269168839346_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000129167449582070680650000008754742388787339906_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000173844957854710575290000013724132104013151908_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000019676629174781255850000017642090880572268466_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000017488351532113167140000017886000692336239529_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000108271992230182031160000013681084410146977927_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000110452429327300996980000003418988003851443587_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000031930370618207018500000013673887691073147787_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000029025105357695948750000005799071091818512529_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000031911953754016287110000005503222053693000879_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000120561057883346528010000017744403254598585984_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000093895331215735498430000013358162978054311577_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000078044944318729508220000006739625698041573769_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000181063768342507011800000003905352180294035343_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000026130852363763680710000009601713748885557416_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000061458148698036196540000008601406772660588686_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000114796265974082369490000008473252561839703465_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000108291281226037119270000015088000250684550054_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000041988046912291267590000005821862302735191974_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000026127908624162758690000010577477314418859916_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000139257610492444086370000017383318231711182984_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000117677157501426310380000009257962906975083678_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000152962568409874019080000001881113710878610830_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000080184801483232877630000016667335014240487836_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000128446850364370755170000011621975402387648689_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000148634900071069634640000011615685623790944131_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000006701012135816877250000014989218883577349818_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000159452073413659652140000008237163164978012075_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000051368746186962325120000009285740334715203260_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000088113684190523370770000007234194485548960154_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000147933704304833779220000010688299489167996349_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000033334475205254951920000009394163088646749343_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000089535431813427698570000008334162082086501040_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000036973288682247406610000010916234569897274789_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000170261844226044482130000009184471299114248885_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000044873651514233245360000002048518915722289300_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000114070538621364358940000015118357621964189605_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000160157087498200776610000007623397270537264523_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000120542989875273225580000009309559437090722978_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000124853687912362504620000003007752386278604466_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000051353928552910106180000008033519008302060955_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000154385555046995069990000004814243717739995800_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000077299186587759539750000001244595925067473803_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000047024593219639518770000008508511798079802023_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <rect x="18.1" y="27.8" className="st0" width="92.8" height="11.5" />
      <path
        className="st0"
        d="M28.4,80.9h-5.4c-3.4,0-6.3-2.9-6.3-6.3V20.4c0-3.4,2.9-6.3,6.3-6.3H106c3.4,0,6.3,2.9,6.3,6.3v54.2
		c0,3.3-2.3,5.7-5.6,6.1c-0.8,0-1.3-0.4-1.3-1.1s0.4-1.3,1.1-1.3c1.9-0.2,3.3-1.7,3.3-3.6V20.4c0-2.1-1.7-3.6-3.6-3.6H23.1
		c-2.1,0-3.6,1.7-3.6,3.6v54.2c0,2.1,1.7,3.6,3.6,3.6h5.4c0.8,0,1.3,0.6,1.3,1.3C29.8,80.3,29.2,80.9,28.4,80.9z"
      />
      <g>
        <path
          className="st1"
          d="M86.5,94.1c-5.6,0-10.3-3.6-12.4-9H48.4l-2.9-2.3l-2.9,2.3h-9.2l-4.8-5.7l4.8-5.7h41.2
			c2.3-5.2,6.9-8.4,12.2-8.4c7.5,0,13.4,6.5,13.4,14.5S94,94.1,86.5,94.1z M49.4,82.4H76l0.2,1c1.5,4.8,5.7,8,10.3,8
			c5.9,0,10.9-5.4,10.9-11.9c0-6.5-5-11.9-10.9-11.9c-4.6,0-8.4,2.9-10.1,7.5L76,75.9H34.5l-2.7,3.3l2.7,3.3h7.1l3.8-3.1L49.4,82.4z
			"
        />
      </g>
      <circle className="st0" cx="64.5" cy="79.5" r="3.1" />
      <path
        className="st0"
        d="M90.5,49.1H38.5c-0.8,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h52.1c0.8,0,1.3,0.6,1.3,1.3
		C91.9,48.5,91.3,49.1,90.5,49.1z"
      />
    </g>
  </svg>
);

export const wasteRecycle = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 124.8 108.1"
    style={{ enableBackground: "new 0 0 124.8 108.1;" }}
  >
    <style type="text/css">
      {`.st0{fill:#00BDA7;}
	.st1{fill:#5D5D5D;}
	.st2{clip-path:url(#SVGID_00000072245946263689537400000010259056925826224300_);fill:#5D5D5D;}
	.st3{clip-path:url(#SVGID_00000155140648186226195910000007506685919588108181_);fill:#5D5D5D;}
	.st4{clip-path:url(#SVGID_00000089555745209243013640000008157816693770528686_);fill:#5D5D5D;}
	.st5{clip-path:url(#SVGID_00000116930235916850312220000017399821173357428415_);fill:#5D5D5D;}
	.st6{clip-path:url(#SVGID_00000164515608136488462800000016172330492952388492_);fill:#5D5D5D;}
	.st7{clip-path:url(#SVGID_00000060749509178175773740000001228804419384602529_);fill:#5D5D5D;}
	.st8{clip-path:url(#SVGID_00000037665441625732078080000014573064090067398839_);fill:#5D5D5D;}
	.st9{clip-path:url(#SVGID_00000140732092800281090370000000249945393387924149_);fill:#5D5D5D;}
	.st10{clip-path:url(#SVGID_00000170240167349541559760000012779563925753266566_);fill:#5D5D5D;}
	.st11{clip-path:url(#SVGID_00000096747363637066128940000006660866282962695578_);fill:#5D5D5D;}
	.st12{clip-path:url(#SVGID_00000007407696389965755550000017432752864749691790_);fill:#5D5D5D;}
	.st13{clip-path:url(#SVGID_00000136402202263180560740000015341848279858699136_);fill:#5D5D5D;}
	.st14{clip-path:url(#SVGID_00000053522499577245330690000010370170944116375716_);fill:#5D5D5D;}
	.st15{clip-path:url(#SVGID_00000178170681625184252820000000309951808000990373_);fill:#5D5D5D;}
	.st16{clip-path:url(#SVGID_00000008135086353038128560000017971777792704945564_);fill:#5D5D5D;}
	.st17{clip-path:url(#SVGID_00000075127005856702242820000011514448034727724690_);fill:#5D5D5D;}
	.st18{clip-path:url(#SVGID_00000129193830448184718870000005139176770057366694_);fill:#5D5D5D;}
	.st19{clip-path:url(#SVGID_00000158014654020339758770000000138548480540459199_);fill:#5D5D5D;}
	.st20{clip-path:url(#SVGID_00000039851859809068557710000010702276118909317527_);fill:#5D5D5D;}
	.st21{clip-path:url(#SVGID_00000037676053988916655940000009812253983075503268_);fill:#5D5D5D;}
	.st22{clip-path:url(#SVGID_00000159463591779787386690000017478694417373043348_);fill:#5D5D5D;}
	.st23{clip-path:url(#SVGID_00000004507472247061686560000004882760619577627285_);fill:#5D5D5D;}
	.st24{clip-path:url(#SVGID_00000175292626383481809290000010435407966634328743_);fill:#5D5D5D;}
	.st25{clip-path:url(#SVGID_00000031166477405417172550000003219522756841632649_);fill:#5D5D5D;}
	.st26{clip-path:url(#SVGID_00000112600395893102865690000012526830641903255700_);fill:#5D5D5D;}
	.st27{clip-path:url(#SVGID_00000174579076948630892750000006982473804947385740_);fill:#5D5D5D;}
	.st28{clip-path:url(#SVGID_00000088095837223541158650000009041421925959795377_);fill:#5D5D5D;}
	.st29{clip-path:url(#SVGID_00000116939821914744464750000015837728708278698672_);fill:#5D5D5D;}
	.st30{clip-path:url(#SVGID_00000095306001017594290680000002039354530126836365_);fill:#5D5D5D;}
	.st31{clip-path:url(#SVGID_00000096759221166399675210000010690500282840747649_);fill:#5D5D5D;}
	.st32{clip-path:url(#SVGID_00000008862849169842186590000004088741475794767260_);fill:#5D5D5D;}
	.st33{clip-path:url(#SVGID_00000140010813382057289130000011526538358673632140_);fill:#5D5D5D;}
	.st34{clip-path:url(#SVGID_00000172433717782659018650000011752682065862972801_);fill:#5D5D5D;}
	.st35{clip-path:url(#SVGID_00000176726732762023975330000004371868450246147209_);fill:#5D5D5D;}
	.st36{clip-path:url(#SVGID_00000169540952120581945470000001853501939315230600_);fill:#5D5D5D;}
	.st37{clip-path:url(#SVGID_00000021837683948728904080000004973619833789863603_);fill:#5D5D5D;}
	.st38{clip-path:url(#SVGID_00000080927797963551684860000005958180133157481858_);fill:#5D5D5D;}
	.st39{clip-path:url(#SVGID_00000062888050947120821320000000939270080205814686_);fill:#5D5D5D;}
	.st40{clip-path:url(#SVGID_00000179611505712781161110000005141073654601521054_);fill:#5D5D5D;}
	.st41{clip-path:url(#SVGID_00000065753763357235940440000013924663322311681947_);fill:#5D5D5D;}
	.st42{clip-path:url(#SVGID_00000127031293557361894080000016839746888361900971_);fill:#5D5D5D;}
	.st43{clip-path:url(#SVGID_00000146493005914801837020000001189831625938991756_);fill:#5D5D5D;}
	.st44{clip-path:url(#SVGID_00000181050959124148110330000018383954644265255335_);fill:#5D5D5D;}
	.st45{clip-path:url(#SVGID_00000118356331674024903070000004731589605080553405_);fill:#5D5D5D;}
	.st46{clip-path:url(#SVGID_00000097491526190801773230000000665801389178979718_);fill:#5D5D5D;}
	.st47{clip-path:url(#SVGID_00000018948570913835264050000000565460021485064857_);fill:#5D5D5D;}
	.st48{clip-path:url(#SVGID_00000039841470325157075130000000661691664322246059_);fill:#5D5D5D;}
	.st49{clip-path:url(#SVGID_00000049189461671840193790000004115262079354556833_);fill:#5D5D5D;}
	.st50{clip-path:url(#SVGID_00000047025604585610099610000006703785811701395350_);fill:#5D5D5D;}
	.st51{clip-path:url(#SVGID_00000042707093589686456130000010104086471659770293_);fill:#5D5D5D;}
	.st52{clip-path:url(#SVGID_00000077304244126151356860000005655234793455175842_);fill:#5D5D5D;}
	.st53{clip-path:url(#SVGID_00000016769416787560517660000010259196945465161655_);fill:#5D5D5D;}
	.st54{clip-path:url(#SVGID_00000117672760015456059480000010801549969247743677_);fill:#5D5D5D;}
	.st55{clip-path:url(#SVGID_00000020374960838003403060000000452656064372992444_);fill:#5D5D5D;}
	.st56{clip-path:url(#SVGID_00000021809392204637926230000014955032584530491834_);fill:#5D5D5D;}
	.st57{clip-path:url(#SVGID_00000067231943594247744270000007641959221066720956_);fill:#5D5D5D;}
	.st58{clip-path:url(#SVGID_00000023251236580994993830000010080162841643177404_);fill:#5D5D5D;}
	.st59{clip-path:url(#SVGID_00000125567970353556219620000000060899871117920128_);fill:#5D5D5D;}
	.st60{clip-path:url(#SVGID_00000087390826460987125030000017325490589794489992_);fill:#5D5D5D;}
	.st61{clip-path:url(#SVGID_00000113316117703356680360000005142531822003562167_);fill:#5D5D5D;}
	.st62{clip-path:url(#SVGID_00000085218935078746795750000007813206348577496479_);fill:#5D5D5D;}
	.st63{clip-path:url(#SVGID_00000043449862008988109400000012182544861427915152_);fill:#5D5D5D;}
	.st64{clip-path:url(#SVGID_00000120556765406987108190000015292594385090547874_);fill:#5D5D5D;}
	.st65{clip-path:url(#SVGID_00000093855260048713696330000016156696663515919747_);fill:#5D5D5D;}
	.st66{clip-path:url(#SVGID_00000098902880888002582000000016870987703111775374_);fill:#5D5D5D;}
	.st67{clip-path:url(#SVGID_00000003064182720365959710000008079053726007626131_);fill:#5D5D5D;}
	.st68{clip-path:url(#SVGID_00000137850767045149019260000003743124869335683519_);fill:#5D5D5D;}
	.st69{clip-path:url(#SVGID_00000006675931329040177500000008924901462544609413_);fill:#5D5D5D;}
	.st70{clip-path:url(#SVGID_00000072240446183222276350000007097397194897674652_);fill:#5D5D5D;}
	.st71{clip-path:url(#SVGID_00000149346776818494647490000009513647916462055563_);fill:#5D5D5D;}
	.st72{clip-path:url(#SVGID_00000066512785306967438260000013215967714736934532_);fill:#5D5D5D;}
	.st73{clip-path:url(#SVGID_00000031927416045097050070000000951326441682146220_);fill:#5D5D5D;}
	.st74{clip-path:url(#SVGID_00000010296383002005075050000015828748595816695473_);fill:#5D5D5D;}
	.st75{clip-path:url(#SVGID_00000095313763100460618220000015566674512989836429_);fill:#5D5D5D;}
	.st76{clip-path:url(#SVGID_00000144332238386585270250000015498819473241333903_);fill:#5D5D5D;}
	.st77{clip-path:url(#SVGID_00000166669735183623165130000015381098408607974547_);fill:#5D5D5D;}
	.st78{clip-path:url(#SVGID_00000000185427841194559500000013477754226236385688_);fill:#5D5D5D;}
	.st79{clip-path:url(#SVGID_00000111180876221533790400000000268667993956607668_);fill:#5D5D5D;}
`}
    </style>
    <g>
      <path
        className="st1"
        d="M31.1,28.1l-0.6-3.8c-0.4-1.9,1-3.8,3.1-4.2l6.5-1.1l-0.2-1.1c-0.4-1.9,1-3.8,3.1-4.2l37.3-6.1
		c1-0.2,1.9,0,2.7,0.6s1.3,1.3,1.3,2.3l0.2,1.1l6.7-1c1-0.2,1.9,0,2.5,0.6c0.8,0.6,1.3,1.3,1.3,2.3l0.6,4L31.1,28.1z M80.7,10.1
		L80.7,10.1l-37.3,6.1c-0.6,0.2-1,0.6-1,1.3l0.6,3.6l-9,1.5c-0.6,0.2-1,0.6-1,1.1l0.2,1.1l59.5-9.8l-0.2-1.3c0-0.4-0.6-0.8-1-0.8
		l-9.2,1.5l-0.6-3.8c0-0.2-0.2-0.4-0.4-0.6C81,10.1,80.8,10.1,80.7,10.1z"
      />
      <g>
        <path
          className="st0"
          d="M90,28.3H38c-2.1,0-3.6,1.7-3.6,3.6v10.7c0,1.3,1.1,2.7,2.3,3.3v25.6c0.8-0.4,1.7-0.6,2.5-0.8V44l-1-0.2
			c-0.8-0.2-1.3-0.8-1.3-1.1V31.9c0-0.6,0.6-1.1,1.1-1.1h52.3c0.6,0,1.1,0.6,1.1,1.1v10.7c0,0.6-0.6,1.1-1.1,1.1h-3.1L83,93
			c0,0.6-0.6,1-1.1,1H58.3c-0.4,1-1,1.7-1.5,2.5h24.9c1.9,0,3.4-1.5,3.6-3.4l4-46.7H90c2.1,0,3.6-1.7,3.6-3.6V31.9
			C93.9,29.8,92.1,28.3,90,28.3z"
        />
      </g>
      <path
        className="st0"
        d="M43.1,100.8c-7.7,0-14-6.3-14-14s6.3-14,14-14s14,6.3,14,14S50.8,100.8,43.1,100.8z M43.1,75.5
		c-6.3,0-11.3,5.2-11.3,11.3c0,6.1,5.2,11.3,11.3,11.3S54.4,93,54.4,86.8C54.4,80.7,49.5,75.5,43.1,75.5z"
      />
      <circle className="st0" cx="43.1" cy="86.8" r="4.6" />
      <g>
        <path
          className="st1"
          d="M73.8,47.4l-3.8,2.9c2.1,1.9,3.4,4.6,3.4,7.5c0,5.6-4.6,10.1-10.1,10.1s-10.1-4.6-10.1-10.1
			c0-3.4,1.9-6.7,4.6-8.4c1.1-0.8,2.5-1.3,3.8-1.5v3.8l4.8-3.4l3.3-2.3l-3.6-2.7l-4.2-3.1v3.1c-1.3,0.2-2.7,0.4-3.8,1
			c-5.6,2.1-9.4,7.5-9.4,13.8c0,8.2,6.7,14.7,14.7,14.7S78,66,78,57.9C78,53.7,76.3,50.1,73.8,47.4z"
        />
      </g>
    </g>
  </svg>
);

export const aboutIcon1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115.12"
    height="130.84"
    viewBox="0 0 115.12 130.84"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M1138.14,2040.6H1023V1909.76h115.14V2040.6Zm-109.91-5.23h104.68V1914.99H1028.23v120.38Zm52.34-38.43a14.37,14.37,0,1,1-14.37,14.37A14.372,14.372,0,0,1,1080.57,1996.94Zm-30.86-41.43a13.02,13.02,0,1,1,13.02-13.02A13.034,13.034,0,0,1,1049.71,1955.51Zm0-21.15a8.13,8.13,0,1,0,8.13,8.13A8.143,8.143,0,0,0,1049.71,1934.36Zm30.86,21.15a13.02,13.02,0,1,1,13.02-13.02A13.034,13.034,0,0,1,1080.57,1955.51Zm0-21.15a8.13,8.13,0,1,0,8.13,8.13A8.143,8.143,0,0,0,1080.57,1934.36Zm30.86,21.15a13.02,13.02,0,1,1,13.02-13.02A13.034,13.034,0,0,1,1111.43,1955.51Zm0-21.15a8.13,8.13,0,1,0,8.13,8.13A8.143,8.143,0,0,0,1111.43,1934.36Zm-28.16,68.19h-5.23v-50.32h5.23v50.32Zm-0.47,8.33-2.16-4.77,17.83-8.03a18.345,18.345,0,0,0,10.79-16.7v-28.09h5.24v28.09a23.6,23.6,0,0,1-13.88,21.47Zm-4,0-17.83-8.03a23.6,23.6,0,0,1-13.88-21.47v-28.09h5.23v28.09a18.355,18.355,0,0,0,10.8,16.7l17.82,8.03Z"
      transform="translate(-1023 -1909.75)"
    />
  </svg>
);
export const aboutIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115.125"
    height="130.84"
    viewBox="0 0 115.125 130.84"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M267.788,2371.85H152.649V2241.01H267.788v130.84Zm-109.9-5.23H262.554V2246.25H157.883v120.37Zm30.819-81.7h43.034v43.03H188.7v-43.03Zm56.47,56.47H175.265v-69.91h69.907v69.91Zm-64.673-5.24h59.44v-59.44H180.5v59.44Zm32.337,30.97H207.6v-30.17h5.234v30.17Zm-18.828,0h-5.233v-30.17h5.233v30.17Zm37.655,0h-5.234v-30.17h5.234v30.17Zm34.981-58.07H242.555v-5.23h24.089v5.23Zm0,18.83H242.555v-5.24h24.089v5.24Zm0-37.66H242.555v-5.23h24.089v5.23Zm-88.762,18.83H153.793v-5.23h24.089v5.23Zm0,18.83H153.793v-5.24h24.089v5.24Zm0-37.66H153.793v-5.23h24.089v5.23Z"
      transform="translate(-152.656 -2241)"
    />
  </svg>
);

export const aboutIcon3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115.094"
    height="128.21"
    viewBox="0 0 115.094 128.21"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M267.408,2040.13h-5.291v-71.48c0-28.41-23.44-51.54-52.25-51.54s-52.249,23.13-52.249,51.54v71.48h-5.292v-71.48c0-31.29,25.813-56.75,57.541-56.75s57.541,25.46,57.541,56.75v71.48Zm-4.842,0h-105.4v-5.22h105.4v5.22Zm-52.7-23.75-1.42-1.55a177.3,177.3,0,0,1-13.9-17.75c-9.33-13.64-14.062-25.03-14.062-33.84a29.385,29.385,0,0,1,58.764,0c0,8.81-4.731,20.2-14.062,33.84a177.3,177.3,0,0,1-13.9,17.75Zm0-78.35a25.414,25.414,0,0,0-25.556,25.21c0,16.76,19.992,41.02,25.556,47.45,5.563-6.43,25.556-30.7,25.556-47.45A25.414,25.414,0,0,0,209.867,1938.03Zm0,39.68a14.471,14.471,0,1,1,14.667-14.47A14.585,14.585,0,0,1,209.867,1977.71Zm0,40.61-2.4-2.62a176.32,176.32,0,0,1-14.016-17.89c-9.481-13.86-14.287-25.49-14.287-34.57a30.707,30.707,0,0,1,61.408,0c0,9.08-4.806,20.71-14.287,34.57a175.591,175.591,0,0,1-14.017,17.89Zm0-82.76a27.905,27.905,0,0,0-28.059,27.68c0,8.55,4.655,19.69,13.835,33.11a172.415,172.415,0,0,0,13.785,17.6l0.439,0.48,0.438-.48a173.032,173.032,0,0,0,13.786-17.6c9.18-13.42,13.835-24.56,13.835-33.11A27.905,27.905,0,0,0,209.867,1935.56Zm0,77.14-1.006-1.16c-6.069-7.01-25.872-31.21-25.872-48.3a26.881,26.881,0,0,1,53.756,0c0,17.07-19.8,41.28-25.872,48.3Zm0-73.36a24.094,24.094,0,0,0-24.232,23.9c0,15.37,17.4,37.38,24.232,45.43,6.834-8.06,24.232-30.08,24.232-45.43A24.094,24.094,0,0,0,209.867,1939.34Zm0,39.67a15.771,15.771,0,1,1,15.989-15.77A15.9,15.9,0,0,1,209.867,1979.01Zm0-28.93a13.161,13.161,0,1,0,13.344,13.16A13.266,13.266,0,0,0,209.867,1950.08Zm0,25.16a12.094,12.094,0,0,1-12.163-12h2.645a9.519,9.519,0,0,0,19.036,0h2.645A12.094,12.094,0,0,1,209.867,1975.24Zm54.9,47.17H153.98v-5.22H264.762v5.22Z"
      transform="translate(-152.312 -1911.91)"
    />
  </svg>
);

export const abouIcon4 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="115.19"
    height="130.87"
    viewBox="0 0 115.19 130.87"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #fff;
        fill-rule: evenodd;
      }`}
      </style>
    </defs>
    <path
      className="cls-1"
      d="M1078.09,2314.66c-0.02-.05-0.04-0.1-0.06-0.14l-5.25-11.96,3.32-7.57a4.786,4.786,0,0,1,8.84,0l4.38,9.99,4.44-10.09a4.708,4.708,0,0,1,6.4-2.47,5.255,5.255,0,0,1,2.33,6.79l-8.69,19.78a5.013,5.013,0,0,1-2.15,2.38,3.9,3.9,0,0,1-.35.19,4.7,4.7,0,0,1-6.4-2.47l-4.38-9.96Zm-11.17-19.67a4.708,4.708,0,0,0-6.4-2.47,5.255,5.255,0,0,0-2.33,6.79l8.69,19.78a4.711,4.711,0,0,0,6.41,2.47,5.249,5.249,0,0,0,2.33-6.79Zm71.01,76.09h-5.31v-72.93a52.281,52.281,0,1,0-104.56,0v72.93h-5.31v-72.93c0-31.93,25.84-57.92,57.59-57.92s57.59,25.99,57.59,57.92v72.93Zm-4.85,0H1027.6v-5.34h105.48v5.34Zm-50.83-112.6a2.871,2.871,0,0,0-3.82,0l-9.3,8.26a2.85,2.85,0,0,1-2.18.71l-12.35-1.18a2.88,2.88,0,0,0-3.09,2.25l-2.69,12.18a2.967,2.967,0,0,1-1.35,1.87l-10.68,6.34a2.9,2.9,0,0,0-1.19,3.65l4.94,11.45a2.9,2.9,0,0,1,0,2.31l-4.94,11.44a2.914,2.914,0,0,0,1.19,3.66l10.68,6.34a2.942,2.942,0,0,1,1.35,1.87l2.69,12.17a2.888,2.888,0,0,0,3.09,2.26l12.35-1.18a2.85,2.85,0,0,1,2.18.71l9.3,8.26a2.871,2.871,0,0,0,3.82,0l9.3-8.26a2.862,2.862,0,0,1,2.18-.71l12.35,1.18a2.888,2.888,0,0,0,3.09-2.26l2.7-12.17a2.874,2.874,0,0,1,1.35-1.87l10.68-6.34a2.921,2.921,0,0,0,1.18-3.66l-4.94-11.44a2.9,2.9,0,0,1,0-2.31l4.94-11.45a2.908,2.908,0,0,0-1.18-3.65l-10.68-6.34a2.9,2.9,0,0,1-1.35-1.87l-2.7-12.18a2.88,2.88,0,0,0-3.09-2.25l-12.35,1.18a2.862,2.862,0,0,1-2.18-.71Zm-2.39,80.55a34.351,34.351,0,1,0-34.15-34.35A34.26,34.26,0,0,0,1079.86,2339.03Zm23.48,31.72h-46v-29.07h5.31v23.73h35.38v-23.73h5.31v29.07Z"
      transform="translate(-1022.75 -2240.22)"
    />
  </svg>
);
