import React, { useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import AdminHeader from "../../components/adminHeader/header";
import { Navigate, Outlet } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";

const AdminLayout = ({ setIsAuthenticated, loginData }) => {
  const [activeTab, setActiveTab] = useState("wePlaced");
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  // Function to toggle sidebar collapse state
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  console.log("activeTab",activeTab)
  if (loginData) {
    return (
      <div
        className={`admin-layout ${
          sidebarCollapsed ? "sidebar-collapsed" : "sidebar-expanded"
        }`}
        style={{ display: "flex" }}
      >
        <Sidebar
          setIsAuthenticated={setIsAuthenticated}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          collapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />
        <div
          className="admin-content"
          style={{
            flex: 1,
            transition: "margin-left 0.3s ease",
            marginLeft: sidebarCollapsed ? "80px" : "250px",
          }}
        >
          <AdminHeader
            userName="Admin"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            toggleSidebar={toggleSidebar}
          />
          <div className="content_wrapper">
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to={adminPathUrl.login} />;
  }
};

export default AdminLayout;
