import React, { useState } from "react";
import { Formik, Form } from "formik";
import "./addworkforce.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PhoneInputComponent from "../../../components/PhoneInputComp";
import { ages, countries, languages, passport } from "../../../utils/helpers";
import FormikSelect from "../../../components/FormikFields/FormikSelect";
import FormikInput from "../../../components/FormikFields/FormikInput";
import ImageUpload from "../../../components/ImageUpload";
import { useDispatch } from "react-redux";
import { addWorkforce } from "../../../services/admin/manageworkforce/manageworkforceservices";
import { useLocation, useNavigate } from "react-router-dom";
import SkillsSelection from "../../../components/FormikFields/FormikSkillsSelection";
import { toast } from "react-toastify";

const AddWorkForce = () => {
  const [previewImage, setPreviewImage] = useState(null); // State for preview image

  //redux functions
  const dispatch = useDispatch();

  //router functions
  const navigate = useNavigate();

  const location = useLocation();
  const skillsList = location.state;

  //formik initial state
  const initialValues = {
    firstName: "",
    lastName: "",
    // email: "",
    phone: "",
    profilePic: null,
    age: "",
    nationality: "",
    skills: [],
    countryCode: "",
    passport: "",
    language: "",
  };

  // const handleSubmit = async (values, { resetForm }) => {
  //   const data = {
  //     fullName: values.firstName.concat(" ", values.lastName),
  //     countryCode: values.countryCode,
  //     phone: values.phone,
  //     age: values.age,
  //     nationality: values.nationality,
  //     skill: values.skills,
  //     // profile_pic: values.profilePic,
  //     is_passport: values.passport,
  //     language: values.language,
  //     // is_verified: false,
  //     // is_active: false,
  //     created_by: "admin",
  //   };
  //   try {
  //     const response = await addWorkforce(data);
  //     if (response.status === true) {
  //       navigate(-1);
  //     } else {
  //       console.error("Failed to add category:", response.message);
  //       resetForm();
  //     }
  //   } catch (error) {
  //     console.error("Error adding category:", error);
  //   }
  // };
  console.log("skillsList",skillsList)
  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      fullName: values.firstName.concat(" ", values.lastName),
      countryCode: values.countryCode,
      phone: values.phone,
      age: values.age,
      nationality: values.nationality,
      skill: values.skills,
      // profile_pic: values.profilePic,
      is_passport: values.passport,
      language: values.language,
      // is_verified: false,
      // is_active: false,
      created_by: "admin",
    };
    try {
      const response = await addWorkforce(data);
      if (response.status === true) {
        navigate(-1);
      } else {
        toast.error("Failed to add category:", response.message);
        // resetForm();
      
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    // if (!values.email) {
    //   errors.email = "Email is required";
    // } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
    //   errors.email = "Invalid email address";
    // }
    if (!values.nationality) {
      errors.nationality = "Nationality is required";
    }
    if (!values.age) {
      errors.age = "Age is required";
    }
    if (!values.skills) {
      errors.skills = "Skills is required";
    }
    if (!values.passport) {
      errors.passport = "Passport is required";
    }
    if (!values.language) {
      errors.language = "Language is required";
    }
    if (values.skills.length <= 0) {
      errors.skills = "Skills are required";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
    } else if (!/^\+[1-9]\d{1,14}$/.test(values.countryCode + values.phone)) {
      errors.phone = "Invalid phone number format";
    }

    return errors;
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("profilePic", file);

    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onPhoneChange = (value, setFieldValue) => {
    console.log(value, "value");
    setFieldValue("phone", value.number);
    setFieldValue("countryCode", value.countryCode);
  };

  return (
    <div className="add_user_page">
      <div className="card shadow p-5 m-5">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="row">
                <ImageUpload
                  mainClass="col-md-4 form-group profile_pic_update"
                  previewConClass="img_block"
                  previewImage={previewImage}
                  placeholder="Profile Picture"
                  id="profilePic"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  inputClass="form-control-file d-none"
                  name="profilePic"
                  label="Upload Profile"
                  labelClass="global_admin_btn"
                />

                <div className="col-md-8 ps-4">
                  <h2 className="text-center pb-4">Add Workforce</h2>
                  <div className="form-group">
                    <div className="form-row row">
                      <FormikInput
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="firstName"
                      />
                      <FormikInput
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="lastName"
                      />
                    </div>
                  </div>
                  <div className="form-row row">
                    {/* <FormikInput
                      type="email"
                      id="email"
                      placeholder="Email"
                      name="email"
                      fieldClass="form-control"
                      mainClass="form-group col-md-6"
                      errName="email"
                    /> */}

                    <div className="form-group col-md-6">
                      <PhoneInputComponent
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(value) =>
                          onPhoneChange(value, setFieldValue)
                        }
                        errName="phone"
                      />
                    </div>
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="age"
                      name="age"
                      mainClass="form-group col-md-6"
                      errName="age"
                      options={ages}
                      placeholder="Select Age"
                    />
                    <FormikSelect
                      as="select"
                      id="nationality"
                      name="nationality"
                      mainClass="form-group col-md-6"
                      errName="nationality"
                      options={countries}
                      placeholder="Select Nationality"
                    />
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="passport"
                      name="passport"
                      mainClass="form-group col-md-6"
                      errName="passport"
                      options={passport}
                      placeholder="Select Passport"
                    />
                    <FormikSelect
                      as="select"
                      id="language"
                      name="language"
                      mainClass="form-group col-md-6"
                      errName="language"
                      options={languages}
                      placeholder="Select Language"
                    />
                  </div>
                  <SkillsSelection
                    errName="skills"
                    data={skillsList}
                    onSelect={(value) => setFieldValue("skills", value)}
                  />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="global_admin_btn me-2"
                      disabled={isSubmitting}
                    >
                      Add Workforce
                    </button>
                    <button
                      type="button"
                      className="global_admin_btn"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddWorkForce;
