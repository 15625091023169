import React from "react";
import Spinner from "react-bootstrap/Spinner";

const ButtonLoader = () => {
  return (
    <Spinner
      animation="border"
      role="status"
      style={{ height: "25px", width: "25px" }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default ButtonLoader;
