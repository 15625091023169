import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  addPrivacyPolicy,
  getCMSContentsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import LoaderSpinner from "../../../components/loaderSpinner/spinner";

function ManagePrivacyPolicy() {
  const [content, setContent] = useState(""); // State to manage editor content
  const [cmsContents, setCMSContents] = useState(null); // State to manage CMS contents
  const [isWeplaced, setIsWeplaced] = useState(false); // State to manage is_weplaced flag
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage loading

  const MAX_LENGTH = 8129;

  // Function to handle editor content change
  const handleEditorChange = (value) => {
    if (value?.length <= MAX_LENGTH) {
      setContent(value);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set loading state to true
    try {
      // Create payload object
      const payload = {
        privacy_policy: content,
        is_weplaced: isWeplaced, // Include is_weplaced flag in payload
      };

      const response = await addPrivacyPolicy(payload);

      if (response.status === true) {
        toast.success("Privacy Policy updated successfully!", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Failed to update Privacy Policy: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error updating privacy policy.");
      console.error("Error updating privacy policy:", error);
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };

  // Effect to fetch CMS contents when component mounts
  useEffect(() => {
    const fetchCMSContents = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[0]);
          setContent(response.data[0].privacy_policy);
          setIsWeplaced(response.data[0].is_weplaced === true);

          // Console log the value of is_weplaced
          console.log("is_weplaced from API:", response.data[0].is_weplaced);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchCMSContents();
  }, []);

  return (
    <div className="manage_privacy_policy_page card p-4 rounded shadow">
      <PageTile heading1={"Manage Privacy Policy"} />
      {isWeplaced && (
        <CommonEditor
          value={content}
          onChange={handleEditorChange}
          maxLength={MAX_LENGTH}
        />
      )}
      <div className="py-3">
        <button
          className="global_admin_btn"
          onClick={handleSubmit}
          disabled={isSubmitting} // Disable button while submitting
        >
          {isSubmitting ? <LoaderSpinner /> : "Save"}
        </button>
      </div>
    </div>
  );
}

export default ManagePrivacyPolicy;
