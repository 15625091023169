import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import { LogoSvg } from "../../../assests/svg";
import { adminPathUrl } from "../../../shared/pathUrl";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import "./resetPassword.css";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle new password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State to toggle confirm password visibility
  const [token, setToken] = useState(""); // State to hold the token

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      // toast.error("Token not found in the URL");
      navigate(adminPathUrl.forgotPassword); // Redirect to an appropriate page if no token
    }
  }, [location.search, navigate]);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setError("Both fields are required");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError("");
    setLoading(true);

    try {
      const res = await axios.post(`${API_URL_ENDPOINT.resetPassword}`, {
        token,
        newPassword: password
      });

      if (res?.status === 200) {
        toast.success("Password reset successfully");
        navigate("/admin");
      } else {
        toast.error("Failed to reset password");
      }
    } catch (error) {
      // Log and handle detailed error
      console.error("Error details:", error);
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <section className="auth_section">
      <Container>
        <div className="login_block">
          <Link
            to="#"
            className="d-flex align-items-center justify-content-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
          >
            <div
              className="header-logo-worker-list border-end-0"
              style={{ backgroundColor: "white" }}
            >
              <span style={{ cursor: "pointer", textAlign: "center" }}>
                {LogoSvg()}
              </span>
            </div>
          </Link>

          <h2 className="">Reset Password</h2>
          <Form className="form_reset_password" onSubmit={handleSubmit}>
            <div className="form_input position-relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="New Password"
                className="form-control"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="off"
              />
              {password && (
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                >
                  {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              )}
            </div>
            <div className="form_input position-relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="Confirm Password"
                className="form-control"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                autoComplete="off"
              />
              {confirmPassword && (
                <span
                  className="password-toggle"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                >
                  {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              )}
            </div>
            {error && <span className="text-danger mb-2 d-block">{error}</span>}
            <div className="btn_login text-center">
              <button className="global_admin_btn" type="submit">
                {loading ? "Loading..." : "Reset Password"}
              </button>
            </div>
            <div className="text-center mt-3">
              <Link to={adminPathUrl.forgotPassword} className="back-to-login-link">
                Back
              </Link>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default ResetPassword;
