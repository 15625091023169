import UpdateReview from "../../admin/adminPages/manageReviews/updateReviews/updateReview";

const baseUrl = process.env.REACT_APP_API_URL;
export const API_URL_ENDPOINT = {
  getWorkerasperthecategory: `${baseUrl}/manpowerEnquiry`,
  getDeleteWorkForceList:`${baseUrl}/deletedProfiles`,
  downloadedCsvPdfData:`${baseUrl}/extractListing`,
  getCategory: `${baseUrl}/getNewSkillSet`,
  submitManPowerDataFromtheCart: `${baseUrl}/add_cart`,
  getTheBlog: `${baseUrl}/getBlogs`,
  // admin
  adminlogin: `${baseUrl}/login`,
  forgotPassword :`${baseUrl}/forgetPassword`,
  resetPassword :`${baseUrl}/resetPassword`,

  getworkforcelistingUrl: `${baseUrl}/manpowerList`,
  getworkforceskill: `${baseUrl}/getNewSkillSet`,
  getenquirylist: `${baseUrl}/manpowerEnquiryList`,
  getenquirylistId: `${baseUrl}/manpowerEnquiryList/:id`,
  getProfileId: `${baseUrl}/getAdminProfile/:id`,
  updateProfile: `${baseUrl}/getAdminProfile/:id`,
  addSubSkill: `${baseUrl}/addSubSkill`,
  verifyWorkforceUser: `${baseUrl}/verifiedUserProfile/:id`,

  addCategory: `${baseUrl}/addSkill`,
  updateCategoryWithSubCat: `${baseUrl}/updateSkill`,
  addBlog: `${baseUrl}/addBlog`,
  getBlogs: `${baseUrl}/getBlogs`,
  updateBlog: `${baseUrl}/editBlog`,
  deleteBlog: `${baseUrl}/deleteBlog/:id`,
  deleteCategory: `${baseUrl}/deleteSkill`,
  getreview: `${baseUrl}/getReviews`,
  addreview: `${baseUrl}/addReview`,
  UpdateReview: `${baseUrl}/editReview`,

  deletereview: `${baseUrl}/deleteReview`,
  addWorkforce: `${baseUrl}/addProfile`,
  addDataLakeWorkforce: `${baseUrl}/addWorkforce`,
  editDataLakeWorkforce: `${baseUrl}/editProfile`,
  addPrivacyPolicy: `${baseUrl}/addContent`,
  addTermsAndCond: `${baseUrl}/addContent`,
  addAboutUs: `${baseUrl}/addAboutUs`,
  getAboutUs: `${baseUrl}/getAboutUs`,
  getSkillset: `${baseUrl}/getSkillSet `,
  addSkillset: `${baseUrl}/addSkillSet `,
  deleteSkillSet: `${baseUrl}/deleteSkillSet/:id`,

  wePlacedEnquiry: `${baseUrl}/weplacedEnquiry`,
  addexecutive: `${baseUrl}/addExecutive`,
  getExecutive: `${baseUrl}/getExecutive`,
  updateExecutive: `${baseUrl}/editExecutive/:id`,
  deleteexecutive: `${baseUrl}/deleteExecutive/:id`,
  //cms endpoints
  getReviews: `${baseUrl}/getReviews`,
  addContactUs: `${baseUrl}/contactUs`,
  getCMSContents: `${baseUrl}/getContents`,
  getBanner: `${baseUrl}/getBanner`,
  deleteBanner: `${baseUrl}/banner/:id`,
  updateBanner: `${baseUrl}/editBanner/:id`,
  //manage user crud
  addAdminUser: `${baseUrl}/addAdminUser`,
  getAdminUsersList: `${baseUrl}/getAdminUsers`,
  deleteAdminUser: `${baseUrl}/deleteAdminUser/:id`,
  updateAdminUser: `${baseUrl}/updateAdminUser/:id`,
  getSkillSetList: `${baseUrl}/getSkillSet`,
  updateSkillSet: `${baseUrl}/editSkillSet/:id`,
  getBannersList: `${baseUrl}/getBanner`,
  getHomePageWeplaced: `${baseUrl}/getweplacedHomepage`,
  addHomePageWeplaced: `${baseUrl}/addWeplacedHomepage`,
  addstatusWorkforce:`${baseUrl}/deactivateUserProfile`,

  //News and article
  getNewsArticle: `${baseUrl}/getNewsArticle`,
  addNewsArticle: `${baseUrl}/addNewsArticle`,
  updateNewsArticle: `${baseUrl}/editNewsArticle`,
  deleteNewsArticle: `${baseUrl}/deleteNewsArticle/:id`,
  getWorkerDetail: `${baseUrl}/getProfile`,
  // gulfkaam homepage
  getGulfHomepage: `${baseUrl}/getHomepage`,
  addGulfHomepage: `${baseUrl}/addHomepage`,
  // dashboard
  getDashboardData: `${baseUrl}/adminDashboard`,
  // mark as completed
  addCompletedStatus: `${baseUrl}/markAsCompleted/:id`,
  updateMyProfileAdmin: `${baseUrl}/updateMyProfile/:id`,
  addNotification: `${baseUrl}/addNotification`,
  updateNotification:`${baseUrl}/editNotification`,
  deleteNotification: `${baseUrl}/deleteNotification/:id`,

  getGulfNotifications: `${baseUrl}/getNotifications`,
};
