import { createSlice } from "@reduxjs/toolkit";
import { MAN_POWER_ENQUIRY_REDUCER } from "./manPowerEnquiryReducer";
export const initialState = {
  formData: {
    name: {
      value: "",
      warning: false,
    },
    email: {
      value: "",
      warning: false,
    },
    contactNumber: {
      value: "",
      warning: false,
    },
    companyName: {
      value: "",
      warning: false,
    },
    country: {
      value: "",
      warning: false,
    },
    city: {
      value: "",
      warning: false,
    },
    accomodation: {
      value: "",
      warning: false,
    },
    transportation: {
      value: "",
      warning: false,
    },
    message: {
      value: "",
      warning: false,
    },
    tenure: {
      value: "",
      warning: false,
    },
    userType: {
      value: "",
      warning: false,
    },
    categoryWorkerRequired: [],
  },
  categoryTable: [],
  selectedCart: [],
  cartSelectedItem: [],
  editNavigation: false,
  userId: null,
};

export const manPowerEnquirySlice = createSlice({
  name: "manPowerEnquiry",
  initialState: initialState,
  reducers: MAN_POWER_ENQUIRY_REDUCER,
});
