import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MaxLimit from "../MaxLimit";
import { getTextLength } from "../../utils/methods";

const CommonEditor = ({
  value,
  onChange,
  maxLength = 5,
  showMaxCount = true,
  label,
  maxWidth = "100%"
}) => {
  const [content, setContent] = useState(value);
  const editorRef = useRef(null);

  useEffect(()=>{
setContent(value)
  },[value])
  useEffect(() => {
    const editor = editorRef.current.getEditor();

    const handleTextChange = () => {
      const plainText = editor.getText();
      if (plainText.length >= maxLength + 1) {
        editor.deleteText(maxLength, plainText.length);
      }
    };

    editor.on("text-change", handleTextChange);

    return () => {
      editor.off("text-change", handleTextChange);
    };
  }, [content]);

  const handleChange = (newValue) => {
    const editor = editorRef.current.getEditor();
    const plainText = editor.getText();
    if (plainText.length <= maxLength + 1) {
      setContent(newValue);
      onChange(newValue);
    }
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <div className="mt-4" style={{ maxWidth }}>
      {label && <label>{label}</label>}
      {showMaxCount && (
        <MaxLimit current={getTextLength(content)} max={maxLength} />
      )}
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        style={{ maxWidth: maxWidth }}
      />
    </div>
  );
};

export default CommonEditor;
