import React, { useEffect, useState } from "react";
import { FaBlog, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { adminPathUrl } from "../../../shared/pathUrl";
import "./newsArticle.css";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import {
  deleteNewsArticle,
  getNewsArticles,
} from "../../../services/admin/manageworkforce/manageworkforceservices";

function ManageNewsArticle() {
  const navigate = useNavigate();
  const [newsArticleData, setNewsArticleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedNewsArticleId, setSelectedNewsArticleId] = useState(null);
  const [expandedNewsArticleId, setExpandedNewsArticleId] = useState(null);

  const handleAddNewsClick = () => {
    navigate(adminPathUrl.gulfAddNewsAtricle);
  };

  const handleCardClicked = (e, newsArticle) => {
    e.stopPropagation();
    navigate(`${adminPathUrl.gulfUpdateNewsAtricle}/${newsArticle._id}`, {
      state: { newsArticle: newsArticle },
    });
  };

  const handleDelete = (id) => {
    setSelectedNewsArticleId(id);
    setDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteNewsArticle(selectedNewsArticleId);
      setNewsArticleData(newsArticleData.filter(
        (newsArticle) => newsArticle._id !== selectedNewsArticleId
      ));
      setDeleteModal(false);
    } catch (error) {
      console.error("Error deleting newsArticle:", error);
      setError("Failed to delete the newsArticle. Please try again.");
    }
  };

  const handleReadMoreClick = (blogId) => {
    setExpandedNewsArticleId(expandedNewsArticleId === blogId ? null : blogId);
  };

  useEffect(() => {
    const fetchNewsArticles = async () => {
      try {
        setLoading(true);
        const response = await getNewsArticles();
        if (response && response.data) {
          setNewsArticleData(response.data);
        } else {
          setError("No News or Article data available.");
        }
      } catch (error) {
        console.error("Error fetching News or Article:", error);
        setError("Failed to fetch News or Article data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchNewsArticles();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const isVideo = (url) => {
    return url && (url.endsWith('.mp4') || url.endsWith('.avi') || url.endsWith('.mov'));
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <div className="d-flex justify-content-between">
        <PageTile heading1={"News and Articles"} />
        <button className="add-user-button" onClick={handleAddNewsClick}>
          <FaBlog /> Add News and Article
        </button>
      </div>

      <div className="row">
        {newsArticleData.map((item) => (
          <div key={item._id} className="col-md-4 col-sm-6 col-12">
            <div className="card_blogs">
              <div className="blogs_head">
                {isVideo(item.newsArticle_pic) ? (
                  <video
                    controls
                    src={item.newsArticle_pic}
                    alt={item.title}
                    className="media_content"
                    height={200}
                    width={"100%"}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={item.newsArticle_pic}
                    alt={item.title}
                    className="media_content"
                  />
                )}
              </div>
              <div className="card_bottom">
                <h5>{new Date(item.createdAt).toLocaleDateString()}</h5>
                <h3>{item.title}</h3>
                {expandedNewsArticleId === item._id ? (
                  <div className="expanded_content">
                    <p>{item.content}</p>
                    <button
                      className="btn btn-link mt-2"
                      onClick={() => setExpandedNewsArticleId(null)}
                    >
                      Read Less
                    </button>
                  </div>
                ) : null}

                <div className="read_more d-flex justify-content-between">
                  {expandedNewsArticleId !== item._id && (
                    <button
                      className="text-decoration-none global_admin_btn"
                      onClick={() => handleReadMoreClick(item._id)}
                    >
                      Read More
                    </button>
                  )}
                  <div className="group_btns">
                    <button
                      onClick={(e) => handleCardClicked(e, item)}
                      className="me-2 bg-transparent border-0"
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="bg-transparent border-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item._id);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this newsArticle?"}
        onConfirm={confirmDelete}
      />
    </div>
  );
}

export default ManageNewsArticle;
