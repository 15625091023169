import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addskillset } from "../../services/admin/manageworkforce/manageworkforceservices";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaSortDown } from "react-icons/fa";
import { adminPathUrl } from "../../shared/pathUrl";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const categories = location.state;

  const initialValues = {
    skillName: "",
    subSkillName: "",
  };

  const validationSchema = Yup.object().shape({
    skillName: Yup.string().required("Category is required"),
    subSkillName: Yup.string().required("Sub category is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await addskillset(values);
      if (response.status === true) {
        navigate(-1);
      } else {
        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate(adminPathUrl.category);
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add category"} backButton={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="skillName">Category Name</label>
                <div
                  className="row  combo_input"
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "7px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    className="col-4 px-0 "
                    style={{ borderRight: "2px solid #ccc" }}
                  >
                    <Field
                      as="select"
                      name="skillName"
                      className="form-control  mb-0 border-0 appearance-show mt-1"
                      value={values.skillName}
                      onChange={handleChange}
                    >
                      <option value="">Select option</option>
                      {categories.map((item, index) => (
                        <option key={index} value={item.skillName}>
                          {item.skillName}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-8 px-0">
                    <Field
                      type="text"
                      name="skillName"
                      className="form-control border-0 mb-0"
                      value={values.skillName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="skillName"
                  component="span"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="subSkillName">Sub Category Name</label>
                <Field
                  type="text"
                  name="subSkillName"
                  className="form-control"
                  value={values.subSkillName}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="subSkillName"
                  component="span"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="py-3">
              <Button
                type="submit"
                className="global_admin_btn btn btn-weplaced py-2 px-4 "
                disabled={isSubmitting}
              >
                Save
              </Button>
              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
