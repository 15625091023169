import React, { useEffect, useState } from "react";
import { FaBlog, FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  getbloglisting,
  deletebloglisting,
} from "../../../services/admin/CmsPages/cmsPagesServices";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { adminPathUrl } from "../../../shared/pathUrl";
import "./blogs.css";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";

function ManageBlogs() {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [expandedBlogId, setExpandedBlogId] = useState(null); // State to manage expanded blog post

  const handleAddBlogClick = () => {
    navigate(adminPathUrl.addblogs);
  };

  const handleCardClicked = (e, blog) => {
    e.stopPropagation();
    navigate(`${adminPathUrl.updateBlogs}/${blog._id}`, {
      state: { blog: blog },
    });
  };

  const handleDelete = (id) => {
    setSelectedBlogId(id);
    setDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deletebloglisting(selectedBlogId);
      setBlogData(blogData.filter((blog) => blog._id !== selectedBlogId));
      setDeleteModal(false);
    } catch (error) {
      console.error("Error deleting blog:", error);
      setError("Failed to delete the blog. Please try again.");
    }
  };

  const handleReadMoreClick = (blogId) => {
    setExpandedBlogId(expandedBlogId === blogId ? null : blogId); // Toggle expanded state
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await getbloglisting();
        if (response && response.data) {
          setBlogData(response.data);
        } else {
          setError("No blog data available.");
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to fetch blog data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <div className="d-flex justify-content-between">
        <PageTile heading1={"Blogs"} />
        <button className="add-user-button" onClick={handleAddBlogClick}>
          <FaBlog /> Add Blogs
        </button>
      </div>

      <div className="row">
        {blogData?.map((item) => (
          <div key={item._id} className="col-md-4 col-sm-6 col-12">
            <div className="card_blogs">
              <div className="blogs_head">
                <img src={item.blog_short_pic} alt={item.title} />
              </div>
              <div className="card_bottom">
                <h5>{new Date(item.createdAt).toLocaleDateString()}</h5>
                <h3>{item.title}</h3>
                {/* Toggle content based on expandedBlogId */}
                {expandedBlogId === item._id ? (
                  <div className="expanded_content">
                    <p>{item.content}</p>
                    <button
                      className="btn btn-link mt-2"
                      onClick={() => setExpandedBlogId(null)} // Handle Read Less
                    >
                      Read Less
                    </button>
                  </div>
                ) : null}

                <div className="read_more d-flex justify-content-between">
                  {expandedBlogId !== item._id && (
                    <button
                      className="text-decoration-none global_admin_btn"
                      onClick={() => handleReadMoreClick(item._id)}
                    >
                      Read More
                    </button>
                  )}
                  {/* <Link
                    to={`/blog/${item._id}`}
                    className="text-decoration-none"
                    onClick={(e) => e.stopPropagation()} // Prevent card click when clicking Read More
                  >
                    Read More
                  </Link> */}
                  <div className="group_btns">
                    <button
                      onClick={(e) => handleCardClicked(e, item)}
                      className="me-2 bg-transparent border-0"
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="bg-transparent border-0"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the card click from firing
                        handleDelete(item._id);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this blog?"}
        onConfirm={confirmDelete}
      />
    </div>
  );
}

export default ManageBlogs;
