import React from "react";
import "./title.css";
import BackButton from "../../BackButton";
function PageTile({ heading1, heading2, backButton }) {
  return (
    <>
      <div className="d-flex">
        {backButton && <BackButton />}
        {heading1 && <h1 className="heading1">{heading1}</h1>}
      </div>
      {heading2 && <h2 className="heading2">{heading2}</h2>}
    </>
  );
}

export default PageTile;
