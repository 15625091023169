import React from "react";

const RadioComp = ({
  type = "radio",
  name,
  checked,
  title,
  subTitle,
  onChange,
  value,
}) => {
  const handleClick = (e) => {
    if (e.target.type !== "radio") {
      e.currentTarget.querySelector("input").click();
    }
  };

  return (
    <div className="radio_Comp" onClick={handleClick}>
      <input
        type={type}
        name={name}
        onChange={onChange}
        checked={checked}
        value={value}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="heading_section">
        <p>{title}</p>
        <span>{subTitle}</span>
      </div>
    </div>
  );
};

export default RadioComp;
