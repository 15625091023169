export const STATUS_CLASES = {
  pending: "pending",
  completed: "completed",
};

export const countries = [
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Oman", label: "Oman" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Qatar", label: "Qatar" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Nepal", label: "Nepal" },
  { value: "Sri Lanka", label: "Sri Lanka" },
];

export const ages = [
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  { value: "86", label: "86" },
  { value: "87", label: "87" },
  { value: "88", label: "88" },
  { value: "89", label: "89" },
  { value: "90", label: "90" },
  { value: "91", label: "91" },
  { value: "92", label: "92" },
  { value: "93", label: "93" },
  { value: "94", label: "94" },
  { value: "95", label: "95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
  { value: "100", label: "100" },
];

export const workersSkills = [
  { value: "Plumbing", label: "Plumbing" },
  { value: "Electrical", label: "Electrical" },
  { value: "Carpentry", label: "Carpentry" },
  { value: "Masonry", label: "Masonry" },
  { value: "Painting", label: "Painting" },
  { value: "Welding", label: "Welding" },
];

export const passport = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const userTypes = [
  { value: "individual", label: "Individual" },
  { value: "recruiting_firm", label: "Recruiting Firm" },
];

export const languages = [
  { value: "English", label: "English" },
  { value: "हिंदी", label: "हिंदी" },
  { value: "বাংলা", label: "বাংলা" },
  { value: "తెలుగు", label: "తెలుగు" },
  { value: "मराठी", label: "मराठी" },
  { value: "தமிழ்", label: "தமிழ்" },
  { value: "ಕನ್ನಡ", label: "ಕನ್ನಡ" },
  { value: "മലയാളം", label: "മലയാളം" },
  { value: "ਪੰਜਾਬੀ", label: "ਪੰਜਾਬੀ" },
  { value: "ଓଡ଼ିଆ", label: "ଓଡ଼ିଆ" },
  { value: "සිංහල", label: "සිංහල" },
  { value: "नेपाली", label: "नेपाली" },
];

export const years = Array.from({ length: 30 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} years`,
}));

export const categoriesData = [
  {
    skillName: "Civil",
    subSkills: [
      { name: "Supervisor", workers: 0, days: 0, hours: 0 },
      { name: "Engineer", workers: 0, days: 0, hours: 0 },
      { name: "Foreman", workers: 0, days: 0, hours: 0 },
      { name: "Chargehand", workers: 0, days: 0, hours: 0 },
      { name: "Scaffolder Certified", workers: 0, days: 0, hours: 0 },
      { name: "Scaffolder Normal", workers: 0, days: 0, hours: 0 },
      { name: "Shuttering Carpenter", workers: 0, days: 0, hours: 0 },
      { name: "Steel Fixer", workers: 0, days: 0, hours: 0 },
      { name: "Gypsum Carpenter", workers: 0, days: 0, hours: 0 },
      { name: "Finishing Carpenter", workers: 0, days: 0, hours: 0 },
      { name: "Block Mason", workers: 0, days: 0, hours: 0 },
      { name: "Plaster Mason", workers: 0, days: 0, hours: 0 },
      { name: "Tile Mason", workers: 0, days: 0, hours: 0 },
      { name: "Sand Blaster", workers: 0, days: 0, hours: 0 },
      { name: "Wall Painter", workers: 0, days: 0, hours: 0 },
      { name: "Spray Painter", workers: 0, days: 0, hours: 0 },
      { name: "Wood Painter", workers: 0, days: 0, hours: 0 },
      { name: "Wood Polisher", workers: 0, days: 0, hours: 0 },
      { name: "Rigger", workers: 0, days: 0, hours: 0 },
      { name: "Civil Helper", workers: 0, days: 0, hours: 0 },
      { name: "Safety Officer", workers: 0, days: 0, hours: 0 },
    ],
  },
  {
    skillName: "Electrical",
    subSkills: [
      { name: "Pipe Fabricator", workers: 0, days: 0, hours: 0 },
      { name: "Steel Fabricator", workers: 0, days: 0, hours: 0 },
      { name: "Aluminum Fabricator", workers: 0, days: 0, hours: 0 },
      { name: "Aluminum Fitter", workers: 0, days: 0, hours: 0 },
      { name: "3G Welder", workers: 0, days: 0, hours: 0 },
      { name: "6G Welder", workers: 0, days: 0, hours: 0 },
      { name: "A6G Welder", workers: 0, days: 0, hours: 0 },
      { name: "MIG Welder", workers: 0, days: 0, hours: 0 },
      { name: "TIG Welder", workers: 0, days: 0, hours: 0 },
      { name: "Insulator", workers: 0, days: 0, hours: 0 },
      { name: "Pipe Fitter", workers: 0, days: 0, hours: 0 },
      { name: "Helper", workers: 0, days: 0, hours: 0 },
    ],
  },
  {
    skillName: "Mechanical",
    subSkills: [
      { name: "Civil Helper", workers: 0, days: 0, hours: 0 },
      { name: "Electrical Helper", workers: 0, days: 0, hours: 0 },
      { name: "Mechanical Helper", workers: 0, days: 0, hours: 0 },
      { name: "General Helper", workers: 0, days: 0, hours: 0 },
      { name: "Cleaner", workers: 0, days: 0, hours: 0 },
    ],
  },
  {
    skillName: "Helper",
    subSkills: [
      { name: "Constructions", workers: 0, days: 0, hours: 0 },
      { name: "Electricians", workers: 0, days: 0, hours: 0 },
      { name: "General Workers", workers: 0, days: 0, hours: 0 },
      { name: "Plumber", workers: 0, days: 0, hours: 0 },
      { name: "Carpainters", workers: 0, days: 0, hours: 0 },
      { name: "Welders", workers: 0, days: 0, hours: 0 },
    ],
  },
];

export const gulfCountries = [
  "AE",
  "BH",
  "KW",
  "OM",
  "QA",
  "SA",
  "IN",
  "PK",
  "NP",
  "BD",
  "LK",
];

export const phoneLengthLimits = {
  AE: 15, // UAE: +971 (3 digits) + 12 digits = 15 digits total
  BH: 13, // Bahrain: +973 (3 digits) + 8 digits = 11 digits total
  KW: 14, // Kuwait: +965 (3 digits) + 8 digits = 11 digits total
  OM: 14, // Oman: +968 (3 digits) + 8 digits = 11 digits total
  QA: 13, // Qatar: +974 (3 digits) + 8 digits = 11 digits total
  SA: 14, // Saudi Arabia: +966 (3 digits) + 9 digits = 12 digits total
  IN: 13, // India: +91 (2 digits) + 10 digits = 12 digits total
  PK: 13, // Pakistan: +92 (2 digits) + 10 digits = 12 digits total
  NP: 14, // Nepal: +977 (3 digits) + 10 digits = 13 digits total
  BD: 14, // Bangladesh: +880 (3 digits) + 10 digits = 13 digits total
  LK: 13, // Sri Lanka: +94 (2 digits) + 10 digits = 12 digits total
};
