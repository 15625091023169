import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./addUsers.css"; // Make sure to import your CSS file
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import ImageUploader from "../../../../components/ImageUploader";
import {
  addAdminUser,
  addGulfAdminUser,
} from "../../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import { adminPathUrl } from "../../../../shared/pathUrl";

// Validation schema including both sets of permissions
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  password: Yup.string().required("Password is required"),
  permissions: Yup.object().shape({
    wePlaced: Yup.object().shape({
      manageAdminRole: Yup.boolean(),
      manageBanner: Yup.boolean(),
      manageCategory: Yup.boolean(),
      manageWorkforceEnquiry: Yup.boolean(),
      cms: Yup.boolean(),
      contactUs: Yup.boolean(),
    }),
    gulfKaam: Yup.object().shape({
      manageGulfCategory: Yup.boolean(),
      manageGulfWorkforce: Yup.boolean(),
      manageGulfEnquiry: Yup.boolean(),
    }),
  }),
});

export default function AddUsers() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);

  // Handler to manage image uploads
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue("file", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // List of permissions for "wePlaced"
  const permissionsList = [
    { name: "manageAdminRole", label: "Manage Admin Role" },
    { name: "manageBanner", label: "Manage Banner" },
    { name: "manageCategory", label: "Manage Category" },
    { name: "manageWorkforceEnquiry", label: "Manage Workforce Enquiry" },
    { name: "cms", label: "Manage CMS Pages" },
    { name: "contactUs", label: "Manage Contact Us" },
  ];

  // List of permissions for "gulfKaam"
  const gulfKaamPermissionsList = [
    { name: "manageGulfCategory", label: "Manage Gulf Category" },
    { name: "manageGulfWorkforce", label: "Manage Gulf Workforce" },
    { name: "manageGulfEnquiry", label: "Manage Gulf Enquiry" },
  ];

  // Submit handler for the form
  const onSubmitHandler = async (values) => {
    const selectedWePlacedPermissions = Object.keys(
      values.permissions.wePlaced
    ).filter((key) => values.permissions.wePlaced[key]);

    const selectedGulfKaamPermissions = Object.keys(
      values.permissions.gulfKaam
    ).filter((key) => values.permissions.gulfKaam[key]);

    const body = {
      fullName: `${values.firstName} ${values.lastName}`,
      email: values.email,
      phone: values.phone,
      password: values.password,
      permissions: {
        wePlaced: selectedWePlacedPermissions,
        gulfKaam: selectedGulfKaamPermissions,
      },
      admin_pic: values.file,
    };

    const response = await addGulfAdminUser(body); // Adjust this if needed
    if (response.status) {
      navigate(-1);
    } else {
      toast.error(response.response.data.message);
    }
  };

  // Handler to navigate to the manage users page
  const handleCancel = () => {
    navigate(adminPathUrl.manageUsers);
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add User"} backButton={true} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          image: null,
          file: null,
          permissions: {
            wePlaced: {
              manageAdminRole: true,
              manageBanner: false,
              manageCategory: false,
              manageWorkforceEnquiry: false,
              cms: false,
              contactUs: false,
            },
            gulfKaam: {
              manageGulfCategory: false,
              manageGulfWorkforce: false,
              manageGulfEnquiry: false,
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ setFieldValue }) => (
          <Form className="form-group">
            <ImageUploader
              image={image}
              onChangeHandler={(e) => handleImageChange(e, setFieldValue)}
            />

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="firstName">First Name</label>
                <Field type="text" name="firstName" className="form-control" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName">Last Name</label>
                <Field type="text" name="lastName" className="form-control" />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="email">Email</label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="phone">Phone</label>
                <Field
                  type="tel"
                  name="phone"
                  className="form-control"
                  maxLength="10"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  name="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <b>Weplaced Permissions:</b>
              </div>
              {permissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.wePlaced.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <b>GulfKaam Permissions:</b>
              </div>
              {gulfKaamPermissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.gulfKaam.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="py-3">
              <button type="submit" className="global_admin_btn mr-2">
                Save
              </button>

              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-danger ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
