import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  addGulfHomepageService,
  getGulfHomepageService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import MaxLimit from "../../../components/MaxLimit";

function GulfWorkingInfo() {
  const [contents, setContents] = useState(null);

  // Define different maximum lengths for each field
  const MAX_LENGTHS = {
    hiw_title: 30,
    hiw_title_1: 30,
    hiw_title_2: 30,
    hiw_title_3: 30,
    hiw_title_4: 30,
    hiw_content_1: 500,
    hiw_content_2: 500,
    hiw_content_3: 500,
    hiw_content_4: 500,
  };

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response);

        if (response.status) {
          setContents(response.data[0]);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents();
  }, []);

  const initialValues = {
    hiw_title: contents ? contents.hiw_title : "",
    hiw_content_1: contents ? contents.hiw_content_1 : "",
    hiw_content_2: contents ? contents.hiw_content_2 : "",
    hiw_content_3: contents ? contents.hiw_content_3 : "",
    hiw_content_4: contents ? contents.hiw_content_4 : "",
    hiw_title_1: contents ? contents.hiw_title_1 : "",
    hiw_title_2: contents ? contents.hiw_title_2 : "",
    hiw_title_3: contents ? contents.hiw_title_3 : "",
    hiw_title_4: contents ? contents.hiw_title_4 : "",
  };

  const handleLengthCheck = (value, maxLength, fieldName) => {
    if (value.length >= maxLength) {
      toast.warning(`Maximum length reached for ${fieldName}`);
    }
  };

  const handleSubmit = async (values, { setSubmitting }, section) => {
    try {
      const response = await addGulfHomepageService(
        "66accf0a35fa014192b8f810",
        {
          hiw_title: values.hiw_title,
          hiw_content_1: values.hiw_content_1,
          hiw_content_2: values.hiw_content_2,
          hiw_content_3: values.hiw_content_3,
          hiw_content_4: values.hiw_content_4,
          hiw_title_1: values.hiw_title_1,
          hiw_title_2: values.hiw_title_2,
          hiw_title_3: values.hiw_title_3,
          hiw_title_4: values.hiw_title_4,
        }
      );

      if (response.status === true) {
        setContents(response.data);
        toast.success(`${section} content updated successfully!`);
      } else {
        toast.error(`Failed to update ${section} content: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section} content:`, error);
      toast.error(`Error updating ${section} content: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="manage_terms_conditions_page card p-4 rounded shadow">
        <PageTile heading1={"How It Works Section"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, { setSubmitting }, "How It Works")
          }
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="hiw_title">Title</label>
                <MaxLimit
                  current={values.hiw_title.length}
                  max={MAX_LENGTHS.hiw_title}
                />
                <Field
                  type="text"
                  id="hiw_title"
                  name="hiw_title"
                  value={values.hiw_title}
                  onChange={(e) => {
                    handleChange(e);
                    handleLengthCheck(e.target.value, MAX_LENGTHS.hiw_title, "Main Title");
                  }}
                  onBlur={handleBlur}
                  maxLength={MAX_LENGTHS.hiw_title}
                  className={`form-control ${
                    touched.hiw_title && errors.hiw_title ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="hiw_title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              {[1, 2, 3, 4].map((index) => (
                <div className="form-group" key={`hiw_title_${index}`}>
                  <label htmlFor={`hiw_title_${index}`}>Sub-Title {index}</label>
                  <MaxLimit
                    current={values[`hiw_title_${index}`].length}
                    max={MAX_LENGTHS[`hiw_title_${index}`]}
                  />
                  <Field
                    type="text"
                    id={`hiw_title_${index}`}
                    name={`hiw_title_${index}`}
                    value={values[`hiw_title_${index}`]}
                    onChange={(e) => {
                      handleChange(e);
                      handleLengthCheck(e.target.value, MAX_LENGTHS[`hiw_title_${index}`], `Sub-Title ${index}`);
                    }}
                    onBlur={handleBlur}
                    maxLength={MAX_LENGTHS[`hiw_title_${index}`]}
                    className={`form-control ${
                      touched[`hiw_title_${index}`] && errors[`hiw_title_${index}`] ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name={`hiw_title_${index}`}
                    component="div"
                    className="invalid-feedback"
                  />
                  <CommonEditor
                    value={values[`hiw_content_${index}`]}
                    onChange={(content) => {
                      handleChange({
                        target: { name: `hiw_content_${index}`, value: content },
                      });
                      handleLengthCheck(content, MAX_LENGTHS[`hiw_content_${index}`], `Content ${index}`);
                    }}
                    maxLength={MAX_LENGTHS[`hiw_content_${index}`]}
                  />
                </div>
              ))}

              <div className="py-3">
                <button type="submit" className="global_admin_btn">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default GulfWorkingInfo;
