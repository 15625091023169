import React, { useState } from "react";
import { LogoSvg } from "../assests/svg";
import { Button } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { pathUrl } from "../shared/pathUrl";
import ThanksModal from "../pages/cart/thanksModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { manPowerEnquirySlice } from "./../redux/manpowerEnquiry/manpowerEnquirySLice";
import { submitManpowerDataDromthecartPage } from "../services/web/manpowerEnquiry/manpowerEnquiryservices";
export default function CartHeader({ userId }) {
  const navigate = useNavigate();
  const { cartSelectedItem, formData, categoryTable } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );
  const [thankModal, setThankModal] = useState(false);
  const dispatch = useDispatch();

  // final data send to backend
  const submitData = async () => {
    // convert the data as per the backend requirement

    const enquiriesData = categoryTable.flatMap((item) =>
      item.subSkills
        .filter((skill) => skill.workers > 0)
        .map((skill) => ({
          skill_name: skill.name,
          sub_id: skill.sub_id,
          workers: skill.workers,
          // years: skill?.days ?? 0,
          months: skill?.days ?? 0,
        }))
    );

    // body to set send to the backend
    const body = {
      email: formData?.email?.value,
      fullName: formData?.name?.value,
      contactNumber: formData?.contactNumber?.value,
      companyName: formData?.companyName?.value,
      accommodation: formData?.accomodation?.value,
      transportation: formData?.transportation?.value,
      message: formData?.message?.value,
      city: formData?.city?.value,
      country: formData?.country?.value,
      user_ids:
        cartSelectedItem &&
        Object.keys(cartSelectedItem)
          .map((item) =>
            cartSelectedItem[item]?.user?.filter(
              (filterValue) => filterValue.checked
            )
          )
          .flat()
          .map((idItem) => idItem?._id),
      enquiries: enquiriesData,
      tenure: formData?.tenure?.value,
      user_type: formData?.userType?.value,
    };
    const postData = await submitManpowerDataDromthecartPage(body, userId);
    if (postData?.status) {
      setThankModal(true);
    } else {
      toast.error("Something went wrong");
    }
  };

  const continueFindWorker = () => {
    dispatch(manPowerEnquirySlice.actions.resetToTheInitialState());
    navigate(pathUrl.homePage);
    setThankModal(false);
  };
  return (
    <div className="container">
      <div className="row pt-4 pb-4">
        <div className="col-md-8 header_worker_list">
          <div className="d-flex show_logo">
            <div className="header-logo-worker-list">
              {" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(pathUrl.homePage, {
                    state: {
                      redirect: true,
                    },
                  })
                }
              >
                {LogoSvg()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-end">
          <div className="d-flex gap-5 justify-content-end align-items-center">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate(pathUrl.workerList)}
              className="back-propfile"
            >
              {" "}
              <GoArrowLeft />
              Back to profiles
            </span>
            {/* <Button onClick={() => submitData()} className="submit-profile-btn">
              Submit <FaArrowRight />
            </Button> */}
          </div>
        </div>
      </div>
      <ThanksModal
        open={thankModal}
        setOpen={setThankModal}
        content={
          "For submit your query. We will contact you as soon as possible."
        }
        continueBtnFun={continueFindWorker}
        btnText={"Continue Find Workers"}
      />
    </div>
  );
}
