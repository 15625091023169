import React from "react";

const MaxLimit = ({ current, max }) => {
  return (
    <div className="text-end text-muted">
      {current}/{max}
    </div>
  );
};

export default MaxLimit;
