import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteexecutivelisting, getexecutivelisting } from "../../../../services/admin/CmsPages/cmsPagesServices";
import DeleteModalAdmin from "../../../../components/deleteModalAdmin";
import DataTableComponent from "../../../../components/dataTable/dataTable";
import { adminPathUrl } from "../../../../shared/pathUrl";
import Loader from "../../../../components/loader/loader";
import Avatar from "../../../../components/Avatar";

const ManageReviews = () => {
  const [executiveData, setExecutiveData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedExeId, setSelectedExeId] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const userColumns = [
    {
      name: "Picture",
      selector: (row) => (
        <Avatar imgSrc={row.executive_pic} />
      ),
      sortable: true
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="group_btns">
          <button
            className="bg-transparent border-0 me-2"
            onClick={() => handleRowClicked(row)}
          >
            <FaEdit />
          </button>
          <button
            className="bg-transparent border-0"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      sortable: true
    },
  ];

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        const response = await getexecutivelisting();
        if (response && response.data) {
          setExecutiveData(response.data);
        } else {
          throw new Error('No data returned from the API');
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        toast.error("Failed to fetch executive data"); // Show error toast
      } finally {
        setLoading(false);
      }
    };
    fetchReviews();
  }, []);

  const confirmDelete = async () => {
    try {
      if (!selectedExeId) {
        console.error("No executive selected for deletion.");
        setError("No executive selected for deletion."); 
        return;
      }
      await deleteexecutivelisting(selectedExeId);
      setExecutiveData(executiveData.filter((exe) => exe._id !== selectedExeId));
      toast.success("Executive deleted successfully"); 
      setDeleteModal(false);
      setSelectedExeId(null);
    } catch (error) {
      console.error("Error deleting executive:", error);
      setError("Failed to delete the executive. Please try again."); 
      toast.error("Failed to delete the executive. Please try again."); 
    }
  };

  const handleRowClicked = (row) => {
    console.log("Clicked row:", row);
    navigate(`${adminPathUrl.editExecutive}/${row._id}`, { state: { executive: row } });
  };
  

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleEdit = (row) => {
    console.log("Edit row:", row);
    // navigate(`${adminPathUrl.editExecutive}/${row._id}`); 
  };

  const handleDelete = (row) => {
    setSelectedExeId(row._id); 
    setDeleteModal(true);
  };

  const handleAddUserClick = () => {
    navigate(adminPathUrl.addExecutive); 
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="user_page">
      <ToastContainer /> 
      <button className="add-user-button" onClick={handleAddUserClick}>
        Add Executive
      </button>
      <div className="datatable-section">
      <DataTableComponent
        title="Manage Executive"
        data={executiveData}
        columns={userColumns}
        selectedRows={selectedRows}
        clickedrow={handleRowClicked}
        checkboxchange={handleCheckboxChange}
        responsive={true}
      />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this executive?"} // Updated heading
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ManageReviews;
