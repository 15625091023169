import React from "react";
import { Carousel } from "react-bootstrap";

export default function SkillSetSection({ skillList }) {
  const chunkArray = (array, maxItems) => {
    const result = [];
    for (let i = 0; i < array.length; i += maxItems) {
      result.push(array.slice(i, i + maxItems));
    }
    return result;
  };

  const skillChunks = chunkArray(skillList, 10);

  return (
    <div className="skil_section">
      <div className="container skill-set-heading">
        <h4>Skill sets we can place</h4>
        <div className="skill-set-img-card">
          <Carousel wrap={true} pause={false}>
            {skillChunks.map((chunk, chunkIndex) => (
              <Carousel.Item key={chunkIndex} interval={4000}>
                <div className="row">
                  {chunk.map((item, index) => (
                    <div
                      key={index}
                      className="col-xl-2 col-md-4 col-sm-6 position-relative mb-3"
                    >
                      <div className="main-catagories">
                        <span>{item.title}</span>
                        <img src={item.skill_picture} alt={item.title} />
                      </div>
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
