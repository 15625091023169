import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";

function Addnotifications() {
  const initialValues = {
    title: "",
    message: "",
  };

  const handleSubmit = (values) => {
    console.log(values);
    // You can handle form submission here
  };

  const validateMessage = (value) => {
    let error;
    if (value.length > 60) {
      error = "Message must be 100 words or less";
    }
    return error;
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add Notification"} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <Field type="text" name="title" className="form-control" />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message (Limit 60 words)</label>
            <Field
              as="textarea"
              name="message"
              validate={validateMessage}
              className="form-control"
            />
            <ErrorMessage name="message" component="div" className="error" />
          </div>

          <div className="py-3">
            <button type="submit" className="global_admin_btn">
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Addnotifications;
