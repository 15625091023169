import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  addPrivacyPolicy,
  getCMSContentsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import CommonEditor from "../../../components/commonEditor/commonEditor";

function GulfPrivacyPolicy() {
  const [content, setContent] = useState(""); // State to manage editor content
  const [cmsContents, setCMSContents] = useState(null); // State to manage CMS contents
  const [isWeplaced, setIsWeplaced] = useState(false); // State to manage is_weplaced flag
  const MAX_LENGTH = 15000;

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!content) {
      toast.warning("Please provide content before saving.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      // Create payload object
      const payload = {
        gulfweb_privacy_policy: content,
        is_weplaced: isWeplaced, // Include is_weplaced flag in payload
      };

      const response = await addPrivacyPolicy(payload);
      if (response.status === true) {
        toast.success("Privacy Policy updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Failed to update Privacy Policy: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error updating Privacy Policy. Please try again.");
      console.error("Error updating privacy policy:", error);
    }
  };

  // Effect to fetch CMS contents when component mounts
  useEffect(() => {
    const fetchCMSContents = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[1]);
          setContent(response.data[1].gulfweb_privacy_policy); // Set editor content with fetched privacy policy
          setIsWeplaced(response.data[1].is_weplaced === true);
        } else {
          toast.error("Failed to fetch CMS contents. Please try again.");
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        toast.error("Error fetching CMS contents. Please try again.");
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchCMSContents();
  }, []);

  const handleEditorChange = (value) => {
    if (value?.length <= MAX_LENGTH) {
      setContent(value);
    } else {
      toast.warning(`Content exceeds maximum length of ${MAX_LENGTH} characters`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="manage_privacy_policy_page card p-4 rounded shadow">
      <PageTile heading1={"Manage Privacy Policy"} />
      <CommonEditor
        value={content}
        onChange={handleEditorChange}
        maxLength={MAX_LENGTH}
      />
      <div className="py-3">
        <button className="global_admin_btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  );
}

export default GulfPrivacyPolicy;
