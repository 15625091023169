// src/components/Modal.js
import React from 'react';
import './style.css'; // Create a CSS file for modal styling

const Modal = ({ imageSrc, onClose }) => {
    if (!imageSrc) return null;
  
    return (
      <div className="modal-overlay doc-view" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <img src={imageSrc} alt="Document" className="modal-image" />
          <button className="modal-close" onClick={onClose}>X</button>
        </div>
      </div>
    );
  };
  
export default Modal;
