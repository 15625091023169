import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { useLocation, useNavigate } from "react-router-dom";
import { updateSkillSetService } from "../../../services/admin/manageworkforce/manageworkforceservices";
import { resizeImage } from "../../../utils/methods";
import ImageResolutionWarningModal from "../../../components/ImageCompressModal";
import Description from "../../../components/Description";
import MaxLimit from "../../../components/MaxLimit";

// Define maximum lengths for each field
const MAX_LENGTHS = {
  title: 30,
};

const EditSkillSet = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tempFile, setTempFile] = useState(null);
  const fileInputRef = useRef(null);

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(MAX_LENGTHS.title, `Title cannot exceed ${MAX_LENGTHS.title} characters`)
      .required("Skill title is required"),
  });

  const maxWidth = 380;
  const maxHeight = 200;

  const {
    state: { skill },
  } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (skill) {
      setImageUrl(skill.skill_picture);
    }
  }, [skill]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = async () => {
      const { width, height } = img;

      if (width > maxWidth || height > maxHeight) {
        setTempFile(file);
        setShowModal(true);
      } else {
        setImage(file);
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCompressImage = async () => {
    if (!tempFile) return;
    const resizedFile = await resizeImage(tempFile, maxWidth, maxHeight);
    setImage(resizedFile);
    const imageUrl = URL.createObjectURL(resizedFile);
    setImageUrl(imageUrl);
    setShowModal(false);
    setTempFile(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTempFile(null);
    setImage(null);
    fileInputRef.current.value = "";
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      if (image) {
        formData.append("skill_picture", image);
      }

      const response = await updateSkillSetService(formData, skill._id);

      if (response.status) {
        toast.success("Skill updated successfully!");
        resetForm();
        setImage(null);
        setImageUrl(null);
        navigate(-1);
      } else {
        toast.error(`Failed to update skill: ${response.message}`);
      }
    } catch (error) {
      console.error("Error updating skill:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="skill-set-container">
      <div className="add-skill-form mt-4">
        <PageTile heading1={"Update Skill"} backButton={true} />
        <Formik
          initialValues={{ title: skill.title }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="title">Skill Title</label>
                <MaxLimit
                    current={values.title.length}
                    max={MAX_LENGTHS.title}
                  />
                <div className="input-wrapper">
                  <Field
                    type="text"
                    name="title"
                    className="form-control"
                    maxLength={MAX_LENGTHS.title}
                  />
                 
                </div>
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="skill_picture">Skill Image</label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    name="skill_picture"
                    id="skill_picture"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Upload Image
                    </button>
                  </div>
                </div>
                <Description
                  message={`Please upload an image with a resolution of (${maxWidth} ×
                  ${maxHeight}) pixels.`}
                />
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Selected"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                )}
              </div>

              <div className="py-3">
                <button
                  type="submit"
                  className="global_admin_btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update Skill"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ImageResolutionWarningModal
        show={showModal}
        onHide={handleCloseModal}
        onCompress={handleCompressImage}
        resolution={`${maxWidth}\u00D7${maxHeight}`}
      />
    </div>
  );
};

export default EditSkillSet;
