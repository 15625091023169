import React from "react";
import "./index.css";
import { RiDoubleQuotesR } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { pathUrl } from "../../shared/pathUrl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomRating from "../CustomRating";
export default function ClientReview({ reviews }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const location = useLocation();
  return (
    <section
      id="client-reviews"
      className={"client-review"}
      style={{
        backgroundColor: location.pathname === pathUrl.aboutUs ? "" : "#F8F8F8",
      }}
    >
      <div className=" container text-center">
        <h4 className="pt-4">Client reviews</h4>
        <span>See what our satisfied customers have to say!</span>
        <Carousel
          infinite={true}
          arrows={false}
          autoPlaySpeed={1500}
          className="mt-4"
          autoPlay={true}
          responsive={responsive}
        >
          {reviews.map((item, i) => {
            return (
              <div style={{ margin: "10px" }} className="mb-5" key={i}>
                <div className="client-review-card">
                  <div className="pb-3">
                    <CustomRating ratingValue={item?.rating ?? 0} />
                  </div>
                  <span>{item?.review}</span>
                  <div className="row p-2 align-items-center">
                    <div className="col-md-2">
                      <span
                        style={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#02bea8",
                        }}
                      >
                        {item?.name?.charAt(0)}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <h6>{item?.name}</h6>
                      <span>
                        {item?.city}, {item?.country}
                      </span>
                    </div>
                  </div>
                  <div className="quoto_commas">
                    <RiDoubleQuotesR size={140} />{" "}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
        {/* <Carousel>
          <div className="row mt-4">
            {clientArray &&
              clientArray?.slice(0, 3).map((item) => (
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="client-review-card">
                    <div className="pb-3">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                    <span>
                      {item?.review}
                    </span>
                    <div className="row p-2 align-items-center">
                      <div className="col-md-2">
                        <span
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#02bea8",
                          }}
                        >
                          {item?.name?.charAt(0)}
                        </span>
                      </div>
                      <div className="col-md-10">
                        <h6>{item?.name}</h6>
                        <span>{item?.city}, {item?.country}</span>
                      </div>
                    </div>
                    <div className="quoto_commas">
                      <RiDoubleQuotesR size={140} />{" "}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="row mt-4">
            {clientArray &&
              clientArray?.slice(3, clientArray?.length).map((item) => (
                <div className="col-lg-4 col-md-6 mb-5">
                  <div className="client-review-card">
                    <div className="pb-3">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </div>
                    <span>
                      {item?.review}
                    </span>
                    <div className="row p-2 align-items-center">
                      <div className="col-md-2">
                        <span
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#02bea8",
                          }}
                        >
                          {item?.name?.charAt(0)}
                        </span>
                      </div>
                      <div className="col-md-10">
                        <h6>{item?.name}</h6>
                        <span>{item?.city}, {item?.country}</span>
                      </div>
                    </div>
                    <div className="quoto_commas">
                      <RiDoubleQuotesR size={140} />{" "}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Carousel> */}
      </div>
    </section>
  );
}
