import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  addGulfHomepageService,
  getGulfHomepageService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import MaxLimit from "../../../components/MaxLimit";

function GulfHomeFeatures() {
  const [contents, setContents] = useState(null);

  const MAX_LENGTHS = {
    feature_heading: 8,
    language_section_title_1: 50,
    language_section_title_2: 50,
    language_section_title_3: 50,
    language_section_title_4: 50,
    language_section_title_5: 50,
    ai_sub_title: 45,
    ai_title: 29,
    address: 100,
    email: 50,
    phone: 15,
    facebook_link: 100,
    instagram_link: 100,
  };

  const MAX_LENGTH_FEATURES = 300;
  const MAX_LENGTH_HOW_IT_WORKS = 72;

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        if (response.status) {
          setContents(response.data[0]);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents();
  }, []);

  const initialValues = {
    ai_sub_title: contents ? contents.ai_sub_title : "",
    ai_content: contents ? contents.ai_content : "",
    ai_title: contents ? contents.ai_title : "",
    language_section_content_1: contents ? contents.language_section_content_1 : "",
    language_section_content_2: contents ? contents.language_section_content_2 : "",
    language_section_content_3: contents ? contents.language_section_content_3 : "",
    language_section_content_4: contents ? contents.language_section_content_4 : "",
    language_section_content_5: contents ? contents.language_section_content_5 : "",
    language_section_title_1: contents ? contents.language_section_title_1 : "",
    language_section_title_2: contents ? contents.language_section_title_2 : "",
    language_section_title_3: contents ? contents.language_section_title_3 : "",
    language_section_title_4: contents ? contents.language_section_title_4 : "",
    language_section_title_5: contents ? contents.language_section_title_5 : "",
    intro_title: contents ? contents.intro_title : "",
    intro_content: contents ? contents.intro_content : "",
    address: contents ? contents.address : "",
    email: contents ? contents.email : "",
    phone: contents ? contents.phone : "",
    facebook_link: contents ? contents.facebook_link : "",
    instagram_link: contents ? contents.instagram_link : "",
    feature_heading: contents ? contents.feature_heading : "",
  };

  const handleSubmit = async (values, { setSubmitting }, section) => {
    try {
      const response = await addGulfHomepageService("66accf0a35fa014192b8f810", values);
      if (response.status) {
        setContents(response.data);
        toast.success(`${section} content updated successfully!`);
      } else {
        toast.error(`Failed to update ${section} content: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section} content:`, error);
      toast.error(`Error updating ${section} content: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleInputChange = (name, maxLength) => (event) => {
    const { value } = event.target;
    if (value.length > maxLength) {
      toast.warning(`Content exceeds maximum length of ${maxLength} characters`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div>
      {/* Features Section 1 */}
      <div className="manage_terms_conditions_page card p-4 rounded shadow">
        <PageTile heading1={"Features 1"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting }, "features")}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="feature_heading">Heading</label>
                <MaxLimit current={values.feature_heading.length} max={MAX_LENGTHS.feature_heading} />
                <Field
                  type="text"
                  id="feature_heading"
                  name="feature_heading"
                  value={values.feature_heading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={handleInputChange("feature_heading", MAX_LENGTHS.feature_heading)}
                  maxLength={MAX_LENGTHS.feature_heading}
                  className={`form-control ${touched.feature_heading && errors.feature_heading ? "is-invalid" : ""}`}
                />
                <ErrorMessage name="feature_heading" component="div" className="invalid-feedback" />
              </div>

              {/* Repeat for other fields... */}

              <CommonEditor
                value={values.language_section_content_1}
                onChange={(content) => {
                  handleChange({
                    target: { name: "language_section_content_1", value: content },
                  });
                  if (content.length > MAX_LENGTH_FEATURES) {
                    toast.warning(`Content exceeds maximum length of ${MAX_LENGTH_FEATURES} characters`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                  }
                }}
                maxLength={MAX_LENGTH_FEATURES}
              />

              {/* ... Other form fields and sections ... */}

              <div className="py-3">
                <button type="submit" className="global_admin_btn">Save</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* Features Section 2 */}
      <div className="manage_terms_conditions_page card p-4 rounded shadow">
        <PageTile heading1={"Features 2"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting }, "how-it-works")}
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="ai_sub_title">Title</label>
                <MaxLimit current={values.ai_sub_title.length} max={MAX_LENGTHS.ai_sub_title} />
                <Field
                  type="text"
                  id="ai_sub_title"
                  name="ai_sub_title"
                  value={values.ai_sub_title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={handleInputChange("ai_sub_title", MAX_LENGTHS.ai_sub_title)}
                  maxLength={MAX_LENGTHS.ai_sub_title}
                  className={`form-control ${touched.ai_sub_title && errors.ai_sub_title ? "is-invalid" : ""}`}
                />
                <ErrorMessage name="ai_sub_title" component="div" className="invalid-feedback" />
              </div>

              {/* Similar for other fields... */}

              <CommonEditor
                value={values.ai_content}
                onChange={(content) => {
                  handleChange({
                    target: { name: "ai_content", value: content },
                  });
                  if (content.length > MAX_LENGTH_HOW_IT_WORKS) {
                    toast.warning(`Content exceeds maximum length of ${MAX_LENGTH_HOW_IT_WORKS} characters`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                  }
                }}
                maxLength={MAX_LENGTH_HOW_IT_WORKS}
              />
              <div className="py-3">
                <button type="submit" className="global_admin_btn">Save</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default GulfHomeFeatures;
