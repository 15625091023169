import React from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
export default function PrivacyPolicy() {
  const location = useLocation();
  const { privacy_policy } = location.state;
  return (
    <div className="container">
    <span className="bread-crumb py-3 d-block">
        <Link to="/" className="breadcrumb-home">
          Home
        </Link> 
        / <span className="bread-crumb-extend-text">Privacy Policy</span>
      </span>
      <div className="privacy-policy">
        <div dangerouslySetInnerHTML={{ __html: privacy_policy }} />
      </div>
    </div>
  );
}
