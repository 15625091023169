// src/components/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = ({ message }) => {
  const navigate = useNavigate();

  return (
    <div className="not_found_main">
      <div className="not-found-container">
        <h1>{message}</h1>
        <button className="global_admin_btn" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
