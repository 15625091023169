import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";
import { getMyProfileById } from "../../services/admin/manageworkforce/manageworkforceservices";

function AdminHeader({ activeTab, setActiveTab }) {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("Loading...");
  const [userId, setUserId] = useState(null);
  const [admin_pic, setAdminPic] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const permissions = loginData?.permissions;

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData) {
      setUserId(loginData._id);
      fetchUserData(loginData._id);
    }
  }, []);

  const fetchUserData = async (id) => {
    try {
      const response = await getMyProfileById(id);
      console.log("API Response:", response);
      const { fullName, admin_pic } = response.data.data;
      console.log("Extracted Data:", { fullName, admin_pic });

      setFullName(fullName || "User");
      setAdminPic(admin_pic);
    } catch (error) {
      console.error("Failed to fetch user data", error);
    }
  };

  const handleClick = () => {
    if (userId) {
      navigate(`${adminPathUrl.profile}/${userId}`, {
        state: { userId },
      });
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("/admin/gulf")) {
      setActiveTab("gulfKaam");
    } else if (["/admin/data-lake","/admin/data-lake-enquiry","/admin/maange-delete-profile","/admin/manage-rejected-profile"]?.includes(location.pathname)) {
      setActiveTab("dataLake");
    } else if (location?.pathname?.includes("/admin/adminRole")) {
      setActiveTab("adminRoles");
    } else {
      setActiveTab("wePlaced");
    }
  }, [location.pathname, setActiveTab]);

  const handleTabClick = (tab) => {
    switch (tab) {
      case "wePlaced":
        navigate(adminPathUrl.dashboard);
        break;
      case "gulfKaam":
        navigate(adminPathUrl.gulfDashboard);
        break;
      case "dataLake":
        navigate(adminPathUrl.dataWorkforce);
        break;
      case "adminRoles":
        navigate(adminPathUrl.manageUsers);
        break;
      default:
        break;
    }
    setActiveTab(tab);
  };

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("loginData");
    localStorage.removeItem("token");
    navigate("/admin");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header admin_header justify-content-between align-items-center">
      <div className="empty-div"></div>
      <div className="dashboard-tabs profile-wrapper d-flex align-items-center">
        <button
          className={`tab w-100 ${activeTab === "wePlaced" ? "active" : ""}`}
          onClick={() => handleTabClick("wePlaced")}
        >
          WePlaced
        </button>
        <button
          className={`tab w-100 ${activeTab === "gulfKaam" ? "active" : ""}`}
          onClick={() => handleTabClick("gulfKaam")}
        >
          GulfKaam
        </button>
        {permissions?.includes(
          "manageProfile"
        ) && (
          <button
            className={`tab w-100 ${activeTab === "dataLake" ? "active" : ""}`}
            onClick={() => handleTabClick("dataLake")}
          >
            Data Lake
          </button>
        )}
        {permissions?.includes("manageAdminRole") && (
          <button
            className={`tab w-100 ${
              activeTab === "adminRoles" ? "active" : ""
            }`}
            onClick={() => handleTabClick("adminRoles")}
          >
            Admin Roles
          </button>
        )}
      </div>
      <div className="user-info justify-content-end" ref={dropdownRef}>
        <div
          className="profile-wrapper d-flex align-items-center"
          onClick={toggleDropdown}
        >
          {admin_pic ? (
            <img
              src={admin_pic}
              className="profile-icon rounded-circle"
              alt="Profile"
              style={{ width: "40px", height: "40px", objectFit: "cover" }}
            />
          ) : (
            <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
          )}
          <span className="user-name">{fullName}</span>
        </div>
        {dropdownVisible && (
          <div className="dropdown-menu">
            <div className="dropdown-item" onClick={toggleDropdown}>
              <span onClick={handleClick} style={{ cursor: "pointer" }}>
                My Profile
              </span>
            </div>
            <div className="dropdown-item" onClick={handleLogout}>
              <span>Logout</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminHeader;
