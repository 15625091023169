import React from "react";
import NoImg from "../assests/images/noImg.png"; // Ensure this path is correct

const Avatar = ({
  mainClass = "profile-pic",
  imgClass = "products-image",
  alt = "profile-pic",
  imgSrc,
}) => {
  // Use default avatar if imgSrc is null, undefined, or an empty string
  const avatarSrc = imgSrc && imgSrc.trim() !== "" ? imgSrc : NoImg;
  
  return (
    <div className={mainClass}>
      <img src={avatarSrc} className={imgClass} alt={alt} />
    </div>
  );
};

export default Avatar;
