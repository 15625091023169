import { ErrorMessage, Field } from "formik";
import React from "react";

const FormikSelect = ({
  as = "select",
  fieldClass = "form-control",
  mainClass = "form-group",
  errComp = "div",
  errClass = "error",
  id,
  name,
  errName,
  style = { color: "red" },
  options = [],
  placeholder = "Select option",
  multiple = false,
}) => {
  return (
    <div className={mainClass}>
      <Field
        as={as}
        id={id}
        name={name}
        className={fieldClass}
        multiple={multiple}
      >
        <option value="">{placeholder}</option>
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </Field>
      <ErrorMessage
        name={errName}
        component={errComp}
        className={errClass}
        style={style}
      />
    </div>
  );
};

export default FormikSelect;
