import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import WorkerListHeader from "../components/workerListHeader";
import CartHeader from "../components/cartHeader";
import { pathUrl } from "../shared/pathUrl";
import { useSelector } from "react-redux";

//common layout file
export default function Layout() {
  const location = useLocation();
  const { userId } = useSelector((state) => state.MANPOWERENQUIRY);
  return (
    <>
      {/* condition rendering of the header as per the path name of the app */}
      {location.pathname === pathUrl.workerList ? (
        <WorkerListHeader />
      ) : location.pathname === pathUrl.cart ? (
        <CartHeader userId={userId} />
      ) : (
        <Header />
      )}
      <Outlet />
      {location.pathname !== pathUrl.homePage && <Footer />}
    </>
  );
}
