import React from "react";
import { Modal, Button } from "react-bootstrap";

const ImageResolutionWarningModal = ({
  show,
  onHide,
  onCompress,
  resolution,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Image Resolution Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The image resolution does not match the required standard (
          {resolution}). Please upload an image with the standard size or
          compress the image. Compressing may affect the quality of the image.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "#02bea8", borderColor: "#02bea8" }}
          onClick={onCompress}
        >
          Compress and Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageResolutionWarningModal;
