import React, { useEffect, useState } from "react";
import { Accordion, Table, Form, Button } from "react-bootstrap";
import { years } from "../../utils/helpers";
import { ErrorMessage } from "formik";
import { addSubSkill } from "../../services/admin/manageworkforce/manageWorkEnquiryServices";
import { getskillset } from "../../services/admin/manageworkforce/manageworkforceservices";

const SkillsSelectionAdd = ({
  data,
  onSelect,
  errName,
  errComp = "div",
  errClass = "error text-center",
  style = { color: "red", textAlign: "center", marginBottom: "20px" },
}) => {
  const [skills, setSkills] = useState([]);
  const [showOthersForm, setShowOthersForm] = useState(false);
  const [newSubSkill, setNewSubSkill] = useState({ name: "", year: 0 });
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(null);

  // Update internal skills state when data prop changes
  useEffect(() => {
    const initialSkillsState = data?.map(({ skillName, subSkills }) => ({
      skillName,
      subSkills: subSkills?.map(({ name, sub_id }) => ({
        name,
        selected: false,
        year: 0,
        sub_id,
      })),
    }));
    setSkills(initialSkillsState);
  }, [data]);

  const fetchSkillset = async () => {
    try {
      const data = await getskillset({ body: null, type: "data_lake" });
      setSkills(data.data);
    } catch (error) {
      console.error("Error fetching skillset:", error);
    }
  };

  const handleCheckboxChange = (skillIndex, subskillIndex) => {
    const updatedSkills = [...skills];
    const subSkills = [...updatedSkills[skillIndex].subSkills];
    subSkills[subskillIndex].selected = !subSkills[subskillIndex].selected;
    if (!subSkills[subskillIndex].selected) {
      subSkills[subskillIndex].year = 0;
    }
    updatedSkills[skillIndex].subSkills = subSkills;
    setSkills(updatedSkills);
    onSelect(
      updatedSkills.flatMap((skill) =>
        skill.subSkills
          .filter((subSkill) => subSkill.selected)
          .map((subSkill) => ({
            skillName: skill.skillName,
            subSkillName: subSkill.name,
            subSkillId: subSkill.sub_id,
            experience: `${subSkill.year} ${
              subSkill.year > 1 ? "years" : "year"
            }`,
          }))
      )
    );
  };

  const handleYearChange = (skillIndex, subskillIndex, event) => {
    const updatedSkills = [...skills];
    const subSkills = [...updatedSkills[skillIndex].subSkills];
    subSkills[subskillIndex].year = parseInt(event.target.value);
    updatedSkills[skillIndex].subSkills = subSkills;
    setSkills(updatedSkills);
    onSelect(
      updatedSkills.flatMap((skill) =>
        skill.subSkills
          .filter((subSkill) => subSkill.selected)
          .map((subSkill) => ({
            skillName: skill.skillName,
            subSkillName: subSkill.name,
            subSkillId: subSkill.sub_id,
            experience: `${subSkill.year} ${
              subSkill.year > 1 ? "years" : "year"
            }`,
          }))
      )
    );
  };

  const handleAddSubSkill = async () => {
    try {
      const payload = {
        skillName: "Others",
        subSkillName: newSubSkill.name,
      };

      const response = await addSubSkill(payload);
      const addedSubSkill = response.data;

      if(response.status){
        fetchSkillset();
      }

      // Add the new sub-skill to the state
      const updatedSkills = [...skills];
      const othersSkillIndex = updatedSkills.findIndex(
        (skill) => skill.skillName === "Others"
      );

      if (othersSkillIndex !== -1) {
        updatedSkills[othersSkillIndex].subSkills.push({
          name: addedSubSkill.subSkillName,
          sub_id: addedSubSkill.sub_id,
          selected: false,
          year: 0,
        });
        setSkills(updatedSkills);
        setNewSubSkill({ name: "", year: 0 });
        setShowOthersForm(false);
      }
    } catch (error) {
      console.error("Error adding sub-skill:", error);
      // Handle the error as needed
    }
  };
  console.log("adsasd",skills)
  return (
    <>
      <Accordion defaultActiveKey="0" className="mb-3">
        {skills?.map(({ skillName, subSkills }, skillIndex) => (
          <Accordion.Item eventKey={skillIndex.toString()} key={skillIndex}>
            <Accordion.Header>{skillName}</Accordion.Header>
            <Accordion.Body>
              {skillName === "Others" && (
                <>
                  <Button
                    onClick={() => {
                      setShowOthersForm(!showOthersForm);
                      setSelectedSkillIndex(skillIndex);
                    }}
                    style={{background:"#02bea8",borderColor:"#02bea8"}}
                  >
                    {showOthersForm ? "Hide Form" : "Add New Sub-Skill"}
                  </Button>
                  {showOthersForm && selectedSkillIndex === skillIndex && (
                    <Form className="mt-3">
                      <Form.Group className="mb-3">
                        <Form.Label>Sub-Skill Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={newSubSkill.name}
                          onChange={(e) =>
                            setNewSubSkill({ ...newSubSkill, name: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Experience</Form.Label>
                        <Form.Select
                          value={newSubSkill.year}
                          onChange={(e) =>
                            setNewSubSkill({ ...newSubSkill, year: parseInt(e.target.value) })
                          }
                        >
                          <option value={0}>No Experience</option>
                          {years.map(({ label, value }, index) => (
                            <option value={value} key={index}>
                              {label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Button onClick={handleAddSubSkill}  style={{background:"#02bea8",borderColor:"#02bea8"}}>Add Sub-Skill</Button>
                    </Form>
                  )}
                </>
              )}
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Skill</th>
                    <th>Experience</th>
                  </tr>
                </thead>
                <tbody>
                  {subSkills?.map(({ name, selected, year }, subskillIndex) => (
                    <tr key={subskillIndex}>
                      <td>
                        <div className="d-flex" style={{ width: "200px" }}>
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleCheckboxChange(skillIndex, subskillIndex)
                            }
                            checked={selected}
                            className="mx-2"
                            style={{ opacity: 1 }}
                          />
                          <span>{name}</span>
                        </div>
                      </td>
                      <td>
                        <Form.Select
                          onChange={(event) =>
                            handleYearChange(skillIndex, subskillIndex, event)
                          }
                          value={year}
                          disabled={!selected}
                        >
                          <option value={0}>No Experience</option>
                          {years.map(({ label, value }, index) => (
                            <option value={value} key={index}>
                              {label}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <ErrorMessage
        name={errName}
        component={errComp}
        className={errClass}
        style={style}
      />
    </>
  );
};

export default SkillsSelectionAdd;
