import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import { pathUrl } from "../../shared/pathUrl";
import { getTheBlog } from "../../services/web/blog/blogServices";
import SmallBlogCard from "./smallBlogCard";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Loader from "../../components/loader/loader";

const BlogPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true); 
  const [blogData, setBlogData] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    setSelectedBlog(location.state);
  }, [location.state]);

  const handleReadMore = (id) => {
    navigate(`${pathUrl.blogDetail}/${id}`);
  };

  const getTheBlogData = async () => {
    try {
      const getData = await getTheBlog();
      if (getData?.status) {
        setBlogData(getData?.data);
      }
    } catch (error) {
      console.log("exceptional error", error);
    }
    finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    getTheBlogData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "Invalid Date";
    }

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  if (loading) {
    return <Loader />; 
  }

  return (
    <>
      <section className="mt-xl-5">
        <div className="container insight_container">
          <PageTile backButton={true} /> 

          <div className="row">
            <div className="col-xl-6" style={{ backgroundColor: "#eafcfa" }}>
              <span>{blogData && formatDate(blogData[0]?.createdAt)}</span>

             
              <h5>{blogData && blogData[0]?.title}</h5>
              {/* Render content as HTML */}
              <div
                dangerouslySetInnerHTML={{
                  __html: blogData[0]?.content?.slice(0, 300) + '...'
                }}
              />
              <Button onClick={() => handleReadMore(blogData[0]?._id)} className="read-more-btn">
                Read More
              </Button>
            </div>
            <div className="col-xl-6 blog_img_1 p-0">
              <img
                className="img-fluid"
                src={blogData && blogData[0]?.blog_large_pic}
                alt="Insights Banner"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 margin-bottom">
        <div className="container">
          <div className="row">
            {blogData && blogData?.map((item) => (
              <SmallBlogCard
                key={item._id}
                cardData={item}
                onClick={() => handleReadMore(item._id)}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;
