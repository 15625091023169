import { ErrorMessage, Field, useField } from "formik";
import React from "react";

const FormikInput = ({
  type = "text",
  fieldClass = "form-control",
  mainClass = "form-group",
  errComp = "div",
  errClass = "error",
  id,
  name,
  placeholder = "",
  style = { color: "red" },
}) => {
  const [field, meta] = useField(name); // useField hook for Formik field state

  return (
    <div className={mainClass}>
      <Field
        type={type}
        id={id}
        placeholder={placeholder}
        name={name}
        className={fieldClass}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div className={errClass} style={style}>
          {meta.error}
        </div>
      ) : null}
    </div>
  );
};

export default FormikInput;
