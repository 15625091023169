import React, { useEffect, useState } from "react";
import "../../pages/cart/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getWorkerDetail,
  verifyWorkforceUser,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Modal from "../../components/imageModal";
import { adminPathUrl } from "../../shared/pathUrl";
import { FaUser } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { getCategory } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";

export default function DataLakeWorkerForceDetail() {
  const [editIndex, setEditIndex] = useState(-1);
  const [imageSources, setImageSources] = useState([]);
  const [data, setData] = useState(null);
  console.log(data, "data");
  const [loading, setLoading] = useState(false);
  const [skillList, setSkillList] = useState([]);

  const [status, setStatus] = useState({
    verified: false,
    unverified: false,
    rejected: false,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // const handleEditClick = (index) => {
  //   setEditIndex(index);
  //   document.getElementById(`file-input-${index}`).click();
  // };
  console.log("---", data);
  const handleEditClick = () => {
    if (data) {
      navigate(adminPathUrl.editDataWorkforce, {
        state: {
          id: data._id,
          profile_pic: data.profile_pic,
          fullName: data.fullName,
          skill: data.skill || [],
          nationality: data.nationality,
          city: data.city || "",
          age: data.age || "",
          language: data.language || "",
          is_passport: data.is_passport || "",
          countryCode: data.countryCode || "",
          phone: data.phone || "",
        },
      });
    } else {
      toast.error("No data available to edit.");
    }
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSources((prev) => {
          const newImageSources = [...prev];
          newImageSources[index] = reader.result;
          return newImageSources;
        });
        setEditIndex(-1);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setStatus({
      verified: name === "verified",
      unverified: name === "unverified",
      rejected: name === "rejected",
    });
  };

  const handleSave = async () => {
    try {
      const statusString = status.verified
        ? "Verified"
        : status.unverified
        ? "Unverified"
        : "Rejected";
      const body = { is_admin_verified: statusString };

      const response = await verifyWorkforceUser({ body, id: data._id });
      if (response.status) {
        toast.success("Status updated successfully!");
        navigate(adminPathUrl.dataWorkforce, {
          state: {
            currentPage: location.state?.currentPage,
            deleteUserCall: location?.state?.deleteUserCall,
          },
        });
      } else {
        toast.error("Failed to update status.");
      }
    } catch (error) {
      toast.error("Error saving status.");
    }
  };

  useEffect(() => {
    const fetchWorkerDetail = async () => {
      setLoading(true);
      try {
        const body = { user_id: location.state.id };
        const response = await getWorkerDetail(body);
        console.log("in");
        if (response?.data) {
          const workerData = response.data.data;
          setData(workerData);
          setStatus({
            verified: workerData.is_admin_verified === "Verified",
            unverified: workerData.is_admin_verified === "Unverified",
            rejected: workerData.is_admin_verified === "Rejected",
          });
        } else {
          toast.error("No data available.");
        }
      } catch (error) {
        toast.error("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchWorkerDetail();
  }, [location.state.id]);
  console.log("data",data)
  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);
  console.log("locatuon", location);
  return (
    <section>
      <div className="container">
        <div className="prev-detail">
          <PageTile heading1={"Worker Details"} backButton={true} />
          <div className="row">
            <div className="col-md-4">
              <div className="position-relative text-center py-5 bg-light shadow rounded">
                <div className="m-0 d-flex align-items-center gap-2 text-end justify-content-end">
                  <button
                    onClick={handleEditClick}
                    className="bg-transparent border-0 me-2"
                  >
                    <FaEdit size={24}/>
                  </button>
                </div>
                <div className="profileName">
                  <img src={data?.profile_pic} alt="Worker" />
                </div>
                <div className="modal-detail-content">
                  <h1>{data?.fullName}</h1>
                  <h2>
                    {data?.skill?.[0]?.SubSkillName} | {data?.nationality} |{" "}
                    {data?.city}
                  </h2>
                  <h3 className="border-top">Skills with Experience</h3>
                  {data?.skill?.map((skill, index) => (
                    <div className="modal-detail-content-exp pb-3" key={index}>
                      {skill?.SubSkillName} ({skill?.experience}) |{" "}
                      {skill?.skillName} <br />
                    </div>
                  ))}
                  <div className="modal-detail-content-personal pt-3 border-top">
                    <div>
                      Age: <span>{data?.age}</span>
                    </div>
                    <div>
                      Language: <span>{data?.language}</span>
                    </div>
                    <div>
                      Passport:{" "}
                      <span className="text-capitalize">
                        {data?.is_passport}
                      </span>
                    </div>
                    <div>
                      Phone:{" "}
                      <span className="text-capitalize">{data?.phone}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="doc-card position-relative p-2 bg-light shadow rounded h-100">
                <h3>Documents</h3>
                <div className="row">
                  {data?.documents?.map((item, index) => (
                    <div key={index} className="col-md-4">
                      <div className="position-relative bg-light shadow rounded">
                        <img
                          style={{ borderRadius: "10px", cursor: "pointer" }}
                          className="img-fluid w-100"
                          src={item?.user_document}
                          alt={`Document ${index}`}
                          onClick={() => setSelectedImage(item?.user_document)}
                        />
                        <p className="text-center">{item?.doc_title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="status-section pt-0 pb-3">
            <h5 className="mt-4">Status</h5>
            <div className="d-flex flex-wrap gap-4 align-items-center">
              {["verified", "unverified", "rejected"].map((statusKey) => (
                <div
                  className="form-check"
                  key={statusKey}
                  style={{ opacity: 0.8 }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input opacity-1"
                    id={statusKey}
                    name={statusKey}
                    checked={status[statusKey]}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor={statusKey}>
                    {statusKey.charAt(0).toUpperCase() + statusKey.slice(1)}
                  </label>
                </div>
              ))}
              <button
                className="btn btn-primary btn-global"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {selectedImage && (
        <Modal
          imageSrc={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </section>
  );
}
