import React, { useEffect, useState } from "react";

import skilled from "../../assests/images/Skilled-Trades-01.svg";
import genralWorkers from "../../assests/images/General Workers.svg";
import constructors from "../../assests/images/Constructions-01.svg";
import energyIndustry from "../../assests/images/Energy & Industrial.svg";
import transport from "../../assests/images/Transpotation-01.svg";
import manfacturing from "../../assests/images/Manufacturing-01.svg";
import Retail from "../../assests/images/Retail-01.svg";
import disaterRestored from "../../assests/images/Disaster Restoration-01.svg";
import hospitality from "../../assests/images/Hospitality-01.svg";
import wasteRecycle from "../../assests/images/Waste & Recycling-01.svg";
import { getHomepageWeplacedService } from "../../services/admin/manageworkforce/manageworkforceservices";
export default function IndustryServe() {
  const [homepageWeplaced, setHomepageWeplaced] = useState(null);

  const GetWeplacedHomepageData = async () => {
    try {
      const response = await getHomepageWeplacedService();
      if (response.status && response.data.length > 0) {
        console.log(response.data); // Debugging: Check the data here
        setHomepageWeplaced(response.data[0]); // Access the first item in the array
      }
    } catch (error) {
      console.error("Error fetching homepage data:", error);
    }
  };

  useEffect(() => {
    GetWeplacedHomepageData();
  }, []);

  if (!homepageWeplaced) {
    return <div>Loading...</div>; // Show a loading indicator while data is fetched
  }
  const array = [
    {
      icon: genralWorkers,
      heading: "General Workers",
    },
    {
      icon: skilled,
      heading: "Skilled Traders",
    },
    {
      icon: constructors,
      heading: "Constructions",
    },
    {
      icon: energyIndustry,
      heading: "Energy & Industrial",
    },
    {
      icon: transport,
      heading: "Transportation",
    },
    {
      icon: manfacturing,
      heading: "Manufacturing",
    },
    {
      icon: Retail,
      heading: "Retail",
    },
    {
      icon: disaterRestored,
      heading: "Disaster Restoration",
    },
    {
      icon: hospitality,
      heading: "Hospitality",
    },
    {
      icon: wasteRecycle,
      heading: "Waste & Recycling",
    },
  ];
  return (
    <section className=" ">
    <div className="container">
      <div className="industry-we-server">
      <h4>{homepageWeplaced.industry_title || "Industries we serve"}</h4>
      <p dangerouslySetInnerHTML={{ __html: homepageWeplaced.industry_description || "Default description" }} />
        {/* <h4>Industries we serve</h4> */}
        {/* <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p> */}
        <div className="row">
          {array?.map((item) => (
            <div className="col-xl-2 col-lg-3   col-md-4 col-sm-6   text-center  mt-4">
              <div className="industry-icons">
                <img style={{ maxWidth: "60%" }} src={item.icon} alt="" />
                <h6>{item.heading}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>

    </section>
  );
}
