import React, { useState, useEffect, useCallback } from "react";
import { FaTrash, FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";

import DataTableComponent from "../../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import ToggleButton from "react-toggle-button";
import Loader from "../../../components/loader/loader";

import Avatar from "../../../components/Avatar";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications
import ".././workforce.css";
import "../../index.css";
import usePagination from "../../../hooks/usePagination";
import { adminPathUrl } from "../../../shared/pathUrl";
import {
  addAccountStatusService,
  getDeleteWorkforce,
  getskillset,
  getworkforcelisting,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { getCategory } from "../../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import {
  debounce,
  downloadCSV,
  downloadCsvPdfFile,
  downloadPDF,
  getAllSkills,
} from "../../../utils/methods";
import useSearch from "../../../hooks/useSearch";
import { fDateTime } from "../../../shared/helperfunction";
import { fileFormatDownload, typeForDownload } from "../../../shared/constant";

const formatDateTime = (date) => {
  if (!date) return "";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

const DeletedProfile = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { search, setSearch } = useSearch();
  const [skillFilter, setSkillFilter] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [listData, setListData] = useState([]);
  const [skillsetData, setSkillsetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pagination, setPagination } = usePagination();
  const userColumns = [
    // {
    //   name: "",
    //   selector: (row) => <Avatar imgSrc={row.profile_pic} />,
    // },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => {
        const countryCode = row.countryCode;
        const phoneNumber = row.phone;
        return `${countryCode} ${phoneNumber}`;
      },
      sortable: true,
    },
    {
      name: "Nationality",
      selector: (row) => row?.nationality,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city,
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Skills",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>{item?.SubSkillName}</p>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Experience",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>{item.experience}</p>
          ))}
        </div>
      ),
      sortable: true,
      width: "110px",
    },
    {
      name: "Account Status",
      selector: (row) => (
        <span
          style={{
            color: row.account_status === "Active" ? "green" : "red",
          }}
        >
          {row.account_status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => fDateTime(row.createdAt, "dd-MMM-yyyy"),
      sortable: true,
      width: "110px",
    },
    {
      name: "Time",
      selector: (row) => fDateTime(row.createdAt, "p"),
      sortable: true,
      width: "110px",
    },
    {
      name: "Verification Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.is_admin_verified === "Verified"
                ? "green"
                : row.is_admin_verified === "Unverified"
                ? "darkgoldenrod"
                : row.is_admin_verified === "Rejected"
                ? "red"
                : "black",
          }}
        >
          {row.is_admin_verified}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const fetchDeleteUserData = async (search) => {
    try {
      setLoading(true);
      console.log("searcjinsise", search);
      const getData = await getDeleteWorkforce(currentPage, search);
      console.log("getdata", getData);
      if (getData?.data && getData?.status) {
        setListData(getData?.data?.data);
        setFilteredData(getData?.data?.data);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: getData?.data?.totalPages,
            totalCount: getData?.data?.totalUsers,
          };
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("err", error);
    }
  };
  // create a debounced version for the delete user
  const debouncedSearchForDeleteUser = useCallback(
    debounce(fetchDeleteUserData, 300),
    []
  );
  console.log("pagination", pagination);
  useEffect(() => {
    if (search) {
      debouncedSearchForDeleteUser(search);
    } else {
      fetchDeleteUserData(search);
    }
  }, [search]);
  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getskillset({ body: null, type: "gulf_kaam" });
        console.log("Skillset:", data);
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let filtered = listData;

    if (skillFilter && skillFilter !== "") {
      filtered = filtered.filter((item) =>
        item.skills.some((skill) => skill.SubSkillName === skillFilter)
      );
    }

    // if (experienceFilter) {
    //   filtered = filtered.filter((item) => {
    //     const experienceYears = item.skills.reduce(
    //       (acc, skill) => acc + getExperienceYears(skill.experience),
    //       0
    //     );
    //     switch (experienceFilter) {
    //       case "0-1":
    //         return experienceYears >= 0 && experienceYears <= 1;
    //       case "1-2":
    //         return experienceYears > 1 && experienceYears <= 2;
    //       case "2-4":
    //         return experienceYears > 2 && experienceYears <= 4;
    //       case "4+":
    //         return experienceYears > 4;
    //       default:
    //         return true;
    //     }
    //   });
    // }
    if (experienceFilter) {
      filtered = filtered.filter((item) => {
        const experienceYears = item.skills.reduce(
          (acc, skill) => acc + getExperienceYears(skill),
          0
        );
        switch (experienceFilter) {
          case "0-1":
            return experienceYears >= 0 && experienceYears <= 1;
          case "1-2":
            return experienceYears > 1 && experienceYears <= 2;
          case "2-4":
            return experienceYears > 2 && experienceYears <= 4;
          case "4+":
            return experienceYears > 4;
          default:
            return true;
        }
      });
    }

    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [skillFilter, experienceFilter, searchTerm, listData]);

  // const handleRowClicked = (row) => {
  //   navigate(adminPathUrl.gulfWorkforceDetail, { state: { id: row._id } });
  // };
  const handleRowClicked = (row) => {
    navigate(adminPathUrl.gulfWorkforceDetail, {
      state: { id: row._id, currentPage: currentPage, deleteUser: true },
    }); // Pass current page
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleToggle = async (id) => {
    const updatedData = listData.map((item) => {
      if (item._id === id) {
        const newStatus = !item.is_active;
        return { ...item, is_active: newStatus };
      }
      return item;
    });
    setListData(updatedData);

    try {
      await addAccountStatusService(id, {
        is_active: updatedData.find((item) => item._id === id).is_active,
      });
      toast.success(`Status updated successfully!`); // Show success toast
    } catch (error) {
      console.error("Error updating user status:", error);
      toast.error(`Failed to update status.`); // Show error toast
    }
  };

  const handleSkillFilterChange = (event) => {
    setSkillFilter(event.target.value);
  };

  const handleExperienceFilterChange = (event) => {
    setExperienceFilter(event.target.value);
  };

  // const getExperienceYears = (skill) => {
  //   const match = skill.match(/(\d+) years/);
  //   return match ? parseInt(match[1], 10) : 0;
  // };
  const getExperienceYears = (skill) => {
    // Ensure skill.experience is defined and is a string
    if (typeof skill.experience === "string") {
      const match = skill.experience.match(/(\d+) years/);
      return match ? parseInt(match[1], 10) : 0;
    }
    return 0;
  };

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getAllSkills(skillsetData.data);

  const pdfHeaders = [
    "Name",
    "Phone",
    "Age",
    "Skills with Experience",
    "Account Status",
    "Date & Time",
  ];

  const pdfData = filteredData.map((row) => [
    row.fullName,
    `${row.countryCode} ${row.phone}`,
    row.age,
    row.skills
      .map((item) => `${item.SubSkillName} with ${item.experience}`)
      .join(", "),
    row.account_status,
    formatDateTime(row.createdAt),
  ]);

  const pdfFileName = "Manage_workforce.pdf";
  const csvFileName = "Manage_workforce.csv";

  const csvData = filteredData.map((row) => ({
    Name: row.fullName,
    Phone: `${row.countryCode} ${row.phone}`,
    Age: row.age,
    "Skills with Experience": row.skills
      .map((item) => `${item.SubSkillName} with ${item.experience}`)
      .join(", "),
    "Account Status": row.account_status,
    "Date & Time": formatDateTime(row.createdAt),
  }));

  return (
    <div className="user_page">
      <ToastContainer /> {/* Add ToastContainer here */}
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="search...."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>
        <div className="filters_group text-end mt-2 col-md-6 gap-2">
          <FormControl fullWidth className="mx-2">
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="">
                <em>All Skills</em>
              </MenuItem>
              {subSkillNames?.map((skill, index) => (
                <MenuItem key={index} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className="mx-2">
            <InputLabel id="experience-filter-label">Experience</InputLabel>
            <Select
              labelId="experience-filter-label"
              value={experienceFilter}
              onChange={handleExperienceFilterChange}
              label="Experience"
            >
              <MenuItem value="">
                <em>All Experiences</em>
              </MenuItem>
              <MenuItem value="0-1">0-1 years</MenuItem>
              <MenuItem value="1-2">1-2 years</MenuItem>
              <MenuItem value="2-4">2-4 years</MenuItem>
              <MenuItem value="4+">4+ years</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="datatable-section">
        <DataTableComponent
          onCurrentPageChange={(page) => setCurrentPage(page)}
          toRedirectPage={location?.state?.currentPage}
          title="Deleted Users"
          data={filteredData}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          responsive={true}
          downloadBtns={true}
          downloadCSV={() =>
            downloadCsvPdfFile(
              typeForDownload.deletedProfile,
              fileFormatDownload.csv,
              "Deleted Profiles"
            )
          }
          downloadPDF={() =>
            downloadCsvPdfFile(
              typeForDownload.deletedProfile,
              fileFormatDownload.pdf,
              "Deleted Profiles"
            )
          }
          pdfHeaders={pdfHeaders}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Worker"}
      />
    </div>
  );
};

export default DeletedProfile;
