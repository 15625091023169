import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
const commonHeaders = {
  "Content-Type": "application/json",
  Authorization: "Bearer your_token_here",
};

const formDataHeader = { "Content-Type": "multipart/form-data" };

export const getreviewlisting = (body) => {
  return axios
    .post(API_URL_ENDPOINT.getreview, body)
    .then((res) => res.data)
    .catch((err) => err);
};
export const addnewreviews = (body) => {
  return axios
    .post(API_URL_ENDPOINT.addreview, body)
    .then((res) => res.data)
    .catch((err) => err);
};
export const deletereviewlisting = (body) => {
  return axios
    .post(API_URL_ENDPOINT.deletereview, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateReviewService = async (id, body, headers = { ...commonHeaders }) => {
  try {
    // Construct the full URL by replacing the :id placeholder
    const url = API_URL_ENDPOINT.UpdateReview.replace(":id", id);

    // Make the POST request with the body and headers
    const response = await axios.post(url, body, { headers });

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Log the error and re-throw it
    console.error("Error in updateReviewService:", error);
    throw error;
  }
};


// blog listing
export const getbloglisting = (body) => {
  return axios
    .get(API_URL_ENDPOINT.getBlogs, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getBlogsListingById = (id, body) => {
  return axios
    .get(`${API_URL_ENDPOINT.getbloglistId.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deletebloglisting = (id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteBlog.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateBlog = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.updateBlog, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};
// addexecutive :`${baseUrl}/addExecutive`,
//
export const addExecutive = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.addexecutive, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

export const getexecutivelisting = (body) => {
  return axios
    .get(API_URL_ENDPOINT.getExecutive, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// export const deleteexecutivelisting= (body) => {
//   return axios
//     .post(API_URL_ENDPOINT.deleteExecutive, body)
//     .then((res) => res.data)
//     .catch((err) => err);
// };

export const deleteexecutivelisting = (id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteexecutive.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateExecutive = (
  id,
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .put(`${API_URL_ENDPOINT.updateExecutive.replace(":id", id)}`, body, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};
