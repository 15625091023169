import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage/homepage";
import Layout from "./pages/layout";
import AboutUs from "./pages/about/aboutUs";
import { adminPathUrl, pathUrl } from "./shared/pathUrl";
import WorkerListing from "./pages/worker-listing/workListing";
import CartPage from "./pages/cart/cartPage";
import BlogPage from "./pages/blog/blogPage";
import BlogDetail from "./pages/blog/blogDetail";
import PrivacyPolicy from "./pages/privacy-policy&term-condition/privacyPolicy";
import TermCondition from "./pages/privacy-policy&term-condition/termCondition";
import Dashboard from "./admin/dashboard/dashboard";
import AdminLayout from "./admin/adminLayout/layout";
import Login from "./admin/auth/login/login";
import { useState } from "react";
import PrivateRoutes from "./admin/routes/PrivateRoutes";
import Workforce from "./admin/workforce/WorkForce";
import AddWorkForce from "./admin/workforce/addworkforce/addWorkForce";
import ManagePrivacyPolicy from "./admin/adminPages/managePrivacyPolicy/ManagePrivacyPolicy";
import ManageBlogs from "./admin/adminPages/manageBlogs/ManageBlogs";
import AddBlogs from "./admin/adminPages/manageBlogs/addBlogs/AddBlogs";
import TermsAndConditions from "./admin/adminPages/manageTermsConditions/TermsConditions";
import ManageReviews from "./admin/adminPages/manageReviews/ManageReviews";
import AddReview from "./admin/adminPages/manageReviews/addReviews/AddReview";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ManageManpowerEnquiry from "./admin/manage-manpower-enquiry/manageManpowerEnquiry";
import ManageManPowerDetail from "./admin/manage-manpower-enquiry/manageManPowerDetail";
import WokerForceDetail from "./admin/manage-manpower-enquiry/manageManPowerDetail";
import WorkerForceDetail from "./admin/workforce/workerForceDetail";
import Category from "./admin/category/category";
import AddCategory from "./admin/category/addCategory";
import ContactUs from "./admin/gulfAdminPages/contactUs";
import GulfAddBlogs from "./admin/gulfAdminPages/gulfBlogs/gulfAddBlogs";
import GulfWorking from "./admin/gulfAdminPages/working";
import GulfFeatures from "./admin/gulfAdminPages/features";
import Banner from "./admin/adminPages/manageBanner";
import AddFAQs from "./admin/adminPages/manageFAQs";
import AddGulfFAQs from "./admin/gulfAdminPages/gulfFAQs";
import Addnotifications from "./admin/adminPages/manageNotifications/notifications";
import AddGulfnotifications from "./admin/gulfAdminPages/manageGulfNotifications/gulfNotifications";
import UserManagement from "./admin/adminPages/manageUsers/users";
import AddUsers from "./admin/adminPages/manageUsers/addUsers/addUsers";
import UpdateUsers from "./admin/adminPages/manageUsers/updateUsers/updateUsers";
import Profile from "./admin/adminPages/manageProfile/profile";
import DashboardGulf from "./admin/dashboard-gulfkaam/dashboardgulf";
import GulfUserManagement from "./admin/gulfAdminPages/gulfmanageUsers/users";
import GulfUpdateUsers from "./admin/gulfAdminPages/gulfmanageUsers/updateUsers/updateUsers";
import GulfAddUsers from "./admin/gulfAdminPages/gulfmanageUsers/addUsers/addUsers";
import GulfPrivacyPolicy from "./admin/gulfAdminPages/gulfPrivacyPolicy/GulfPrivacyPolicy";
import GulfTermsAndConditions from "./admin/gulfAdminPages/gulfTermsConditions/TermsConditions";
import WeplacedContactUs from "./admin/gulfAdminPages/contactUs/weplacedcontact_us";
import ManageAboutUs from "./admin/adminPages/manageAboutUs/aboutUs";
import Executive from "./admin/adminPages/manageExecutive/executive";
import UpdateBlogs from "./admin/adminPages/manageBlogs/updateBlogs/UpdateBlog";
import ManageExecutive from "./admin/adminPages/manageExecutive/executiveListing/ManageExecutive";
import UpdateExecutive from "./admin/adminPages/manageExecutive/updateExecutive/UpdateExecutive";
import NotFoundPage from "./components/NotFoundPage";
import UpdateCategory from "./admin/category/updateCategory";
import GulfJobLocation from "./admin/gulfAdminPages/jobLocation/jobLocation";
import ManageNewsArticle from "./admin/adminPages/manageNewsArticle/ManageNewsArticle";
import UpdateNewsArticle from "./admin/adminPages/manageNewsArticle/updateNewsArticle/UpdateNewsArticle";
import AddNewsArticle from "./admin/adminPages/manageNewsArticle/addNewsArticle/AddNewsArticle";
import ManageGulfBlogs from "./admin/gulfAdminPages/gulfBlogs";
import DataLakeList from "./dataLake/dataLakeWorkForce/WorkForce";
import UpdateReview from "./admin/adminPages/manageReviews/updateReviews/updateReview";
import AddDataLakeWorkForce from "./dataLake/dataLakeWorkForce/addworkforce/addWorkForce";
import DataLakeWorkerForceDetail from "./dataLake/dataLakeWorkForce/workerForceDetail";
import ForgotPass from "./admin/auth/forgotPassword/forgotPass";
import ResetPassword from "./admin/auth/resetPassword/resetPassword";
import ManageSkillSet from "./admin/adminPages/ManageSkillSet";
import ManageHomepage from "./admin/adminPages/ManageHomepage";
import EditSkillSet from "./admin/adminPages/ManageSkillSet/EditSkillSet";
import GulfMarketing from "./admin/gulfAdminPages/marketing";
import GulfAppPrivacyPolicy from "./admin/gulfAdminPages/appPrivacyPolicy";
import GulfAppTermsAndConditions from "./admin/gulfAdminPages/appTermsAndConditions";
import AdminPublicLayout from "./admin/adminLayout/AdminPublicLayout";
import GulfHomeFeatures from "./admin/gulfAdminPages/working/features";
import GulfLocDetails from "./admin/gulfAdminPages/working/location";
import GulfWorkingInfo from "./admin/gulfAdminPages/working/working";
import UpdateDataLakeWorkForce from "./dataLake/dataLakeWorkForce/editWorkforce/Editworkforce";
import DeletedProfile from "./admin/workforce/deleteProfile/deleteProfile";
import RejectedProfile from "./admin/workforce/rejectedProfile/rejectedProfile";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const permissions = loginData?.permissions ?? [];
  console.log("peermissin",permissions)
  return (
    <>
      <ToastContainer theme="colored" autoClose={1500} />
      <Routes>
        <Route path={`${adminPathUrl.profile}/:id`} element={<Profile />} />

        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path={pathUrl.aboutUs} element={<AboutUs />} />
          <Route path={pathUrl.workerList} element={<WorkerListing />} />
          <Route path={pathUrl.cart} element={<CartPage />} />
          <Route path={pathUrl.blog} element={<BlogPage />} />
          <Route path={`${pathUrl.blog}/:id`} element={<BlogPage />} />
          <Route path={`${pathUrl.blogDetail}/:id`} element={<BlogDetail />} />
          <Route path={pathUrl.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={pathUrl.termCondition} element={<TermCondition />} />
          <Route
            path={"*"}
            element={<NotFoundPage message="Page not found" />}
          />
        </Route>

        {/* Admin Routes */}

        <Route
          path="/admin"
          element={<AdminPublicLayout loginData={loginData} />}
        >
          <Route
            index
            path={adminPathUrl.login}
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path={adminPathUrl.resetPassword}
            element={<ResetPassword />}
          />
          <Route path={adminPathUrl.forgotPassword} element={<ForgotPass />} />
          <Route
            path={"*"}
            element={<NotFoundPage message="Page not found" />}
          />
        </Route>

        <Route path="/admin" element={<AdminLayout loginData={loginData} />}>
          <Route index path={adminPathUrl.dashboard} element={<Dashboard />} />

          {permissions.includes("cms") && (
            <>
              <Route
                path={adminPathUrl.managePrivacyPolicy}
                element={<ManagePrivacyPolicy />}
              />
              <Route
                path={adminPathUrl.manageBlogs}
                element={<ManageBlogs />}
              />
              <Route
                path={adminPathUrl.manageSkillSet}
                element={<ManageSkillSet />}
              />
              <Route
                path={adminPathUrl.editSkillSet}
                element={<EditSkillSet />}
              />
              <Route
                path={adminPathUrl.manageHomePage}
                element={<ManageHomepage />}
              />
              <Route path={adminPathUrl.addblogs} element={<AddBlogs />} />
              <Route
                path={`${adminPathUrl.updateBlogs}/:id`}
                element={<UpdateBlogs />}
              />
              <Route
                path={adminPathUrl.manageTermsConditions}
                element={<TermsAndConditions />}
              />
              <Route
                path={adminPathUrl.manageReviews}
                element={<ManageReviews />}
              />
              <Route path={adminPathUrl.addReviews} element={<AddReview />} />
              <Route
                path={`${adminPathUrl.updateReviews}/:id`}
                element={<UpdateReview />}
              />

              <Route path={adminPathUrl.managefaq} element={<AddFAQs />} />
              <Route path={adminPathUrl.aboutUs} element={<ManageAboutUs />} />
              <Route path={adminPathUrl.addExecutive} element={<Executive />} />
              <Route
                path={adminPathUrl.manageExecutive}
                element={<ManageExecutive />}
              />
              <Route
                path={`${adminPathUrl.editExecutive}/:id`}
                element={<UpdateExecutive />}
              />
            </>
          )}
          {/*we ppaced admin routes*/}
          {permissions.includes("manageWorkforceEnquiry") && (
            <>
              {" "}
              <Route
                path={adminPathUrl.manageManpower}
                element={<ManageManpowerEnquiry />}
              />
              <Route
                path={`${adminPathUrl.manageManpowerDetail}/:id`}
                element={<ManageManPowerDetail />}
              />
              <Route
                path={adminPathUrl.workforceDetail}
                element={<WorkerForceDetail />}
              />
            </>
          )}
          {permissions.includes("manageCategory") && (
            <>
              {" "}
              <Route path={adminPathUrl.category} element={<Category />} />
              <Route
                path={adminPathUrl.addCategory}
                element={<AddCategory />}
              />
              <Route
                path={adminPathUrl.updateCategory}
                element={<UpdateCategory />}
              />
            </>
          )}
          {permissions.includes("manageBanner") && (
            <Route path={adminPathUrl.banner} element={<Banner />} />
          )}
          {permissions.includes("manageAdminRole") && (
            <>
              <Route
                path={adminPathUrl.manageUsers}
                element={<UserManagement />}
              />
              <Route path={adminPathUrl.addUsers} element={<AddUsers />} />
              <Route
                path={`${adminPathUrl.updateUsers}/:id`}
                element={<UpdateUsers />}
              />
            </>
          )}
          {permissions.includes("contactUs") && (
            <Route
              path={adminPathUrl.wePlacedContactUs}
              element={<WeplacedContactUs />}
            />
          )}
          <Route
            path={adminPathUrl.notifications}
            element={<Addnotifications />}
          />

          {/* gulf kaam admin routes */}
          <Route
            path={adminPathUrl.gulfDashboard}
            element={<DashboardGulf />}
          />
          <Route path={adminPathUrl.gulfContact} element={<ContactUs />} />
          {/* <Route path={adminPathUrl.gulfBlogs} element={<ManageGulfBlogs />} /> */}
          <Route path={adminPathUrl.gulfAddBlogs} element={<GulfAddBlogs />} />
          <Route path={adminPathUrl.gulfWorking} element={<GulfWorking />} />
          <Route
            path={adminPathUrl.gulfhomepagefeature}
            element={<GulfHomeFeatures />}
          />
          <Route
            path={adminPathUrl.gulflocdetails}
            element={<GulfLocDetails />}
          />
          <Route
            path={adminPathUrl.gulfworkinginfo}
            element={<GulfWorkingInfo />}
          />
          <Route path={adminPathUrl.gulfFeatures} element={<GulfFeatures />} />
          <Route path={adminPathUrl.gulfAddfaqs} element={<AddGulfFAQs />} />
          <Route path={adminPathUrl.gulfWorkforce} element={<Workforce />} />
          <Route
            path={adminPathUrl.gulfAddWorkforce}
            element={<AddWorkForce />}
          />
          <Route
            path={adminPathUrl.gulfNewsAtricle}
            element={<ManageNewsArticle />}
          />
          <Route
            path={`${adminPathUrl.gulfUpdateNewsAtricle}/:id`}
            element={<UpdateNewsArticle />}
          />
          <Route
            path={adminPathUrl.gulfAddNewsAtricle}
            element={<AddNewsArticle />}
          />
          <Route
            path={adminPathUrl.gulfNotification}
            element={<AddGulfnotifications />}
          />
          <Route
            path={adminPathUrl.gulfmanageUsers}
            element={<GulfUserManagement />}
          />
          <Route path={adminPathUrl.gulfaddUsers} element={<GulfAddUsers />} />
          <Route
            path={`${adminPathUrl.gulfupdateUsers}/:id`}
            element={<GulfUpdateUsers />}
          />
          <Route
            path={adminPathUrl.gulfPrivacyPolicy}
            element={<GulfPrivacyPolicy />}
          />
          <Route
            path={adminPathUrl.gulfAppPrivacyPolicy}
            element={<GulfAppPrivacyPolicy />}
          />
          <Route
            path={adminPathUrl.gulfmanagetermsconditions}
            element={<GulfTermsAndConditions />}
          />
          <Route
            path={adminPathUrl.gulfapptermsconditions}
            element={<GulfAppTermsAndConditions />}
          />
          <Route
            path={adminPathUrl.gulfjobLocation}
            element={<GulfJobLocation />}
          />

          <Route
            path={adminPathUrl.gulfWorkforceDetail}
            element={<WorkerForceDetail />}
          />
          <Route
            path={adminPathUrl.gulfMarketing}
            element={<GulfMarketing />}
          />

          {/* Data Lake routes */}

          <Route path={adminPathUrl.dataWorkforce} element={<DataLakeList />} />
          <Route
            path={adminPathUrl.addDataWorkforce}
            element={<AddDataLakeWorkForce />}
          />
          <Route
            path={adminPathUrl.editDataWorkforce}
            element={<UpdateDataLakeWorkForce />}
          />
          <Route
            path={adminPathUrl.manageDeleteProfile}
            element={<DeletedProfile />}
          />
          <Route
            path={adminPathUrl.manageRejectedProfile}
            element={<RejectedProfile />}
          />
          <Route
            path={adminPathUrl.dataLakeWorkforceDetail}
            element={<DataLakeWorkerForceDetail />}
          />
          <Route
            path={"*"}
            element={
              <NotFoundPage message="Sorry, you don't have permission to access this page." />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
