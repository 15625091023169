import React, { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { pathUrl } from "../../shared/pathUrl";
import { getTheBlog } from "../../services/web/blog/blogServices";

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const IndustryInsights = () => {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const handleCardClick = (item) => {
    navigate(`${pathUrl.blogDetail}/${item._id}`);
  };

  const handleViewAll = () => {
    navigate(pathUrl.blog); // Navigate to blog page with default content
  };

  // Get the blog data from the backend API service call
  const getTheBlogData = async () => {
    try {
      const getData = await getTheBlog();
      console.log("getData", getData);
      if (getData?.status) {
        setBlogData(getData?.data);
      }
    } catch (error) {
      console.log("exceptional error", error);
    }
  };

  useEffect(() => {
    getTheBlogData();
  }, []);

  return (
    <section className="industry_section">
      <div className="container">
        <div className="industry-insights">
          <div className="d-flex justify-content-between mb-4 align-items-center">
            <h4> Industry Insights</h4>
            <button onClick={handleViewAll} className="global-btn">
              View All
            </button>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="blog-img side-blog-img" onClick={() => handleCardClick(blogData && blogData[0])}>
                <img className="img-fluid h-100" src={blogData && blogData[0]?.blog_large_pic} alt="Insights" />
                <div className="blog-content">
                  <span className="blog-span">{blogData && blogData[0]?.createdAt && formatDate(blogData[0].createdAt)}</span>
                  <p className="blog-p">{blogData && blogData[0]?.title}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 desktop-blog">
              {blogData && blogData?.slice(1, 3)?.map((item) => (
                <div className="row px-2" key={item._id} style={{ cursor: "pointer" }} onClick={() => handleCardClick(item)}>
                  <div className="col-md-6 side-blog">
                    <img className="w-100 h-100" src={item.blog_short_pic} alt="Insights Card" />
                  </div>
                  <div className="col-md-6">
                    <span style={{ fontSize: "18px", lineHeight: "36px", color: "#000000", fontFamily: "Graphik" }}>
                      {item.createdAt && formatDate(item.createdAt)} 
                    </span>
                    <h6 style={{ fontSize: "27px", lineHeight: "36px", color: "#010101", fontWeight: "bold", fontFamily: "Graphik" }}>
                      {item.title}
                    </h6>
                    <a
                      style={{
                        fontSize: "18px",
                        lineHeight: "36px",
                        textDecoration: "underline",
                        color: "#000000",
                        fontFamily: "Graphik",
                        cursor: "pointer"
                      }}
                    >
                      Learn More <GoArrowUpRight />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryInsights;
