import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { getAboutUsService } from "../../services/admin/manageworkforce/manageworkforceservices";
export default function WhatWeDo() {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [aboutUsData, setAboutUsData] = useState(null);
    // Fetch about us data from backend
    const getAboutUsData = async () => {
      try {
        const response = await getAboutUsService();
        if (response.status && response.data.length > 0) {
          setAboutUsData(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching about us data:", error);
      }
    };
    useEffect(() => {
      getAboutUsData();
    }, []);
  return (
    <section className="aboutUs">
      {aboutUsData && (
      <div className="container">
        <div className="row">
          <div className="col-sm-9">
            <div className="what-we-do">
              <h6>{aboutUsData.what_we_do_title}</h6>
              <p>
              <div dangerouslySetInnerHTML={{ __html: aboutUsData.what_we_do_content }} />
              </p>
            </div>
          </div>
          {/* <div className="col-sm-3">
            <div className="what-we-do-card">
              <h5>3M +</h5>
              <span>hourly workers in network</span>
            </div>
            <div className="what-we-do-card">
              <h5>40 +</h5>
              <span>hourly workers in network</span>
            </div>
          </div> */}
        </div>
      </div>
       )}
    </section>
  );
}