// src/components/LoaderSpinner.js
import React from 'react';
import './spinner.css'; // Ensure you add CSS for spinner styling

const LoaderSpinner = () => (
  <div className="loader-spinner">
    <div className="spinner-border" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

export default LoaderSpinner;
