import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({ data, year }) => {
  // Filter data based on the selected year
  const filteredData = data.filter(item => item.year === year);

  console.log('Filtered Data:', filteredData); // Debugging log

  // Check if filteredData has any items
  if (filteredData.length === 0) {
    return <div>No data available for year {year}</div>;
  }

  return (
    <ResponsiveContainer width="95%" height={500}>
      <BarChart data={filteredData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" /> {/* Adjust if the key is different */}
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Total" fill="rgb(2 190 167)" barSize={20} /> {/* Adjust if the key is different */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
