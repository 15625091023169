import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash, FaUser } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import { adminPathUrl } from "../../../shared/pathUrl";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { deleteUser, getAdminUsersList } from "../../../services/admin/manageworkforce/manageworkforceservices";
import Loader from "../../../components/loader/loader";
import { toast } from "react-toastify";
import Avatar from "../../../components/Avatar";

const permissionLabels = {
  manageAdminRole: "Manage Admin Role",
  manageBanner: "Manage Banner",
  manageCategory: "Manage Category",
  manageWorkforceEnquiry: "Manage Workforce",
  cms: "CMS",
  contactUs: "Contact Us",
};

const permissionsList = Object.entries(permissionLabels).map(([name, label]) => ({
  name,
  label,
}));

export default function UserManagement() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState("");

  const navigate = useNavigate();

  const userColumns = [
    {
      name: "",
      selector: (row) => <Avatar imgSrc={row.admin_pic} />,
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Permissions",
      selector: (row) => (
        <div>
          {row.permissions.map((permission, index) => (
            <div key={index}>{permissionLabels[permission] || permission}</div>
          ))}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="group_btns">
          <button
            className="bg-transparent border-0 me-2"
            onClick={() => handleRowClicked(row)}
          >
            <FaEdit />
          </button>
          <button
            className="bg-transparent border-0"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleRowClicked = (row) => {
    navigate(`${adminPathUrl.updateUsers}/${row._id}`, {
      state: { userData: row },
    });
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await getAdminUsersList();
      if (response && response.data) {
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const confirmDelete = async () => {
    try {
      if (!selectedUserId) {
        toast.error("No user selected for deletion.");
        return;
      }
      await deleteUser(selectedUserId);
      setUserData(userData.filter((user) => user._id !== selectedUserId));
      toast.success("User deleted successfully");
      setDeleteModal(false);
      setSelectedUserId(null);
    } catch (error) {
      toast.error("Failed to delete the user. Please try again.");
    }
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleDelete = (row) => {
    setSelectedUserId(row._id);
    setDeleteModal(true);
  };

  // Filter user data based on selected permission and search input
  const filteredUserData = userData
    .filter((user) => {
      const fullNameMatches = user.fullName
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      const emailMatches = user.email
        .toLowerCase()
        .includes(searchInput.toLowerCase());
      return fullNameMatches || emailMatches;
    })
    .filter((user) => {
      return selectedPermission
        ? user.permissions.includes(selectedPermission)
        : true;
    });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="user_page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>
        <div className="mb-4 col-md-2 custom-select">
          <FormControl fullWidth>
            <InputLabel id="permissionFilter-label">Roles</InputLabel>
            <Select
              labelId="permissionFilter-label"
              id="permissionFilter"
              value={selectedPermission}
              onChange={(e) => setSelectedPermission(e.target.value)}
              label="Roles"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {permissionsList.map((perm) => (
                <MenuItem key={perm.name} value={perm.name}>
                  {perm.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div>
          <button
            onClick={() => navigate(adminPathUrl.gulfaddUsers)}
            className="add-user-button d-flex gap-2 align-items-center"
          >
            <FaUser /> <span> Add User</span> 
          </button>
        </div>
      </div>
      <div className="datatable-section">
      <DataTableComponent
        title="Manage Users"
        data={filteredUserData}
        columns={userColumns}
        checkboxchange={handleCheckboxChange}
        responsive={true}
      />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this User?"}
        onConfirm={confirmDelete}
      />
    </div>
  );
}
