import React, { useState } from "react";
import "./index.css";
import WorkerDetailModal from "./workerDetailModal";
import { useDispatch, useSelector } from "react-redux";
import labor from "../../assests/images/labor.jpg";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
import { toast } from "react-toastify";

export default function WorkerCard({
  selectedSkill,
  cardData,
  index,
  maxCount,
  selectedCartItems,
}) {
  const [modal, setModal] = useState(false);
  const { cartSelectedItem } = useSelector((state) => state.MANPOWERENQUIRY);
  const dispatch = useDispatch();

  const onChange = (e) => {
    const selectedItems = cartSelectedItem[selectedSkill] || [];
    let totalCheckedCount = 0;
    for (const skill in cartSelectedItem) {
      if (cartSelectedItem.hasOwnProperty(skill)) {
        totalCheckedCount += cartSelectedItem[skill]?.user?.filter(
          (item) => item.checked
        ).length;
      }
    }

    if (selectedCartItems < maxCount || !e.target.checked) {
      const updatedItems = [...selectedItems.user];
      updatedItems[index] = {
        ...updatedItems[index],
        checked: e.target.checked,
      };
      const newData = {
        ...cartSelectedItem,
        [selectedSkill]: {
          ...cartSelectedItem[selectedSkill],
          user: updatedItems,
        },
      };
      dispatch(manPowerEnquirySlice.actions.selectCartItem(newData));
    } else {
      toast.info(`You can select a maximum of ${maxCount} profiles`);
    }
  };

  return (
    <div className="wokrer-card">
      <label className="rounded-checkbox-container">
        <input
          type="checkbox"
          checked={cardData?.checked}
          onChange={onChange}
        />
        <span className="rounded-checkbox"></span>
      </label>
      <img
        style={{ borderRadius: "10px", cursor: "pointer" }}
        className="img-fluid"
        src={cardData?.profile_pic ?? labor}
        onClick={() => setModal(true)}
      />
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setModal(true)}
        className="d-flex justify-content-between"
      >
        <div className="worker-card-content">
          <h6>{cardData?.fullName}</h6>
          <p>{cardData?.skills?.[0]?.SubSkillName ?? ""}</p>
          <p>{cardData?.nationality}</p>
        </div>
        <div className="woker-card-exp mt-3">
          <span>
            EXP
            <br />
            <b>{cardData?.skills?.[0]?.experience ?? "0 YEAR"}</b>
          </span>
        </div>
      </div>
      <WorkerDetailModal
        open={modal}
        setOpen={setModal}
        cardData={cardData}
        onChange={onChange}
        profilePic={cardData?.profile_pic ?? labor}  // Pass the image URL here
      />
    </div>
  );
}
