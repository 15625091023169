import { useEffect, useState } from "react";

const usePagination = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 10,
    limit: 30,
    totalCount: 0,
  });

  return {
    pagination,
    setPagination,
  };
};

export default usePagination;
