import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../../shared/pathUrl";
import DataTableComponent from "../../../components/dataTable/dataTable";
import {
  deletereviewlisting,
  getreviewlisting,
} from "../../../services/admin/CmsPages/cmsPagesServices";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import Loader from "../../../components/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageReviews = () => {
  const [reviewData, setReviewData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const navigate = useNavigate();

  const userColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
    },
    {
      name: "Review",
      selector: (row) => row.review,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="group_btns">
          <button
            className="bg-transparent border-0 me-2"
            onClick={() => handleRowClicked(row)}
          >
            <FaEdit />
          </button>
          <button
            className="bg-transparent border-0"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        const response = await getreviewlisting();
        if (response && response.data) {
          setReviewData(response.data);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchReviews();
  }, []);

  const confirmDelete = async () => {
    try {
      console.log("Confirm delete:", rowToDelete);
      if (!rowToDelete || !rowToDelete._id) {
        console.error("Row to delete is not defined or missing _id");
        return;
      }
      setLoading(true);
      console.log("Deleting review with payload:", { _id: rowToDelete._id });
      const response = await deletereviewlisting({ _id: rowToDelete._id });
      if (response && response.status) {
        // Checking the status in the response
        setReviewData((prevData) =>
          prevData.filter((review) => review._id !== rowToDelete._id)
        );
        toast.success("Review deleted successfully"); // Show success toast
        setDeleteModal(false);
        setRowToDelete(null);
      } else {
        toast.error("Failed to delete review"); // Show error toast
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      toast.error("Error deleting review"); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  const handleRowClicked = (row) => {
    navigate(`${adminPathUrl.updateReviews}/${row._id}`, {
      state: { reviewData: row },
    });
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleEdit = (row) => {
    console.log("Edit row:", row);

    // Implement edit logic here (e.g., navigate to edit page)
  };

  const handleDelete = (row) => {
    console.log("Deleting row:", row);
    setRowToDelete(row);
    setDeleteModal(true);
  };

  const handleAddUserClick = () => {
    navigate(adminPathUrl.addReviews); // Navigate to add reviews page
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="user_page">
      <ToastContainer /> {/* ToastContainer for displaying toast messages */}
      <button className="add-user-button" onClick={handleAddUserClick}>
        Add Review
      </button>
      <div className="datatable-section">
      <DataTableComponent
        title="Manage Reviews"
        data={reviewData}
        columns={userColumns}
        selectedRows={selectedRows}
        responsive={true}
        // clickedrow={handleRowClicked}
        checkboxchange={handleCheckboxChange}
      />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Review?"}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ManageReviews;
