import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import { adminPathUrl } from "../../shared/pathUrl";
import {
  getskillset,
  deleteskillset,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import Loader from "../../components/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  Typography,
} from "@mui/material";

export default function Category() {
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillsetData, setSkillsetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const userColumns = [
    {
      name: "Category",
      selector: (row) => row.newSkillName,
      sortable: true,
      width: "20%",
    },
    {
      name: "SubCategories",
      selector: (row) => (
        <div className="filters_group text-end mt-2">
          <FormControl fullWidth>
            <InputLabel>SubCategories</InputLabel>
            <Select defaultValue="" label="SubCategories">
              {row.subSkills.map(
                (
                  { newSubSkillName, isActive, subCategories, tertiarySkills },
                  index
                ) => (
                  <React.Fragment key={index}>
                    <MenuItem
                      value={newSubSkillName}
                      className="d-flex justify-content-between"
                    >
                      <span>{newSubSkillName}</span>
                      <span
                        className={`${
                          isActive ? "text-success" : "text-danger"
                        }`}
                      >

                        {/* int this error in contro */}
                        {/* is it ok to complete */}
                        {isActive ? "Enabled" : "Disabled"}
                      </span>
                    </MenuItem>

                    {tertiarySkills.map((nestedSub, idx) => (
                      <MenuItem
                        key={`${index}-${idx}`}
                        value={nestedSub.tertiarySkillName}
                        style={{ paddingLeft: "2rem" }}
                      >
                        <Typography variant="body2">
                          {nestedSub.tertiarySkillName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </React.Fragment>
                )
              )}
            </Select>
          </FormControl>
        </div>
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: "Status",
      selector: (row) => (
        <span className={row.isActive ? "text-success" : "text-danger"}>
          {row.isActive ? "Enabled" : "Disabled"}
        </span>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="group_btns">
          <button
            className="bg-transparent border-0"
            onClick={() => handleRowClicked(row)}
          >
            <FaEdit />
          </button>
        </div>
      ),
      width: "20%",
    },
  ];

  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        setLoading(true);
        const response = await getskillset({
          body: null,
          type: "we_placed",
        });

        if (response && response.data) {
          setSkillsetData(response.data);
        }
      } catch (error) {
        console.error("Error fetching skillset:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSkillset();
  }, []);

  const handleRowClicked = (row) => {
    navigate(adminPathUrl.updateCategory, { state: { row } });
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleDelete = (row) => {
    setRowToDelete(row);
    setDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      if (!rowToDelete || !rowToDelete._id) return;
      setLoading(true);
      const response = await deleteskillset({ _id: rowToDelete._id });
      if (response && response.success) {
        setSkillsetData(
          skillsetData.filter((skill) => skill._id !== rowToDelete._id)
        );
        setDeleteModal(false);
        setRowToDelete(null);
      } else {
        toast.error("Failed to delete review");
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      toast.error("Error deleting review");
    } finally {
      setLoading(false);
    }
  };

  const filteredSkillsetData = skillsetData.filter((item) =>
    item.newSkillName.toLowerCase().includes(searchInput.toLowerCase())
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="user_page">
      <div className="d-flex justify-content-between">
        <div className="mb-4">
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>
        <div className="">
          <button
            onClick={() =>
              navigate(adminPathUrl.addCategory, { state: skillsetData })
            }
            className="add-user-button"
          >
            Add Category
          </button>
        </div>
      </div>

      <DataTableComponent
        title="Manage Category"
        data={filteredSkillsetData}
        columns={userColumns}
        selectedRows={selectedRows}
        checkboxchange={handleCheckboxChange}
        responsive={true}
      />

      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Category"}
        onConfirm={confirmDelete}
      />
    </div>
  );
}
