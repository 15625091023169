import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChartGulf = ({ data, year }) => {
  const filteredData = data.filter(item => item.year === year);

  return (
    <ResponsiveContainer width="95%" height={500}>
      <BarChart data={filteredData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Total" fill="rgb(2 190 167)" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartGulf;
