import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import "bootstrap/dist/css/bootstrap.min.css";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";

function AddGulfFAQs() {
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null); 
  const fileInputRef = useRef(null); 

  const MAX_LENGTH = 9000;

  const initialValues = {
    title: "",
    content: "",
    image: null, // Initial value for the image field
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUploadButtonClick = () => {
    // Simulate a click event on the file input
    fileInputRef.current.click();
  };

  const handleSubmit = (values) => {
    console.log(values);
    // You can handle form submission here
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add FAQs"} backButton={true} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <Field type="text" name="title" className="form-control" />
          </div>
          <div className="form-group">
            {/* <label
              htmlFor="image"
              className="global_admin_btn"
              onClick={handleUploadButtonClick}
            >
              <FaUpload /> Add Media
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="form-control-file"
              style={{ display: "none" }} // Hide the file input
              ref={fileInputRef} // Attach ref to the file input
            /> */}
          </div>
          <CommonEditor
            value={content}
            onChange={handleEditorChange}
            maxLength={MAX_LENGTH}
          />

          <div className="py-3">
            <button type="submit" className="global_admin_btn">
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default AddGulfFAQs;
