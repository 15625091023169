import React from "react";
import { LogoSvg } from "../assests/svg";
import "./index.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { pathUrl } from "../shared/pathUrl";
import { headerNavigation } from "../shared/frontendArray";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

// Memoize the LogoSvg component if it's static
const MemoizedLogoSvg = React.memo(LogoSvg);

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  // Define class names based on location
  const headerClass =
    location.pathname === pathUrl.homePage ||
    location.pathname === pathUrl.aboutUs
      ? "weplaced_header header_custom"
      : "header_custom";

  const containerClass =
    location.pathname === pathUrl.homePage ||
    location.pathname === pathUrl.aboutUs
      ? "container px-0"
      : "container border-bottom";

  return (
    <header className={headerClass}>
      <div className={containerClass}>
        <Navbar expand="lg" className="py-0">
          <Container className="ps-1">
            <Navbar.Brand>
              <div className="header-logo">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(pathUrl.homePage)}
                >
                  <MemoizedLogoSvg />
                </span>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <div className="menu_links">
                  <ul>
                    {headerNavigation.map((item) => (
                      <li key={item.title}>
                        {item.navLink ? (
                          <NavLink
                            to={item.navLink}
                            className={({ isActive }) =>
                              isActive ? "active" : undefined
                            }
                          >
                            {item.title}
                          </NavLink>
                        ) : (
                          <a href={item.target}>{item.title}</a>
                        )}
                      </li>
                    ))}
                    <li>
                      {location.pathname !== pathUrl.homePage ? (
                        <Link
                          to={{
                            pathname: pathUrl.homePage,
                            hash: "#search_manpower",
                          }}
                          className="btn_book hover-btn"
                          onClick={() => navigate(pathUrl.homePage)}
                        >
                          <span>Find Manpower</span>
                        </Link>
                      ) : (
                        <a
                          href="/#search_manpower"
                          className="btn_book hover-btn"
                        >
                          <span>Find Manpower</span>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
}
