import React, { useEffect, useState } from "react";
import { StartIcon, quailifiedIcon2, quailifiedIcon3 } from "../../assests/svg";
import { useNavigate } from "react-router-dom";
import { getHomepageWeplacedService } from "../../services/admin/manageworkforce/manageworkforceservices";

export default function QualifiedWorkerSection() {
  const navigate = useNavigate();
  const [homepageWeplaced, setHomepageWeplaced] = useState(null);

  const GetWeplacedHomepageData = async () => {
    try {
      const response = await getHomepageWeplacedService();
      if (response.status && response.data.length > 0) {
        console.log(response.data); // Debugging: Check the data here
        setHomepageWeplaced(response.data[0]); // Access the first item in the array
      }
    } catch (error) {
      console.error("Error fetching homepage data:", error);
    }
  };

  useEffect(() => {
    GetWeplacedHomepageData();
  }, []);

  if (!homepageWeplaced) {
    return <div>Loading...</div>; // Show a loading indicator while data is fetched
  }

  // Map the API content to the structure needed for rendering
  const contentArray = [
    {
      icon: StartIcon,
      paragraph: homepageWeplaced.info_content2 || "Default content", // Default if undefined
    },
    {
      icon: quailifiedIcon3,
      paragraph: homepageWeplaced.info_content3 || "Default content",
    },
    {
      icon: quailifiedIcon2,
      paragraph: homepageWeplaced.info_content4 || "Default content",
    },
  ];

  return (
    <section className="qualified-skill-worker">
      <div className="heading-p">
        <h4>{homepageWeplaced.info_title || "Default title"}</h4>
        <span dangerouslySetInnerHTML={{ __html: homepageWeplaced.info_content1 || "Default content" }} />
      </div>

      <div className="row quailified_row">
        {contentArray.map((item, index) => (
          <div key={index} className="quailified-worker-icons col-lg-4 spacing-qualified">
            {item.icon()}
            <p dangerouslySetInnerHTML={{ __html: item.paragraph }} />
          </div>
        ))}
      </div>
    </section>
  );
}
