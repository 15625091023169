import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./updateUsers.css"; // Import your CSS file
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import ImageUploader from "../../../../components/ImageUploader";
import { toast } from "react-toastify";
import { updateAdminUser } from "../../../../services/admin/manageworkforce/manageworkforceservices";
import { adminPathUrl } from "../../../../shared/pathUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  // lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  // phone: Yup.string().required("Phone is required"),
  password: Yup.string().optional(),
  confirmPassword: Yup.string().when("password", {
    is: (password) => password && password.length > 0,
    then: () =>
      Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    otherwise: () => Yup.string().notRequired(),
  }),

  permissions: Yup.object().shape({
    manageAdminRole: Yup.boolean(),
    manageBanner: Yup.boolean(),
    manageCategory: Yup.boolean(),
    manageWorkforceEnquiry: Yup.boolean(),
    cms: Yup.boolean(),
    contactUs: Yup.boolean(),
    manageGulfCategory: Yup.boolean(),
    manageGulfWorkforce: Yup.boolean(),
    manageGulfEnquiry: Yup.boolean(),
    gulfCms: Yup.boolean(),
    gulfAppCms: Yup.boolean(),
    gulfContactUs: Yup.boolean(),
    notifications: Yup.boolean(),
    manageProfile: Yup.boolean(),
  }),
});

export default function UpdateUsers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState(null);
  const { state } = location;
 
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    image: null,
    file: null,
    permissions: {
      gulfDashboard: false,
      weplacedDashboard: false,
      manageAdminRole: false,
      manageBanner: false,
      manageCategory: false,
      manageWorkforceEnquiry: false,
      cms: false,
      contactUs: false,
      // manageGulfCategory: false,
      manageGulfWorkforce: false,
      // manageGulfEnquiry: false,
      gulfCms: false,
      gulfAppCms: false,
      gulfContactUs: false,
      notifications: false,
      manageProfile: false,
    },
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleCancel = () => {
    navigate(adminPathUrl.manageUsers);
  };

  useEffect(() => {
    if (location.state && location.state.userData) {
      const { fullName, email, phone, permissions, password, admin_pic } =
        location.state.userData;
      const [firstName, lastName] = fullName.split(" ");
      setImage(admin_pic);
      setInitialValues({
        ...initialValues,
        firstName,
        lastName,
        email,
        phone,
        permissions: {
          manageAdminRole: permissions.includes("manageAdminRole"),
          weplacedDashboard:permissions.includes("weplacedDashboard"),
          manageBanner: permissions.includes("manageBanner"),
          manageCategory: permissions.includes("manageCategory"),
          manageWorkforceEnquiry: permissions.includes(
            "manageWorkforceEnquiry"
          ),
          
          cms: permissions.includes("cms"),
          contactUs: permissions.includes("contactUs"),
          // manageGulfCategory: permissions.includes("manageGulfCategory"),
          manageGulfWorkforce: permissions.includes("manageGulfWorkforce"),
          // manageGulfEnquiry: permissions.includes("manageGulfEnquiry"),
          gulfDashboard:permissions.includes("gulfDashboard"),

          gulfCms: permissions.includes("gulfCms"),
          gulfAppCms: permissions.includes("gulfAppCms"),
          gulfContactUs: permissions.includes("gulfContactUs"),
          notifications: permissions.includes("notifications"),
          manageProfile: permissions.includes("manageProfile"),
        },
      });
    }
  }, [location.state]);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue("file", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const permissionsList = [
    // { name: "manageAdminRole", label: "Manage Admin Role" },
    { name: "manageBanner", label: "Manage Banner" },
    { name: "manageCategory", label: "Manage Category" },
    { name: "manageWorkforceEnquiry", label: "Manage Workforce Enquiry" },
    { name: "cms", label: "Manage CMS Pages" },
    { name: "contactUs", label: "Manage Contact Us" },
    // { name: "weplacedDashboard", label: "Manage Weplaced Dashboard" },
  ];

  // const gulfKaamPermissionsList = [
  //   { name: "manageGulfCategory", label: "Manage Gulf Category" },
  //   { name: "manageGulfWorkforce", label: "Manage Gulf Workforce" },
  //   { name: "manageGulfEnquiry", label: "Manage Gulf Enquiry" },
  //   { name: "gulfCms", label: "Manage CMS Pages" },
  //   { name: "gulfContactUs", label: "Manage Contact Us" },
  //   { name: "notifications", label: "Manage Notifications" },
  // ];
  const gulfKaamPermissionsList = [
    // { name: "manageGulfCategory", label: "Manage Gulf Category" },
    { name: "manageGulfWorkforce", label: "Manage Gulf Workforce" },
    // { name: "manageGulfEnquiry", label: "Manage Gulf Enquiry" },
    { name: "gulfCms", label: "Manage Web CMS " },
    { name: "gulfAppCms", label: "Manage App CMS" },
    { name: "gulfContactUs", label: "Manage Contact Us" },
    { name: "notifications", label: "Manage Notifications" },
    // { name: "gulfDashboard", label: "Manage Gulfkaam Dashboard" },

  ];
  const adminRolePermissionsList = [
    { name: "manageAdminRole", label: "Manage Admin Role" },
  ];

  const dataLakePermissionsList = [
    { name: "manageProfile", label: "Manage Profiles" },
    { name: "manageDeletedProfile", label: "Manage Deleted  Profiles" },
    { name: "manageRejectedProfile", label: "Manage Rejected Profiles" },
  ];

  const onSubmitHandler = async (values) => {
    const userId =
      location.state && location.state.userData && location.state.userData._id;

    if (!userId) {
      console.error("User ID not found in state:", location.state);
      return;
    }

    const selectedPermissions = Object.keys(values.permissions).filter(
      (key) => values.permissions[key]
    );

    const body = {
      _id: userId, // Include _id in the body
      fullName: `${values.firstName} ${values.lastName}`,
      email: values.email,
      phone: values.phone,
      password: values.password,
      permissions: selectedPermissions,
      admin_pic: values.file,
    };

    if (values.password === "") {
      delete body.password;
    }

    try {
      // Perform API call to update user
      const response = await updateAdminUser(userId, body); // Assuming `_id` is your user's ID field
      console.log("Updated user:", response);

      // Navigate back to previous page or do something else on success
      navigate(-1); // Navigate back after successful update
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user. Please try again.");

      // Optionally, log detailed error information
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Update User"} backButton={true} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ setFieldValue }) => (
          <Form className="form-group">
            <ImageUploader
              image={image}
              onChangeHandler={(e) => handleImageChange(e, setFieldValue)}
            />

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="firstName">First Name</label>
                <Field type="text" name="firstName" className="form-control" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="lastName">Last Name</label>
                <Field type="text" name="lastName" className="form-control" />
                {/* <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-danger"
                /> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  className="form-control"
                  disabled
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="phone">Phone</label>
                <Field
                  type="tel"
                  name="phone"
                  className="form-control"
                  maxLength="10"
                />
                {/* <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-danger"
                /> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 position-relative">
                <label htmlFor="password">Password</label>
                <Field
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  className="form-control"
                />
                <button
                  type="button"
                  className="user-password-toggle"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <FontAwesomeIcon
                    icon={passwordVisible ? faEyeSlash : faEye}
                  />
                </button>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-6 position-relative">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Field
                  type={confirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  className="form-control"
                />
                <button
                  type="button"
                  className="user-password-toggle"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                >
                  <FontAwesomeIcon
                    icon={confirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </button>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mt-4 premission-check">
              <div className="col-md-12">
                <b>Weplaced Permissions</b>
              </div>
              {permissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="row mt-4 premission-check">
              <div className="col-md-12">
                <b>Gulf Kaam Permissions</b>
              </div>
              {gulfKaamPermissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="row mt-4 premission-check">
              <div className="col-md-12">
                <b>Admin Role Permissions</b>
              </div>
              {adminRolePermissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="row mt-4 premission-check">
              <div className="col-md-12">
                <b>Data Lake Permissions</b>
              </div>
              {dataLakePermissionsList.map((permission) => (
                <div
                  key={permission.name}
                  className="col-md-6 checkbox-container"
                >
                  <Field
                    type="checkbox"
                    name={`permissions.${permission.name}`}
                    className="form-check-input"
                  />
                  <label htmlFor={permission.name}>{permission.label}</label>
                </div>
              ))}
            </div>

            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Update
              </button>

              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
