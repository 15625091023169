import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import { getQueryParams } from "../../../utils/methods";

export const getWorkforceEnquiryListing = ({ page, limit }) => {
  const queryParams = getQueryParams({ page, limit });
  return axios
    .post(`${API_URL_ENDPOINT.getenquirylist}${queryParams}`)
    .then((res) => res.data)
    .catch((err) => err);
};
// export const getWorkforceEnquirybyId = (body) => {
//   return axios
//     .post(API_URL_ENDPOINT.getenquirylist, body)
//     .then((res) => res.data)
//     .catch((err) => err);
// };

export const getWorkforceEnquiryById = (id, body) => {
  return axios
    .post(`${API_URL_ENDPOINT.getenquirylistId.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getSkillSetList = () => {
  return axios
    .get(`${API_URL_ENDPOINT.getSkillSetList}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getBannersList = () => {
  return axios
    .get(`${API_URL_ENDPOINT.getBannersList}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const wePlacedEnquiryService = (url) => {
  return axios
    .post(url)
    .then((res) => res.data)
    .catch((err) => err);
};
// get profile

export const getProfileById = (id, body) => {
  return axios
    .get(`${API_URL_ENDPOINT.getProfileId.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// Existing API functions...
export const addSubSkill = (payload) => {
  return axios
    .post(`${API_URL_ENDPOINT.addSubSkill}?by=Admin`, payload)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error adding sub-skill:", err);
      throw err; // Rethrow the error to handle it in the component if needed
    });
};
