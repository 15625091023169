import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";

export const getTheBlog = (body) => {
  return axios
    .get(API_URL_ENDPOINT.getTheBlog)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getTheBlogDetail = (id) => {
  return axios
    .get(`${API_URL_ENDPOINT.getTheBlog}/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};