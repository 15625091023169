import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import blog from "../../assests/images/main-feature.png";
import "./index.css";
import { getTheBlogDetail } from "../../services/web/blog/blogServices";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Loader from "../../components/loader/loader";

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const BlogDetail = () => {
  const { id } = useParams(); // Fetching ID from URL parameter
  const location = useLocation(); // Getting location to access state
  const [loading, setLoading] = useState(true); 

  const [blogDetailData, setBlogDetailData] = useState({
    _id: "",
    title: "",
    subTitle: "",
    content: "",
    blog_large_pic: "",
    blog_short_pic: "",
    is_weplaced: false,
    createdAt: "",
    updatedAt: "",
  });

  const fallbackDetails = {
    title: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
    date: "Mar 12, 2024",
    content: `<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born...</p>`,
    quote: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
  };

  const blogDetails = location.state || fallbackDetails;

  const imgUrl = blogDetails.img || blog;

  const getTheBlogDetailData = async () => {
    try {
      const getData = await getTheBlogDetail(id);
      if (getData?.status) {
        setBlogDetailData(getData?.data?.data);
      }
    } catch (error) {
      console.log("exceptional error", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    getTheBlogDetailData();
  }, [id]);

  if (loading) {
    return <Loader />; 
  }

  return (
    <section className="blog-detail mt-5">
      <div className="container">
        <PageTile backButton={true} /> 

        <span className="bread-crumb py-0 d-block">
          <span className="bread-crumb-extend-text">
            {/* {blogDetailData?.title} */}
          </span>
        </span>
        <span>{blogDetailData?.createdAt && formatDate(blogDetailData.createdAt)}</span>
        <h3>{blogDetailData?.title}</h3>
        <img
          className="w-100 img-fluid"
          src={blogDetailData?.blog_large_pic || imgUrl}
          alt="Insights Banner"
        />
        
        {/* Render content as HTML */}
        <div dangerouslySetInnerHTML={{ __html: blogDetailData?.content }} />

        {blogDetailData?.subTitle && (
          <div className="special-note mb-5">
            <RiDoubleQuotesL className="quotes-start" size={55} />
            <span>{blogDetailData?.subTitle}</span>
            <RiDoubleQuotesR size={55} className="quotes-end" />
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogDetail;
