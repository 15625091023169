import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  getHomepageWeplacedService,
  addHomepageWeplacedService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTextLength } from "../../../utils/methods";
import MaxLimit from "../../../components/MaxLimit"; 
import LoaderSpinner from "../../../components/loaderSpinner/spinner";

function ManageHomepage() {
  const [homepageContents, setHomepageContents] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const MAX_LENGTHS = {
    info_title: 63,
    info_content1: 200,
    info_content2: 87,
    info_content3: 81,
    info_content4: 74,
    industry_title: 30,
    industry_description: 124,
  };

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const response = await getHomepageWeplacedService();
        if (response.status) {
          const homepageData = response.data[0];
          setHomepageContents(homepageData);
        }
      } catch (error) {
        console.error("Error fetching homepage content:", error);
      }
    };
    fetchHomePage();
  }, []);

  const handleSubmit = async (values, { setSubmitting }, section) => {
    setIsSubmitting(true);
    try {
      await addHomepageWeplacedService(values._id, values);
      toast.success(`${section} updated successfully!`);
      console.log(`${section} submitted`, values);
    } catch (error) {
      toast.error("Error submitting data. Please try again.");
      console.error("Error submitting data:", error);
    }
    setIsSubmitting(false);
    setSubmitting(false);
  };

  if (!homepageContents) {
    return <div>Loading...</div>;
  }

  const handleEditorChange = (setFieldValue, field, MAX_LENGTH) => (value) => {
    if (getTextLength(value) <= MAX_LENGTH) {
      setFieldValue(field, value);
    } else {
      toast.warning(`Content exceeds maximum length of ${MAX_LENGTH} characters`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="manage_blogs_page shadow card p-4 rounded">
        <PageTile heading1={"Homepage Content"} />
        <Formik
          enableReinitialize
          initialValues={{
            _id: homepageContents._id || "",
            info_title: homepageContents.info_title || "",
            info_content1: homepageContents.info_content1 || "",
            info_content2: homepageContents.info_content2 || "",
            info_content3: homepageContents.info_content3 || "",
            info_content4: homepageContents.info_content4 || "",
          }}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, "homepage-content")
          }
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="info_title">Title</label>
                <MaxLimit
                  current={values.info_title.length}
                  max={MAX_LENGTHS.info_title}
                />
                <Field
                  type="text"
                  name="info_title"
                  className="form-control"
                  maxLength={MAX_LENGTHS.info_title}
                  onInput={e => {
                    const { value } = e.target;
                    if (value.length > MAX_LENGTHS.info_title) {
                      toast.warning(`Content exceeds maximum length of ${MAX_LENGTHS.info_title} characters`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                    } else {
                      setFieldValue("info_title", value);
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <CommonEditor
                  value={values.info_content1}
                  onChange={handleEditorChange(setFieldValue, "info_content1", MAX_LENGTHS.info_content1)}
                  maxLength={MAX_LENGTHS.info_content1}
                  label="Content 1"
                />
                <CommonEditor
                  value={values.info_content2}
                  onChange={handleEditorChange(setFieldValue, "info_content2", MAX_LENGTHS.info_content2)}
                  maxLength={MAX_LENGTHS.info_content2}
                  label="Content 2"
                />
                <CommonEditor
                  value={values.info_content3}
                  onChange={handleEditorChange(setFieldValue, "info_content3", MAX_LENGTHS.info_content3)}
                  maxLength={MAX_LENGTHS.info_content3}
                  label="Content 3"
                />
                <CommonEditor
                  value={values.info_content4}
                  onChange={handleEditorChange(setFieldValue, "info_content4", MAX_LENGTHS.info_content4)}
                  maxLength={MAX_LENGTHS.info_content4}
                  label="Content 4"
                />
              </div>
              <div className="py-3">
                <button type="submit" className="global_admin_btn">
                  {isSubmitting ? <LoaderSpinner /> : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="manage_blogs_page shadow card p-4 rounded">
        <PageTile heading1={"Industry Standards"} />
        <Formik
          enableReinitialize
          initialValues={{
            _id: homepageContents._id || "",
            industry_title: homepageContents.industry_title || "",
            industry_description: homepageContents.industry_description || "",
          }}
          onSubmit={(values, actions) =>
            handleSubmit(values, actions, "industry_standards")
          }
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="industry_title">Title</label>
                <MaxLimit
                  current={values.industry_title.length}
                  max={MAX_LENGTHS.industry_title}
                />
                <Field
                  type="text"
                  name="industry_title"
                  className="form-control"
                  maxLength={MAX_LENGTHS.industry_title}
                  onInput={e => {
                    const { value } = e.target;
                    if (value.length > MAX_LENGTHS.industry_title) {
                      toast.warning(`Content exceeds maximum length of ${MAX_LENGTHS.industry_title} characters`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                      });
                    } else {
                      setFieldValue("industry_title", value);
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <CommonEditor
                  value={values.industry_description}
                  onChange={handleEditorChange(setFieldValue, "industry_description", MAX_LENGTHS.industry_description)}
                  maxLength={MAX_LENGTHS.industry_description}
                  label="Content"
                />
              </div>
              <div className="py-3">
                <button type="submit" className="global_admin_btn">
                  {isSubmitting ? <LoaderSpinner /> : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ManageHomepage;
