import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  addGulfNotification,
  getGulfNotifications,
  deleteGulfNotification,
  updateGulfNotification,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import DataTableComponent from "../../../components/dataTable/dataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import MaxLimit from "../../../components/MaxLimit";

function AddGulfnotifications() {
  const initialValues = {
    title: "",
    message: "",
  };

  const MAX_LENGTHS = {
    title: 63,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(2, "Title must be at least 2 characters")
      .max(50, "Title must be 50 characters or less"),
    message: Yup.string()
      .required("Message is required")
      .max(250, "Message must be 250 characters or less"),
  });

  const [notifications, setNotifications] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedExeId, setSelectedExeId] = useState(null);

  // Ref for scrolling to form
  const formRef = useRef(null);

  const userColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="group_btns">
          <button
            className="bg-transparent border-0 me-2"
            onClick={() => handleRowClicked(row)}
          >
            <FaEdit />
          </button>
          <button
            className="bg-transparent border-0"
            onClick={() => handleDelete(row)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const fetchNotifications = () => {
    getGulfNotifications().then((res) => setNotifications(res?.data));
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleRowClicked = (row) => {
    setSelectedNotification(row);
    setEditMode(true);
    // Scroll to the form
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDelete = (row) => {
    setSelectedExeId(row._id);
    setDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      if (!selectedExeId) {
        console.error("No notification selected for deletion.");
        return;
      }

      const response = await deleteGulfNotification(selectedExeId, {});
      if (response) {
        toast.success("Notification deleted successfully");
        setDeleteModal(false);
        fetchNotifications();
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
      toast.error("Error deleting notification");
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let response;
      if (editMode) {
        response = await updateGulfNotification(
          values,
          selectedNotification._id
        );
        toast.success("Notification updated successfully");
      } else {
        response = await addGulfNotification(values);
        toast.success("Notification added successfully");
      }
      if (response?.status) {
        resetForm();
        fetchNotifications();
        setEditMode(false);
        setSelectedNotification(null);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
    }
  };

  const errorStyle = {
    color: "red",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  };

  return (
    <>
      <div className="manage_blogs_page shadow card p-4 rounded">
        <PageTile
          heading1={editMode ? "Edit Notification" : "Add Notification"}
        />
        <div ref={formRef}>
          {" "}
          {/* Attach ref here */}
          <Formik
            initialValues={selectedNotification || initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <MaxLimit
                    current={values.title.length}
                    max={MAX_LENGTHS.title}
                  />
                  <Field type="text" 
                  name="title"
                  maxLength={MAX_LENGTHS.title}

                   className="form-control" />
                  <ErrorMessage name="title" component="div" style={errorStyle} />
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message (Limit 250 characters)</label>
                  <Field as="textarea" name="message" className="form-control" />
                  <ErrorMessage
                    name="message"
                    component="div"
                    style={errorStyle}
                  />
                </div>

                <div className="py-3">
                  <button type="submit" className="global_admin_btn">
                    {editMode ? "Update" : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="manage_blogs_page shadow card p-4 rounded">
        <div className="datatable-section">
          <DataTableComponent
            title="Manage Notification"
            data={notifications}
            columns={userColumns}
            responsive={true}
          />
        </div>
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this notification?"}
        onConfirm={confirmDelete}
      />
    </>
  );
}

export default AddGulfnotifications;
