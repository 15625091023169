import React, { useEffect, useState } from "react";
import "./index.css";
import {
  abouIcon4,
  aboutIcon1,
  aboutIcon2,
  aboutIcon3,
} from "../../assests/svg";
import { getAboutUsService } from "../../services/admin/manageworkforce/manageworkforceservices";
import { useNavigate } from "react-router-dom";

export default function WhatSetUspart() {
  const arr = [aboutIcon3, aboutIcon1, aboutIcon2, abouIcon4];
  const navigate = useNavigate();
  const [aboutUsData, setAboutUsData] = useState(null);

  const getAboutUsData = async () => {
    try {
      const response = await getAboutUsService();
      if (response.status && response.data.length > 0) {
        setAboutUsData(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching about us data:", error);
    }
  };

  useEffect(() => {
    getAboutUsData();
  }, []);

  return (
    <section className="what-set-us-apart">
      {aboutUsData && (
        <div className="container">
          <div className="what-set-heading">
            <h5>{aboutUsData.what_sets_up_apart_title}</h5>
          </div>
          <div className="row ps-2">
            {arr.map((item, index) => (
              <div key={index} className="col-md-6 set-icon-margin">
                <div className="d-flex">
                  <div className="set-icon">{item()}</div>
                  <div className="set-icon-content">
                    {/* <span>
                      The largest platform connecting businesses to hourly
                      workers.
                    </span> */}
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: aboutUsData[`content_${index + 1}`],
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}
