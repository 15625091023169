import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from "libphonenumber-js";
import { Form } from "react-bootstrap";
import { IoInformationCircleOutline } from "react-icons/io5";
import { gulfCountries, phoneLengthLimits } from "../utils/helpers";

const PhoneInputComp2 = ({
  id,
  value,
  onChange,
  name = { name },
  className = "pt-3",
  placeholder = "Enter your contact number",
  defaultCountry = "AE",
  error,
  style = { color: "red" },
  onBlurHandler,
  dataTooltipId,
  required = false,
}) => {
  const [contactNumber, setContactNumber] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [maxLength, setMaxLength] = useState(
    phoneLengthLimits[defaultCountry] || 15
  );
  const [isTouched, setIsTouched] = useState(false);

  const handlePhoneChange = (value) => {
    setContactNumber(value);
    if (value) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (contactNumber) {
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      if (phoneNumber) {
        const country = phoneNumber.country;
        if (country && phoneLengthLimits[country]) {
          setMaxLength(phoneLengthLimits[country]);
        }
      }
    }
  }, [contactNumber]);

  const handleBlur = () => {
    setIsTouched(true);
    if (contactNumber) {
      const phoneNumber = parsePhoneNumberFromString(contactNumber);
      if (phoneNumber) {
        const valid = phoneNumber.isValid();
        const countryCode = phoneNumber.country;
        const countryCallingCode = getCountryCallingCode(countryCode);

        const numberWithoutCountryCode = contactNumber
          .replace(`+${countryCallingCode}`, "")
          .replace(/\D/g, "");

        if (phoneLengthLimits[countryCode]) {
          const validLength =
            numberWithoutCountryCode.length <= phoneLengthLimits[countryCode];
          setIsValid(valid && validLength);
          onBlurHandler(valid && validLength, contactNumber);
        } else {
          setIsValid(valid);
          onBlurHandler(valid, contactNumber);
        }
      } else {
        setIsValid(false);
        onBlurHandler(false, contactNumber);
      }
    } else {
      setIsValid(false);
      onBlurHandler(false, contactNumber);
    }
  };

  return (
    <>
      <Form.Group
        className="mb-3 position-relative form-control"
        controlId="formBasicContactNumber"
      >
        <Form.Label
          style={{
            position: "absolute",
            top: "8px",
            left: "16px",
            fontWeight: "bold",
          }}
          className="label-size"
        >
          Contact Number {required && <span style={{ color: "red" }}>*</span>}{" "}
          <IoInformationCircleOutline data-tooltip-id={dataTooltipId} />
        </Form.Label>
        <PhoneInput
          className={className}
          name={name}
          id={id}
          placeholder={placeholder}
          value={contactNumber}
          onChange={handlePhoneChange}
          onBlur={handleBlur}
          defaultCountry={defaultCountry}
          inputProps={{
            maxLength: maxLength,
          }}
          countries={gulfCountries}
          international
          addInternationalIcon
        />
      </Form.Group>

      {error && !isTouched ? (
        <span style={style}>Enter contact number</span>
      ) : !isValid && isTouched ? (
        // <span style={style}>Please enter a valid contact number</span>
        <span/>
      ) : null}
    </>
  );
};

export default PhoneInputComp2;
