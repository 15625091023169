import React from "react";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import "./index.css";

export default function SmallBlogCard({ cardData, onClick }) {
  const { id, date, title, blog_short_pic } = cardData; // Destructuring props
  const handleClick = () => {
    onClick(cardData); // Pass the entire cardData object to the parent's onClick handler
  };

  return (
    <div className="col-md-4">
      <div className="small-blog-card" onClick={handleClick} style={{ cursor: "pointer" }}>
        <img className="w-100 mb-3" src={blog_short_pic} alt="Insights Thumbnail" />
        <div className="pe-5">
          <span>{date} </span>
          <h5>{title}</h5>
          <Link to={`/insights/${id}`}>
            Learn More <GoArrowUpRight />
          </Link>
        </div>
      </div>
    </div>
  );
}
