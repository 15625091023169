import React from "react";
import Modal from "react-bootstrap/Modal";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

function DeleteModalAdmin({ open, setOpen, heading, onConfirm }) {
  const handleClose = () => {
    setOpen(false); // Close the modal if Cancel button is clicked
  };

  const handleConfirm = async () => {
    await onConfirm(); // Call the onConfirm function to delete the item
    setOpen(false); // Close the modal after confirmation
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Body>
        <RxCross1 onClick={handleClose} className="icon-position" size={20} />
        <div className="delete-modal">
          <RiDeleteBin5Line size={87} />
          <div>
            <span>{heading}</span>
            <div className="d-flex justify-content-center mt-3">
              <Button onClick={handleClose} className="delete-modal-no-btn">
                No
              </Button>
              <Button onClick={handleConfirm} className="delete-modal-yes-btn">
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModalAdmin;
