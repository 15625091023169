import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  addTermsAndConditions,
  getCMSContentsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import LoaderSpinner from "../../../components/loaderSpinner/spinner";

function TermsAndConditions() {
  const [content, setContent] = useState("");
  const [cmsContents, setCMSContents] = useState(null);
  const [isWeplaced, setIsWeplaced] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const MAX_LENGTH = 15000;

  const handleEditorChange = (value) => {
    if (value?.length <= MAX_LENGTH) {
      setContent(value);
    } else {
      toast.error(`Content cannot exceed ${MAX_LENGTH} characters.`);
    }
  };

  useEffect(() => {
    const fetchCMSContents = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[0]);
          setContent(response.data[0].terms_and_conditions);
          setIsWeplaced(response.data[0].is_weplaced === true);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchCMSContents();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const payload = {
        terms_and_conditions: content,
        is_weplaced: isWeplaced,
      };

      const response = await addTermsAndConditions(payload);
      if (response.status === true) {
        toast.success("Terms and conditions updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Failed to update terms and conditions: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error updating terms and conditions.");
      console.error("Error updating terms_and_conditions:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="manage_terms_conditions_page card p-4 rounded shadow">
      <PageTile heading1={"Manage Terms And Conditions"} />
      <CommonEditor
        value={content}
        onChange={handleEditorChange}
        maxLength={MAX_LENGTH}
      />
      <div className="py-3">
        <button className="global_admin_btn" onClick={handleSubmit}>
          {isSubmitting ? <LoaderSpinner /> : "Save"}
        </button>
      </div>
    </div>
  );
}

export default TermsAndConditions;
