import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore
} from "redux-persist";
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/es/storage";
import { manPowerEnquirySlice } from './manpowerEnquiry/manpowerEnquirySLice';
import workforceManageSlice from './manageWorkforce/manageWorkforceSlice';


const rootReducer = combineReducers({
  MANPOWERENQUIRY: manPowerEnquirySlice.reducer,
  // WORKFORCEDATA: workforceListingSlice.reducer
  MANAGEWORKFORCE:workforceManageSlice.reducer
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['MANAGEWORKFORCE', 'MANPOWERENQUIRY'] 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };
