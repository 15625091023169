import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import { getQueryParams } from "../../../utils/methods";

const commonHeaders = {
  "Content-Type": "application/json",
  Authorization: "Bearer your_token_here",
};

const formDataHeader = { "Content-Type": "multipart/form-data" };

export const getworkforcelisting = ({
  body,
  type,
  page,
  limit,
  search,
  skills_Filter,
  experience_Filter,
  // verification_Filter,
}) => {
  const queryParams = getQueryParams({
    type,
    page,
    limit,
    search,
    skills_Filter,
    experience_Filter,
    // verification_Filter,
  });
  return axios
    .post(`${API_URL_ENDPOINT.getworkforcelistingUrl}${queryParams}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};
// export const getworkforcelisting = ({ body, type, page, limit}) => {
//   const params = new URLSearchParams({
//     type,
//     page,
//     limit,
//   });

//   return axios
//     .post(`${API_URL_ENDPOINT.getworkforcelistingUrl}?${params.toString()}`, body)
//     .then((res) => res.data)
//     .catch((err) => {
//       console.error("Error fetching workforce listing:", err);
//       throw err; // Optionally re-throw the error
//     });
// };
// export const getskillset = (body) => {
//   return axios
//     .get(API_URL_ENDPOINT.getworkforceskill, body)
//     .then((res) => res.data)
//     .catch((err) => err);
// };
export const getskillset = ({ body = null, type = "we_placed", id }) => {
  return axios
    .get(
      `${API_URL_ENDPOINT.getworkforceskill}?user_id=${id}&type=${type}`,
      body
    )
    .then((res) => res.data)
    .catch((err) => err);
};

export const addskillset = (body) => {
  return axios
    .post(API_URL_ENDPOINT.addCategory, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateSkillsetWithSubSkills = (body) => {
  return axios
    .put(API_URL_ENDPOINT.updateCategoryWithSubCat, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addWorkforce = (body, headers = { ...commonHeaders }) => {
  return axios
    .post(API_URL_ENDPOINT.addWorkforce, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

export const addDataLakeWorkforce = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.addDataLakeWorkforce, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};
export const updateDataLakeWorkforce = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.editDataLakeWorkforce, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

// Function to update the workforce data
// export const updateDataLakeWorkforce = (id, body, headers = { ...commonHeaders, ...formDataHeader }) => {
//   return axios
//     .post(`${API_URL_ENDPOINT.editDataLakeWorkforce}?id=${id}`, body, { headers })
//     .then((res) => res.data)
//     .catch((err) => {
//       console.error(err); // Log the error for debugging
//       throw new Error(err.response?.data?.message || 'Error updating workforce data');
//     });
// };

export const deleteskillset = (body) => {
  return axios
    .post(API_URL_ENDPOINT.deleteCategory, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addPrivacyPolicy = (body) => {
  return axios
    .patch(API_URL_ENDPOINT.addPrivacyPolicy, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addTermsAndConditions = (body) => {
  return axios
    .patch(API_URL_ENDPOINT.addTermsAndCond, body)
    .then((res) => res.data)
    .catch((err) => err);
};
export const getReviewsService = () => {
  return axios
    .post(API_URL_ENDPOINT.getReviews)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addContactUsService = (data) => {
  return axios
    .post(API_URL_ENDPOINT.addContactUs, data)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getCMSContentsService = () => {
  return axios
    .post(API_URL_ENDPOINT.getCMSContents)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addAboutUsService = (id, body) => {
  return axios
    .post(`${API_URL_ENDPOINT.addAboutUs}/${id}`, body)
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};

export const addSkillSetService = (body) => {
  return axios
    .post(`${API_URL_ENDPOINT.addSkillset}`, body)
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};

export const updateSkillSetService = (body, id) => {
  return axios
    .post(`${API_URL_ENDPOINT.updateSkillSet.replace(":id", id)}`, body)
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};
export const deleteSkillSetService = (id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteSkillSet.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};
export const getAboutUsService = () => {
  return axios
    .get(API_URL_ENDPOINT.getAboutUs)
    .then((res) => res.data)
    .catch((err) => err);
};
export const getSkillSetService = () => {
  return axios
    .get(API_URL_ENDPOINT.getSkillset)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addBlog = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.addBlog, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

// manage user crud

export const addAdminUser = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.addAdminUser, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

export const addGulfAdminUser = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(`${API_URL_ENDPOINT.addAdminUser}?we_placed=${false}`, body, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const getAdminUsersList = ({ page, limit }) => {
  const queryParams = getQueryParams({ page, limit });
  return axios
    .get(`${API_URL_ENDPOINT.getAdminUsersList}${queryParams}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteAdminUser = (body) => {
  return axios
    .post(API_URL_ENDPOINT.deleteAdminUser, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateAdminUser = (
  id,
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .put(`${API_URL_ENDPOINT.updateAdminUser.replace(":id", id)}`, body, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};
export const deleteUser = (_id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteAdminUser.replace(":id", _id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// banners
export const getBanners = () => {
  return axios
    .get(API_URL_ENDPOINT.getBanner)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteBanner = (_id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteBanner.replace(":id", _id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// export const updateBanners = (
//   _id,
//   body,
//   headers = { ...commonHeaders, ...formDataHeader }
// ) => {
//   return axios
//     .put(`${API_URL_ENDPOINT.updateBanner.replace(":id", _id)}`, body, {
//       headers,
//     })
//     .then((res) => res.data)
//     .catch((err) => err);
// };
export const updateBanners = async (
  _id,
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  try {
    const response = await axios.put(
      `${API_URL_ENDPOINT.updateBanner.replace(":id", _id)}`,
      body,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating banner:", error);
    throw error;
  }
};

// news and articles apis
export const addNewsArticle = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.addNewsArticle, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

// add gulf notifications
export const addGulfNotification = (body, headers = { ...commonHeaders }) => {
  return axios
    .post(API_URL_ENDPOINT.addNotification, body, { headers })
    .then((res) => res.data)
    .catch((err) => err);
};

// get gulf notifications
export const getGulfNotifications = (body) => {
  return axios
    .get(API_URL_ENDPOINT.getGulfNotifications, body)
    .then((res) => res.data)
    .catch((err) => err);
};
// delete gulf notifications
export const deleteGulfNotification = (id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteNotification.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};
// update gulf notifications
export const updateGulfNotification = (body, id) => {
  return axios
    .post(`${API_URL_ENDPOINT.updateNotification.replace(":id", id)}`, body)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNewsArticles = (body) => {
  return axios
    .get(API_URL_ENDPOINT.getNewsArticle, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteNewsArticle = (id, body) => {
  return axios
    .delete(`${API_URL_ENDPOINT.deleteNewsArticle.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const updateNewsArticles = (
  body,
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  return axios
    .post(API_URL_ENDPOINT.updateNewsArticle, body, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err);
};

export const getWorkerDetail = (body, urlParam) => {
  return axios
    .post(
      urlParam
        ? `${API_URL_ENDPOINT.getWorkerDetail}?type=deletedProfile`
        : API_URL_ENDPOINT.getWorkerDetail,
      body,
      {
        commonHeaders,
      }
    )
    .then((res) => res.data)
    .catch((err) => err);
};

export const verifyWorkforceUser = ({ body, id }) => {
  return axios
    .post(API_URL_ENDPOINT.verifyWorkforceUser.replace(":id", id), body)
    .then((res) => res.data)
    .catch((err) => err);
};
// gulfkaam homepage
export const getGulfHomepageService = () => {
  return axios
    .get(API_URL_ENDPOINT.getGulfHomepage)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addGulfHomepageService = (id, body) => {
  return axios
    .post(`${API_URL_ENDPOINT.addGulfHomepage}/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};

export const getAdminDashboard = () => {
  return axios
    .get(API_URL_ENDPOINT.getDashboardData)
    .then((res) => res.data)
    .catch((err) => err);
};

// Updated addStatus function
export const addStatus = ({ body, _id }) => {
  return axios
    .post(API_URL_ENDPOINT.addCompletedStatus.replace(":id", _id), body)
    .then((res) => res.data)
    .catch((err) => err);
};

// update my profile

export const updateMyProfileService = (
  id, // This is the _id
  body,
  // headers = { ...commonHeaders, "Content-Type": "application/json" }
  headers = { ...commonHeaders, ...formDataHeader }
) => {
  const url = API_URL_ENDPOINT.updateMyProfileAdmin.replace(":id", id);

  return axios
    .post(url, body, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error in updateMyProfileService:", err);
      throw err;
    });
};
export const getMyProfileById = (id, body) => {
  return axios
    .get(`${API_URL_ENDPOINT.getProfileId.replace(":id", id)}`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getHomepageWeplacedService = () => {
  return axios
    .get(API_URL_ENDPOINT.getHomePageWeplaced)
    .then((res) => res.data)
    .catch((err) => err);
};

export const addHomepageWeplacedService = (id, body) => {
  return axios
    .post(`${API_URL_ENDPOINT.addHomePageWeplaced}/${id}`, body)
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};
export const addAccountStatusService = (id, body) => {
  return axios
    .post(`${API_URL_ENDPOINT.addstatusWorkforce}/${id}`, body)
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};

export const getDeleteWorkforce = (page, search) => {
  return axios
    .get(
      `${API_URL_ENDPOINT.getDeleteWorkForceList}?page=${page}&limit=30&search=${search}`
    )
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};

// manage data lake download csv
/**
 *
 * @param {*} type which section you have to download
 * @param {*} format in which format you have to download this like pdf or csv
 * @returns the response
 */
export const getDownloadedData = (type, format) => {
  return axios
    .get(
      `${API_URL_ENDPOINT.downloadedCsvPdfData}?type=${type}&format=${format}`
    )
    .then((res) => {
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      throw error; // Re-throw the error to be handled elsewhere
    });
};
