// list we have to show on the top of the country array
export const countryOnTopList = {
  uae: "United Arab Emirates",
  saudiArabia: "Saudi Arabia",
  oman: "Oman",
  kuwait: "Kuwait",
  qatar: "Qatar",
  bahrain: "Bahrain",
};

// format to download
export const fileFormatDownload = {
  csv: "csv",
  pdf: "pdf",
};

// type for download file for rejected data lake deleted
export const typeForDownload = {
  rejectedProfile: "rejected_profiles",
  dataLake: "data_lake",
  gulf_kaam: "gulf_kaam",
  deletedProfile:"deleted_profiles"
};
