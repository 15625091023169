import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import { updateExecutive } from "../../../../services/admin/CmsPages/cmsPagesServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminPathUrl } from "../../../../shared/pathUrl";

const UpdateExecutive = () => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null); // State to store image data URL
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  // console.log(state,"stateeeeeeeeeeeeeee")

  useEffect(() => {
    // Set image state if executive_pic exists in state
    if (state?.executive?.executive_pic) {
      setImage(state.executive.executive_pic);
    }
  }, [state]); // Update when state changes

  // Initial values for the form
  const initialValues = {
    name: state?.executive.name || "",
    designation: state?.executive.designation || "",
    executive_pic: state?.executive.executive_pic || "",
  };

  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    designation: Yup.string().required("Designation is required"),
    executive_pic: Yup.mixed().required("Executive picture is required"),
  });

  // Function to handle image change and update form field
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set image preview
        setFieldValue("executive_pic", file); // Update Formik field value
      };
      reader.readAsDataURL(file); // Read file as data URL
    }
  };
 
  // Function to clear the current image
  const clearImage = (setFieldValue) => {
    setImage(null); // Clear image state
    setFieldValue("executive_pic", ""); // Clear Formik field value
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Perform API call to update executive
      const response = await updateExecutive(state.executive._id, values); // Assuming `_id` is your executive's ID field
      console.log("Updated executive:", response);

      // Navigate back to previous page or do something else on success
      navigate(-1); // Navigate back after successful update
    } catch (error) {
      console.error("Error updating executive:", error);
    } finally {
      setSubmitting(false); // Reset submitting state
    }
  };

  // Function to trigger file input click
  const handleUploadButtonClick = () => {
    fileInputRef.current.click(); // Programmatically click file input
  };
  const handleCancel = () => {
    navigate(adminPathUrl.manageReviews);
  };
  // JSX structure for the component
  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Update Executive Details"} backButton={true}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" className="form-control" />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="designation">Designation</label>
              <Field type="text" name="designation" className="form-control" />
              <ErrorMessage
                name="designation"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="image-upload-section mb-4">
              <input
                type="file"
                accept="image/*"
                name="executive_pic"
                onChange={(e) => handleImageChange(e, setFieldValue)}
                className="d-none"
                id="imageUpload"
                ref={fileInputRef}
              />
              {image && (
                <div className="image-preview">
                  <img
                    src={image}
                    alt="Executive Preview"
                    className="img-fluid"
                  />
                  <button
                    type="button"
                    className="btn btn-link btn-sm mt-2"
                    onClick={() => clearImage(setFieldValue)}
                  >
                    Clear Image
                  </button>
                </div>
              )}
              {!image && (
                <button
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={handleUploadButtonClick}
                >
                  Upload Image
                </button>
              )}
              <ErrorMessage
                name="executive_pic"
                component="div"
                className="text-danger mt-4"
              />
            </div>
            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Save
              </button>

              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateExecutive;
