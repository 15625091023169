// GulfWorking.jsx

import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  addGulfHomepageService,
  getGulfHomepageService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";

function GulfLocDetails() {
  const [contents, setContents] = useState(null);

  // Define different maximum lengths for each section
  const MAX_LENGTH_INTRO = 55;
  const MAX_LENGTH_FEATURES = 306;
  const MAX_LENGTH_HOW_IT_WORKS = 72;

  useEffect(() => {
    const fetchContents = async () => {
      try {
        const response = await getGulfHomepageService();
        console.log("API Response:", response);

        if (response.status) {
          setContents(response.data[0]);
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchContents();
  }, []);

  const initialValues = {
    ai_content: contents ? contents.ai_content : "",
    ai_title: contents ? contents.ai_title : "",
    language_section_content: contents ? contents.language_section_content : "",
    language_section_title: contents ? contents.language_section_title : "",
    intro_title: contents ? contents.intro_title : "",
    intro_content: contents ? contents.intro_content : "",
    address: contents ? contents.address : "",
    email: contents ? contents.email : "",
    phone: contents ? contents.phone : "",
    phone_dubai:  contents ? contents.phone_dubai : "",
    facebook_link: contents ? contents.facebook_link : "",
    instagram_link: contents ? contents.instagram_link : "",
  };

  const handleSubmit = async (values, { setSubmitting }, section) => {
    try {
      const response = await addGulfHomepageService(
        "66accf0a35fa014192b8f810",
        {
          intro_title: values.intro_title,
          intro_content: values.intro_content,
          language_section_title: values.language_section_title,
          language_section_content: values.language_section_content,
          ai_title: values.ai_title,
          ai_content: values.ai_content,
          address: values.address,
          email: values.email,
          phone: values.phone,
          phone_dubai: values.phone_dubai,

          facebook_link: values.facebook_link,
          instagram_link: values.instagram_link,
        }
      );

      if (response.status === true) {
        setContents(response.data); // Update contents state with the updated data
        toast.success(`${section} content updated successfully!`);
      } else {
        toast.error(`Failed to update ${section} content: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section} content:`, error);
      toast.error(`Error updating ${section} content: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
  

      {/* Location & Details Section */}
      <div className="manage_terms_conditions_page card p-4 rounded shadow">
        <PageTile heading1={"Location & details"} />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) =>
            handleSubmit(values, { setSubmitting }, "location-details")
          }
        >
          {({ errors, touched, values, handleChange, handleBlur }) => (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="address">Address</label>
                  <Field
                    type="text"
                    id="address"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.address && errors.address ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.email && errors.email ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="phone_dubai">Phone(Dubai)</label>
                  <Field
                    type="tel"
                    id="phone_dubai"
                    name="phone_dubai"
                    value={values.phone_dubai}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.phone_dubai && errors.phone_dubai ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Phone Number"
                  />
                  <ErrorMessage
                    name="phone_dubai"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone">Phone(India)</label>
                  <Field
                    type="tel"
                    id="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.phone && errors.phone ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Phone Number"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                
              </div>

              <div className="row">
              <div className="col-md-6">
                  <label htmlFor="facebook_link">Facebook Link</label>
                  <Field
                    type="url"
                    id="facebook_link"
                    name="facebook_link"
                    value={values.facebook_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.facebook_link && errors.facebook_link
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Facebook Link"
                  />
                  <ErrorMessage
                    name="facebook_link"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="instagram_link">Instagram Link</label>
                  <Field
                    type="url"
                    id="instagram_link"
                    name="instagram_link"
                    value={values.instagram_link}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.instagram_link && errors.instagram_link
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Instagram Link"
                  />
                  <ErrorMessage
                    name="instagram_link"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="py-3">
                <button type="submit" className="global_admin_btn">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default GulfLocDetails;
