import React from "react";
import { LogoSvg } from "../assests/svg";
import "./index.css";

import { FaArrowRight } from "react-icons/fa6";
import { BsCart2 } from "react-icons/bs";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { pathUrl } from "../shared/pathUrl";
import { useSelector } from "react-redux";

export default function WorkerListHeader() {
  const navigate = useNavigate();
  const { cartSelectedItem } = useSelector((state) => state.MANPOWERENQUIRY);
  return (
    <div className="container">
      <div className="border-bottom">
        <div className="row pt-4 pb-4 worker-header">
          <div className="col-md-7">
            <div className="d-flex show_logo">
              <div className="header-logo-worker-list">
                {" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(pathUrl.homePage, {
                      state: {
                        redirect: true,
                      },
                    })
                  }
                >
                  {LogoSvg()}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-5 text-end">
            <div className="d-flex gap-5 justify-content-end">
              <Button
                onClick={() => navigate(pathUrl.cart)}
                className="cart-btn"
              >
                {cartSelectedItem ? (
                  <span className="cart-span">
                    {cartSelectedItem &&
                      cartSelectedItem &&
                      Object.keys(cartSelectedItem)
                        ?.map((item) =>
                          cartSelectedItem[item]?.user?.filter(
                            (filterValue) => filterValue.checked
                          )
                        )
                        .flat().length}
                  </span>
                ) : null}
                <BsCart2 />
                My Selections
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
