import { useEffect } from "react";

const HandleHash = ({location}) => {
  useEffect(() => {
    let timer;
    if (location.hash) {
      const link = document.createElement("a");
      link.href = location.hash;
      document.body.appendChild(link);
      timer = setTimeout(() => {
        link.click();
        document.body.removeChild(link);
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  return null;
};

export default HandleHash;
