import { Country, State, City } from "country-state-city";
import { format, getTime, formatDistanceToNow } from "date-fns";
// *** global function for the validation   ***///
export function globalValidationFunction() {
  // ***  function for the validation of the user email   ***///
  function validateEmail(value) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  return {
    validateEmail: validateEmail,
  };
}

// global regex function
export function globalRegex() {
  // onchange only type number
  function onChangeToNumber(value) {
    return value?.replace(/\D/g, "");
  }
  return {
    onChangeToNumber: onChangeToNumber,
  };
}

// get country and state
export const fetchCountriesAndCities = () => {
  const countries = Country.getAllCountries();
  const countriesData = countries.map((country) => {
    const cities = City.getCitiesOfCountry(country.isoCode);
    return {
      country: country.name,
      cities: cities.map((city) => city.name),
    };
  });
  return countriesData;
};

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}
