import React, { useState, useEffect, useMemo } from "react";
import { FaTrash } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import { adminPathUrl } from "../../shared/pathUrl";
import {
  getWorkforceEnquiryListing,
  getWorkforceEnquiryById,
} from "../../services/admin/manageworkforce/manageWorkEnquiryServices";
import { getskillset } from "../../services/admin/manageworkforce/manageworkforceservices";
import Loader from "../../components/loader/loader";
import { textFormatter } from "../../utils/methods";
import { Form, InputGroup } from "react-bootstrap";
import { STATUS_CLASES } from "../../utils/helpers";

export default function ManageManpowerEnquiryList() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [workerCategory, setWorkerCategory] = useState("");
  const [skillsetData, setSkillsetData] = useState([]);
  const [skillFilter, setSkillFilter] = useState("");
  const [subSkillFilter, setSubSkillFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [termFilter, setTermFilter] = useState("");
  const [workforceEnquiries, setWorkforceEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const location = useLocation()

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Define columns and data using useMemo to avoid unnecessary re-renders
  const userColumns = useMemo(() => [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Contact number",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "Worker Category",
      cell: (row) => (
        <div>
          {row.worker_category.map((category, index) => (
            <div key={index}>{category}</div>
          ))}
        </div>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <span className={STATUS_CLASES[row.status.toLowerCase()]}>
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Term",
      selector: (row) =>
        row.tenure === "" ? "n/a" : textFormatter(row.tenure),
      sortable: true,
    },
  ], []);

  const fetchWorkforceEnquiries = async () => {
    setLoading(true);
    try {
      const response = await getWorkforceEnquiryListing();
      if (response.data && response.status) {
        setWorkforceEnquiries(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getskillset();
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);
  useEffect(() => {
    fetchWorkforceEnquiries();
    // fetchSkillset();
  }, []);

  const filteredData = workforceEnquiries
    .filter((row) => {
      const matchesSkillFilter = skillFilter
        ? row.worker_category.includes(skillFilter)
        : true;
      const matchesSubSkillFilter = subSkillFilter
        ? row.worker_category.includes(subSkillFilter)
        : true;
      const matchesTermFilter = termFilter
        ? row?.tenure?.includes(termFilter)
        : true;
      const matchesSearchQuery = searchQuery
        ? row.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.contactNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.companyName.toLowerCase().includes(searchQuery.toLowerCase())
        : true;
      return (
        matchesSkillFilter &&
        matchesSubSkillFilter &&
        matchesTermFilter &&
        matchesSearchQuery
      );
    })
    .slice(0, 5); // Consider implementing pagination for a better UX

  const handleRowClicked = async (row) => {
    try {
      const enquiryData = await getWorkforceEnquiryById(row._id);
      if (enquiryData && enquiryData.data && enquiryData.data.status) {
        navigate(`${adminPathUrl.manageManpowerDetail}/${row._id}`, {
          state: { rowData: enquiryData.data.data ,
            currentPage: currentPage},
        });
      }
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    }
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="user_page">
      <div className="d-flex justify-content-between">
        {/* <div className="mb-4"> */}
          {/* Uncomment or improve search bar */}
          {/* <input className="searchbar" type="search" placeholder="search...." />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div> */}
      </div>

      <div className="manager_manpower">
        <div className="btn_all">
          <button
            onClick={() => navigate(adminPathUrl.manageManpower)}
            className="add-user-button"
          >
            {/* <FaUser/> */}
            {""} View All
          </button>
        </div>
        <div className="datatable-section">
          <DataTableComponent
          onCurrentPageChange={(page) => setCurrentPage(page)}
          toRedirectPage={location?.state?.currentPage}
            title="Manage Manpower Enquiry"
            data={filteredData}
            columns={userColumns}
            selectedRows={selectedRows}
            clickedrow={handleRowClicked}
            checkboxchange={handleCheckboxChange}
            responsive={true}
          />
        </div>
      </div>

      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this enquiry"}
      />
    </div>
  );
}
