import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./updateUsers.css"; // Make sure to import your CSS file
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";

export default function GulfUpdateUsers() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get id from URL parameter

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    permissions: {
      manageUsers: true,
      manageWorkforce: false,
      dashboard: false, // Corrected 'Dashboard' to 'dashboard' to match permissionsList
    },
  });

  // Sample data - Replace with your actual data fetching logic
  const mockUserData = [
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      email: "johndoe@example.com",
      phone: "1234567890",
      role: "admin",
      permissions: {
        manageUsers: true,
        manageWorkforce: false,
        dashboard: false,
      },
    },
    {
      id: 2,
      firstName: "Jane",
      lastName: "Smith",
      email: "janesmith@example.com",
      phone: "9876543210",
      role: "user",
      permissions: {
        manageUsers: false,
        manageWorkforce: true,
        dashboard: true,
      },
    },
  ];

  useEffect(() => {
    // Replace with actual data fetching logic using id
    const userData = mockUserData.find((user) => user.id === parseInt(id));
    if (userData) {
      setFormData({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        role: userData.role,
        permissions: {
          ...formData.permissions,
          ...userData.permissions,
        },
      });
    }
  }, [id]); // Fetch data whenever id changes

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        permissions: {
          ...formData.permissions,
          [name]: checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to an API)
    console.log("Form Data:", formData);
    navigate(-1); // Navigate back
  };


  const permissionsList = [
    { name: "manageUsers", label: "Manage Users" },
    { name: "manageBanner", label: "Manage Banner" },
    { name: "manageCategory", label: "Manage Category" },

    { name: "dashboard", label: "Dashboard" },
    { name: "cmspage", label: "Manage CMS pages" },
  ];

  const permissionsListGulfkaam = [
    { name: "manageworkforceenquiry", label: "Manage workforce Enquiry" },
    { name: "manageWorkforce", label: "Manage Workforce" },
    { name: "dashboardgulf", label: "Dashboard" },
    { name: "notifications", label: "Manage Notifications" },
    { name: "cms", label: "Manage CMS pages" },
  ];
  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Update User"}  backButton={true}/>

      <form onSubmit={handleSubmit} className="form-group">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="role">Role</label>
            <select
              name="role"
              className="form-control"
              value={formData.role}
              onChange={handleChange}
            >
             <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value="guest">Finance</option>
              <option value="guest">Sales</option>
              <option value="guest">Legal</option>
            </select>
          </div>
        </div>
     
        <div className="row mt-4">
          <div className="col-md-12">
            <b> GulfKaam Permissions:</b>
          </div>
          {permissionsListGulfkaam.map((permission) => (
            <div
              key={permission.name}
              className="col-md-6 checkbox-container"
            >
              <input
                type="checkbox"
                name={permission.name}
                className="form-check-input"
                checked={formData.permissions[permission.name]}
                onChange={handleChange}
              />
              <label htmlFor={permission.name}>{permission.label}</label>
            </div>
          ))}
        </div>

        <div className="py-3">
          <button type="submit" className="global_admin_btn">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
