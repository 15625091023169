import { createSlice } from "@reduxjs/toolkit";
import { WORKFORCE_MANAGE_REDUCER } from "./manageWorkforceReducer";

export const initialState = {
  data: [],
  formData: [],
  loading: false,
  error: null,
};

const workforceManageSlice = createSlice({
  name: "manageWorkforce",
  initialState: initialState,
  reducers: WORKFORCE_MANAGE_REDUCER,
});

export default workforceManageSlice;
