import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { updateSkillsetWithSubSkills } from "../../services/admin/manageworkforce/manageworkforceservices";
import Switch from "react-switch";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";

const initialValues = {
  newSkillName: "",
  isActive: true,
  subSkills: [],
};

const findDifferences = (original, updated) => {
  const differences = {
    newSkillName: {
      newSubSkillName: updated.newSkillName,
      _id: original._id,
      isActive: updated.isActive,
    },
  };

  for (let key in updated) {
    if (Array.isArray(updated[key])) {
      const editedItems = updated[key].filter((item, index) => {
        return JSON.stringify(item) !== JSON.stringify(original[key][index]);
      });
      if (editedItems.length > 0) {
        differences[key] = editedItems;
      }
    } else if (updated[key] !== original[key] && key !== "newSkillName") {
      differences[key] = updated[key];
    }
  }
  return differences;
};

const UpdateCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.row;
  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    setInitState(data);
  }, [data]);

  const validationSchema = Yup.object().shape({
    newSkillName: Yup.string().required("Category is required"),
    isActive: Yup.boolean(),
    subSkills: Yup.array().of(
      Yup.object().shape({
        newSubSkillName: Yup.string().required("Sub category name is required"),
        // sub_id: Yup.string().required("Sub category ID is required"),
        isActive: Yup.boolean(),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const differences = findDifferences(initState, values);
    try {
      const response = await updateSkillsetWithSubSkills(differences);
      if (response.status === true) {
        navigate(-1);
      } else {
        toast.error(response.response.data.message);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1="Update category" backButton={true} />
      <Formik
        initialValues={initState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="newSkillName">Category Name</label>
                <div className="d-flex align-items-center mb-3 form-control p-0">
                  {" "}
                  <Field
                    type="text"
                    name="newSkillName"
                    className="w-100 p-3 mb-0 border-0"
                    value={values.newSkillName}
                    onChange={handleChange}
                    disabled={!values.isActive}
                  />
                  <Switch
                    className="switch_active me-2"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={(checked) => {
                      setFieldValue("isActive", checked);
                    }}
                    checked={values.isActive}
                  />
                </div>

                <ErrorMessage
                  name="newSkillName"
                  component="span"
                  className="text-danger"
                />
              </div>
            </div>
            <FieldArray name="subSkills">
              {({ push, remove }) => (
                <div className="row mt-3">
                  <label htmlFor="subSkills">Sub Categories</label>
                  {values.subSkills.map((subSkill, index) => (
                    <div key={index} className="col-md-6 input_row_col">
                      <div className="d-flex align-items-center mb-3 form-control p-0">
                        <Field
                          name={`subSkills[${index}].newSubSkillName`}
                          className="w-100 p-3 mb-0 border-0"
                          placeholder="Sub Category Name"
                          disabled={!subSkill.isActive || !values.isActive}
                        />
                        <Switch
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className="switch_active me-2"
                          onChange={(checked) => {
                            setFieldValue(
                              `subSkills[${index}].isActive`,
                              checked
                            );
                            if (!checked) {
                              setFieldValue(
                                `subSkills[${index}].newSubSkillName`,
                                values.subSkills[index].newSubSkillName
                              );
                            }
                          }}
                          checked={subSkill.isActive}
                          disabled={!values.isActive} // Disable if the main category is inactive
                        />
                      </div>
                      <ErrorMessage
                        name={`subSkills[${index}].newSubSkillName`}
                        component="span"
                        className="text-danger"
                      />
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="py-3">
              <button
                type="submit"
                className="global_admin_btn me-2"
                disabled={isSubmitting}
              >
                Update
              </button>
              <button
                type="button"
                className="global_admin_btn1 btn btn-secondary py-2 px-3"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateCategory;
