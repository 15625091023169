import { initialState } from "./manpowerEnquirySLice";

const categoryTableDataIncrementDecrement = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    categoryTable: action.payload,
  };
  return data;
};

const slectedCategoriesCart = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    selectedCart: action.payload,
  };
  return data;
};

const setFormData = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    formData: action.payload,
  };
  return data;
};

const selectCartItem = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    cartSelectedItem: action.payload,
  };
  return data;
};

const editNavigate = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    editNavigate: action.payload,
  };
  return data;
};

const setUserId = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    userId: action.payload,
  };
  return data;
};

const resetToTheInitialState = () => {
  return initialState;
};
export const MAN_POWER_ENQUIRY_REDUCER = {
  categoryTableDataIncrementDecrement,
  setFormData,
  selectCartItem,
  resetToTheInitialState,
  editNavigate,
  slectedCategoriesCart,
  setUserId
};
