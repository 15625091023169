import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  addTermsAndConditions,
  getCMSContentsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import useMaxLimit from "../../../hooks/useMaxLimit";
import CommonEditor from "../../../components/commonEditor/commonEditor";

function GulfAppTermsAndConditions() {
  const [content, setContent] = useState("");
  const [cmsContents, setCMSContents] = useState(null);
  const [isWeplaced, setIsWeplaced] = useState(false);

  const { handleEditorChange } = useMaxLimit(setContent);
  const MAX_LENGTH = 15000;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        terms_and_conditions: content,
        is_weplaced: isWeplaced,
      };

      const response = await addTermsAndConditions(payload);
      if (response.status === true) {
        toast.success("Terms and conditions updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Failed to update terms and conditions: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error updating terms and conditions.");
      console.error("Error updating terms_and_conditions:", error);
    }
  };

  useEffect(() => {
    const fetchCMSContents = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[1]);
          setContent(response.data[1].terms_and_conditions);
          setIsWeplaced(response.data[1].is_weplaced === "true");
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchCMSContents();
  }, []);

  return (
    <div className="manage_terms_conditions_page card p-4 rounded shadow">
      <PageTile heading1={"Manage Terms And Conditions"} />
      <CommonEditor
        value={content}
        onChange={(value) => {
          if (value.length > MAX_LENGTH) {
            toast.error(`Content cannot exceed ${MAX_LENGTH} characters.`);
          } else {
            handleEditorChange(value);
          }
        }}
        maxLength={MAX_LENGTH}
      />
      <div className="py-3">
        <button className="global_admin_btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  );
}

export default GulfAppTermsAndConditions;
