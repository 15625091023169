import { initialState } from "./manageWorkforceSlice";

const addWorkforce = (state, action) => {
  let data = { ...state };
  data = {
    ...data,
    formData: action.payload,
  };
  return data;
};

const resetToTheInitialState = () => {
  return initialState;
};
export const WORKFORCE_MANAGE_REDUCER = {
  addWorkforce,
  resetToTheInitialState,
};
