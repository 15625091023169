import React from "react";

const ImageUploader = ({ image, onChangeHandler }) => {
  const onChange = (e) => {
    onChangeHandler(e);
  };
  return (
    <div className="image-upload-section text-center mb-4">
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        className="d-none"
        id="imageUpload"
      />
      <label htmlFor="imageUpload" className="image-upload-label">
        {image ? (
          <img
            src={image}
            alt="Uploaded"
            className="img-fluid rounded-circle"
          />
        ) : (
          <div className="image-placeholder">
            <span>Upload Image</span>
          </div>
        )}
      </label>
    </div>
  );
};

export default ImageUploader;
