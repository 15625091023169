import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { addExecutive } from "../../../services/admin/CmsPages/cmsPagesServices";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { adminPathUrl } from "../../../shared/pathUrl";
import "bootstrap/dist/css/bootstrap.min.css";

const Executive = () => {
  const [content, setContent] = useState("");
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    designation: "",
    executive_pic: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    designation: Yup.string().required("Designation is required"),
    executive_pic: Yup.mixed().required("Executive picture is required"),
  });

  const handleEditorChange = (value, setFieldValue) => {
    setContent(value);
    setFieldValue("content", value);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (values) => {
    const response = await addExecutive({ ...values });
    if (response.status) {
      navigate(-1);
    }
  };

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setFieldValue("executive_pic", file);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCancel = () => {
    navigate(adminPathUrl.manageReviews);
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add Executive Details"}  backButton={true}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" className="form-control" />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="designation">Designation</label>
              <Field type="text" name="designation" className="form-control" />
              <ErrorMessage name="designation" component="div" className="text-danger" />
            </div>
            <div className="image-upload-section mb-4">
              <input
                type="file"
                accept="image/*"
                name="executive_pic"
                onChange={(e) => handleImageChange(e, setFieldValue)}
                className="d-none"
                id="imageUpload"
              />
              <label htmlFor="imageUpload" className="image-upload-label">
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: "200px",
                      padding: "10px",
                      border: "1px solid",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div className="image-placeholder">
                    <span className="upload_img">Upload Image</span>
                  </div>
                )}
              </label>
              <ErrorMessage name="executive_pic" component="div" className="text-danger mt-4" />
            </div>
            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Save
              </button>
              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Executive;
