import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const CustomDropdown = ({ label, options, onChange,selected }) => {
  const [selectedOption, setSelectedOption] = useState(label);

  const handleSelect = (name, id) => {
    setSelectedOption(name);
    if (onChange) {
      onChange({ name, id });
    }
  };

  return (
    <div className="dropdown-container">
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-toggle-custom"
        >
          {selected??selectedOption}
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-custom">
          {options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() =>
                handleSelect(option.tertiarySkillName, option.subSkillId)
              }
            >
              {option.tertiarySkillName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CustomDropdown;
