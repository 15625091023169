import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LogoSvg } from "../../../assests/svg";
import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import Swal from "sweetalert2";
import "./forgotPass.css"; // Ensure you have appropriate styles
import { adminPathUrl } from "../../../shared/pathUrl";

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle email input change
  const handleEmailChange = (e) => setEmail(e.target.value);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required");
      return;
    }

    setError(""); // Clear any previous error
    setLoading(true); // Start loading

    try {
      await axios.post(API_URL_ENDPOINT.forgotPassword, { email });
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Password reset link has been sent to your email",
        confirmButtonText: "OK",
      }).then(() => {
        // Navigate to the reset password page or any other page after alert is confirmed
        navigate(adminPathUrl.login);
      });
    } catch (error) {
      setError(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  return (
    <section className="auth_section">
      <Container>
        <div className="login_block">
          <div className="logo_block">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <div
                className="header-logo-worker-list border-end-0"
                style={{ backgroundColor: "white" }}
              >
                <span style={{ cursor: "pointer", textAlign: "center" }}>
                  {LogoSvg()}
                </span>
              </div>
            </Link>
          </div>
          <h2 className="">Forgot Password</h2>
          <Form className="form_forgot_password" onSubmit={handleSubmit}>
            <div className="form_input">
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control"
                value={email}
                onChange={handleEmailChange}
                autoComplete="off"
              />
              {error && (
                <span className="text-danger mb-2 d-block">{error}</span>
              )}
            </div>
            <div className="btn_login text-center">
              <button
                className="global_admin_btn"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ms-2">Sending...</span>
                  </>
                ) : (
                  "Send Reset Link"
                )}
              </button>
            </div>
            <div className="text-center mt-3">
              <Link to="/admin" className="back-to-login-link">
                Back to Login
              </Link>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default ForgotPass;
