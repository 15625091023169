import React from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
export default function TermCondition() {
  const location = useLocation();
  const { terms_and_conditions } = location.state;
  return (
    <div className="container">
     <span className="bread-crumb py-3 d-block">
        <Link to="/" className="breadcrumb-home">
          Home
        </Link> 
        / <span className="bread-crumb-extend-text">Term & Condition</span>
      </span>
      <div className="privacy-policy">
      <div dangerouslySetInnerHTML={{ __html: terms_and_conditions }} />
      </div>
    </div>
  );
}
