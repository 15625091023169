import React, { useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import DataTableComponent from "../../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import Avatar from "../../../components/Avatar";
import { wePlacedEnquiryService } from "../../../services/admin/manageworkforce/manageWorkEnquiryServices";
import image from "../../../assests/images/Glass Installer.jpg"; // Ensure correct path to image
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import usePagination from "../../../hooks/usePagination";

const ContactUs = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const { pagination, setPagination } = usePagination();
  const { currentPage, limit } = pagination;
  useEffect(() => {
    const fetchData = async (currentPage, limit) => {
      const url = `${
        API_URL_ENDPOINT.wePlacedEnquiry
      }?is_weplaced=${false}&page=${currentPage}&limit=${limit}`;
      try {
        const res = await wePlacedEnquiryService(url);
        if (res.status) {
          setPagination((prev) => {
            return {
              ...prev,
              totalPages: res?.data?.totalPages,
              totalCount: res?.data?.totalSupport,
            };
          });
          setData(res?.data?.data);
        }
        console.log(res, "res");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(currentPage, limit);
  }, [currentPage]);

  const handleRowClicked = (row) => {
    console.log("Clicked row:", row);
    // Handle navigation or any other action
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const userColumns = [
    // {
    //   name: "",
    //   selector: (row) => (
    //     <Avatar imgSrc={image} alt="Profile" imgClass="products-image" />
    //   ),
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
  ];

  return (
    <div className="contact-us-page">
      {/* Search bar if needed */}
      {/* <div className="search-bar-container mb-3">
        <input className="searchbar" type="search" placeholder="Search..." />
        <IoSearchSharp className="searchbar-icon" size={24} />
      </div> */}

      <div className="datatable-section">
        <DataTableComponent
          title="Contact Us "
          data={data}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          responsive={true}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>

      {/* Delete modal if needed */}
      {/* <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this?"}
      /> */}
    </div>
  );
};

export default ContactUs;
