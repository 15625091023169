import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LogoSvg } from "../../../assests/svg";
import { adminPathUrl } from "../../../shared/pathUrl";
import axios from "axios";
import "./login.css";
import ButtonLoader from "../../../components/buttonLoader";
import { toast } from "react-toastify";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

function Login({ setIsAuthenticated }) {
  // State for form data and loading state
  const [formData, setFormData] = useState({
    inputData: {
      username: "",
      password: "",
    },
    inputError: {},
    loading: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();
  
  function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token);
    }, []);
    return isAuthenticated;
  }

  // Function for handling input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      inputData: {
        ...prev.inputData,
        [name]: value,
      },
      inputError: {
        ...prev.inputError,
        [name]: value.length > 0 ? "" : `${name} is required`,
      },
    }));
  };

  // Function for handling form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, password } = formData.inputData;
    const errors = {};

    if (!username) errors.username = "Username is required";
    if (!password) errors.password = "Password is required";

    if (Object.keys(errors).length > 0) {
      setFormData((prev) => ({
        ...prev,
        inputError: errors,
      }));
      return;
    }

    const payload = {
      email: username,
      password,
    };

    try {
      setFormData((prev) => ({
        ...prev,
        loading: true,
      }));

      const res = await axios.post(API_URL_ENDPOINT.adminlogin, payload);

      if (res?.status === 200) {
        const loginData = res.data.data;
        delete loginData.password; // Removing sensitive information
        toast.success(res?.data?.message);
        localStorage.setItem("token", res?.data?.accessToken);
        localStorage.setItem("loginData", JSON.stringify(loginData));
        setIsAuthenticated(true); // Notify parent component of successful login
        navigate(adminPathUrl.dashboard);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setFormData((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <section className="auth_section">
      <Container>
        <div className="login_block">
          <div className="logo_block">
            <Link
              to="#"
              className="d-flex align-items-center justify-content-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <div
                className="header-logo-worker-list border-end-0"
                style={{ backgroundColor: "white" }}
              >
                <span style={{ cursor: "pointer",textAlign: "center"}}>{LogoSvg()}</span>
                </div>
            </Link>
          </div>
          <h2 className="">Login</h2>
          <Form className="form_login" onSubmit={handleSubmit}>
            <div className="form_input">
              <input
                type="email"
                placeholder="Email"
                className="form-control"
                id="username"
                name="username"
                value={formData.inputData.username}
                onChange={handleInputChange}
                autoComplete="off"
              />
              {formData.inputError.username && (
                <span className="text-danger mb-2 d-block">
                  {formData.inputError.username}
                </span>
              )}
            </div>
            <div className="form_input position-relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                className="form-control"
                id="password"
                name="password"
                value={formData.inputData.password}
                onChange={handleInputChange}
                autoComplete="off"
              />
              <span
                className="password-toggle"
                onClick={togglePasswordVisibility}
                style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
              >
                {passwordVisible ?<FaEye /> : <FaEyeSlash /> }
              </span>
              {formData.inputError.password && (
                <span className="text-danger mb-2 d-block">
                  {formData.inputError.password}
                </span>
              )}
            </div>
            <div className="btn_login text-center">
              <button className="global_admin_btn" type="submit">
                {formData.loading ? <ButtonLoader /> : "Login"}
              </button>
            </div>
            <div className="text-center mt-3">
              <Link to={adminPathUrl.forgotPassword} className="forgot-password-link">
                Forgot Password?
              </Link>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default Login;
