import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { Col, Container, Form, Row } from "react-bootstrap";
import footerLogo from "../assests/images/footer-logo.png";
import { pathUrl } from "../shared/pathUrl";
import { Link, useNavigate } from "react-router-dom";
import { menuArr } from "../shared/frontendArray";
import { globalValidationFunction } from "../shared/helperfunction";
import { toast } from "react-toastify";
import ThanksModal from "../pages/cart/thanksModal";
import {
  addContactUsService,
  getCMSContentsService,
  getHomepageWeplacedService,
} from "../services/admin/manageworkforce/manageworkforceservices";
export default function Footer() {
  const navigate = useNavigate();

  const [cmsContents, setCMSContents] = useState(null);
  const [formDataValue, setFormDataValue] = useState({
    name: {
      value: "",
      warning: false,
    },
    email: {
      value: "",
      warning: false,
    },
    message: {
      value: "",
      warning: false,
    },
  });
  const [thanksModal, setThanksModal] = useState(false);
  const [homepageWeplaced, setHomepageWeplaced] = useState(null);

  const GetWeplacedHomepageData = async () => {
    try {
      const response = await getHomepageWeplacedService();
      if (response.status && response.data.length > 0) {
        console.log(response.data); // Debugging: Check the data here
        setHomepageWeplaced(response.data[0]); // Access the first item in the array
      }
    } catch (error) {
      console.error("Error fetching homepage data:", error);
    }
  };

  useEffect(() => {
    GetWeplacedHomepageData();
  }, []);

  //onchange function to set the value of form
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormDataValue((prev) => ({
      ...prev,
      [name]: { value: value?.trimStart(), warning: value === "" },
    }));
  };

  // onclick func to submit the from
  const onSubmit = async (e) => {
    e.preventDefault();
    let data = { ...formDataValue };
    data = {
      ...data,
      name: { ...data?.name, warning: data?.name?.value === "" },
      email: {
        ...data?.email,
        warning: globalValidationFunction().validateEmail(data?.email?.value),
      },
      message: { ...data?.message, warning: data?.message?.value === "" },
    };
    setFormDataValue({ ...data });
    if (
      !data?.name?.warning &&
      !data?.email?.warning &&
      !data?.message?.warning
    ) {
      const response = await addContactUsService({
        name: data.name.value,
        email: data.email.value,
        message: data.message.value,
      });
      if (response.status) {
        setThanksModal(true);
        setFormDataValue({
          name: {
            value: "",
            warning: false,
          },
          email: {
            value: "",
            warning: false,
          },
          message: {
            value: "",
            warning: false,
          },
        });
      }
    }
  };

  useEffect(() => {
    {
      (async () => {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[0]);
        }
      })();
    }
  }, []);
  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
  };

  const linkHoverStyle = {
    textDecoration: "underline",
  };
  return (
    <footer className="footer">
      <Container fluid>
        <div className="row">
          <div className="col-lg-6 left_footer_widget">
            <div className="footer-left-blur">
              <img src={footerLogo} />
              <div className="footer-menu">
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ borderRight: "1px solid grey" }}
                  >
                    {menuArr.map((item) => {
                      if (item.navLink) {
                        return (
                          <Link key={item.title} to={item.navLink}>
                            {item.title}
                          </Link>
                        );
                      } else {
                        return (
                          <a key={item.title} href={item.target}>
                            {item.title}
                          </a>
                        );
                      }
                    })}
                  </div>

                  <div className="col-md-6">
                    <div className="footer-info text-center">
                      <h6>{homepageWeplaced?.phone || "+971 50 215 0204"}</h6>
                      <h5 className="mailsend">
                        <a
                          href={`mailto:${
                            homepageWeplaced?.website || "hello@we-placed.com"
                          }`}
                        >
                          {homepageWeplaced?.website || "hello@we-placed.comm"}
                        </a>
                      </h5>
                      <div className="d-flex linkdin_block align-items-center justify-content-center text-center">
                        {homepageWeplaced?.linkedIn_link && (
                          <a
                            href={homepageWeplaced.linkedIn_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="facebook_icon_parent_style"
                          >
                            <FaLinkedinIn />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy_right">
              <p className="text-center">
                © 2024 WePlaced. All Rights Reserved.{" "}
                <span
                  style={{ ...linkStyle, cursor: "pointer" }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.textDecoration =
                      linkHoverStyle.textDecoration)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.textDecoration =
                      linkStyle.textDecoration)
                  }
                  onClick={() =>
                    navigate(pathUrl.termCondition, {
                      state: {
                        terms_and_conditions: cmsContents.terms_and_conditions,
                      },
                    })
                  }
                >
                  Terms & Conditions
                </span>{" "}
                |
                <span
                  style={{ ...linkStyle, cursor: "pointer" }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.textDecoration =
                      linkHoverStyle.textDecoration)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.textDecoration =
                      linkStyle.textDecoration)
                  }
                  onClick={() =>
                    navigate(pathUrl.privacyPolicy, {
                      state: { privacy_policy: cmsContents.privacy_policy },
                    })
                  }
                >
                  Privacy Policy
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-6 right_footer_widget" id="contact_us_footer">
            <div className="footer-contact-form">
              <h3 className="mb-5">Contact us</h3>
              <form onSubmit={onSubmit}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicName"
                    >
                      <Form.Label
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Name
                      </Form.Label>
                      <Form.Control
                        style={{ height: "80px", paddingTop: "29px" }}
                        type="text"
                        name="name"
                        placeholder="Enter your name here"
                        autoComplete="off"
                        onChange={onChange}
                        value={formDataValue?.name?.value}
                      />
                      {formDataValue?.name?.warning && (
                        <span className="text-danger">Enter the name</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      controlId="formBasicEmail"
                      className="mb-3 position-relative"
                    >
                      <Form.Label
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        style={{ height: "80px", paddingTop: "29px" }}
                        type="email"
                        name="email"
                        placeholder="Enter your email here"
                        autoComplete="off"
                        onChange={onChange}
                        value={formDataValue?.email?.value}
                      />
                      {formDataValue?.email?.warning && (
                        <span className="text-danger"> Enter your email </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicCompanyName"
                    >
                      <Form.Label
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "17px",
                          fontWeight: "bold",
                        }}
                      >
                        Message
                      </Form.Label>
                      <Form.Control
                        as={"textarea"}
                        rows={4}
                        style={{ paddingTop: "29px" }}
                        type="text"
                        name="message"
                        placeholder="Enter your Message"
                        autoComplete="off"
                        onChange={onChange}
                        value={formDataValue?.message?.value}
                      />
                      {formDataValue?.message?.warning && (
                        <span className="text-danger">Enter the message</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <button
                  style={{ width: "350px !important" }}
                  className="steper_submit_button footer-submit"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
      <ThanksModal
        open={thanksModal}
        setOpen={setThanksModal}
        content={
          "Thank you for contacting us. Your message has been received, and a member of our team will get back to you"
        }
        btnText={"Ok"}
        continueBtnFun={() => setThanksModal(false)}
      />
    </footer>
  );
}
