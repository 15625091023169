import { useState, useEffect } from "react";

const usePageRefresh = (location) => {
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);

  useEffect(() => {
    // Check if the page has been refreshed
    const isRefreshed = sessionStorage.getItem("isRefreshed") === "true";
    if (isRefreshed) {
      setIsPageRefreshed(true);
      location.state=null
    } else {
      console.log("Page was not refreshed");
    }

    sessionStorage.setItem("isRefreshed", "true");

    return () => {
      sessionStorage.removeItem("isRefreshed");
    };
  }, []);

  return isPageRefreshed;
};

export default usePageRefresh;
