import React from "react";
import Modal from "react-bootstrap/Modal";
import { RiInfoI } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

function ConfirmationPupup({
  open,
  setOpen,
  heading,
  onConfirm,
  handleClose,
  btnText = "Ok",
  noBtn,
}) {
  const handleConfirm = async () => {
    setOpen(false); // Close the modal after confirmation
    onConfirm();
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Body>
        <RxCross1 onClick={handleClose} className="icon-position" size={20} />
        <div className="confirm-modal">
          <RiInfoI size={87} />
          <div>
            <span>{heading}</span>
            <div className="d-flex justify-content-center mt-3">
              <Button onClick={handleConfirm} className="confirm-modal-yes-btn">
                {btnText}
              </Button>
              <Button onClick={handleClose} className="confirm-modal-no-btn">
                {noBtn}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationPupup;
