import React from "react";
import { FaBlog, FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { blogs } from "../../../assests/admin-images";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { adminPathUrl } from "../../../shared/pathUrl";
import "./style.css";
function ManageGulfBlogs() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleAddUserClick = () => {
    // Navigate to '/user' route
    navigate(adminPathUrl.gulfAddBlogs);
  };
  const blog = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <button
        className="add-user-button"
        onClick={() => {
          handleAddUserClick();
        }}
      >
        <FaBlog /> Add Blogs
      </button>
      <PageTile heading1={"Blogs"} />

      <div className="row">
        {blog.map((item, index) => (
          <div key={index} className="col-md-4 col-sm-6 col-12">
            <div className="card_blogs">
              <div className="blogs_head">
                <img src={blogs} alt="" />
              </div>
              <div className="card_bottom">
                <h5>24 Jan 2024</h5>
                <h3>Housing Bubble 2024: Are housing Market Crashing?</h3>
                <div className="read_more d-flex justify-content-between">
                  <Link>Read More</Link>
                  <div className="group_btns ">
                    <button className="bg-transparent border-0 me-2">
                      <FaEdit />
                    </button>
                    <button className="bg-transparent border-0 ">
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageGulfBlogs;
