import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import "bootstrap/dist/css/bootstrap.min.css";
import Rating from "react-rating-stars-component";
import { updateReviewService } from "../../../../services/admin/CmsPages/cmsPagesServices";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminPathUrl } from "../../../../shared/pathUrl";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  content: Yup.string().required("Content is required"),
  rating: Yup.number()
    .min(1, "Rating is required")
    .required("Rating is required"),
});

function UpdateReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  if (!state || !state.reviewData) {
    console.error("No state or reviewData found");
    navigate('/error-page'); 
  }
    console.log(state,"stateeeeeeeeeeeeeee")



    const handleSubmit = async (values, { setSubmitting }) => {
      try {
        const payload = {
          _id: state.reviewData._id, 
          name: values.name,
          email: values.email,
          country: values.country,
          city: values.city,
          rating: values.rating,
          review: values.content,
        };
    
        console.log("Payload:", payload);
    
        const response = await updateReviewService(state.reviewData._id, payload);
        console.log("Response:", response);
    
        if (response.status === true) {
          toast.success(" updated successfully!", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate(-1); 
        } else {
          toast.error("Failed to update review. Please try again.");
        }
      } catch (error) {
        console.error("Error updating review:", error);
        toast.error("Error updating review. Please try again.");
      } finally {
        setSubmitting(false); 
      }
    };
    
 

// const handleSubmit = async (values, { setSubmitting }) => {
//   try {
   
//     const payload = {
//       _id: state.reviewData._id, 
//       name: values.name,
//       email: values.email,
//       country: values.country,
//       city: values.city,
//       rating: values.rating,
//       review: values.content,
//     };

//     console.log("Payload:", payload);

//     const response = await updateReviewService(state.reviewData._id, payload);
//     console.log("Updated review:", response);

//     navigate(-1); 
//   } catch (error) {
//     console.error("Error updating review:", error);
//   } finally {
//     setSubmitting(false); 
//   }
// };




  // Set initial values based on reviewData
  const initialValues = {
    name: state.reviewData.name || "",
    email: state.reviewData.email || "",
    country: state.reviewData.country || "",
    city: state.reviewData.city || "",
    content: state.reviewData.review || "",
    rating: state.reviewData.rating || 0,
  };

  const handleCancel = () => {
    navigate(adminPathUrl.manageReviews);
  };

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   try {
  //     const payload = {
  //       name: values.name,
  //       email: values.email,
  //       country: values.country,
  //       city: values.city,
  //       rating: values.rating,
  //       review: values.content,
  //     };

  //     // Perform API call to update the review
  //     const response = await updateReviewService(reviewData._id, payload);

  //     if (response._id) { // Check if the response contains the review ID
  //       toast.success("Review updated successfully");
  //       navigate(adminPathUrl.manageReviews); // Navigate back to manage reviews
  //     } else {
  //       toast.error("Failed to update review");
  //     }
  //   } catch (error) {
  //     console.error("Error updating review:", error);
  //     toast.error("Error updating review");
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Update Review"} backButton={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage name="name" component="div" className="text-danger" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" className="form-control" />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <Field type="text" name="country" className="form-control" />
                  <ErrorMessage name="country" component="div" className="text-danger" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <Field type="text" name="city" className="form-control" />
                  <ErrorMessage name="city" component="div" className="text-danger" />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <Field as="textarea" name="content" className="form-control" />
                <ErrorMessage name="content" component="div" className="text-danger" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group d-flex align-items-center">
                  <label className="mb-0 me-2">Rating</label>
                  <Rating
                    count={5}
                    value={values.rating}
                    onChange={(newRating) => setFieldValue("rating", newRating)}
                    size={24}
                    activeColor="rgb(250, 175, 0)"
                  />
                </div>
                <ErrorMessage name="rating" component="div" className="text-danger" />
              </div>
            </div>

            <div className="py-3">
              <button
                type="submit"
                className="global_admin_btn"
                disabled={isSubmitting}
              >
                Save
              </button>

              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateReview;
