import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";

const AdminPublicLayout = ({ loginData }) => {
  if (!loginData) {
    return <Outlet />;
  } else {
   return <Navigate to={adminPathUrl.dashboard} />;
  }
};

export default AdminPublicLayout;
