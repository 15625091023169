import { pathUrl } from "./pathUrl";
import blog from "../assests/images/blog.png";
import blog2 from "../assests/images/blog1.png";
// frontend array for dropdown
export const accomodationArray = ["Yes", "No"];
export const transportation = ["Yes", "No"];

// footer menu array
export const menuArr = [
  {
    title: "About",
    navLink: pathUrl.aboutUs,
  },
  {
    title: "Search Manpower",
    target: "/#search_manpower",
  },
  {
    title: "Reviews",
    // navLink: "",
    target: "/#client-reviews",
  },
  {
    title: " Industry Insights",
    navLink: pathUrl.blog,
  },
];

// hader navigation array
export const headerNavigation = [
  {
    title: "Home",
    navLink: pathUrl.homePage,
  },
  {
    title: "About",
    navLink: pathUrl.aboutUs,
  },
  {
    title: "Industry Insights",
    navLink: pathUrl.blog,
  },
  {
    title: "Contact",
    target: "#contact_us_footer",
  },
];

// blog section home page array
export const blogDataHome = {
  blogData: {
    img: blog,
    heading: "Explain loan approval",
    date: "2 Jan 2023",
    id: 1,
  },
  blogCard: [
    {
      img: blog2,
      heading: "Home selling strategies",
      date: "8 June 2023",
      id: 2,
    },
    {
      img: blog2,
      heading: "Home improvement ideas",
      date: "11 July 2022",
      id: 3,
    },
  ],
};
