import axios from "axios";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";

export const getTheWorkerAsPerCategory = (body) => {
  return axios
    .post(API_URL_ENDPOINT.getWorkerasperthecategory, body)
    .then((res) => res.data)
    .catch((err) => err);
};

export const getCategory = () => {
  return axios
    .get(API_URL_ENDPOINT.getCategory)
    .then((res) => res.data)
    .catch((err) => err);
};

export const submitManpowerDataDromthecartPage = (body, id) => {
  return axios
    .post(
      id
        ? `${API_URL_ENDPOINT.submitManPowerDataFromtheCart}?id=${id}`
        : API_URL_ENDPOINT.submitManPowerDataFromtheCart,
      body
    )
    .then((res) => res.data)
    .catch((err) => err);
};
