import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaUpload } from "react-icons/fa";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import {
  getBanners,
  updateBanners,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import ImageResolutionWarningModal from "../../../components/ImageCompressModal";
import { resizeImage } from "../../../utils/methods";
import Description from "../../../components/Description";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import MaxLimit from "../../../components/MaxLimit";

function Banner() {
  const [cmsContents, setCMSContents] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [images, setImages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [tempFile, setTempFile] = useState(null);
  const fileInputRefs = useRef({});

  // Define maximum lengths for each banner's title fields
  const MAX_LENGTHS = {
    banner1: {
      title1: 22,
      title2: 28,
    },
    banner2: {
      title1: 22,
      title2: 32,
    },
    banner3: {
      title1: 22,
      title2: 26,
    },
  };
  const CONTENT_MAX_LENGTH = 63;
  const minWidth = 1920; 
  const minHeight = 1080; 

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await getBanners();
        if (response.status) {
          setCMSContents(response.data);
          const urls = {};
          response.data.forEach((banner) => {
            if (banner.banner_picture) {
              urls[banner._id] = banner.banner_picture;
            }
          });
          setImageUrls(urls);
        } else {
          console.error("Failed to fetch banners:", response.message);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };
    fetchBanners();
  }, []);

  const handleEditorChange = (value, section, bannerId) => {
    if (section === "content") {
      if (value?.length <= CONTENT_MAX_LENGTH) {
        setCMSContents((prevState) =>
          prevState.map((banner) =>
            banner._id === bannerId ? { ...banner, [section]: value } : banner
          )
        );
      }
    } else {
      const bannerIndex = cmsContents.findIndex((banner) => banner._id === bannerId);
      const bannerKey = `banner${bannerIndex + 1}`;
      if (value?.length <= MAX_LENGTHS[bannerKey][section]) {
        setCMSContents((prevState) =>
          prevState.map((banner) =>
            banner._id === bannerId ? { ...banner, [section]: value } : banner
          )
        );
      }
    }
  };

  const handleImageChange = (e, bannerId) => {
    const file = e.target.files[0];
    if (!file) return;

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = () => {
      const { width, height } = img;

      if (width < minWidth || height < minHeight) {
        setTempFile({ file, bannerId });
        setShowModal(true);
      } else {
        setImages((prevImages) => ({
          ...prevImages,
          [bannerId]: file,
        }));
        setImageUrls((prevUrls) => ({
          ...prevUrls,
          [bannerId]: URL.createObjectURL(file),
        }));
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCompressImage = async () => {
    if (!tempFile) return;
    const { file, bannerId } = tempFile;
    const resizedFile = await resizeImage(file, minWidth, minHeight);
    setImages((prevImages) => ({
      ...prevImages,
      [bannerId]: resizedFile,
    }));
    setImageUrls((prevUrls) => ({
      ...prevUrls,
      [bannerId]: URL.createObjectURL(resizedFile),
    }));
    setShowModal(false);
    setTempFile(null); // Reset tempFile after compressing the image
  };

  const handleUploadButtonClick = (bannerId) => {
    if (fileInputRefs.current[bannerId]) {
      fileInputRefs.current[bannerId].click();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTempFile(null);
  };

  const handleSubmit = async (values, { setSubmitting }, bannerId) => {
    try {
      const formData = new FormData();
      formData.append("title1", values.title1);
      formData.append("title2", values.title2);
      formData.append("content", values.content);

      if (images[bannerId]) {
        formData.append("banner_picture", images[bannerId]);
      } else if (imageUrls[bannerId]) {
        formData.append("banner_picture", imageUrls[bannerId]);
      }

      formData.append("_id", bannerId);

      const response = await updateBanners(bannerId, formData);

      if (response.status === true) {
        toast.success("Banner updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setImages((prevImages) => ({
          ...prevImages,
          [bannerId]: null,
        }));
      } else {
        toast.error(`Failed to update banner: ${response.message}`);
      }
    } catch (error) {
      console.error("Error updating banner:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {cmsContents.length > 0 && (
        <div>
          {cmsContents.map((banner, index) => {
            const bannerKey = `banner${index + 1}`;
            return (
              <div
                className="manage_blogs_page shadow card p-4 rounded"
                key={banner._id}
              >
                <PageTile heading1={`Banner ${index + 1}`} />
                <Formik
                  enableReinitialize
                  initialValues={{
                    title1: banner.title1 || "",
                    title2: banner.title2 || "",
                    content: banner.content || "",
                  }}
                  onSubmit={(values, actions) => {
                    handleSubmit(values, actions, banner._id);
                  }}
                >
                  {({ values, handleChange, handleBlur }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="title1">Title 1</label>
                        <MaxLimit
                          current={values.title1.length}
                          max={MAX_LENGTHS[bannerKey].title1}
                        />
                        <Field
                          type="text"
                          id="title1"
                          name="title1"
                          value={values.title1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={MAX_LENGTHS[bannerKey].title1}
                          className={`form-control ${
                            values.title1.length > MAX_LENGTHS[bannerKey].title1 ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="title1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="title2">Title 2</label>
                        <MaxLimit
                          current={values.title2.length}
                          max={MAX_LENGTHS[bannerKey].title2}
                        />
                        <Field
                          type="text"
                          id="title2"
                          name="title2"
                          value={values.title2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={MAX_LENGTHS[bannerKey].title2}
                          className={`form-control ${
                            values.title2.length > MAX_LENGTHS[bannerKey].title2 ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="title2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="banner_picture">Image Upload</label>
                        <div className="input-group mb-3">
                          <input
                            type="file"
                            name="banner_picture"
                            id={`banner_picture_${banner._id}`}
                            onChange={(e) => handleImageChange(e, banner._id)}
                            ref={(el) => (fileInputRefs.current[banner._id] = el)}
                            style={{ display: "none" }}
                          />
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleUploadButtonClick(banner._id)}
                          >
                            <FaUpload /> Upload Image
                          </button>
                        </div>
                        {imageUrls[banner._id] && (
                          <img
                            src={imageUrls[banner._id]}
                            alt="Selected"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                        )}
                      </div>

                      <div className="form-group">
                        <CommonEditor
                          value={banner.content || ""}
                          onChange={(value) =>
                            handleEditorChange(value, "content", banner._id)
                          }
                          maxLength={CONTENT_MAX_LENGTH}
                          label="Content"
                        />
                      </div>
                      <div className="py-3">
                        <button type="submit" className="global_admin_btn">
                          Save
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            );
          })}
        </div>
      )}
      <ImageResolutionWarningModal
        show={showModal}
        onClose={handleCloseModal}
        onCompress={handleCompressImage}
      />
    </div>
  );
}

export default Banner;
