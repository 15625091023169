import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  getSkillSetService,
  addSkillSetService,
  deleteSkillSetService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import { useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../../shared/pathUrl";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import ImageResolutionWarningModal from "../../../components/ImageCompressModal";
import { resizeImage } from "../../../utils/methods";
import Description from "../../../components/Description";
import MaxLimit from "../../../components/MaxLimit";

const validationSchema = Yup.object({
  title: Yup.string().required("Skill title is required"),
});

function ManageSkillSet() {
  const [skillSet, setSkillSet] = useState([]);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tempFile, setTempFile] = useState(null);

  const maxWidth = 380;
  const maxHeight = 200;
  const MAX_LENGTHS = {
    title: 30,
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSkillSet = async () => {
      try {
        const response = await getSkillSetService();
        if (response.status) {
          setSkillSet(response.data);
        } else {
          console.error("Failed to fetch skill set:", response.message);
        }
      } catch (error) {
        console.error("Error fetching skill set:", error);
      }
    };
    fetchSkillSet();
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = async () => {
      const { width, height } = img;

      if (width > maxWidth || height > maxHeight) {
        setTempFile(file);
        setShowModal(true);
      } else {
        setImage(file);
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCompressImage = async () => {
    if (!tempFile) return;
    const resizedFile = await resizeImage(tempFile, maxWidth, maxHeight);
    setImage(resizedFile);
    const imageUrl = URL.createObjectURL(resizedFile);
    setImageUrl(imageUrl);
    setShowModal(false);
    setTempFile(null); // Reset tempFile after compressing the image
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTempFile(null);
    setImage(null);
    fileInputRef.current.value = "";
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      if (image) {
        formData.append("skill_picture", image);
      }

      const response = await addSkillSetService(formData);

      if (response.status) {
        toast.success("Skill added successfully!");
        setSkillSet((prevSkills) => [...prevSkills, response.data]);
        resetForm();
        setImage(null);
        setImageUrl(null);
      } else {
        toast.error(`Failed to add skill: ${response.message}`);
      }
    } catch (error) {
      console.error("Error adding skill:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const confirmDelete = async () => {
    if (skillToDelete) {
      try {
        const response = await deleteSkillSetService(skillToDelete);
        if (response.status) {
          toast.success("Skill deleted successfully!");
          setSkillSet((prevSkills) =>
            prevSkills.filter((skill) => skill._id !== skillToDelete)
          );
        } else {
          toast.error(`Failed to delete skill: ${response.message}`);
        }
      } catch (error) {
        console.error("Error deleting skill:", error);
      } finally {
        setDeleteModal(false);
        setSkillToDelete(null);
      }
    }
  };

  const handleDelete = (id) => {
    setSkillToDelete(id);
    setDeleteModal(true);
  };

  const onEditHandler = (skill) => {
    navigate(adminPathUrl.editSkillSet, {
      state: {
        skill: skill,
      },
    });
  };

  return (
    <div className="skill-set-container">
      <div className="add-skill-form mt-4">
        <PageTile heading1={"Add New Skill"} />
        <Formik
          initialValues={{ title: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, values }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="title">Skill Title</label>
                <MaxLimit
                  current={values.title.length}
                  max={MAX_LENGTHS.title}
                />
                <Field
                  type="text"
                  name="title"
                  maxLength={MAX_LENGTHS.title}
                  className="form-control"
                />
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="skill_picture">Skill Image</label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    name="skill_picture"
                    id="skill_picture"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Upload Image
                    </button>
                  </div>
                </div>
                <Description
                  message={`Please upload an image with a resolution of (${maxWidth} ×
                  ${maxHeight}) pixels.`}
                />
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Selected"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                )}
              </div>

              <div className="py-3">
                <button
                  type="submit"
                  className="global_admin_btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Adding..." : "Add Skill"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="skill-set-list">
        <h3>Skill Set List</h3>
        <div className="row">
          {skillSet.length > 0 ? (
            skillSet.map((skill) => (
              <div key={skill._id} className="col-md-4 mb-4">
                <div className="card">
                  <img
                    src={skill.skill_picture}
                    alt={skill.title}
                    className="card-img-top"
                    style={{ maxHeight: "200px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{skill.title}</h5>
                    <button
                      className="bg-transparent border-0 me-2"
                      onClick={() => onEditHandler(skill)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="bg-transparent border-0"
                      onClick={() => handleDelete(skill._id)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No skills found.</p>
          )}
        </div>
        <DeleteModalAdmin
          open={deleteModal}
          setOpen={setDeleteModal}
          heading={"Are you sure to delete this?"}
          onConfirm={confirmDelete}
        />

        <ImageResolutionWarningModal
          show={showModal}
          onHide={handleCloseModal}
          onCompress={handleCompressImage}
          resolution={`${maxWidth}\u00D7${maxHeight}`}
        />
      </div>
    </div>
  );
}

export default ManageSkillSet;
