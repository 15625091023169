import Modal from "react-bootstrap/Modal";
import labor from "../../assests/images/labor.jpg";
import { RxCross1 } from "react-icons/rx";

function WorkerDetailModal({ open, setOpen, cardData, onChange, profilePic }) {
  return (
    <Modal className="modal_details" show={open} onHide={() => setOpen(false)}>
      <Modal.Body className="worker-details-modal">
        <RxCross1 onClick={() => setOpen(false)} className="icon-position" size={20} />
        <div className="row">
          <div className="col-md-6 position-relative">
            <label className="rounded-checkbox-container">
              <input type="checkbox" checked={cardData?.checked} onChange={(e) => onChange(e)} />
              <span className="rounded-checkbox"></span>
            </label>
            <img
              style={{ borderRadius: "10px" }}
              className="img-fluid"
              src={profilePic ?? labor}  // Use profilePic from props, fallback to labor image
              alt="Profile"
            />
          </div>
          <div className="col-md-6">
            <div className="modal-detail-content">
              <h1>{cardData?.fullName}</h1>
              <h2>{cardData?.nationality}</h2>
              <h3 className="pt-3 border-top">Skills with Experience</h3>
              {cardData?.skills?.map((item, index) => (
                <div key={index} className="modal-detail-content-exp pb-3">
                  {item?.SubSkillName} ({item?.experience})
                </div>
              ))}
              <div className="modal-detail-content-personal pt-3 border-top">
                Age: <span>{cardData?.age}</span> <br />
                Passport: <span>{cardData?.is_passport}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default WorkerDetailModal;
