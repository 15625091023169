import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import { addPrivacyPolicy, getCMSContentsService } from "../../../services/admin/manageworkforce/manageworkforceservices";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import { toast } from "react-toastify";
import useMaxLimit from "../../../hooks/useMaxLimit";

function GulfFeatures() {
  const [content, setContent] = useState(""); // State to manage editor content
  const [cmsContents, setCMSContents] = useState(null); // State to manage CMS contents
  const MAX_LENGTH = 100;

  const {  handleEditorChange } = useMaxLimit(setContent);

  useEffect(() => {
    const fetchGulfFeatures = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setCMSContents(response.data[1]);
          setContent(response.data[1].contact_us); // Set editor content with fetched data
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchGulfFeatures();
  }, []);

  const handleEditorChangeWithToast = (value) => {
    // Check if the content length exceeds the maximum allowed length
    if (value.length > MAX_LENGTH) {
      toast.warning(`Maximum length of ${MAX_LENGTH} characters reached.`);
    }
    handleEditorChange(value);
  };

  const handleSubmit = async (section) => {
    try {
      let payload = {
        is_weplaced: false, // Send is_weplaced as false
        contact_us: content, // Include the editor content in the payload
      };

      const response = await addPrivacyPolicy(payload);
      if (response.status) {
        toast.success(`Updated successfully!`);
      } else {
        toast.error(`Failed to update ${section}: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section}:`, error);
    }
  };

  return (
    <div className="manage_terms_conditions_page card p-4 rounded shadow">
      <PageTile heading1={"Contact Us"} />
      <CommonEditor
        value={content}
        onChange={handleEditorChangeWithToast} // Use the updated change handler
        maxLength={MAX_LENGTH}
      />
      <div className="py-3">
        <button className="global_admin_btn" onClick={() => handleSubmit("Contact Us")}>
          Save
        </button>
      </div>
    </div>
  );
}

export default GulfFeatures;
