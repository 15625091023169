import React, { useState } from "react";
const _styles = {
  rating: {
    display: "flex",
    direction: "row",
  },

  star: {
    fontSize: "2rem",
    cursor: "pointer",
  },
};
const CustomRating = ({ ratingValue }) => {
  const [hover, setHover] = useState(0);
  return (
    <div style={_styles?.rating}>
      {Array(parseInt(ratingValue)).fill(
        <i
          style={{
            ..._styles.star,
            color:
              ratingValue <= (hover || ratingValue) ? "#ffc107" : "#e4e5e9",
          }}
          onMouseEnter={() => setHover(ratingValue)}
          onMouseLeave={() => setHover(0)}
        >
          ★
        </i>
      )}
    </div>
  );
};

export default CustomRating;
