import React from "react";
import Modal from "react-bootstrap/Modal";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./index.css";
import { Button } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
export default function DeleteWorkerModal({ open, setOpen, data }) {
  const { cartSelectedItem } = useSelector((state) => state.MANPOWERENQUIRY);
  const dispatch = useDispatch();
  // remove the worker from the selected list

  const removeWorker = () => {
    const newData = { ...cartSelectedItem };

    Object.keys(newData).forEach((elItem) => {
      const userList = newData[elItem]?.user || [];
      const index = userList.findIndex((idMatch) => idMatch?._id === data._id);

      if (index !== -1) {
        if (userList[index]?.checked) {
          newData[elItem] = {
            ...newData[elItem],
            user: [
              ...userList.slice(0, index),
              { ...userList[index], checked: false },
              ...userList.slice(index + 1),
            ],
          };
        }
      }
    });

    dispatch(manPowerEnquirySlice.actions.selectCartItem(newData));
    setOpen(false);
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Body>
        <RxCross1
          onClick={() => setOpen(false)}
          className="icon-position"
          size={20}
        />
        <div className="delete-modal">
          <RiDeleteBin5Line size={87} />
          <div>
            <span>Do you want to remove this worker from the cart?</span>
            <div className="d-flex justify-content-center mt-3">
              <Button
                onClick={() => setOpen(false)}
                className="delete-modal-no-btn"
              >
                No
              </Button>
              <Button
                onClick={() => removeWorker()}
                className="delete-modal-yes-btn"
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
