import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/dataTable/dataTable";
import { wePlacedEnquiryService } from "../../../services/admin/manageworkforce/manageWorkEnquiryServices";
import { FaTrash } from "react-icons/fa";
import { API_URL_ENDPOINT } from "../../../shared/apiUrl/apiUrlEndPoints";
import usePagination from "../../../hooks/usePagination";

const WeplacedContactUs = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { pagination, setPagination } = usePagination();
  const { currentPage, limit } = pagination;
  const handleRowClicked = (row) => {
    console.log("Clicked row:", row);
    // navigate(adminPathUrl.workforceDetail);
  };

  // Handle checkbox change event
  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };
  const userColumns = [
    // {
    //   name: "",
    //   selector: (row) => (
    //     <div className="profile-pic">
    //       <img src={image} alt="Profile" className="products-image" />
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async (currentPage, limit) => {
      const url = `${
        API_URL_ENDPOINT.wePlacedEnquiry
      }?is_weplaced=${true}&page=${currentPage}&limit=${limit}`;
      try {
        const res = await wePlacedEnquiryService(url);
        if (res.status) {
          setData(res?.data?.data);
          setPagination((prev) => {
            return {
              ...prev,
              totalPages: res?.data?.totalPages,
              totalCount: res?.data?.totalSupport,
            };
          });
        }
        console.log(res, "res");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(currentPage, limit);
  }, [currentPage]);
  return (
    <div className="contact-us-page">
      {/* <div className="search-bar-container mb-3">
        <input className="searchbar" type="search" placeholder="Search..." />
        <IoSearchSharp className="searchbar-icon" size={24} />
      </div> */}
      <div className="datatable-section">
        <DataTableComponent
          title="Contact Us"
          data={data}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          responsive={true}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      {/* <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this?"}
      /> */}
    </div>
  );
};

export default WeplacedContactUs;
