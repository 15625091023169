import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Tooltip = ({ id, content, place = "bottom", variant = "info" }) => {
  return (
    <ReactTooltip id={id} place={place} variant={variant} content={content} />
  );
};

export default Tooltip;
