import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import "bootstrap/dist/css/bootstrap.min.css";
import Rating from "react-rating-stars-component";
import { addnewreviews } from "../../../../services/admin/CmsPages/cmsPagesServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminPathUrl } from "../../../../shared/pathUrl";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  content: Yup.string().required("Content is required"),
  rating: Yup.number()
    .min(1, "Rating is required")
    .required("Rating is required"),
});

function AddReview() {
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    country: "",
    city: "",
    content: "",
    rating: 0,
  };
  const handleCancel = () => {
    navigate(adminPathUrl.manageReviews);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        country: values.country,
        city: values.city,
        rating: values.rating,
        review: values.content,
      };

      const response = await addnewreviews(payload);
      if (response.status === true) {
        toast.success("Review added successfully");
        navigate(-1);
      } else {
        toast.error("Failed to add review");
      }
    } catch (error) {
      console.error("Error adding review:", error);
      toast.error("Error adding review");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add Review"}  backButton={true}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <Field type="text" name="country" className="form-control" />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <Field type="text" name="city" className="form-control" />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <Field as="textarea" name="content" className="form-control" />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group d-flex align-items-center">
                  <label className="mb-0 me-2">Rating</label>
                  <Rating
                    count={5}
                    value={values.rating}
                    onChange={(newRating) => setFieldValue("rating", newRating)}
                    size={24}
                    activeColor="rgb(250, 175, 0)"
                  />
                </div>
                <ErrorMessage
                  name="rating"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="py-3">
              <button
                type="submit"
                className="global_admin_btn"
                disabled={isSubmitting}
              >
                Save
              </button>

              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddReview;
